import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ContentTab, AreaBtn, Button, AreaCheck, ButtonEdit, BorderBtn, InfoHelp, AreaBallon, ContentBallon, AreaSwitch, Switch } from './styles';
import BtnNovo from '../../Icons/BtnNovo';
import BtnImportar from '../../Icons/BtnImportar';
import BtnEditar from '../../Icons/BtnEditar';
import IconRemove from '../../Icons/IconRemove';
import api from '../../../../../services/api';
import Alert from '@material-ui/lab/Alert';
import { ModalContext } from '../../../../../contextModal';
import geocodingClient from '@mapbox/mapbox-sdk/services/geocoding';
import mbxClient from '@mapbox/mapbox-sdk';
import AlertDialog from '../../../../AlertDialog';
import { validarPermissao } from '../../../../../services/permission';
import { vozInfoEquipamentoLocal } from '../../../../Audio';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../../Balloons';
import icoInfo from '../../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../../assets/icons/icoSom.png';
import axios from 'axios';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Local({ moveInitialModal, moveModalStatus, statusCSV, setStatusCSV, id, setId, numeroAnel }) {

    const [id_cruzamento, setIdCruzamento] = useState('');
    const [longitude, setLongitute] = useState('');
    const [latitude, setLatitude] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [referencia, setReferencia] = useState('');
    const [anel, setAnel] = useState('');
    const [longitudeAnel1, setLongitudeAnel1] = useState('');
    const [latitudeAnel1, setLatitudeAnel1] = useState('');;
    const [unalterable, setUnalterable] = useState('disable');
    const [btnUnalterable, setBtnUnalterable] = useState('enable');
    const [idLocal, setIdLocal] = useState([]);
    const [infoId, setInfoId] = useState([]);
    const [dados, setDados] = useState([]);
    const [msgErro, setMgsErro] = useState('');
    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [typeRequest, setTypeRequest] = useState('save');
    const { coordenadasClickLocal, setCoordenadasClickLocal } = useContext(ModalContext);
    const { anelGlobal, setAnelGlobal } = useContext(ModalContext);


    async function handleRegisterLocal() {

        if ([id_cruzamento, anel, longitude, latitude, logradouro, referencia].includes('')) {
            setAlertMessage('Preencha todos os campos !');
            setTypeAlert('error');
            setAlertView('block');
        }

        else {

            if (dados.length == 0) {

                await api.post('/centraldefalhas/local', {
                    "id_cruzamento": id_cruzamento,
                    "anel": id_cruzamento + '_anel-0' + anel,
                    "logradouro": logradouro,
                    "referencia": referencia,
                    "latitude": latitude,
                    "longitude": longitude,
                    "latitudeAnterior": '',
                    "longitudeAnterior": '',
                    "type": typeRequest,
                }).then(res => {

                    reqInfos(id_cruzamento);
                    setAlertMessage('Dados salvos com sucesso!');
                    setTypeAlert('success');
                    setAlertView('block');
                    setBtnUnalterable('enable');


                }).catch(err => {

                    setAlertMessage(err.response.data.mensagem);
                    setTypeAlert('error');
                    setAlertView('block');

                });

            } else {
                var latitudeAnterior;
                var longitudeAnterior;

                dados.anel.forEach(item => {
                    if (item.anel.slice(-1) === anel) {
                        latitudeAnterior = item.latitude;
                        longitudeAnterior = item.longitude;
                    }
                });

                await api.post('/centraldefalhas/local', {
                    "id_cruzamento": id_cruzamento,
                    "anel": id_cruzamento + '_anel-0' + anel,
                    "logradouro": logradouro,
                    "referencia": referencia,
                    "latitude": latitude,
                    "longitude": longitude,
                    "latitudeAnterior": anel == 1 ? latitudeAnel1 : latitudeAnterior,
                    "longitudeAnterior": anel == 1 ? longitudeAnel1 : longitudeAnterior,
                    "type": typeRequest,
                }).then(res => {
                    //reqInfos(id_cruzamento); //chamar novamente a requisição para atualizar lat e long anterior quando o registro é o primeiro a ser cadastrado(????)
                    setAlertMessage('Dados salvos com sucesso!');
                    setTypeAlert('success');
                    setAlertView('block');
                    setBtnUnalterable('enable');

                }).catch(err => {

                    setAlertMessage(err.response.data.mensagem);
                    setTypeAlert('error');
                    setAlertView('block');

                });
            }
        }
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonLocal = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Local</h3>
                    <label onClick={vozInfoEquipamentoLocal}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nesta opção será armazenado todas as informações referentes ao controlador, aqui
                    nessa aba de "Local" será cadastrado o ID do cruzamento, local em exatidão, e o
                    ponto de referencia (destinado ao operador, com informações como posto de gasolina,
                    local para refeição etc.).
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const openModal = () => {
        setAlertOpen(true);
    }

    const closeModal = () => {
        setAlertOpen(false);
    }

    async function handleRemoveLocal() {

        await api.delete('/centraldefalhas/local/' + id_cruzamento).then(res => {
            setAlertMessage(`Local ${id_cruzamento} excluido`);
            setTypeAlert('success');
            setAlertView('block');
            enableData();
            setAlertOpen(false);

        }).catch(err => {
            setAlertMessage(err.response.data.mensagem);
            setTypeAlert('error');
            setAlertView('block');
        });
    }

    function insertEquipment(event) {
        moveModalStatus({ marginTop: '157vh', transition: '.1s' });
        const eventType = event.type; // => "click"

        if (eventType === "click") {
            localStorage.setItem('awaitNextClick', 'true');
            localStorage.setItem('longitude', '');
            localStorage.setItem('latitude', '');

            document.getElementById("iconRegisterController").style.background = '#605a83';
            toast.info(`Clique no mapa nas coordenadas que deseja cadastrar o novo Local`, {
                toastId: `cadastroDePonto`,
                position: "top-center",
                hideProgressBar: false,
                closeOnClick: false,
                draggable: true,
                progress: undefined,
                autoClose: false
            });
        }

        setInfoId({ longitude: localStorage.getItem('longitude'), latitude: localStorage.getItem('latitude') });
        setLongitute(localStorage.getItem('longitude'));
        setLatitude(localStorage.getItem('latitude'));
    }

    const listData = async () => {
        const response = await api.get('/CentralDeFalhas/local/Lista');
        setIdLocal(response.data);
    }

    const setouCoordenadasLocal = () => {
        moveModalStatus({ marginTop: '0vh', transition: '.1s' });
        //armazena coordenadas
        setInfoId({ longitude: localStorage.getItem('longitude'), latitude: localStorage.getItem('latitude') });
        const longitude = localStorage.getItem('longitude');
        const latitude = localStorage.getItem('latitude');
        setLongitute(longitude);
        setLatitude(latitude);

        //pesquisa logradouro
        verificarLogradouro(longitude, latitude);

        toast.update(`cadastroDePonto`, {
            toastId: `cadastroDePonto`,
            position: "top-center",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
        });
    }

    const reqInfos = async (id) => {

        setAlertView('none');
        setId(id);

        try {
            const response = await api.get(`/CentralDeFalhas/Local/${id}`);
            setInfoId(response.data.local);
            setIdCruzamento(response.data.local.id_cruzamento);
            setLongitute(response.data.local.longitude);
            setLatitude(response.data.local.latitude);
            setLongitudeAnel1(response.data.local.longitude);
            setLatitudeAnel1(response.data.local.latitude);
            setLogradouro(response.data.local.logradouro);
            setReferencia(response.data.local.referencia);
            setAnel('1');

            if (numeroAnel !== '1') {
                response.data.anel.forEach(item => {
                    if (item.identificador === anelGlobal) {
                        setIdCruzamento(item.id_cruzamento);
                        setLongitute(item.longitude);
                        setLatitude(item.latitude);
                        setLongitudeAnel1(item.longitude);
                        setLatitudeAnel1(item.latitude);
                        setLogradouro(item.logradouro);
                        setReferencia(item.referencia);
                        setAnel(item.identificador);
                    }
                })
            } else {
                setIdCruzamento(response.data.local.id_cruzamento);
                setLongitute(response.data.local.longitude);
                setLatitude(response.data.local.latitude);
                setLongitudeAnel1(response.data.local.longitude);
                setLatitudeAnel1(response.data.local.latitude);
                setLogradouro(response.data.local.logradouro);
                setReferencia(response.data.local.referencia);
                setAnel('1');
            }
            setDados(response.data);
            setUnalterable('enable');
            setAnel(numeroAnel)
        } catch (error) {
        }
    }

    const listarAneis = () => {

        console.log('dados', dados)

        if (dados.length > 0) {
            console.log(dados.anel.length)
        }

        if (anel === '1') {
            if (dados.local == undefined) {
                return;

            } else {
                setIdCruzamento(dados.local.id_cruzamento);
                setLongitute(dados.local.longitude);
                setLatitude(dados.local.latitude);
                setLongitudeAnel1(dados.local.longitude);
                setLatitudeAnel1(dados.local.latitude);
                setLogradouro(dados.local.logradouro);
                setReferencia(dados.local.referencia);
                setAnel('1');
            }

        } else {
            console.log('caiu no else')
            setLongitute('');
            setLatitude('');
            setLogradouro('');
            if (dados.anel === undefined) {
                console.log('caiu no undefined')
                return;
            } else {
                dados.anel.forEach(item => {
                    if (item.identificador === anel) {
                        setIdCruzamento(item.id_cruzamento);
                        setLongitute(item.longitude);
                        setLatitude(item.latitude);
                        setLongitudeAnel1(item.longitude);
                        setLatitudeAnel1(item.latitude);
                        setLogradouro(item.logradouro);
                        setReferencia(item.referencia);
                        setAnel(item.identificador);
                    } else {
                        setLongitute('');
                        setLatitude('');
                        setLogradouro('');
                    }
                })
            }
        }
    }

    const enableData = () => {
        setUnalterable('enable');
        //limpar requisição
        setInfoId('');
        //limpar campos:
        setIdCruzamento('');
        setLongitute('');
        setLatitude('');
        setLogradouro('');
        setReferencia('');
        setBtnUnalterable('disable');
    }

    const openCardCSV = () => {
        setStatusCSV(true);
    }

    useEffect(() => {

        if (coordenadasClickLocal == 'true') {
            setouCoordenadasLocal();
        }

        setCoordenadasClickLocal('false');

    }, [coordenadasClickLocal]);

    useEffect(() => {
        reqInfos(id);
        if (id.length > 0) {
            setTypeRequest('update');
        } else {
            setTypeRequest('save');
        }
    }, [id]);

    useEffect(() => {
        listarAneis();
    }, [anel]);

    const verificarLogradouro = async (longitude, latitude) => {

		console.log('trocar token - chamou verificar Logradouro')

        if (typeof longitude !== 'undefined' && typeof latitude !== 'undefined' && longitude !== "" && latitude !== "") {
            /*const longitudeConvertida = parseFloat(longitude);
            const latitudeConvertida = parseFloat(latitude);

            const baseClient = mbxClient({ accessToken: 'pk.eyJ1IjoidmluaWNpdXNndyIsImEiOiJjbGRhNmc0azcwNW1oM25wazFxcHRob3NtIn0.17CN4GHd_uQJur5A5Wxshg' });
            const mbxGeocodingService = geocodingClient(baseClient);

            mbxGeocodingService.reverseGeocode({
                query: [longitudeConvertida, latitudeConvertida],
                mode: "mapbox.places",
                types: ["address"]
            }).send().then(response => {
                const features = response.body.features;

                if (features != '') {
                    const logradouro = features[0].place_name
                    setInfoId({ logradouro: logradouro });
                    setLogradouro(logradouro)
                }
            }).catch(err =>  setLogradouro('Sem acesso no momento...'));*/

			await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=81c50dac012a45e2814cca9629173d0b`)
			
				.then(res => {
					console.log(res.data.results[0])
					const logradouro = res.data.results[0].formatted
					setInfoId({ logradouro: logradouro });
					setLogradouro(logradouro)
				}).catch(err =>  setLogradouro('Sem acesso no momento...'))
        }
    }

    const verificaCaracter = (e) => {
        if (e.key === '/') {
            setAlertMessage('Caractere inválido " / "!');
            setTypeAlert('error');
            setAlertView('block');
            setTimeout(() => {
                setAlertView('none');
            }, 10000);
        }
        /*if (e.key === '-') {
            setAlertMessage('Caractere inválido " - (traço)"!');
            setTypeAlert('error');
            setAlertView('block');
            setTimeout(() => {
                setAlertView('none');
            }, 10000);
        }*/
    }

    const removeBadCharacters = (text) => {
        let textValidated = '';
        textValidated = text.replace('/', '').trim();
        //textValidated = textValidated.replace('-', '').trim();
        setIdCruzamento(textValidated);
    }

    return (
        <ContentTab>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonLocal /></BallonsInfoModal>
            </AreaBallon>
            <fieldset>
                <legend>ID do local</legend>
                <select name="" id="" onClick={listData} onChange={(e) => reqInfos(e.target.value)}>
                    <option value={id}>{id}</option>
                    {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(item => (
                        <option key={item.id_cruzamento} defaultValue={item.id_cruzamento}>{item.id_cruzamento}</option>
                    ))}
                </select>
                <div className={permissao}>
                    <Button id="btnNovo" className={btnUnalterable} onClick={enableData}>
                        <BtnNovo />NOVO
                    </Button>
                </div>
            </fieldset>
            <fieldset className={unalterable}>
                <legend>Dados</legend>
                <form>
                    <div className='row'>
                        <div className="group">
                            <label>Licença:</label>
                            <select name="" id="" onClick={listData} onChange={(e) => reqInfos(e.target.value)} disabled>
                                <option value="">Selecione...</option>
                                {idLocal.map(item => (
                                    <option key={item.id_cruzamento} defaultValue={item.id_cruzamento}>{item.id_cruzamento}</option>
                                ))}
                            </select>
                        </div>
                        <Button className="btnImportar" onClick={openCardCSV}>
                            IMPORTAR
                        </Button>
                    </div>
                    <div className='row'>
                        <div className="group">
                            <label>ID do local:</label>
                            <input
                                type="text"
                                defaultValue={infoId.id_cruzamento}
                                value={id_cruzamento}
                                onChange={(e) => removeBadCharacters(e.target.value)}
                                onBlur={(e) => reqInfos(e.target.value.trim())}
                                onKeyPress={verificaCaracter}
                                required
                            />
                        </div>
                        <div className="group">
                            <label>Anel:</label>
                            <select name="" id="anel" onChange={(e) => setAnel(e.target.value)}>
                                <option value={anel}>
                                    {
                                        anel === '' ? 'Selecione...' :
                                            anel === undefined ? 'Anel 1' :
                                                'Anel ' + anel}
                                </option>
                                {Array(4).fill(1).map((items, index) => {
                                    if ('Anel ' + anel !== `Anel ${index + 1}`) {
                                        return (
                                            <option value={index + 1}>Anel {index + 1}</option>
                                        );
                                    } else {
                                        return null
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row" style={{ display: 'none' }}>{/*oculto até criar funcionalidades*/}
                        <div className="group">
                            <label>Grupos de monitoramento:</label>
                            <AreaCheck>
                                <div className="box">
                                    <label>G1</label>
                                    <input type="checkbox" />
                                </div>

                                <div className="box">
                                    <label>G1</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G2</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G3</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G4</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G5</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G6</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G7</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G8</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G9</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G10</label>
                                    <input type="checkbox" />
                                </div>
                                <div className="box">
                                    <label>G11</label>
                                    <input type="checkbox" />
                                </div>
                            </AreaCheck>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group">
                            <label>Ponto de referência:</label>
                            <input type="text" defaultValue={infoId.referencia} value={referencia} onChange={(e) => setReferencia(e.target.value)} required />
                        </div>
                    </div>
                    <div className='row' id="local">
                        <div className="group column">
                            <label>Longitude:</label>
                            <div className="areaInput">
                                <input type="text" defaultValue={infoId.longitude} value={longitude} onChange={(e) => setLongitute(e.target.value)} required />
                                <ButtonEdit className="btnEditar" onClick={insertEquipment} >
                                    <BtnEditar />
                                </ButtonEdit>
                            </div>
                        </div>
                        <div className="group column">
                            <label>Latitude:</label>
                            <div className="areaInput">
                                <input type="text" defaultValue={infoId.latitude} value={latitude} onChange={(e) => setLatitude(e.target.value)} required />
                                <ButtonEdit className="btnEditar" onClick={insertEquipment}>
                                    <BtnEditar />
                                </ButtonEdit>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group">
                            <label>Logradouro:</label>
                            <input type="text" defaultValue={infoId.logradouro} value={logradouro} onChange={(e) => setLogradouro(e.target.value)} required disabled={true} />
                        </div>
                    </div>
                </form>
            </fieldset>
            <AreaBtn view={alertView} className={permissao}>
                <div className="alert">
                    <Alert severity={typeAlert}>{alertMessage === '' ? '' : alertMessage}</Alert>
                </div>
                <div className="buttons">
                    <BorderBtn className={permissao === 'disabled' ? '' : unalterable} title='Excluir Local' onClick={openModal} onClose={closeModal}>
                        <IconRemove />
                    </BorderBtn>
                    <Button title='Salvar informações' width="92" heigth="41" className="btnSalvar" onClick={handleRegisterLocal}>SALVAR</Button>
                </div>
            </AreaBtn>
            <AlertDialog open={alertOpen} onClick={handleRemoveLocal} onClose={closeModal}>
                Deseja realmente excluir o local?
            </AlertDialog>
        </ContentTab>
    );
}


