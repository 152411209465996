import styled from 'styled-components';

export const Container = styled.section`
    overflow-y: scroll;
    height: 100vh;

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #717171;
		}
	}

    h1{
        font-family: 'Roboto',sans-serif;
        margin: 0 15px 0 5px;
        font-size: 18px;
        font-weight: 900;
        user-select: none; 
    }
`;

export const Screen = styled.div`
    width: 60%;
    height: auto;
    margin: 20px 40px;
    position: relative;

    @media(max-width: 1280px){
        margin: -10px 40px;
        width: 90%;
    }

    fieldset{
        border: 1px solid silver;
        border-radius: 3px;

        @media(max-width: 1280px){
            height: 70vh;
            overflow: hidden;
        }
    }

    legend{
        font-size: 12px;
        font-family: 'roboto';
        color: #737070;
        user-select: none;
    }

    #show{
        display: block;
    }

    #hidden{
        display:none;
    }
`;

export const AreaCamera = styled.div`
    display: flex;

    @media(max-width: 950px){
        flex-direction: column-reverse;
        align-items: center;
    }

    #hidden{
        display: none;
        border: 1px solid red;
    }
    #show{
        display:block;
    }

    .calendario{
        height: auto;
        max-height: 302px;
        margin: 47px auto;
        border-radius: 3px;
        user-select: none;

        .react-calendar__navigation {
            background: #062467;
            color: #FFF !important;
        }
        .react-calendar{
            &__tile{
                &--active {
                    background: #006edc;
                    color: white;
                    border-radius: 50px;
                }
                &--now {
                    background: #0C9410;
                }
            }
        }

        span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
            color: #FFF;
            font-weight: 700;   
        }

        .react-calendar__navigation button:enabled:hover, 
        .react-calendar__navigation button:enabled:focus {
            background-color: #5d75ad;
        }

        button{

            &.react-calendar__navigation__arrow.react-calendar__navigation{
                &__prev-button {
                    color: #FFF;
                }
                &__next-button {
                    color: #FFF;
                }
                &__next2-button {
                    color: #FFF;
                }
                &__prev2-button {
                    color: #FFF;
                }
            }
            &.react-calendar__tile.react-calendar__tile--now.react-calendar{
                &__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
                border-radius: 50px;
                background: #062467;
                }
            }
            &.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
                border-radius: 50px;
            }
        }
    }
`;

export const Tabs = styled.ul`
    position: relative;
    margin-top: 70px;
    display: flex;
    align-items: center;
   

    @media(max-width: 950px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    @media(max-width: 420px){
        padding: 0;
    }

    fieldset{
        width: 350px;
        height: 40px;
        border: 1px solid silver;
        border-radius: 3px;
       
        @media(max-width: 570px){
            width: 320px;
        }
        
        legend{
            font-size: 12px;
            font-family: 'roboto', sans-serif;
            color: #737070;
        }
        select{
            width: 100%;
            height: 25px;
            border: 1px solid silver;
            border-radius: 2px;
            color: #424040;
        }
    }

    ul{
        display: flex;
        align-items: center;
        position: relative;
        @media(max-width: 920px){
            margin-top: 20px;
        }
        @media(max-width: 480px){
            padding: 0 !important;
        }

        &:before {
            content: '';
            width: 151px;
            height: 3px;
            background: #062467;
            position: absolute;
            bottom: -5px;
            left: ${props => props.position + 'px'};
            transition: .3s ease-out;

            @media(max-width: 950px){
                bottom: -5px;
                width: 130px;
                left: ${props => props.position === '41' ? '50px' :
                    props => props.position === '190' ? '198px' :
                    props => props.position === '340' ? '348px' : ''
                };
            }
            @media(max-width: 716px){
                bottom: -5px;
                width: 130px;
                left: ${props => props.position === '41' ? '49px' :
                    props => props.position === '190' ? '199px' :
                    props => props.position === '340' ? '350px' : ''
                };
            }
            @media(max-width: 420px){
                bottom: -5px;
                width: 92px;
                left: ${props => props.position === '41' ? '8px' :
                    props => props.position === '190' ? '116px' :
                    props => props.position === '340' ? '220px' : ''
                };

                //display: none;
            }
        }
    }
    
    li{
        list-style: none;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        color: #5f5d5d;
        height: 23px;
        font-size: 14px;
        user-select: none;

        @media(max-width: 420px){
            font-size: 12px;
            width: 27vw;
        }

        &#inactive{
            font-weight: normal;
        }

        &#active{
            transition: .3s ease-out;
            color: #001E62;
            font-weight: 900;
            letter-spacing: .5px;
        }
    }
`;

/*export const AreaUnderlined = styled.div`
    display: none;
    @media(max-width: 420px){
        display: block;
        width: 80vw;
        display: flex;
        align-items: center;
        justify-content: ${props => props.position === '353' ? 'left' :
            props.position === '505' ? 'center' : props.position === '654' ? 'end' : ''
        };
    }
    
`;*/

export const Underlined = styled.div`
    width: 25vw;
    height: 2px;
    background: #001E62;
`;

export const Options = styled.div`
    @media(max-width: 1280px){
        margin: -50px 22px 0 0;
    }
    @media(max-width: 480px){
        margin: 0;
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 30px;

   @media(max-width: 480px){
        display: flex;
        gap: 10px;
        margin-bottom: 30px;
        justify-content: center;
        align-items: center;
   }
`;

export const Button = styled.div`
    border: 1px solid #001E62;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    cursor: pointer;

    //temporario
    //pointer-events: none;
    //opacity: 0.4;

    &:active{
        position: relative;
        top: 1px;
    }

    svg {
        width: 23px;
        height: 23px;
    }
`;


export const BtnPhoto = styled.div`
    background: #001E62;
    color: #FFF;
    font-family: 'roboto', sans-serif;
    font-size: 14px;
    width: 153px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    border-radius: 32px;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;

    @media(max-width: 480px){
        width: 120px;
        font-size: 11px;
    }

    &:active{
        position: relative;
        top:1px;
    }

    svg {
        width: 19px;
        height: 19px;
        margin-top: -5px;
    }

    &.disabled{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enabled{
        pointer-events: auto;
        opacity: 1;
    }
`;



