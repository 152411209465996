import React from 'react';

export default function IconBloqueado(){
    return(
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M10 13.125V11.875C10 11.212 9.73661 10.5761 9.26777 10.1072C8.79893 9.63839 8.16304 9.375 7.5 9.375H3.125C2.46196 9.375 1.82607 9.63839 1.35723 10.1072C0.888392 10.5761 0.625 11.212 0.625 11.875V13.125" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.3125 6.875C6.69321 6.875 7.8125 5.75571 7.8125 4.375C7.8125 2.99429 6.69321 1.875 5.3125 1.875C3.93179 1.875 2.8125 2.99429 2.8125 4.375C2.8125 5.75571 3.93179 6.875 5.3125 6.875Z" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.25 5L14.375 8.125" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.375 5L11.25 8.125" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}