import React, { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import { Marker, Map, TileLayer, Polygon, Tooltip, Circle } from 'react-leaflet';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EyeOff, Eye, Bell, BellOff, Volume2, VolumeX } from "react-feather";
import MenuItem from '../MenuItem';
import MenuHorizontal from '../MenuHorizontal';
import FilterControllers from '../FilterControllers';
import Quicklog from '../Quicklog';
import DashBoard from '../Dashboard';
import api from '../../services/api';
import DetailRoute from "../DetailRoute";
import DetailNameRoute from "../DetailNameRoute";
import RegisterControllers from '../Registers/RegisterControllers';
import ReferenceController, { Intersection } from '../ReferenceController';
import { FaDrawPolygon } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { MdZoomOutMap } from "react-icons/md";
import "./styles.css";
import io from "socket.io-client";

let infRoutes = [];
let routesId = [];
let areaId = [];
let contError = 0;

const falha = "Falha";
const id = "ID";
const rotas = "Rotas";
const area = "Área / Subárea";
const modelo = "Modelo";
const statusDoor = "aberta";
const offiline = "Offline";
const filterOpen = localStorage.getItem('filterOpen');
const valueFilter = localStorage.getItem('valueFilterControllers');
const ModoFiltro = localStorage.getItem('ModoFiltro');


const dark = 'https://api.mapbox.com/styles/v1/greenwaveind/cl2opdwlw003i14mn6jqdf6hs/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg';
const trafego = 'https://api.mapbox.com/styles/v1/greenwaveind/cky1okhew0lbh14qwn8von0rm/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg';

const diurno = 'https://api.mapbox.com/styles/v1/greenwaveind/cky0ijwcd0iny15qtxqjms57q/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg';

const padrao = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
const claro = 'https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}@2x.png?key=tglGFiClEY1lXDkbfubW';
const dark2 = 'https://api.mapbox.com/styles/v1/greenwaveind/cky1es3ct0bun14qw52k1g5ns/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg'
const satelite = 'https://api.mapbox.com/styles/v1/greenwaveind/cky1e2ejy0gf815nzkgj1m7x7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg';
const destacado = 'https://api.mapbox.com/styles/v1/greenwaveind/cky0izhehakyd15pec8tgh7jq/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZ3JlZW53YXZlaW5kIiwiYSI6ImNsMm9tNzM1bTB0cnAzZmx4d2NtdDA5bWYifQ.ahBY1oewEaLQHwIerTpYyg'
const modo = localStorage.getItem('modoMapa');


const socket = io(process.env.REACT_APP_SERVER_HOST + "/", {
    query: { token: `Bearer ${localStorage.getItem('@central-de-falhas-greenwave/tokenAuth')}` }
});

function Mapas(props) {

    const [openRegisterController, setOpenRegisterController] = useState(false);
    const [zoom, setZoom] = useState(parseInt(localStorage.getItem('@central-de-falhas-greenwave/zoom')));
    const [viewId, setViewId] = useState({ view: 'false' });
    const [infoRoutes, setInfoRoutes] = useState([]);
    const [intersections, setIntersections] = useState([]);
    const [dadosEstatisticas, setDadosEstatisticas] = useState([]);
    const [newIntersection, setNewIntersection] = useState([]);
    const [openLogs, setOpenLogs] = useState(false);
    const [idForLogs, setIdForLogs] = useState('');
    const [typeFilter, setTypeFilter] = useState(localStorage.getItem('typeFilterControllers'));
    const [timeReload, setTimeReload] = useState(localStorage.getItem('@central-de-falhas-greenwave/setTimeReload'))
    const [lat, setLat] = useState(localStorage.getItem('@central-de-falhas-greenwave/positionLat'));
    const [lng, setLng] = useState(localStorage.getItem('@central-de-falhas-greenwave/positionLng'));
    const [longitudePosition, setLongitudePosition] = useState(0);
    const [latitudePosition, setLatitudePosition] = useState(0);
    const [iconButtonAlert, setIconButtonAlert] = useState(<Bell size={35} stroke-width={4} color='#fff' />);
    const [iconButtonSom, setIconButtonSom] = useState(true);
    const [textButtonAlert, setTextButtonAlert] = useState('Ocultar alarme de notificação');
    const [textButtonSom, setTextButtonSom] = useState('Desativar alarme sonoro');
    const [textButtonPolygon, setTextButtonPolygon] = useState('Marcar polygono por controlador');
    const [textButtonRede, setTextButtonRede] = useState('Mostar sinal de Wi-fi');
    const [retreatModals, setRetreatModals] = useState(true);
    const [multiPolygon, setMultiPolygon] = useState([]);
    const [viewPolygon, setViewPolygon] = useState('hidden');
    const [viewRede, setViewRede] = useState('hidden');
    const [raioArea, setRaioArea] = useState(0);
    const [mediaLatitude, setMediaLatitude] = useState(0);
    const [mediaLongitude, setMediaLongitude] = useState(0);
    const [getAltMoni, setGetAltMoni] = useState(0);
    const { permissionRequest, setPermissionRequest } = useContext(ModalContext);
    const { setCoordenadasClickLocal } = useContext(ModalContext);
    const { setInactiveTime } = useContext(ModalContext);
    const { setModalMove } = useContext(ModalContext);
    const { cursor, setCursor } = useContext(ModalContext);
    const { somEnabled, setSomEnabled } = useContext(ModalContext);
    const { setLengthRoutesFilter } = useContext(ModalContext);

    const [url, setUrl] = useState(
       /* modo === 'dark' ? dark :
            modo === 'satelite' ? satelite :
                modo === 'padrao' ? padrao :
                    modo === 'diurno' ? diurno :
                        modo === 'claro' ? claro :
                            modo === 'dark2' ? dark2 :
                                modo === 'trafego' ? trafego : destacado*/

								///modo === 'padrao' ? padrao : claro
								padrao
								
    );
    const history = useHistory();

    const moveCenter = (lat, lng) => {
        // move posição do equipamento para o centro da tela ao clicar no alerta
        setZoom('18');
        setLat(`${lat}`);
        setLng(`${lng}`);
    }

    const moveCenterFilter = () => {
        if (!typeFilter) {
            return
        } else {
            setTimeout(() => {

                if (localStorage.getItem('moveLatitude') == null || localStorage.getItem('moveLongitude') == null) {
                    setLat(localStorage.getItem('@central-de-falhas-greenwave/positionLat'));
                    setLng(localStorage.getItem('@central-de-falhas-greenwave/positionLng'));
                } else {
                    setLat(localStorage.getItem('moveLatitude'));
                    setLng(localStorage.getItem('moveLongitude'));
                }

            }, 300);
        }
    }
    let idTimerDadosMapa = null;

    async function solicitarDadosMapa() {//determino o tempo que vai ser feito as requisicoes para atualizar as informaçoes dos controladores no mapa
        await api.get('/centraldefalhas/intersection/list/all').then(res => {

            if (localStorage.getItem('tocarAudio') !== 'ativo') { // verifica se o audio esta tocando, caso esteja ativo executa o else chamando o activePlayAudio
                if (res.status === 200) {

                    setIntersections(res.data.locaisMapa);
                    setDadosEstatisticas(res.data.estatistica);
                    qtdCharts(res.data.locaisMapa);
                    getLocation();

                    const locais = [...multiPolygon];
                    res.data.locaisMapa.forEach(items => {
                        locais.push(items.multiPolygon);
                    });
                    setMultiPolygon(locais);

                } else {
                    window.location.href = "/";
                }
            } else {
                activePlayAudio()
            }

            if (res.status === 401) {
                console.log('caiu no 401')
            }

        }).catch(error => {
            if (error.message === ' Token invalid') {
                console.log('Desconexão por tempo de inatividade!'); // chamar função qnd ficar com tempo de inatividade      
                setInactiveTime('block');
                history.push('/');
                clearInterval(idTimerDadosMapa); // limpa o intervalo criado na linha 165 setInterval(solicitarDadosMapa, parseFloat(timeReload));
            }
        });
    }

    async function searcheIntersections() {//list all intersections
        getControllersRoute();
        getControllersArea();
        const res = await api.get('/centraldefalhas/intersection/list/all'); //Alteração Middleware
        setIntersections(res.data.locaisMapa);
        setDadosEstatisticas(res.data.estatistica);
        qtdCharts(res.data.locaisMapa);
        idTimerDadosMapa = setInterval(solicitarDadosMapa, parseFloat(timeReload));
        moveCenterFilter();
        setTimeReload(localStorage.getItem('@central-de-falhas-greenwave/setTimeReload'));//salvo valor de atualizacao do mapa
    }

    const activePlayAudio = () => { //Essa função é responsavel pelo reinicio da requisição
        setTimeout(() => solicitarDadosMapa, 16000); // tempo de gap entre a execução do audio e uma nova solicitação de dados
    }
    //pegar info rotas
    async function getControllersRoute() {
        await api.post('/centraldefalhas/routes/get', {
            "rota": localStorage.getItem('valueFilterControllers')
        }).then(res => {
            if (typeof res.data.error != 'undefined') {
                return
            }
            setInfoRoutes(res.data[0].infos.reverse());
            res.data[0].infos.map(d => {
                routesId.push(d.id_cruzamento);
                infRoutes.push({ id: `${d.id_cruzamento}`, anel: `${d.anel}`, grupo: `${d.grupo}` });
            });
            setLengthRoutesFilter(res.data[0].total);
        }).catch(error => {
            console.log(error.message);
        });
    }

    async function getControllersArea() {
        await api.post('/centraldefalhas/zones/get', {
            "area": localStorage.getItem('valueFilterControllers')
        }).then(res => {
            if (typeof res.data.error != 'undefined') {
                return
            }

            res.data[0].infos.map(d => {
                areaId.push(d.id_cruzamento);
            });
            setRaioArea(res.data[1].mediaFinal); // pegar o raio da area, calculo realizado no back-end
            setMediaLatitude(res.data[2].mediaLatitude); // pegar a média de latitude, calculo realizado no back-end
            setMediaLongitude(res.data[3].mediaLongitude); // pegar a média de longitude, calculo realizado no back-endw
        }).catch(error => {
            console.log(error);
        });
    }

    function qtdCharts(data) {
        let contNormal = 0;
        let contFalha = 0;
        let contOffiline = 0;
        for (let i = 0; i <= data.length - 1; i++) {
            if (data[i].modo_operacao === "Normal") {
                contNormal++;
            } else if (data[i].modo_operacao === "Offline") {
                contOffiline++;
            } else {
                contFalha++;
            };
        };
        localStorage.setItem('normais', '' + contNormal);
        localStorage.setItem('falhas', '' + contFalha);
        localStorage.setItem('offiline', '' + contOffiline);
    }

    //-------------------------click--------------------------------------------------

    function handleClick(e) {
        //verifica se esta disponivel o click do cadastro de controlador ou centro do mapa se true abre o form de cadastro com latlng salvas de onde clicou

        // document.getElementById("div-id-grafic").style.display = 'none';//tirar grafico da tela
        const openRegisterTrue = localStorage.getItem('awaitNextClick');//boolean
        const setCenterMap = localStorage.getItem('awaitNextClickConfCenterMap');//boolean
        if (openRegisterTrue === "true") {
            localStorage.setItem('latitude', e.latlng.lat);
            localStorage.setItem('longitude', e.latlng.lng);
            localStorage.setItem('awaitNextClick', 'false');
            setCoordenadasClickLocal('true');
            //subir modal
        } else if (setCenterMap === "true") {
            localStorage.setItem('latitude', e.latlng.lat);
            localStorage.setItem('longitude', e.latlng.lng);
            localStorage.setItem('@central-de-falhas-greenwave/positionLat', localStorage.getItem('latitude'));
            localStorage.setItem('@central-de-falhas-greenwave/positionLng', localStorage.getItem('longitude'));
            confCenterMap();
        }
    }

    const confCenterMap = async () => {
        //Envia novo centro do mapa

        await api.post('/centraldefalhas/params_user', {
            "lat": localStorage.getItem('latitude'),
            "long": localStorage.getItem('longitude'),
            "zoom": zoom,
            "timerMap": localStorage.getItem('@central-de-falhas-greenwave/setTimeReload')
        }).then(res => {
            setCursor({});
            setModalMove({ marginTop: '0', transition: '.4s' });
            //icon: ({theme, type}) =>  <img src={teste}/>,
            toast.info(`Redefinido centro do mapa!`, {
                icon: "🚀",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                onClose: () => {
                    localStorage.setItem('awaitNextClickConfCenterMap', 'false');
                }
            });
        }).catch(err => {
            console.log(err)
            return err;
        })
    }

    const handleCloseRegisterController = () => {
        setOpenRegisterController(false);
        document.getElementById("iconRegisterController").style.background = '';
        localStorage.setItem('awaitNextClick', 'false');
        setCoordenadasClickLocal('true');
    };

    //++++++++++++++++++ Pedir monitoramento ++++++++++++++++++++

    var idAnel = '';

    const authMoniGroup = (id) => {
        // Para solicitar monitoramento do controlador
        const getSocket = async () => {
            await api.get(`CentralDeFalhas/getmoni?id_cruzamento=${id}`)
                .then(res => {
                    setPermissionRequest(true);
                })
                .catch(err => console.log(err.message))
        }

        if (permissionRequest === false) {
            //console.log('chamou requisição')
            getSocket();
        } else {
            return;
        }
    }

    const callRequestEvery5seconds = () => {
        setTimeout(() => {
            setGetAltMoni(getAltMoni + 1);
        }, 5000);
    }

    const callRequestEvery5minutes = () => {
        setTimeout(() => {
            setGetAltMoni(getAltMoni + 1);
        }, 300000);
    }

    //a cada 5 min vai mudar o valor de getAltMoni e requisitar novamento o monitoramento
    useEffect(() => {
        setPermissionRequest(false);
        authMoniGroup(idAnel);
        if (typeFilter === 'Rotas' && getAltMoni <= 1) {
            callRequestEvery5seconds();
        }
        if (typeFilter === 'Rotas' && getAltMoni > 1) {
            callRequestEvery5minutes();
        }
    }, [getAltMoni]);

    //------------------------------------------------------------

    const viewIds = (e) => {
        //Torna visivel o id do equipamento no mapa
        if (viewId.view !== 'true') {
            setViewId({ view: 'true' });
        } else {
            setViewId({ view: 'false' });
        }
    }

    const iconButtonViewsId = () => {
        // Altera o icone do viewId
        if (viewId.view === 'true') {
            return <Eye size={35} stroke-width={4} color='#fff' />
        } else {
            return <EyeOff size={35} stroke-width={4} color='#fff' />
        }
    }

    const DefineViewsAlert = () => {
        // Altera o icone de visualizção de alerta
        try {
            const conteinerToast = document.getElementsByClassName('Toastify__toast-container Toastify__toast-container--bottom-right toast-container');

            const Alertas = localStorage.getItem('Alertas');

            if (Alertas === 'false') {
                conteinerToast[0].style.display = 'block'
                setIconButtonAlert(<Bell title={textButtonAlert} size={35} stroke-width={4} color='#fff' />);
                setTextButtonAlert('Ocultar alarme de notificação');
                localStorage.setItem('Alertas', JSON.stringify(true));
            } else {
                conteinerToast[0].style.display = 'none'
                setIconButtonAlert(<BellOff title={textButtonAlert} size={35} stroke-width={4} color='#fff' />);
                setTextButtonAlert('Ativar alarme de notificação');
                localStorage.setItem('Alertas', JSON.stringify(false));
            }

        } catch (error) {
            console.log("nao tem toast para retirar da tela");
        }
    }

    const DefineSomAlert = () => {
        if (somEnabled) {
            setSomEnabled(false);
            setTextButtonSom('Ativar alarme sonoro')
        } else {
            setSomEnabled(true);
            setTextButtonSom('Desativar alarme sonoro')
        }
    }

    const alertDefect = () => {
        intersections.map((intersection) => {
            //verifica se tem algum controlador em falhar
            if (intersection.modo_operacao !== offiline) {
                if (intersection.descricao_falha !== "Normal" || (intersection.porta === statusDoor)) {
                    contError += 1;
                }
            }
        })
    }

    const getIdForLogs = (id) => {
        setIdForLogs(id);
        setOpenLogs(true);
    }

    const handleCloseLogs = () => {
        setOpenLogs(false);
    };

    window.onload = searcheIntersections;



    const trocarMapa = (mapa) => {
        if (mapa === "satelite") {
            setUrl(satelite);
        } else if (mapa === "padrao") {
            setUrl(padrao);
        } else if (mapa === 'diurno') {
            setUrl(diurno)
        } else if (mapa === 'claro') {
            setUrl(claro)
        } else if (mapa === 'dark') {
            setUrl(dark);
        } else if (mapa === 'dark2') {
            setUrl(dark2);
        } else if (mapa === 'trafego') {
            setUrl(trafego);
        } else {
            setUrl(destacado);
        }
    }

    function getLocation() {
        //pegar a posição aproximada 
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        //seta a latitude e longitude da minha posição
        setLongitudePosition(position.coords.longitude);
        setLatitudePosition(position.coords.latitude);
    }

    function toggleFullScreen() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    //

    useEffect(() => { setNewIntersection(intersections) }, [intersections]);

    const enabledPolygon = () => {
        if (viewPolygon === 'view') {
            setViewPolygon('hidden');
            setTextButtonPolygon('Marcar polygono por controlador');
        } else {
            setViewPolygon('view');
            setTextButtonPolygon('Ocultar poligonos dos controladores');
        }
    }

    const visibleWifi = () => {
        if (viewRede === 'view') {
            setViewRede('hidden');
            setTextButtonRede('Mostrar sinal de rede');
        } else {
            setViewRede('view');
            setTextButtonRede('Ocultar sinal de rede');
        }
    }

    //usar para Polygon
    const purpleOptions = { color: 'purple' }

    const zoomMais = () => {
        if (zoom <= 22) {
            setZoom(zoom + 1);
        } else {
            return;
        }
    }

    const zoomMenos = () => {
        if (zoom >= 0) {
            setZoom(zoom - 1);
        } else {
            return;
        }
    }

    return (
        <div className='map-content'>
            <MenuHorizontal mudarMapa={trocarMapa} retreatModals={retreatModals} setRetreatModals={setRetreatModals} />
            <MenuItem idLogs={idForLogs} openLog={openLogs} mudarMapa={trocarMapa} onClose={handleCloseLogs} ></MenuItem>
            <DetailRoute className="rotas-informacoes" controllersRoute={infoRoutes} value={localStorage.getItem('valueFilterControllers')} />
            <DetailNameRoute className="name-rotas-informacoes" >{localStorage.getItem('valueFilterControllers')}</DetailNameRoute>
            <Quicklog retreatModals={retreatModals} setRetreatModals={setRetreatModals} />
            <FilterControllers retreatModals={retreatModals} setRetreatModals={setRetreatModals} />
            <DashBoard dadosEstatisticas={dadosEstatisticas} locaisMapa={intersections} />
            <Map
                className="map"
                key={id}
                worldCopyJump={true}
                center={[lat, lng]}
                zoom={zoom}
                maxZoom={22}
                onClick={handleClick}
                zoomControl={false}
                keyboard={false}
                style={cursor}
            >
                <TileLayer
                    url={url}
                    id="mapbox/streets-v11"
                    attribution={`<a href='https://www.greenwave.ind.br/'> @GreenWave </a> | v0.0.3`}
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  //link openstreetMap(ANTIGO)
                />
                <Marker
                    className="Marker"
                    key={id}
                    title="EU"
                    position={[latitudePosition, longitudePosition]}
                    openPopup={true}
                    riseOnHover='true'
                    autoPan={true}
                    bubblingMouseEvents={true}
                ></Marker>
                {props.children}
                {
                    newIntersection.map((intersection, index) => {
                        if (filterOpen !== "true") {
                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>//show all intersections
                        } else if (filterOpen === "true") {
                            //se o filtro esta ativo verifica qual o tipo de filtro
                            document.getElementById("detail-name-route").style.display = 'flex';
                            document.getElementById("iconFIlter").style.background = '#605a83'; //MUDAR COR DE FUNDO DO BOTAO FILTRO DA BARRA DE MENU PARA MOSTRAR AO USUARIO QUE A FILTRO APLICADO

                            // separa em um array de string para poder fazer o fitro
                            var filtroGeral = valueFilter !== "" ? valueFilter.split(",") : []

                            if (filtroGeral !== "" || filtroGeral !== undefined) {
                                return (
                                    filtroGeral.map((e) => {
                                        if (typeFilter === id && intersection.id_cruzamento === e) {
                                            //Typo do filtro == id então eu pego o equipamento que tem id_cruzamento == Filtro digitado

                                            localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                            localStorage.setItem('moveLongitude', `${intersection.longitude}`);
                                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                        }


                                        if (typeFilter === falha && intersection.descricao_falha === e && ModoFiltro === 'estado') {
                                            console.log('e', e)
                                            //Typo do filtro == falha então eu pego o equipamento que tem descricaoDeFalha == Filtro digitado

                                            localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                            localStorage.setItem('moveLongitude', `${intersection.longitude}`);
                                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                        }
                                        if (typeFilter === falha && intersection.modo_operacao === e && ModoFiltro === 'operacional') {


                                            localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                            localStorage.setItem('moveLongitude', `${intersection.longitude}`);
                                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                        }

                                        if ((typeFilter === falha) && (e === statusDoor) && (intersection.porta === e)) {
                                            localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                            localStorage.setItem('moveLongitude', `${intersection.longitude}`);

                                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                        }
                                        if (typeFilter === modelo && intersection.modelo === e) {
                                            localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                            localStorage.setItem('moveLongitude', `${intersection.longitude}`);

                                            return <ReferenceController className="referenceController" key={index} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                        }
                                        if (typeFilter === rotas) {
                                            idAnel = intersection.id_cruzamento;
                                            let index = routesId.indexOf(`${intersection.id_cruzamento}`);
                                            document.getElementById("div-id-detail-route").style.display = 'flex';
                                            if (index != -1) {
                                                localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                                localStorage.setItem('moveLongitude', `${intersection.longitude}`);
                                                if (permissionRequest === false) {
                                                    authMoniGroup(intersection.id_cruzamento);
                                                }
                                                return <ReferenceController className="referenceController" key={intersection.id_cruzamento} intersection={intersection} rotas={infoRoutes} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                            }
                                        }
                                        if (typeFilter === area) {
                                            let filterArea = areaId.indexOf(`${intersection.id_cruzamento}`);

                                            if (filterArea != -1) {
                                                localStorage.setItem('moveLatitude', `${intersection.latitude}`);
                                                localStorage.setItem('moveLongitude', `${intersection.longitude}`);

                                                return <ReferenceController className="referenceController" key={intersection.id_cruzamento} intersection={intersection} viewIds={viewId} move={moveCenter}  >{getIdForLogs}</ReferenceController>
                                            }
                                        }
                                    })
                                )
                            }


                        }
                        return 0;
                    })
                }
                {alertDefect(contError)}
                <Polygon pathOptions={purpleOptions} positions={viewPolygon === 'view' ? multiPolygon : [[[]]]}>{/*polígono inserido entre os controladores registrados no mapa*/}
                    <Tooltip sticky>Controlador:</Tooltip>
                </Polygon >
                {/*<Circle
                    center={localStorage.getItem('typeFilterControllers') === 'Rotas' ? [0, 0] : [mediaLatitude, mediaLongitude]}
                    pathOptions={{ fillColor: 'red' }}
                    radius={raioArea}>
                </Circle>{/*Circulo inserido entre as áreas registradas no mapa, aguardando acerto de bug ao filtrar estatisticas */}
                <RegisterControllers RegisterControllers
                    name="resgisterControllers"
                    open={openRegisterController}
                    nameButton="cadastrar"
                    onClose={handleCloseRegisterController}
                >
                    Equipamentos
                </RegisterControllers >
                <button
                    className="view-ids-button"
                    title="Visualizar pontos de referência"
                    type="submit"
                    onClick={viewIds}
                >
                    {iconButtonViewsId()}
                </button>
                <button
                    className="zoom"
                    title="Full scream"
                    onClick={toggleFullScreen}
                >
                    <MdZoomOutMap color="#FFF" size="22" />
                </button>
                <button
                    className="view-alert-button"
                    title={textButtonAlert}
                    type="button"
                    onClick={() => DefineViewsAlert()}
                >
                    {iconButtonAlert}
                </button>
                <button
                    className="view-alert-button som"
                    title={textButtonSom}
                    type="button"
                    onClick={() => DefineSomAlert()}
                >
                    {somEnabled ?
                        <Volume2 title="desativar som do alerta" size={35} stroke-width={4} color='#fff' />
                        :
                        <VolumeX title="Ativar som do alerta" size={35} stroke-width={4} color='#fff' />
                    }
                </button>
                <button
                    className="view-alert-button polygon"
                    onClick={enabledPolygon}
                    title={textButtonPolygon}
                >
                    <FaDrawPolygon size={35} stroke-width={4} color='#fff' />
                </button>
                <div className='buttonsZoom'>
                    <button id='zoomMais' onClick={zoomMais}>+</button>
                    <button id='zoomMenos' onClick={zoomMenos}>-</button>
                </div>
            </Map>
        </div>
    )
}

export default Mapas;