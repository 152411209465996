import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartRigthToBottom from '../../../img/Setas/arrowStartRigthToBottom.png';
import arrowPauseRigthToBottom from '../../../img/Setas/arrowPauseRigthToBottom.png';
import arrowStopRigthToBottom from '../../../img/Setas/arrowStopRigthToBottom.png';
import animateArrowRigthToBottom from '../../../img/Setas/animateArrowRigthToBottom.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveRightToBottom, Options, ButtonSettings } from './styles';

export default function ArrowRightToBottom(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'À direita vire a direita', image: arrowStartRigthToBottom, object: props.arrowRightToBottom, setObject: props.setArrowRightToBottom });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowRightToBottom.map((item, index) => {
                if (props.arrowRightToBottom[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowRightToBottom'
                            top={item.top} left={item.left}
                            setArrowRightToBottom={props.setArrowRightToBottom}
                            arrowRightToBottom={props.arrowRightToBottom}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveRightToBottom
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartRigthToBottom :
                                        item.color_group === 1 ? arrowStopRigthToBottom :
                                            item.color_group === 2 ? arrowPauseRigthToBottom :
                                                item.color_group === 4 ? animateArrowRigthToBottom : ''
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowRightToBottom(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
