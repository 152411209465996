import React, { useState } from 'react';
import { Overlay, Modal, AreaLogo, Form, Footer} from './styles';
import Toast from 'light-toast';
import logoLogin from '../../../assets/logoLogin.png';
import api from '../../../services/api';

export default function ModalRecover({status, setStatus, slide, setSlide, slideResponsive, setSlideResponsive, view, setView}){

    const [email, setEmail] = useState('');

    async function handleResetPassword(e){
        e.preventDefault();
        await api.post('/auth/recuperacao', {
          email: email
        }).then(res => {
            Toast.success('E-mail de recuperação enviado com sucesso!', 3000);
            setStatus('none');
            setSlide('-42vw');
            setSlideResponsive('none');

        }).catch(err => {   
            Toast.fail('Usuário não encontrado!', 3000);
        })
    }

    return(
        <>
        <Overlay status={status} onClick={() => {setStatus('none'); setSlide('-42vw'); setSlideResponsive('none'); setView('none') }}></Overlay>
            <Modal status={status} slide={slide} setSlide={setSlide} slideResponsive={slideResponsive} setSlideResponsive={setSlideResponsive} display={view}>
                <AreaLogo>
                    <div className='logoPhor'>
                        <img src={logoLogin} alt="" />
                    </div>
                </AreaLogo>
                <Form>
                    <div className='content'>
                        <input type="email" placeholder="Digite o email cadastrado..." onChange={(e) => setEmail(e.target.value)} tabIndex={-2} required/> {/*index negativo para que ao teclar table não entrar no fluxo sequencial*/}
                        <button onClick={handleResetPassword} tabIndex={-1}>Recuperar senha</button>{/*index negativo para que ao teclar table não entrar no fluxo sequencial*/}
                    </div>
                </Form>
                <Footer>
                    <h6>
                        Uma mensagem será enviada para o endereço de email preenchido acima,
                        com instruções para ser feita a recuperação de sua senha.
                    </h6>
                </Footer>
            </Modal>
        </>
        
    );
}