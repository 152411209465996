import styled from 'styled-components';

export const Container = styled.section`

`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 2%;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;    
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const AreaMenu = styled.section`
    display: flex;
    align-items: center;
    position: relative;
`;

export const MenuTabs = styled.ul`
    position: relative;
    padding: 0;

    @media(max-width: 580px){
        width: 100vw;
        margin: auto;
    }

    ul{
        display: flex;
        padding: 0;

        @media(max-width: 580px){
            display: flex;
            flex-direction: column;
            width: fit-content;
            margin: auto;
        }

        &:before {
            content: '';
            width: 143px;
            height: 3px;
            background: #062467;
            position: absolute;
            bottom: -5px;
            left: ${props => props.position + 'px'};
            transition: .3s ease-out;

            @media(max-width: 580px){
                display:none;
            }
        }
    }
    
    li{
        list-style: none;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        color: #5f5d5d;
        height: 23px;
        font-size: 14px;
        user-select: none;
        //border: 1px solid red;

        &#inactive{
            font-weight: normal;
            color: #001E62;
        }

        &#active{
            transition: .3s ease-out;
            color: #001E62;
            font-weight: 900;
            letter-spacing: .5px;
        }

        &.consorcio{
            margin-right: -34px;
            @media(max-width: 420px){
                margin: 0;
            }
        }
    }
`;
export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;

    @media(max-width: 1280px) and (max-height: 470px){
        margin-bottom: 50px;
    }


    .btnEditInfo{
        cursor: pointer;

        img{
            width: 35px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
    }
   
    button{
        border: 0;
        width: 115px;
        height: 25px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }
`;