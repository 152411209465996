import React from 'react';
import semFrontGreen from '../img/Semaforo/semFrontGreen.png';
import semLeftGreen from '../img/Semaforo/semLeftGreen.png';
import semRightGreen from '../img/Semaforo/semRightGreen.png';
import semFrontWalkGreen from '../img/Semaforo/semFrontWalkGreen.png';
import semLeftWalkGreen from '../img/Semaforo/semLeftWalkGreen.png';
import semRightWalkGreen from '../img/Semaforo/semRightWalkGreen.png';

import { Container, TypeObjects, Buttom, Ico } from './styles';

export default function MenuTypeSemaforo(props) {

    return (
        <Container>
            <TypeObjects id={props.name} open={props.openTypes}>
                <Buttom onClick={props.addObject}>
                    <Ico id={props.name} src={semFrontGreen} alt='' className='front' />
                </Buttom>
                <Buttom onClick={props.addObjectRight}>
                    <Ico id={props.name} src={semRightGreen} alt='' className='right' />
                </Buttom>
                <Buttom onClick={props.addObjectLeft}>
                    <Ico id={props.name} src={semLeftGreen} alt='' className='left' />
                </Buttom>
                <Buttom onClick={props.addSemaforoWalk}>
                    <Ico id={props.name} src={semFrontWalkGreen} alt='' className='frontWalk' />
                </Buttom>
                <Buttom onClick={props.addSemaforoWalkRight}>
                    <Ico id={props.name} src={semRightWalkGreen} alt='' className='rightWalk' />
                </Buttom>
                <Buttom onClick={props.addSemaforoWalkLeft}>
                    <Ico id={props.name} src={semLeftWalkGreen} alt='' className='leftWalk' />
                </Buttom>
            </TypeObjects>
        </Container>
    );
}

