import React from 'react';
import { Container, Close, Table } from './styles';
import api from '../../../../../services/api';
import PageTitle from '../../../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import ButtonReflesh from '../../../ButtonReflesh';
import { GrClose } from "react-icons/gr";
import { toast } from 'react-toastify';

function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

export default function ModalStatus(props){

    const reset = async (id) => {
        await api.post('/centraldefalhas/intersection/reset', {
            "id_cruzamento": id,
        }).then(res => {
            toast.success(`Solicitação de reset enviado!`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(err => {
            toast.error(`Erro ao solicitar reset !`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    return(
        <Dialog 
            open={props.open} 
            className="Dialog" 
            onClose={props.onClose} 
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
        <PageTitle 
            id="draggable-dialog-title"
            text="Amarelo Intermitente"
            onClick={props.onClose}
        ></PageTitle>
        <Container>
        <Close onClick={props.onClose}><GrClose size={14}/></Close>
            <Table>
                <thead>
                    <tr>
                        <th>ID local</th>
                        <th>Reset</th>
                    </tr>
                </thead>
                <tbody>
                    {props.locaisMapa.map( (items) => {
                        if(items.descricao_falha === 'Amarelo Intermitente'){
                            return(
                                <tr key={items.id_cruzamento}>
                                    <td>{items.id_cruzamento}</td>
                                    <td><div onClick={() => reset(items.id_cruzamento)}><ButtonReflesh /></div></td>
                                </tr> 
                            );
                        }
                    })}                                         
                </tbody>
            </Table>       
        </Container>
        </Dialog>
    );
}