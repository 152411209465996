import styled from 'styled-components';
import { keyframes } from 'styled-components';

const emerge = keyframes`
    0% { opacity: 0;}
    50% { opacity: 0.4;}
    100% { opacity: 1;}
`;

export const Container = styled.section`
    padding: 2px 27px 0 27px;

    @media(max-width: 420px){
        padding: 0;
        margin: 10px;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const Box = styled.div`
    position: relative;
    margin-bottom: 5px;
    display: flex;

    @media(max-width: 1280px){
        margin-bottom: 2px;
    }

    @media(max-width: 420px){
        margin-bottom: 12px;
    }

    fieldset{
        border: 1px solid #9c9c9c;
        border-radius: 3px;
        width: 100%;
    }

    legend{
        font-family: 'roboto';
        font-size: 13px;
        color: #9c9c9c;
    }
`;

export const Fieldset = styled.fieldset`
    border: 1px solid #9c9c9c;
    border-radius: 3px;
    width: 90%;
    margin-bottom: 15px;

    @media(max-width: 1280px){
        margin-bottom: 5px;
    }

    @media(max-width: 420px){
        margin-bottom: 8px;
    }
`;

export const Legend = styled.legend`
    font-family: 'roboto';
    font-size: 13px;
    color: #9c9c9c;
`;

export const AreaInput = styled.div`
    border: 1px solid #9c9c9c;
    display: flex;
    border-radius: 6px;
    justify-content: space-between;

    button{
        border: 0;
        background: #001E62;
        cursor: pointer;
        width: 34px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 420px){
            width: 33px;
        }

        &:active img{
            position: relative;
            top: 1px;
        }

        &.btnMenos{
            border-radius: 6px 0 0 6px;
        }
        &.btnMais{
            border-radius: 0 6px 6px 0;
        }
        
    }
    input{
        height: 21px;
        border: 0;
        outline: none;
        text-align: center;
        font-family: 'roboto';
    }
`;

export const AreaField = styled.div`

    display: flex;
    align-items: center;

    input{
        width: 30px;
        height: 21px;
        border-radius: 6px;
        border: 1px solid #9c9c9c;
        outline: none;
        text-align: center;
    }
    span {
        font-family: 'roboto';
        font-size: 11px;
        width: 111px;
        display: block;
        color: #9c9c9c;
        margin-left: 16px;
    }

    .iconCapture{
        cursor: pointer;
        width: 23px;
        margin-left: 3px;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;

    @media(max-width: 1280px){
        margin-top: 7px;
    }
`;

export const Button = styled.button`
    border: 0;
    cursor: pointer;
    user-select: none;
    background: #001E62;
    color: #FFF;
    font-family: 'roboto';
    font-size: 14px;
    width: 107px;
    height: 30px;
    border-radius: 6px;
    
    &:active{
        position: relative;
        top:1px;
    }
    &:hover{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
        transition: .5s;
    }
`;

export const InfoHelp = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    z-index: 2;

    svg path {
        stroke: #001E62;
    }
`;

export const ContentBallon = styled.div`

    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 85%;
        margin: 0 auto;
        text-align: justify;
        line-height: 18px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
            margin-top: 20px;
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaBallom = styled.div`
    &#zoom{
        display: ${props => props.view === 'hidden' ? 'none' : 'block'};
        animation: ${emerge} 1s ease;
    }
    &#tempo{
        display: ${props => props.view === 'hidden' ? 'none' : 'block'};
        animation: ${emerge} 1s ease;
    }
    &#tela{
        display: ${props => props.view === 'hidden' ? 'none' : 'block'};
        animation: ${emerge} 1s ease;
    }
`;
