import styled from 'styled-components';

export const Container = styled.figure`
    position: relative;
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 49.3%;
    padding: 0;
    margin: 0;

    .chart{
        font-family: 'roboto', sans-serif;
        margin-top: 50px;
    }

    @media(max-width: 420px){
        height: 80%;

        .chart{
            margin-top: 20px;
        }
    }    
`;

export const Title = styled.span`
    position: absolute;
    top: 13px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'roboto',sans-serif;
    width: 99%;
    text-align: center;

    @media(max-width: 420px){
        font-size: 12px;
        width: 95%;
    }
`;

export const AreaLoading = styled.img`
    width: 20%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: ${props => props.loading == false ? 'none' : 'block'};
`;