import React, { useState, useEffect, memo, useContext } from 'react';
import { AreaIcon, ContentBallon, InfoParametro, InfoEquipamento, InfoAreas, InfoRotas} from './styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MapPin, Filter, ChevronLeft, ChevronRight } from "react-feather";
import './styles.css';
import SettingsPage from '../Settings';
import GetRoutes from '../GetRoutes';
import DeleteUser from '../DeleteUser';
import '../ReferenceController/styles.css';
import ImposicaoDePlano from '../ImposicaoDePlano';
import RegisterScheduleArea from '../RegisterScheduleArea';
import GetAreas from '../GetAreas';
import RegisterControllers from '../Registers/RegisterControllers';
import ButtonNav from '../ButtonNav';
import MenuNavButton from '../MenuNavButton';
import SettingUsers from '../SettingUsers';
import Parametros from '../Parametros';
import StickyHeadTable from '../StickyHeadTable';
import { ModalContext } from '../../contextModal';
import { GrCircleQuestion } from "react-icons/gr";
import { BalloonsMenuLateral } from '../Balloons';
import icoSom from '../../assets/icons/icoSom.png';
import icoInfo from '../../assets/icons/icoInfo.png';
import { tocarVozParametro, tocarVozEquipamento, tocarVozArea, tocarVozRota } from '../Audio';

function MenuItem(props) {

    const [openSettingUsers, setOpenSettingUsers] = React.useState(false);
    const [openDeleteUser, setOpenDeleteUser] = React.useState(false);
    const [openFilter, setOpenFilter] = React.useState(false);
    const [openSettings, setOpenSettings] = React.useState(false);
    const [titleButtonMenu, setTitleButtonMenu] = React.useState('')
    const [openScheduleArea, setOpenScheduleArea] = React.useState(false);
    const [openNameRoutes, setOpenNameRoutes] = React.useState(false);
    const [openNameAreas, setOpenNameAreas] = React.useState(false);
    const [openModalEquipamento, setOpenModalEquipamento] = React.useState(false);
    const [openLogs, setOpenLogs] = React.useState(false);
    const [openImposicao, setOpenImposicao] = React.useState(false);
    const [openParametros, setOpenParametros] = React.useState(false);
    const { coordenadasClickLocal, setCoordenadasClickLocal } = useContext(ModalContext);
    const { idGlobal, setIdGlobal } = useContext(ModalContext);
    const [inactiveArea, setInactiveArea] = useState('enabled');
    const [typeBar, setTypeBar] = React.useState(false);
    const [viewBalloonParametro, setViewBalloonParametro] = useState(false);
    const [viewBalloonEquipamento, setViewBalloonEquipamento] = useState(false);
    const [viewBalloonArea, setViewBalloonArea] = useState(false);
    const [viewBalloonRota, setViewBalloonRota] = useState(false);


    const clickOpenFilter = () => {
        setOpenSettingUsers(false);
        setOpenSettings(false);
        setOpenDeleteUser(false);
        removeControllerRegistrationMarkup();
        setOpenFilter(true);
        window.location.href = "#filtro";
    };

    const clickOpenScheduleArea = () => {//agenda de imposição
        setOpenSettingUsers(false);
        removeControllerRegistrationMarkup();
        setOpenScheduleArea(true);
        window.location.href = "#configuração-da-agenda";
    };

    const openGetRoutes = () => {//informações das rota
        setOpenSettingUsers(false);
        removeControllerRegistrationMarkup();
        setOpenNameRoutes(true);
        window.location.href = "#rotas-cadastradas";
    };

    const openGetArea = () => {//informações da area
        setOpenSettingUsers(false);
        removeControllerRegistrationMarkup();
        setOpenNameAreas(true);
        window.location.href = "#area";
    };

    const openEquipamento = () => {
        localStorage.setItem('longitude', '');
        localStorage.setItem('latitude', '');
        setOpenSettingUsers(false);
        removeControllerRegistrationMarkup();
        setOpenModalEquipamento(true);
        window.location.href = "#equipamento";
        setIdGlobal('');
    }

    const handleClickOpenImposicao = () => {
        setOpenSettingUsers(false);
        removeControllerRegistrationMarkup();
        setOpenImposicao(true);
        window.location.href = "#imposição-de-plano";
    }

    const clickCloseGetRoutes = () => {
        removeControllerRegistrationMarkup();
        setOpenNameRoutes(false);
        window.location.href = "#"
    };
    const clickCloseGetAreas = () => {
        removeControllerRegistrationMarkup();
        setOpenNameAreas(false);
        window.location.href = "#"
    };
    const clickCloseModalEquipamento = () => {
        removeControllerRegistrationMarkup();
        setOpenModalEquipamento(false);
        window.location.href = "#"
    }
    const clickCloseScheduleArea = () => {
        removeControllerRegistrationMarkup();
        setOpenScheduleArea(false);
        window.location.href = "#"
    };
    const handleCloseSettingUsers = () => {
        removeControllerRegistrationMarkup();
        setOpenSettingUsers(false);
        window.location.href = "#"
    };

    const handleCloseParametros = () => {
        setOpenParametros(false);
        window.location.href = "#"
    }

    const clickCloseSettings = () => {
        removeControllerRegistrationMarkup();
        setOpenSettings(false);
        window.location.href = "#"
    };

    const clickCloseDeleteUser = () => {
        removeControllerRegistrationMarkup();
        setOpenDeleteUser(false);
        window.location.href = "#"
    };

    const clickCloseCardImposicao = () => {
        removeControllerRegistrationMarkup();
        setOpenImposicao(false);
        window.location.href = "#"
    }

    const openCardParametros = () => {
        setOpenParametros(true);
    }

    const removeControllerRegistrationMarkup = () => {//quando clica em qualquer parte sem ser o mapa retira a opcao de abrir o registro de controlador (solucoa de bug)
        localStorage.setItem('awaitNextClick', 'false');
        setCoordenadasClickLocal('true');
        document.getElementById("iconRegisterController").style.background = '';
    }

    const moveMenu = () => {
        if (typeBar) {
            document.getElementById('menu-map').animate([
                // keyframes
                { width: '200px' },
                { width: '50px' }
            ], {
                duration: 500,
                iterations: 1,
            });
            document.getElementById('sidebar').animate([
                // keyframes
                { width: '200px' },
                { width: '50px' }
            ], {
                duration: 500,
                iterations: 1,
            });
            document.getElementById('menu-map').style.width = '50px';
            document.getElementById('sidebar').style.width = '50px';
            //document.getElementsByClassName('img-arrow')[0].style.display = 'none';
            const logo = document.getElementById('logoMenuBar').style;
            logo.width = '90px';
            logo.height = '50px';
            logo.margin = '';
            setTypeBar(false);
            setTitleButtonMenu('Ampliar menu');
        } else {
            document.getElementById('menu-map').animate([
                // keyframes
                { width: '50px' },
                { width: '200px' }
            ], {
                duration: 500,
                iterations: 1,
            });
            document.getElementById('sidebar').animate([
                // keyframes
                { width: '50px' },
                { width: '200px' }
            ], {
                duration: 500,
                iterations: 1,
            });
            document.getElementById('sidebar').style.width = '200px';
            document.getElementById('menu-map').style.width = '225px';
            document.getElementsByClassName('img-arrow')[0].style.display = 'block';
            const logo = document.getElementById('logoMenuBar').style;
            logo.width = '';
            logo.height = '';
            logo.margin = '';

            setTypeBar(true);
            setTitleButtonMenu('Recolher menu');
        }
    }

    const iconHome = () => {
        return (
            <svg width="20" height="19" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.25 11.5V7.75H7.75V11.5H10.875V6.5H12.75L6.5 0.875L0.25 6.5H2.125V11.5H5.25Z" fill="white" />
            </svg>
        );
    }

    const iconCadastro = () => {
        return (
            <svg width="20" height="19" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.75 3.25H0.25V4.5H7.75V3.25ZM7.75 0.75H0.25V2H7.75V0.75ZM10.25 5.75V3.25H9V5.75H6.5V7H9V9.5H10.25V7H12.75V5.75H10.25ZM0.25 7H5.25V5.75H0.25V7Z" fill="white" />
            </svg>
        );
    }

    const iconAgenda = () => {
        return (
            <svg width="20" height="19" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.625 5.875H3.375V7.125H4.625V5.875ZM7.125 5.875H5.875V7.125H7.125V5.875ZM9.625 5.875H8.375V7.125H9.625V5.875ZM10.875 1.5H10.25V0.25H9V1.5H4V0.25H2.75V1.5H2.125C1.43125 1.5 0.88125 2.0625 0.88125 2.75L0.875 11.5C0.875 12.1875 1.43125 12.75 2.125 12.75H10.875C11.5625 12.75 12.125 12.1875 12.125 11.5V2.75C12.125 2.0625 11.5625 1.5 10.875 1.5ZM10.875 11.5H2.125V4.625H10.875V11.5Z" fill="white" />
            </svg>
        );
    }

    const iconImposicao = () => {
        return (
            <svg width="16" height="15" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.1875 8.86875L7.0625 8.875C7.48125 8.875 7.85625 8.66875 8.08125 8.35L10.8125 4.5L8.08125 0.65C7.85625 0.33125 7.48125 0.125 7.0625 0.125L0.1875 0.13125L3.2125 4.5L0.1875 8.86875Z" fill="white" />
            </svg>
        );
    }

    const iconArea = () => {
        return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 1.25L1.25 4.375L7.5 7.5L13.75 4.375L7.5 1.25Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.25 10.625L7.5 13.75L13.75 10.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.25 7.5L7.5 10.625L13.75 7.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    const iconRoutes = () => {
        return (
            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.75 0.5L8.18125 1.93125L6.38125 3.73125L7.26875 4.61875L9.06875 2.81875L10.5 4.25V0.5H6.75ZM4.25 0.5H0.5V4.25L1.93125 2.81875L4.875 5.75625V10.5H6.125V5.24375L2.81875 1.93125L4.25 0.5Z" fill="white" />
            </svg>
        );
    }

    const iconParametro = () => {
        return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.875 13.125L7.5 10L3.125 13.125V3.125C3.125 2.79348 3.2567 2.47554 3.49112 2.24112C3.72554 2.0067 4.04348 1.875 4.375 1.875H10.625C10.9565 1.875 11.2745 2.0067 11.5089 2.24112C11.7433 2.47554 11.875 2.79348 11.875 3.125V13.125Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }

    const resetFilterIntersections = () => {
        localStorage.setItem('typeFilterControllers', '');
        localStorage.setItem('valueFilterControllers', '');
        localStorage.setItem('typeFilterControllers', '');
        localStorage.setItem('filterOpen', "false");
        window.location.href = "/mapa";
    }

    useEffect(() => { setOpenLogs(props.idLogs) }, [props.idLogs]);

    const ContentPopBallonParametros = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Parametros</h3>
                    <label onClick={() => tocarVozParametro()}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Na opção de parâmetros deverão ser inseridas as orientações referentes ao consorcio e/ou 
                    empresas mantenedoras, modelos de controlador e câmera.
                </span>
                <div className="areaButton">
                    <button onClick={() => setViewBalloonParametro(false)}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBallonEquipamento = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Equipamentos</h3>
                    <label onClick={tocarVozEquipamento}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Nessa opção estão contidas as informações de cadastro referentes ao controlador.
                    As abas estão posicionadas na parte superior ao abrir a janela: Local, Controlador,
                    Chip e Câmera.
                </span>
                <div className="areaButton">
                    <button onClick={() => setViewBalloonEquipamento(false)}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBallonArea = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Áreas/Subáreas</h3>
                    <label onClick={tocarVozArea}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Campo destinado ao cadastro de áreas específicas para o monitoramento, podendo separar
                    os pontos de visualização dos controladores no mapa.
                </span>
                <div className="areaButton">
                    <button onClick={() => setViewBalloonArea(false)}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBaloonRota = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Rotas</h3>
                    <label onClick={tocarVozRota}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    O Item tem como função informar o sentido, de uma determinada via, podendo
                    posteriormente visualizar os instantes de acionamentos dos grupos focais direcionados a um local.
                </span>
                <div className="areaButton">
                    <button onClick={() => setViewBalloonRota(false)}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const openBallonParametro = () => {
        setViewBalloonParametro(true);
        setViewBalloonEquipamento(false);
        setViewBalloonArea(false);
        setViewBalloonRota(false);
    }

    const openBallonEquipamento = () => {
        setViewBalloonParametro(false);
        setViewBalloonEquipamento(true);
        setViewBalloonArea(false);
        setViewBalloonRota(false);
    }

    const openBallonArea = () => {
        setViewBalloonParametro(false);
        setViewBalloonEquipamento(false);
        setViewBalloonArea(true);
        setViewBalloonRota(false);
    }

    const openBallonRota = () => {
        setViewBalloonParametro(false);
        setViewBalloonEquipamento(false);
        setViewBalloonArea(false);
        setViewBalloonRota(true);
    }

    return (
        <div id="menu-map" className="content-sidebar-map" >
            <div id="sidebar" className="sidebar-map" >
                <button id="button-move-menu" title={titleButtonMenu} className="button-move-menu" onClick={moveMenu}>
                    {typeBar ? <ChevronLeft /> : <ChevronRight />}
                </button>
                <ButtonNav showText={typeBar} id="home" icon={iconHome} title="Home" onClick={resetFilterIntersections} >Início</ButtonNav>
                <MenuNavButton showText={typeBar} title="Cadastro" icon={iconCadastro}>
                    <ButtonNav showText={typeBar} icon={iconParametro} onClick={openCardParametros}>
                        Parâmetros
                        <AreaIcon id="parametros" onMouseOver={openBallonParametro}>
                            <GrCircleQuestion/>
                        </AreaIcon>
                    </ButtonNav>
                    <ButtonNav showText={typeBar} id="iconRegisterController" icon={MapPin} onClick={openEquipamento}>
                        Equipamentos
                        <AreaIcon id="equipamentos" onMouseOver={openBallonEquipamento}>
                            <GrCircleQuestion/>
                        </AreaIcon>
                    </ButtonNav>
                    <ButtonNav showText={typeBar} icon={iconArea} onClick={openGetArea}>
                        Áreas / Subáreas
                        <AreaIcon id="areas" onMouseOver={openBallonArea}>
                            <GrCircleQuestion/>
                        </AreaIcon>
                    </ButtonNav>
                    <ButtonNav showText={typeBar} icon={iconRoutes} onClick={openGetRoutes}>
                        Rotas
                        <AreaIcon id="rotas" onMouseOver={openBallonRota}>
                            <GrCircleQuestion/>
                        </AreaIcon>
                    </ButtonNav>
                </MenuNavButton>
                <ButtonNav showText={typeBar} id="iconFIlter" icon={Filter} onClick={clickOpenFilter}>Filtro</ButtonNav>
                <ButtonNav showText={typeBar} icon={iconImposicao} onClick={handleClickOpenImposicao}>Imposição</ButtonNav>
                <ButtonNav showText={typeBar} icon={iconAgenda} onClick={clickOpenScheduleArea}>Agenda</ButtonNav>
                <InfoParametro viewBalloon={viewBalloonParametro}>
                    <BalloonsMenuLateral><ContentPopBallonParametros /></BalloonsMenuLateral>
                </InfoParametro>
                <InfoEquipamento viewBalloon={viewBalloonEquipamento}>
                    <BalloonsMenuLateral><ContentPopBallonEquipamento /></BalloonsMenuLateral>
                </InfoEquipamento>
                <InfoAreas viewBalloon={viewBalloonArea}>
                    <BalloonsMenuLateral><ContentPopBallonArea /></BalloonsMenuLateral>
                </InfoAreas>
                <InfoRotas viewBalloon={viewBalloonRota}>
                    <BalloonsMenuLateral><ContentPopBaloonRota /></BalloonsMenuLateral>
                </InfoRotas>
            </div>
            <ToastContainer
                className="toast-container"
                containerId="toast-container"
                draggablePercent={50}
                style={{ width: '250px', fontSize: '13px', marginRight: '23px', zIndex: 999 }}
            />
            <SettingsPage
                open={openSettings}
                onClose={clickCloseSettings}
            ></SettingsPage>
            <ImposicaoDePlano
                open={openImposicao}
                onClose={clickCloseCardImposicao}
                cbSetOpenImposicao={setOpenImposicao}
            />
            <SettingUsers
                open={openSettingUsers}
                onClose={handleCloseSettingUsers}
            >
            </SettingUsers>

            <Parametros
                open={openParametros}
                onClose={handleCloseParametros}
            >
            </Parametros>
            <DeleteUser
                open={openDeleteUser}
                onClose={clickCloseDeleteUser}
            >
            </DeleteUser>
            <GetRoutes
                open={openNameRoutes}
                onClose={clickCloseGetRoutes}
            >
            </GetRoutes>
            <GetAreas
                onClose={clickCloseGetAreas}
                open={openNameAreas}
            >
            </GetAreas>
            <RegisterControllers
                open={openModalEquipamento}
                onClose={clickCloseModalEquipamento}
            >
            </RegisterControllers>
            <RegisterScheduleArea
                open={openScheduleArea}
                onClose={clickCloseScheduleArea}
            />
            {openLogs ?
                <StickyHeadTable
                    key={props.idLogs}
                    open={props.openLog}
                    id={props.idLogs}
                    onClose={() => props.onClose()}
                ></StickyHeadTable>
                : <></>},
        </div>
    );
}

export default memo(MenuItem);
