
import React, { useState, useEffect } from 'react';
import { Container, AreaCharts, AreaTable, Table, InfoHelp, AreaBallon, ContentBallon } from './styles';
import controlNormal from '../../../../assets/controlNormal.png';
import icoNormal from '../../../../assets/icoNormal.png';
import controlFalha from '../../../../assets/controlFalha.png';
import controloff from '../../../../assets/controloff.png';
import manutencaoGif from '../../../../assets/manutencaoGif.gif';
import ButtonFilter from '../../ButtonFilter';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../Balloons';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import io from 'socket.io-client';
import { tocarVozOperacional } from '../../../Audio';
import { Chart, Interval, Tooltip, Axis, Coordinate, Interaction, getTheme, registerTheme } from 'bizcharts';

export default function ChartOperacional(props) {
    const [modoNormal, setModoNormal] = useState(0);
    const [modoSupervisao, setModoSupervisao] = useState(0);
    const [modoFalha, setModoFalha] = useState(0);
    const [modoOffline, setModoOffline] = useState(0);
    const [modoManutencao, setModoManutencao] = useState(0);
    const [porcentagemNormal, setPorcentagemNormal] = useState(0);
    const [porcentagemSupervisao, setPorcentagemSupervisao] = useState(0);
    const [porcentagemFalha, setPorcentagemFalha] = useState(0);
    const [porcentagemOffline, setPorcentagemOffline] = useState(0);
    const [porcentagemManutencao, setPorcentagemManutencao] = useState(0);
    const [totalEquipamentos, setTotalEquipamentos] = useState(0);
    const [status, setStatus] = useState([]);
    const [visibleBalloon, setVisibleBalloon] = useState(false);


    useEffect(() => {
        if (typeof props.dadosEstatisticas.modosOperacionais != 'undefined') {
            const estatisticas = props.dadosEstatisticas;
            const modosOperacionais = estatisticas.modosOperacionais;
            const porcentagemModosOperacionais = estatisticas.porcentagemModosOperacionais;

            setModoNormal(modosOperacionais.normal);
            setModoSupervisao(modosOperacionais.supervisao);
            setModoFalha(modosOperacionais.falha);
            setModoOffline(modosOperacionais.offline);
            setModoManutencao(modosOperacionais.manutencao);
            setPorcentagemNormal(porcentagemModosOperacionais.normal);
            setPorcentagemSupervisao(porcentagemModosOperacionais.supervisao);
            setPorcentagemFalha(porcentagemModosOperacionais.falha);
            setPorcentagemOffline(porcentagemModosOperacionais.offline);
            setPorcentagemManutencao(porcentagemModosOperacionais.manutencao);
            setTotalEquipamentos(estatisticas.total);
        }

    }, [props]);

    //salvar no localStorage o tipo de filtro, para quando for realizar o filtro escolher por operacional
    const setModoFilter = () => {
        localStorage.setItem('ModoFiltro','operacional');
    }

    const data = [
        { item: 'Supervisão', count: modoSupervisao, percent: porcentagemSupervisao},
        { item: 'Normal', count: modoNormal, percent: porcentagemNormal },
        { item: 'Falha', count: modoFalha, percent: porcentagemFalha },
        { item: 'Offline', count: modoOffline, percent: porcentagemOffline },
        { item: 'Manutencao', count: modoManutencao, percent: porcentagemManutencao }
    ];

    const cols = {
        percent: {
            formatter: val => {
                val = val + '%';
                return val;
            },
        },
    };

    registerTheme('default', {
        defaultColor: '#505050',
        colors10: ["#009433", "#1771BA", "#F20404", "#000000", "#FF6C02"],
        geometries: {
            interval: {
                rect: {
                    default: { style: { fill: '#6DC8EC', stroke: '#505050', fillOpacity: 0.95 } },
                    active: { style: { stroke: '#5AD8A6', lineWidth: 1 } },
                    inactive: { style: { fillOpacity: 0.3, strokeOpacity: 0.3 } },
                    selected: {},
                }
            }
        }
    })

    const filterIntersections = (event) => {
        event.preventDefault();
        

        localStorage.setItem('filterOpen', 'true');//variavel para definir cor do botao se mostra setado ou não
        localStorage.setItem('typeFilterControllers', 'Falha'); //guarda o tipo do filtro que sera feito
        localStorage.setItem('valueFilterControllers', status);//pega o valor a ser filtrado    
        if (localStorage.getItem('typeFilterControllers') === 'Rotas') {
            const socket = io(process.env.REACT_APP_SERVER_HOST, {
                query: { token: `Bearer ${localStorage.getItem('@central-de-falhas-greenwave/tokenAuth')}` }
            });

           socket.on('connect', () => console.log('[IO] Connect => New connection'));
        }
        window.location.href = "/mapa";
    }
    
     /**
     * @autor Felipe alves
     * @param infos  valor do input selecionado
     * @descrição função criada para poder selecionar mais de um filtro
     * @returns apenas acrescenta o novo valor a variavel (status) 
     */

    function valueFilters(infos,validacao){

        var res = [...status];

        if(infos !== "" || infos !== undefined){

            if(validacao === true){
                res.push(infos)
            }else{
               res.splice(res.indexOf(infos),1)
            }
        }

        var novaArr = res.filter(function(este, i) {
            return res.indexOf(este) === i;
        });
        return setStatus(novaArr)
    }


    const openPopUp = () => {
        if(visibleBalloon){
            setVisibleBalloon(false);
        }else{
            setVisibleBalloon(true);
        }
    }
    
    const ContentPopBallonOperacional = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Estatística Modo Operacional</h3>
                    <label onClick={tocarVozOperacional}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    A tela de “Modo Operacional” é apresentada dados estatísticos referente as informações do ponto,
                    como por exemplo normal, falha, sem comunicação e manutenção. No mesmo também é apresentado um
                    gráfico do tipo pizza refletindo a porcentagem por cada condição de ponto.
                    Ainda mais, a tela de modo operacional também é possível filtrar conforme cada condição semafórica,
                    para isso selecionar as condições requeridas na ultima coluna, feito isso, clique no botão filtrar.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <Container>
            <AreaCharts>
                <Chart height={250} data={data} scale={cols} autoFit forceFit>
                    <Coordinate type="theta" radius={0.75} />
                    <Tooltip showTitle={false} />
                    <Axis visible={false} />
                    <Interval
                        position="percent"
                        adjust="stack"
                        color="item"
                        style={{
                            lineWidth: 1,
                            stroke: '#fff',
                        }}
                        label={['count', {
                            content: (data) => {
                                return `${data.item}: ${data.percent.toFixed(2)}%`;
                            },
                        }]}
                        state={{
                            selected: {
                                style: (t) => {
                                    const res = getTheme().geometries.interval.rect.selected.style(t);
                                    return { ...res, fill: 'red' }
                                }
                            }
                        }}
                    />
                    <Interaction type='element-single-selected' />
                </Chart>
                <InfoHelp onClick={() => openPopUp()}>
                    <GrCircleQuestion size={15} />
                </InfoHelp>
                <AreaBallon visible={visibleBalloon}>
                    <BallonsInfoModal><ContentPopBallonOperacional /></BallonsInfoModal>
                </AreaBallon>
            </AreaCharts>
            <AreaTable>
                <Table>
                    <thead>
                        <tr>
                            <th>Ícone</th>
                            <th>Modo Operacional</th>
                            <th>Qtd</th>
                            <th>%</th>
                            <th>Filtrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><img src={controlNormal} alt="" /></td>
                            <td>Supervisao</td>
                            <td>{modoSupervisao}</td>
                            <td>{porcentagemSupervisao.toFixed(2)}%</td>
                            <td><input type="checkbox" name="groupRadio" value="Supervisão" onClick={(e) => {valueFilters(e.target.value,e.target.checked); setModoFilter()}} /></td>
                        </tr>
                        <tr>
                            <td><img src={icoNormal} alt="" /></td>
                            <td>Normal</td>
                            <td>{modoNormal}</td>
                            <td>{porcentagemNormal.toFixed(2)}%</td>
                            <td><input type="checkbox" name="groupRadio" value="Normal" onClick={(e) => {valueFilters(e.target.value,e.target.checked); setModoFilter()}} /></td>
                        </tr>
                        <tr>
                            <td><img src={controlFalha} alt="" /></td>
                            <td>Falha</td>
                            <td>{modoFalha}</td>
                            <td>{porcentagemFalha.toFixed(2)}%</td>
                            <td><input type="checkbox" name="groupRadio" value="Falha" onClick={(e) => {valueFilters(e.target.value,e.target.checked); setModoFilter()}} /></td>
                        </tr>
                        <tr>
                            <td><img src={controloff} alt="" /></td>
                            <td>Offline</td>
                            <td>{modoOffline}</td>
                            <td>{porcentagemOffline.toFixed(2)}%</td>
                            <td><input type="checkbox" name="groupRadio" value="Offline" onClick={(e) => {valueFilters(e.target.value,e.target.checked); setModoFilter()}} /></td>
                        </tr>
                        <tr>
                            <td><img src={manutencaoGif} alt="" /></td>
                            <td>Manuntenção</td>
                            <td>{modoManutencao}</td>
                            <td>{porcentagemManutencao.toFixed(2)}%</td>
                            <td><input type="checkbox" name="groupRadio" value="Manutenção" onClick={(e) => {valueFilters(e.target.value,e.target.checked); setModoFilter()}} /></td>
                        </tr>
                        <tr>
                            <td colSpan="4" id="total">Total de equipamentos: {totalEquipamentos}</td>
                            <td id="filter"><div onClick={filterIntersections} title="Filtro por modo operacional"><ButtonFilter /></div></td>
                        </tr>
                    </tbody>
                </Table>
            </AreaTable>
        </Container>
    );
}
