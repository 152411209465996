import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../../../contextModal';
import BtnNovo from '../../../Registers/RegisterControllers/Icons/BtnNovo';
import excluir from '../../../../assets/img/excluir.png';
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import api from '../../../../services/api';
import icoSom from '../../../../assets/icons/icoSom.png';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import { Container, AreaIcon, AreaInput, ContentBallon, Group, AreaButtons, Button, InfoControlador, AreaTable, Table, Inputs } from './styles';
import { validarPermissao } from '../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsParametros } from '../../../Balloons';
import { tocarAudioControlador } from '../../../Audio';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Controlador(props) {

    const [nome, setNome] = useState('');
    const [fases, setFases] = useState('');
    const [detectores, setDetectores] = useState('');
    const [controlador, setControlador] = useState([]);
    const [enabled, setEnabled] = useState('enabledInputs');
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [_id, set_id] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [visibleBallonControlador, setVisibleBallonControlador] = useState(false);
    const { abrirAbaParametro } = useContext(ModalContext);


    const addItem = () => {
        setEnabled('desabledInputs');
        set_id('');
        setNome('');
        setFases('');
        setDetectores('');
    }

    const lerDados = async () => {
        const response = await api.get('centraldefalhas/modelo/controlador');
        setControlador(response.data);
    }

    const deleteItem = async (id) => {
        await api.delete(`centraldefalhas/modelo/controlador/${id}`)
            .then(() => {
                setMsg('O nome excluido com sucesso!');
                setAlertMsg('block');
                setTypeAlert('success');
                lerDados();

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                }, 5000);

            }).catch(() => {
                setMsg('O nome não foi excluido!');
                setAlertMsg('block');
                setTypeAlert('warning');
            });
    }

    const saveData = async () => {
        if ([nome, fases].includes('')) {
            setAlertMsg('block');
            setTypeAlert('error');
            setMsg('Preencha os campos nome e fases!');
            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            }, 5000);
            return;
        }
        if (fases > 32 || detectores > 32) {
            setAlertMsg('block');
            setMsg('Limite para nº de fases e detectores é de 32');
            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            }, 5000);
            return;

        } else {
            await api.post('centraldefalhas/modelo/controlador', {
                "nome": nome,
                "quantidadeDeFases": parseInt(fases),
                "quantidadeDeDetectores": parseInt(detectores),
                "_id": _id
            }).then((res) => {

                setAlertMsg('block');
                setMsg('Salvo com sucesso!');
                setTypeAlert('success');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                }, 5000);

                lerDados();
                setNome('');

            }).catch((err) => {
                setAlertMsg('block');
                setMsg(err.response.data.mensagem);
                setTypeAlert('error');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                }, 5000);

            });
        }
    }

    //Usado para verificar se mudou de aba
    //e entao limpar os campos
    useEffect(() => {
        lerDados();
        //limpar campos
        setEnabled('enabledInputs');
        setNome('');
        setFases('');
        setDetectores('');
    }, [props.positionAba]);

    const listDataInput = async (item) => {
        setEnabled('desabledInputs');
        set_id(item._id)
        setNome(item.nome);
        setFases(item.quantidadeDeFases);
        setDetectores(item.quantidadeDeDetectores);
    }

    const openPopUp = () => {
        if (visibleBallonControlador) {
            setVisibleBallonControlador(false);
        } else {
            setVisibleBallonControlador(true)
        }
    }

    const closePopUp = () => {
        if (visibleBallonControlador) {
            setVisibleBallonControlador(false);
        } else {
            setVisibleBallonControlador(true);
        }
    }

    const ContentBallonControlador = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Modelos-Controlador</h3>
                    <label onClick={tocarAudioControlador}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Area responsável por criar os modelos de controlador a serem utilizados.
                </span>
                <div className="areaButton">
                    <button onClick={() => closePopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <Container style={{ display: props.status }}>
            <AreaIcon>
                <div className='icon' onClick={openPopUp}>
                    <GrCircleQuestion />
                </div>
            </AreaIcon>
            <InfoControlador view={visibleBallonControlador}>
                <BallonsParametros>
                    <ContentBallonControlador />
                </BallonsParametros>
            </InfoControlador>
            <AreaInput>
                <Inputs>
                    <Group id={enabled}>{/* caso abrirAbaParametro (setado na aba de Equipamentos) então abrir habilitado, caso não então desabilitado*/}
                        <label htmlFor="">Modelo:</label>
                        <input type="text" placeholder="" id="modelo" value={nome} onChange={(e) => setNome(e.target.value)} /> {/* onChange={(e) => setNome(e.target.value.replace('-', '_'))}*/}
                    </Group>
                    <Group id={enabled}>
                        <label htmlFor="">Nº de Fases:</label>
                        <input type="number" placeholder="" id="fases" value={fases} onChange={(e) => setFases(e.target.value)} />
                    </Group>
                    <Group id={enabled}>
                        <label htmlFor="">Nº de detectores:</label>
                        <input type="number" placeholder="" id="detectores" value={detectores} onChange={(e) => setDetectores((e.target.value))} />
                    </Group>
                </Inputs>
                <AreaButtons message={alertMsg} className={permissao}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{msg}</Alert>
                    </div>
                    <Button id="btnNovo" onClick={addItem}>
                        <BtnNovo />Novo
                    </Button>
                    <Button className="btnSalvar" id={enabled} onClick={saveData}>
                        Salvar
                    </Button>
                </AreaButtons>
            </AreaInput>
            <AreaTable>
                <Table>
                    <tr>
                        <th>Nome</th>
                        <th>Fases</th>
                        <th>Detectores</th>
                        <th>Excluir</th>
                    </tr>
                    {controlador.map((item) => (
                        <tr key={item.nome} onClick={permissao === 'disabled' ? '' : () => listDataInput(item)}>
                            <td>{item.nome}</td>
                            <td>{item.quantidadeDeFases}</td>
                            <td>{item.quantidadeDeDetectores}</td>
                            <td><img src={excluir} alt="" className={permissao} onClick={() => deleteItem(item._id)} /></td>
                        </tr>
                    ))}
                </Table>
            </AreaTable>
        </Container>
    );
}