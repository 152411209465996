import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartLeft from '../../../img/Setas/arrowStartLeft.png';
import arrowPauseLeft from '../../../img/Setas/arrowPauseLeft.png';
import arrowStopLeft from '../../../img/Setas/arrowStopLeft.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveLeft, Options, ButtonSettings } from './styles';

export default function ArrowLeft(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Segue à esquerda', image: arrowStartLeft, object: props.arrowLeft, setObject: props.setArrowLeft });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowLeft.map((item, index) => {
                if (props.arrowLeft[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowLeft'
                            top={item.top}
                            left={item.left}
                            setArrowLeft={props.setArrowLeft}
                            arrowLeft={props.arrowLeft}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveLeft src={
                                item.color_group === undefined || item.color_group === '' ? arrowStartLeft :
                                    item.color_group === 1 ? arrowStopLeft :
                                        item.color_group === 2 ? arrowPauseLeft :
                                            item.color_group === 4 ? arrowStartLeft : ''
                            } alt=""
                                move={
                                    item.color_group === undefined || item.color_group === '' ? 'pause' :
                                        item.color_group === 1 ? 'pause' :
                                            item.color_group === 2 ? 'pause' :
                                                item.color_group === 4 ? true : props.moveArrowLeft
                                }
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowLeft(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
