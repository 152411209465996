import styled from 'styled-components';

export const Container = styled.section`
    padding: 17px 35px; 

    fieldset{
        border: 1px solid #9c9c9c;
        border-radius: 4px;
        position: relative;
        margin-bottom: 15px;
        max-height: 218px;
        overflow-y: scroll;
        position: relative;

        &::-webkit-scrollbar {
		    width: 8px;
            height: 8px;

            &-track-piece {
                background-color: #FFF;
            }

            &-thumb:vertical,
            &-thumb:horizontal {
                background-color: #001E62;
                border-radius:5px;
            }

            &-thumb:vertical:hover,
            &-thumb:horizontal:hover {
                background-color: #717171;
            }
	    }

        @media(max-width: 420px){
            margin-left: -10%;
        }
    }

    legend {
        font-size: 13px;
        font-family: 'roboto';
        user-select: none;
        &.idLocais{

            &::before{
                content: 'Anel';
                right: 120px;
                position: absolute;
                background: #FFFF;
                width: 39px;
                text-align: center;

                @media(max-width: 420px){
                    right: 31vw;
                }
            }

            &::after{
                content: 'Grupo';
                right: 69px;
                position: absolute;
                background: #FFFF;
                width: 47px;
                text-align: center;

                @media(max-width: 420px){
                    right: 9vw;
                }
            }
        }
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const GroupInput = styled.div`
    display: flex;
    gap: 8px;
    margin-top: 5px;
    align-items: center;

    input{
        border: 1px solid #9c9c9c;
        height: 29px;
        border-radius: 6px;
        outline: none;
           
        &#locais {
            text-indent: 7px; 
            width: 290px;
            @media(max-width: 420px){
                width: 47%;
            }
        }

        &#anel{
            width: 34px;
            text-align: center;
            @media(max-width: 420px){
                
            } 
        }

        &#monitor{
            width: 34px;
            text-align: center;
            @media(max-width: 420px){
                
            } 
        }

        &.infoArea{
            text-indent: 7px;
            width: 99%;
            pointer-events: ${props => props.editable === false ? 'none' : 'auto'};
            opacity: ${props => props.editable === false ? '0.4' : '1'};
        }
    }

    @media(max-width: 420px){
        width: 77vw;
        gap: 12px;
    }

`;

export const AreaButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
`;

export const ButtonAdd = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 50px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    cursor: pointer;
    user-select: none;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }

    &:hover{
        
        border: 1px solid #001E62;
        box-sizing: border-box;
        transition: .3s;
        svg{
            g{
                path{
                    stroke: #062467;
                    color: #FFF;
                }
            }
        }
    }

    svg {
        width: 20px;
        height: 22px;
    }

    @media(max-width: 420px){
        margin-left: 0;
    }
`;

export const Button = styled.button`
    width: 137px;
    height: 30px;
    background: #001E62;
    color: #FFF;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    cursor: pointer;

    &.save{
        pointer-events: ${props => props.editable === false ? 'none' : 'auto'};
        opacity: ${props => props.editable === false ? '0.4' : '1'};
    }


    @media(max-width: 420px){
        margin-right: 0%;
    }

    &:hover{
        border: 1px solid #001E62;
        background: #FFF;
        color: #001E62;
        transition: .3s;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        width: 17px;
        height: 17px;
    }
`;
