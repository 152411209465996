import React from 'react';
import carRed from '../../components/img/carRed.png';
import car from '../../components/img/car.png';
import esquerda from '../../components/img/esquerda.png';
import arrowStartTopToRight from '../../components/img/Setas/arrowStartTopToRight.png';
import arrowStartTopLeft from '../../components/img/Setas/arrowStartTopLeft.png';
import arrowStart from '../../components/img/Setas/arrowStart.png';
import arrowStartRigthToBottom from '../../components/img/Setas/arrowStartRigthToBottom.png';
import arrowStartRightToTop from '../../components/img/Setas/arrowStartRightToTop.png';
import arrowStartLeftToBottom from '../../components/img/Setas/arrowStartLeftToBottom.png';
import arrowStartLeftToTop from '../../components/img/Setas/arrowStartLeftToTop.png';
import arrowStartBottomToLeft from '../../components/img/Setas/arrowStartBottomToLeft.png';
import arrowStartBottomToRight from '../../components/img/Setas/arrowStartBottomToRight.png';
import arrowStartBottom from '../../components/img/Setas/arrowStartBottom.png';

import { Container, TypeObjects, Buttom, Ico  } from './styles';

export default function MenuType(props){

    return(
        <Container>
            <TypeObjects id={props.name} open={props.openTypes}>
                <Buttom onClick={props.addObject}>
                    <Ico id={props.name} src={
                        props.name === 'topToright' ? carRed :
                        props.name === 'topToleft'  ? car :
                        props.name === 'top' ? car :
                        props.name === 'rightToDown' ? car :
                        props.name === 'rightTotop' ? car :
                        props.name === 'right' ? car :
                        props.name === 'leftToBottom' ? esquerda :
                        props.name === 'leftToTop' ? esquerda :
                        props.name === 'left' ? esquerda :
                        props.name === 'bottomToLeft' ? esquerda :
                        props.name === 'bottomToRight' ? esquerda :
                        props.name === 'bottom' ? esquerda :
                        props.name === 'bottomToLeft' ? esquerda :
                        props.name === 'semaforo' ? esquerda : ''

                    } alt='' />
                </Buttom>
                <Buttom onClick={props.addObjectArrow}>
                    <Ico id={'arrow' + props.name} src={
                        props.name === 'topToright' ? arrowStartTopToRight :
                        props.name === 'topToleft'  ? arrowStartTopLeft :
                        props.name === 'top' ? arrowStart :
                        props.name === 'rightToDown' ? arrowStartRigthToBottom :
                        props.name === 'rightTotop' ? arrowStartRightToTop :
                        props.name === 'right' ? arrowStart :
                        props.name === 'leftToBottom' ? arrowStartLeftToBottom :
                        props.name === 'leftToTop' ? arrowStartLeftToTop :
                        props.name === 'left' ? arrowStart :
                        props.name === 'bottomToLeft' ? arrowStartBottomToLeft :
                        props.name === 'bottomToRight' ? arrowStartBottomToRight :
                        props.name === 'bottom' ? arrowStartBottom :
                        props.name === 'bottomToLeft' ? arrowStartBottom :
                        props.name === 'semaforo' ? esquerda : ''
                    } alt='' />
                </Buttom>
                <Buttom style={props.name === 'semaforo' ? {display: 'block'} : {display: 'none'}}>4</Buttom>
            </TypeObjects>
        </Container>
    );
}
