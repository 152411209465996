import React from 'react';

export default function IconZoom(){
    return(
        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 2.5L14.4167 4.41667L12.0083 6.80833L13.1917 7.99167L15.5833 5.58333L17.5 7.5V2.5H12.5ZM2.5 7.5L4.41667
            5.58333L6.80833 7.99167L7.99167 6.80833L5.58333 4.41667L7.5 2.5H2.5V7.5ZM7.5 17.5L5.58333 15.5833L7.99167 13.1917L6.80833
            12.0083L4.41667 14.4167L2.5 12.5V17.5H7.5ZM17.5 12.5L15.5833 14.4167L13.1917 12.0083L12.0083 13.1917L14.4167 15.5833L12.5 
            17.5H17.5V12.5Z" fill="white"/>
        </svg>
    );
}