import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semLeftGreen from '../../../img/Semaforo/semLeftGreen.png';
import semLeftYellow from '../../../img/Semaforo/semLeftYellow.png';
import semLeftRed from '../../../img/Semaforo/semLeftRed.png';
import semLeftApagado from '../../../img/Semaforo/semLeftApagado.png';
import semLeftPiscante from '../../../img/Semaforo/piscanteSemLeftYellow.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoLeft(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo à esquerda', image: semLeftGreen, object: props.objSemaforoLeft, setObject: props.setObjSemaforoLeft });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforoLeft.map((item, index) => {
                if (props.objSemaforoLeft[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforoLeft'
                            top={item.top}
                            left={item.left}
                            setObjSemaforoLeft={props.setObjSemaforoLeft}
                            objSemaforoLeft={props.objSemaforoLeft}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img
                                src={
                                    item.color_group === undefined || item.color_group === '' ? semLeftApagado :
                                        item.color_group === 1 ? semLeftRed :
                                            item.color_group === 2 ? semLeftYellow :
                                                item.color_group === 4 ? semLeftGreen :
                                                    item.color_group === 18 ? semLeftPiscante : semLeftApagado
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforoLeft(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
