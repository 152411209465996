import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semRightWalkGreen from '../../../img/Semaforo/semRightWalkGreen.png';
import semRightWalkRed from '../../../img/Semaforo/semRightWalkRed.png';
import semRightWalkApagado from '../../../img/Semaforo/semRightWalkApagado.png';
import semRightWalkRedPiscante from '../../../img/Semaforo/piscanteRightWalk.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoWalkRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo Pedestre a direita', image: semRightWalkGreen, object: props.objSemaforoWalkRight, setObject: props.setObjSemaforoWalkRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforoWalkRight.map((item, index) => {
                if (props.objSemaforoWalkRight[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforoWalkRight'
                            top={item.top} left={item.left}
                            objSemaforoWalkRight={props.objSemaforoWalkRight}
                            setObjSemaforoWalkRight={props.setObjSemaforoWalkRight}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img
                                src={
                                    item.color_group === undefined || item.color_group === '' ? semRightWalkApagado :
                                        item.color_group === 1 ? semRightWalkRed :
                                            item.color_group === 4 ? semRightWalkGreen :
                                                item.color_group === 17 ? semRightWalkRedPiscante : semRightWalkApagado
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforoWalkRight(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}