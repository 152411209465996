import styled from 'styled-components';

export const Container = styled.section`
    padding: 14px 29px;
    fieldset{
        border: 1px solid #c1bdbd;
        border-radius: 4px;
        margin-bottom: 15px;
    }

    legend{
        font-family: 'roboto';
        font-size: 13px;
    }
    
    input{
        width: 99%;
        height: 30px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const ContentData = styled.div`

    fieldset{
        border: 1px solid #c1bdbd;
        border-radius: 4px;
        margin-bottom: 15px;
        max-height: 218px;
        overflow-y: scroll;
        position: relative;

        &::-webkit-scrollbar {
		    width: 8px;
            height: 8px;

            &-track-piece {
                background-color: #FFF;
            }

            &-thumb:vertical,
            &-thumb:horizontal {
                background-color: #001E62;
                border-radius:5px;
            }

            &-thumb:vertical:hover,
            &-thumb:horizontal:hover {
                background-color: #269F2A;
            }
	    }

    }

    legend {
        font-family: 'roboto';
        font-size: 13px;
        position: relative;

        @media(max-width: 614px){
            display: none;
        }
        
        &:before {
            content: 'Anel';
            position: absolute;
            top: 0;
            width: 37px;
            text-align: center;
            right: -223px;
            background: #FFF;

            @media(max-width: 1280px){
                right: -17.5vw;
            }
        }

        &:after {
            content: 'Grupo monitorado';
            position: absolute;
            top: 0;
            width: 112px;
            text-align: center;
            left: 322px;
            background: #FFF;

            @media(max-width: 1280px){
                right: -28vw;
            }
        }
    }

    input{
        width: 235px;
        height: 30px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;
    }

    @media(max-width: 420px){
        legend{
            &::before{
                display:none;
            }
        }

        fieldset{
            max-height: 290px;
        }
    }
`;

export const GroupInput = styled.div`
    display: flex;
    align-items: center;

    @media(max-width: 614px){
        flex-direction: column;
    }

    select {
        width: 47%;
        min-width: 47%;
        height: 32px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;
        margin-right: 15px;

        &#anel{
            width: 60px;

            @media(max-width: 420px){
                width: 49%;
            }
        }
    }

    input{
        width: 85px;
        height: 29px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;

        &#grupo {
            width: 40%;
        }
    }

    @media(max-width: 440px){
        flex-direction: column;
        align-items: end;

        select{
            width: 100%;
            height: 32px;
            border: 1px solid #c1bdbd;
            border-radius: 7px;
            outline: none;
            text-indent: 7px;
            margin-top: 10px;
            margin-bottom: 10px;
            margin-right: 0;
        }

        input{
            width: 98%;
        }
    }
`;

export const Button = styled.div`
    cursor: pointer;
    margin-top: 4px;
    &:active{
        position: relative;
        top: 1px;
    }    
    svg {
        width: 29px;
        height: 30px;
    }
`;  

export const ButtonRemove = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 50px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    margin-top: 13px;
    cursor: pointer;
    user-select: none;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }

    svg {
        width: 20px;
        height: 22px;
    }
`;

export const ButtonSave = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 86px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    cursor: pointer;
    font-family: 'roboto';
    font-size: 14px;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-between;

    .alert{
        visibility: ${props => props.message === 'none' ? 'hidden' : 'visible'};
        width: auto;
        height: 10px;
        margin-right: 22px;

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0 {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

export const Buttons = styled.div`

`;

export const AreaInputs = styled.div`
    @media(max-width: 420px){
        display: flex;
        gap: 5px;
    }
`;
