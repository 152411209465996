import styled from 'styled-components';

export const Container = styled.figure`
    height: 380px;
    background: #FFF;
    width: 95.5%;
    margin: 20px auto;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
    border-radius: 6px;
    padding: 15px 0 15px 0;
    position: relative;
    
    .chart{
        position: relative;
        height: 300px !important;
        width: 95.5% !important;
        padding: 27px;
        position: relative;

        canvas {
            margin-top: 10px;
        }

        @media(max-width: 420px){
            margin-left: -6%;
        }
    }

    .titulo{
        position: absolute;
        top: 0;
        width: 97%;
        text-align: center;
        font-family: 'roboto';
        font-size: 14px;
        font-weight: 600;  
    }

    .subTitulo{
        position: absolute;
        top: 17px;
        width: 97%;
        text-align: center;
        font-family: 'roboto';
        font-size: 13px;
    }

    @media(max-width: 420px){
        .titulo{
            width: 80%;
        }
        .subTitulo{
            width: 80%;
            font-size: 11px;
        }
    }
`;

export const Title = styled.span`
    width: 100%;
    display: flex;
    text-align: center;
    font-size: 13px;
    font-family: 'roboto', sans-serif;
    height: 30px;
    align-items: flex-end;
    justify-content: center;
`;

export const AreaLoading = styled.img`
    width: 20%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: ${props => props.loading === false ? 'none' : 'block'};   
`;