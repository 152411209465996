import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import closeModal from '../img/closeModal.png';
import play from '../img/play.png';
import pause from '../img/pause.png';
import carRed from '../img/carRed - Copia.png';
import carYellow from '../img/carYellow - Copia.png';
import carGrey from '../img/carGrey - Copia.png';
import carBlue from '../img/carBlue - Copia.png';
import carBrown from '../img/carBrown - Copia.png';
import {
	Container, AreaItems, Item, AreaPlay, BodySettings, Group, Label, Input, AreaInput, Switch, Total, AreaDescription, Description, Title,
	AreaFields, SelectFloat, LabelFloat, LabelField, AreaColors, Color
} from './styles';

export default function Settings(props) {

	const [playIco, setPlayIco] = useState(play);

	const idCruzamento = useParams();

	function dragElement(elmnt) {
		var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
		if (document.getElementById(elmnt.id + "header")) {
			/* se presente, o cabeçalho é de onde você move a DIV:*/
			document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
		} else {
			/* caso contrário, mova a DIV de qualquer lugar dentro do DIV:*/
			elmnt.onmousedown = dragMouseDown;
		}

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			// obtém a posição do cursor do mouse na inicialização:
			pos3 = e.clientX;
			pos4 = e.clientY;
			document.onmouseup = closeDragElement;
			// chama uma função sempre que o cursor se move:
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			// calcula a nova posição do cursor:
			pos1 = pos3 - e.clientX;
			pos2 = pos4 - e.clientY;
			pos3 = e.clientX;
			pos4 = e.clientY;
			// define a nova posição do elemento:
			elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
			elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
		}

		function closeDragElement() {
			/* para de se mover quando o botão do mouse é liberado:*/
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	const getSwitch = () => {
		if (props.changeCars) {
			props.setChangeCars(false);
		} else {
			props.setChangeCars(true);
		}
	}

	const getColor = (color) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.color = color;
			} else {
				return;
			}
		});

		props.setObject(values);
	}

	const getGrupo = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.grupo = parseInt(value);
			} else {
				return;
			}
		});

		props.setObject(values);
	}

	const getDetector = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.detector = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getAnel = (value) => {
		const values = [...props.object];
		props.object.forEach((items, index) => {
			items.anel = value;
		});
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.detector = value;
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getControlador = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.controlador = value;
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getTamanho = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.tamanho = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getVelocidade = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.velocidade = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getPercurso = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.percurso = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getPercursoFinal = (value) => {
		const values = [...props.object];
		values.forEach(item => {
			if (item.id === props.item.id) {
				item.percursoFinal = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getAngulo = (value) => {
		const values = [...props.object];
		props.object.forEach((item, index) => {
			if (item.id === props.item.id) {
				item.angulo = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const getAnguloFinal = (value) => {
		const values = [...props.object];
		props.object.forEach((item, index) => {
			if (item.id === props.item.id) {
				item.anguloFinal = parseInt(value);
			} else {
				return;
			}
		});
		props.setObject(values);
	}

	const playAnimate = () => {
		if (props.statusPlay === 'stop') {
			setPlayIco(pause);
			props.setStatusPlay('play');
			props.playAnimate()
		} else {
			setPlayIco(play);
			props.setStatusPlay('stop');
			props.stopAnimate();
		}
	}

	//Altera o icone de play na barra de edição
	useEffect(() => {
		if (props.statusPlay === 'stop') {
			setPlayIco(play);
		} else {
			setPlayIco(pause);
		}
	}, [props.statusPlay]);

	return (
		<Container open={props.open}>
			<div id="mydiv" className="element" onMouseOver={(e) => dragElement(document.getElementById("mydiv"))}>
				<div id="mydivheader" className='elementHeader'>
					Configurações
					<div id="close" onClick={() => props.close(false)}><img src={closeModal} alt="" /></div>
				</div>
				<BodySettings>
					<AreaItems>
						<Item type={props.type}>
							<img src={
								props.object.length === 0 ? props.imgObject :
									props.object[props.index].color === 'yellow' ? carYellow :
										props.object[props.index].color === 'red' ? carRed :
											props.object[props.index].color === 'grey' ? carGrey :
												props.object[props.index].color === 'blue' ? carBlue :
													props.object[props.index].color === 'brown' ? carBrown : props.imgObject
							} alt=""
							/>
						</Item>
						<AreaDescription>
							<Description>
								<Title>{props.title}</Title>
							</Description>
							<AreaFields>

								<LabelFloat title={props.title} id='controlador'>
									<SelectFloat onChange={(e) => getControlador(e.target.value)}>
										<option value={idCruzamento.id.length === 0 ? '' : idCruzamento.id}>{idCruzamento.id.length === 0 ? 'Selecione...' : idCruzamento.id}</option>
										{/* select dos ids existentes*/}
										<option value={idCruzamento.id}>{idCruzamento.id}</option>
									</SelectFloat>
									<LabelField active={idCruzamento.id === 'Selecione...' ? false : true}>Controlador</LabelField>
								</LabelFloat>

								<LabelFloat title={props.title} id='anel'>
									<SelectFloat onChange={(e) => getAnel(e.target.value)}>
										<option value={props.object.length === 0 ? '' : props.object[props.index].anel}>Anel {props.object.length === 0 ? 'Selecione...' : props.object[props.index].anel}</option>
										{Array(4).fill(1).map((item, index) => {
											if (props.object.length > 0) {
												if ((index + 1) !== parseInt(props.object[props.index].anel)) {
													return (
														<option value={index + 1}>Anel {index + 1}</option>
													);
												} else {
													return;
												}
											}
										})}
									</SelectFloat>
									<LabelField active={idCruzamento.id === 'Selecione...' ? false : true}>Anel</LabelField>
								</LabelFloat>

								<LabelFloat title={props.title} id='grupo' >
									<SelectFloat onChange={(e) => getGrupo(e.target.value)}>
										<option value={props.object.length === 0 ? '' : props.object[props.index].grupo}>{props.object.length === 0 ? 'Selecione...' : props.object[props.index].grupo}</option>
										{Array(20).fill(1).map((item, index) => {
											if (props.object.length > 0) {
												if ((index + 1) !== props.object[props.index].grupo) {
													return (
														<option value={index + 1}>{index + 1}</option>
													);
												} else {
													return;
												}
											}

										})}
									</SelectFloat>
									<LabelField active={props.object.length === 0 ? 0 : props.object[props.index].grupo}>Grupo</LabelField>
								</LabelFloat>

								<LabelFloat title={props.title} id='detector' type={props.type}>
									<SelectFloat onChange={(e) => getDetector(e.target.value)}>
										<option value={props.object.length === 0 ? '' : props.object[props.index].detector}>{props.object.length === 0 ? 'Selecione...' : props.object[props.index].detector}</option>
										{Array(40).fill(1).map((item, index) => {
											if (props.object.length > 0) {
												if ((index + 1) !== parseInt(props.object[props.index].detector)) {
													return (
														<option value={index + 1}>{index + 1}</option>
													);
												} else {
													return;
												}
											}

										})}
									</SelectFloat>
									<LabelField active={props.object.length === 0 ? 0 : props.object[props.index].detector}>Detector</LabelField>
								</LabelFloat>

							</AreaFields>
						</AreaDescription>
					</AreaItems>
					<AreaPlay onClick={playAnimate}>
						<img src={playIco} alt="" />
					</AreaPlay>
					<Group id='switch' type={props.type}>
						<Label>Trocar objetos por ocupação:</Label>
						<Switch>
							<input type="checkbox" className="switch-input" onClick={getSwitch} />
							<span className="switch-label" data-on="On" data-off="Off"></span>
							<span className="switch-handle"></span>
						</Switch>
					</Group>
					<Group id='rangeColor' type={props.type}>
						<Label>Cor:</Label>
						<AreaColors>
							<Color id='red' onClick={() => getColor('red')}></Color>
							<Color id='grey' onClick={() => getColor('grey')}></Color>
							<Color id='yellow' onClick={() => getColor('yellow')}></Color>
							<Color id='blue' onClick={() => getColor('blue')}></Color>
							<Color id='brown' onClick={() => getColor('brown')}></Color>
						</AreaColors>
					</Group>
					<Group id='rangeTamanho' type={props.type}>
						<Label>Tamanho:</Label>
						<AreaInput>
							<Input type="range" min="0" max="200" value={props.object.length === 0 ? 0 : props.object[props.index].tamanho} onChange={(e) => getTamanho(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].tamanho}</Total>
						</AreaInput>
					</Group>
					<Group id='rangeVelocidade' type={props.type}>
						<Label>Velocidade:</Label>
						<AreaInput>
							<Input type="range" min="0" max="10" value={props.object.length === 0 ? 0 : props.object[props.index].velocidade} onChange={(e) => getVelocidade(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].velocidade}</Total>
						</AreaInput>
					</Group>
					<Group id='rangePercurso' type={props.type}>
						<Label>Percurso:</Label>
						<AreaInput>
							<Input type="range" min="0" max="100" value={props.object.length === 0 ? 0 : props.object[props.index].percurso} onChange={(e) => getPercurso(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].percurso}</Total>
						</AreaInput>
					</Group>
					<Group id='rangePercursoFinal' type={props.type}>
						<Label>{props.type === 'car-curve' ? 'Percurso da curva final:' : 'Percurso:'}</Label>
						<AreaInput>
							<Input type="range" min="0" max="100" value={props.object.length === 0 ? 0 : props.object[props.index].percursoFinal} onChange={(e) => getPercursoFinal(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].percursoFinal}</Total>
						</AreaInput>
					</Group>

					<Group id='rangeAngulo' type={props.type}>
						<Label>Ângulo:</Label>
						<AreaInput>
							<Input type="range" min="0" max="360" value={props.object.length === 0 ? 0 : props.object[props.index].angulo} onChange={(e) => getAngulo(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].angulo}</Total>
						</AreaInput>
					</Group>

					<Group id='rangeAnguloFinal' type={props.type}>
						<Label>Ângulo Final:</Label>
						<AreaInput>
							<Input type="range" min="0" max="360" value={props.object.length === 0 ? 0 : props.object[props.index].anguloFinal} onChange={(e) => getAnguloFinal(e.target.value)} />
							<Total>{props.object.length === 0 ? 0 : props.object[props.index].anguloFinal}</Total>
						</AreaInput>
					</Group>

				</BodySettings>
			</div>
		</Container>
	);
}
