import styled, { css } from "styled-components";

const dragActive = css`
   border-color: #78e5d5;
 `;

const dragReject = css`
   border-color: #e57878;
 `;

type IDropContainer = {
    isDragActive?: boolean;
    isDragReject?: boolean;
};

interface Props {
    position: string;
    visible: string;
    AreaActive: string;
    view: string;
    edit: boolean;
    update: boolean;

}

export const DropContainer = styled.div<IDropContainer>`
  
   transition: height 0.2s ease;
 
   ${(props: any) => props.isDragActive && dragActive};
   ${(props: any) => props.isDragReject && dragReject};

   
    .container{
        .MuiDialogContent-root.content-dialog {
            //width: 100% !important;
        }
        .MuiFormControl-root.makeStyles-formControl-6 {
            width: 96%;
        }
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
            height: 40px;
        }
    }
`

export const AreaButtonAdd = styled.div`
    display: flex;
    align-items: center;
    gap: 7px;
    padding-left: 3px;
    justify-content: flex-end;

    span{
        display: block;
        font-size: 12px;
        font-family: 'roboto';
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        -webkit-letter-spacing: 0em;
        -moz-letter-spacing: 0em;
        -ms-letter-spacing: 0em;
        letter-spacing: 0em;
        color: #020202;
        padding-bottom: 3px;
        user-select: none;
    }

    .button{
        border: 1px solid #001E62;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px 0 3px 0;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }
    }
`;

export const AreaMultiplyFields = styled.div`
    border: 1px solid #A4A4A4;
    max-height: 100px;
    min-height: 60px;
    height: auto;
    border-radius: 4px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 1px;
    padding-left: 9px;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 5px;
    padding-bottom: 15px;

    .fields{
        width: 98%;
        margin-top: 2px;

        label{
            user-select: none;
        }
    }

    .areaInputs{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;

        .btnRemove{
            cursor: pointer;

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }

    select{
            height: 28px;
            width: 98%;
            text-indent: 5px !important;
            border: 1px solid #afaeae;
            border-radius: 2px;
            outline: none;
            cursor: pointer;
    }
;

`;

const messageColors = {
    default: "#999",
    error: "#e57878",
    success: "#78e5d5",
};

interface ITypeMessageColor {
    type?: "default" | "error" | "success";
}

export const UploadMessage = styled.p<ITypeMessageColor>`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const AreaLicensa = styled.div`
    &.enabled{
        pointer-events: auto;
	    opacity: 1;
    }

    &.disabled{
        pointer-events: none;
        opacity: 0.4;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const Button = styled.div<Props>`
    background: #001E62;
    border: 1px solid #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    box-sizing: border-box;
    font-size: 13px;
    user-select: none;
    

    &#salvar{
        display: ${props => props.update === false ? 'flex' : 'none'};
    }

    &#editar{
        display: ${props => props.update === false ? 'none' : 'flex'};
    }

    &:hover{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
        transition: .3s;
    }

    @media(max-width: 1500px){
        height: 27px;
    }

    &.btnImportar{
        margin-top: 11px;
        width: 166px;
        font-size: 13px;
        margin-left: 20px;
        margin-right: 2px;
    }

    &.btnNovo{
        margin-top: 0;
        width: 120px;
        display: flex;
        justify-content: center;
        
        &:hover svg path{
            fill: #001E62;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
            margin-bottom: 5px; 
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg {
        width: 65px;
        margin-top: 7px;
    }
`;

export const Modal = styled.div`
    width: auto;
    min-width: 553px;
    max-width: 1000px;

    @media tv,(max-width: 1500px){
        min-width: 553px;
        width: auto;
        height: 500px;
    }

    @media(max-width: 420px){
        min-width: 410px;
        width: auto;
        height: 485px; 
    }

    

    .MuiDialogContent-root.content-dialog {
        width: 90%;
        margin: 0 auto;
        height: 82.5vh;
        //height: auto;

        @media(max-width: 1280px){
            height: 388px;
        }

        &::-webkit-scrollbar {
		    width: 8px;
            height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #717171;
		}
    }
}
`;

export const AreaTabs = styled.div`
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(max-width: 420px){
        margin-left: -15%;
    }
`;

export const Tabs = styled.ul<Props>`
    position: relative;
    margin: -5px 0 20px -64px;

    ul{
        display: flex;
        &:before {
            content: '';
            width: 151px;
            height: 3px;
            background: #062467;
            position: absolute;
            bottom: -5px;
            left: ${props => props.position + 'px'};
            transition: .3s ease-out;
        }
    }
    
    li{
        list-style: none;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        color: #001E62;
        height: 23px;
        font-size: 14px;
        user-select: none;

        &#inactive{
            font-weight: normal;
        }

        &#active{
            transition: .3s ease-out;
            color: #001E62;
            font-weight: 900;
            letter-spacing: .5px;
        }
    }
`;

export const ContentModal = styled.form<Props>`
    margin: 0 auto;
    display: ${props => props.visible};
    label{
        display: block;
        font-size: 13px;
        font-family: 'roboto';
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: #020202;
        padding-bottom: 3px;
    }

    fieldset{
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        margin-bottom: 21px;

        @media(max-width: 1280px){
            margin-bottom: 9px;
        }

        @media(max-width: 420px){
            width: 100%;
            margin-left: -10%;
        }
        @media(max-width: 375px){
            width: 91%;
            margin-left: -10%;
        }
        @media(max-width: 360px){
            width: 87%;
            margin-left: -10%;
        }

        legend{
            font-size: 12px;
            font-style: normal;
            font-weight: 550;
            line-height: 12px;
            letter-spacing: 0em;
            color: #020202;

        }
        input[type="text"] {
            border: 1px solid #A4A4A4;
            height: 27px;
            outline: none;
            border-radius: 2px;
            width: 98%;
            text-indent: 10px;

            &#licenca{
                width: 98%;
                text-indent: 10px;
            }
        }
        #telefone {
            height: 26px;
            width: 98%;
            border: 1px solid #A4A4A4;
            border-radius: 2px;
            outline: none;
        }

        &.idLocal{
            display: flex;
            justify-content: space-between;
            align-items: center;

            select{
                border: 1px solid #A4A4A4;
                height: 29px;
                outline: none;
                border-radius: 2px;
                width: 51%;
                text-indent: 10px;
            }
        }
    }
`;

export const AreaDados = styled.div`
    display: flex;
    justify-content: space-between;

    .areaInputDados{
        width: 50%;
    }

`;

export const AreaInsertFolder = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const BoxFolder = styled.div`
    width: 190px;
    .box{
        border: 1px solid #A4A4A4;
        width: 100%;
        min-height: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 4vw;
        cursor: pointer;
        border-radius: 4px;
        position: relative;

        .dropzone {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 150px;
            height: auto;
            margin-top: 12px;

            img{
                width: 100%;
            }
        }

        @media(max-width: 420px){
            width: 90%;
            margin-left: 10%;
        }
    }

    span{
        color: #54ADFF;
        text-decoration: underline;
        font-size: 12px;
        font-style: normal;
        font-weight: 100;
        line-height: 11px;
        letter-spacing: 0em;
        padding-bottom: 15px;
    }

    img {
        width: 100%;
        height: auto;
        max-height: 110px;
        //max-height: 135px;
    }

    label{
        display: block;

        @media(max-width: 420px){
            display: block;
            width: 90%;
            margin-left: 10%;
        }
    }
`;

export const AreaInputs = styled.form`
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    
    input[type="number"] {
        width: 98%;
        height: 27px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
    }
    input[type="email"] {
        width: 98%;
        height: 27px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
    }
    input[type="date"] {
        width: 98%;
        height: 27px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        color: #020202;
        text-indent: 10px;
    }

    @media(max-width: 420px){
        input {
            font-size: 13px;
        }
    }

    label{
        padding-bottom: 3px;
    }

    .groupone{
        width: 50%;
        .inputTwo{
            margin-top:15px;
        }
    }
    .grouptwo{
        width: 45%;
    }
`;

export const AreaButtons = styled.div<Props>`
    display: flex;
    justify-content: flex-end;
    gap: 25px;
    padding: 0 0 0 20px;
    margin-bottom: 35px;


    .btnEditInfo{
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }
    }

    .alert{
        width: auto;
        display: ${props => props.view};

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0,
        .MuiAlert-standardSuccess, 
        .MuiAlert-standardError,
        .MuiAlert-standardInfo {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: -20px;
        }
    }

    button{
        border: 0;
        width: 115px;
        height: 30px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }

    @media(max-width: 375px){
        justify-content: center;
        margin-left: -25%;
    }
`;

export const ContentLicenca = styled.div<Props>`
    display: ${props => props.visible};
`;
