import styled from 'styled-components';

export const Header = styled.header`
    width: 100%;
    height: 50px;
    background: #001E62;
    box-shadow: 2px 6px 6px 0px #79797945;
    //position: absolute;
    position: fixed;
    top: 0;
    z-index: 1;

    img{
        margin: 0 0 0 15px;
        width: 90px;
        height: 50px;
        cursor: pointer;
    }
`;

export const Voltar = styled.div`
    display: none;
    @media(max-width: 420px){
        display: block;
        position: absolute;
        top: 17px;
        left: 135px;
    }
    svg{
        transform: rotate(180deg);
    }
`;