import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../../PageTitle';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import UpdateZone from '../../UpdateInfos/UpdateZone';
import LineTable from "../../LineTable";
import api from "../../../services/api";
import Draggable from 'react-draggable';
import { Container, Close, AreaInput, AreaButton, ButtonAdd } from './styled';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../../services/permission';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function InfoAreas(props) {

    const [infoschedulesZones, setInfoschedulesZones] = useState([]);
    const [nameZone, setNameZone] = useState('');
    const [openUpdateZone, setOpenUpdateZone] = useState(false);

    const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

    const getInfoZones = async () => {
        api.post('centraldefalhas/zones/get', {
            "area": props.value
        }).then(res => {
            if (typeof res.data.error != 'undefined') {
                return
            }
            if (res.status === 200) {
                if (res.data.error === "Failed to find Zone") {
                    return 0
                } else {
                    setInfoschedulesZones(res.data[0].infos.reverse());
                    console.log(res.data[0].infos);
                }
            }
        }).catch(error => {
            //window.location.href = "/";
            console.log(error);
        })
    }

    const clickCloseUpdateZone = () => {
        setOpenUpdateZone(false);
        window.location.href = "#"
    };

    useEffect(() => {
        getInfoZones();
    }, [props.open, openUpdateZone]);

    const editZone = () => {
        setNameZone(props.value);
        setOpenUpdateZone(true);
    }

    const deleteController = async (id) => {
        if (window.confirm('Confirme para tirar o equipamento da área')) {
            toast.info(`aguarde...`, {
                position: "top-center",
                toastId: "loadingDeleted",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            await api.post('centraldefalhas/zones/idRemove', {
                "area": props.value,
                "id_cruzamento": id
            }).then(res => {
                if (res.data.status === "Sucess") {
                    toast.update("loadingDeleted", {
                        render: `Equipamento retirado da área ${props.value}!`,
                        type: toast.TYPE.SUCCESS,
                        position: "top-center",
                        autoClose: 4000,
                    })
                    return getInfoZones();
                } else {
                    toast.update("loadingDeleted", {
                        render: `Erro ao retirar equipamento da área ${props.value}!`,
                        type: toast.TYPE.ERROR,
                        position: "top-center",
                        autoClose: 4000,
                    })
                }
            }).catch(error => {
                toast.update("loadingDeleted", {
                    render: `${error}!`,
                    type: toast.TYPE.ERROR,
                    position: "top-center",
                    autoClose: 4000,
                })
            })
        }
    }

    return (
        <>
            <Dialog
                key={props.key}
                open={props.open}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Informações da Área"
                    onClick={props.onClose}
                ></PageTitle>
                <Container className={permissao}>
                    <Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
                    <fieldset>
                        <legend>Nome da Área</legend>
                        <AreaInput>
                            <input type="text" value={props.value} />
                        </AreaInput>
                    </fieldset>
                    <fieldset>
                        <legend>Id dos locais</legend>
                        {infoschedulesZones.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    <AreaInput>
                                        <input type="text" value={data.id_cruzamento} />
                                        <LineTable idCruzamento={data.id_cruzamento} grupo={data.grupo} deleteController={deleteController}></LineTable>
                                    </AreaInput>
                                </div>
                            )
                        })}
                    </fieldset>
                    <AreaButton>
                        <ButtonAdd onClick={editZone}>
                            {"Editar"}
                        </ButtonAdd>
                    </AreaButton>
                </Container>
            </Dialog>
            <UpdateZone
                open={openUpdateZone}
                value={nameZone}
                onClose={clickCloseUpdateZone}
                valueIds={infoschedulesZones}
            >
                Adicionar equipamento
            </UpdateZone>
        </>
    );
}

export default InfoAreas;