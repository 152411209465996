import styled from 'styled-components';

export const Container = styled.section`
    #enabledInputs{
        pointer-events: none;
        opacity: 0.4;
    }

    #desabledInputs{
        pointer-events: auto;
	    opacity: 1;
    }

    #enabled{
        pointer-events: none;
        opacity: 0.4;
    }

    #desabled{
        pointer-events: auto;
	    opacity: 1;
    }
`;

export const AreaIcon = styled.div`
    position: relative;
    
    .icon{
        position: absolute;
        width: 16px;
        height: 16px;
        right: 60px;
        cursor: pointer;

        @media(max-width: 420px){
            margin-top: -15px;
            position: absolute;
            width: 16px;
            height: 16px;
            right: 60px;
            cursor: pointer;
        }

        svg{
            path{
                stroke: #062467;
            }
        }
    }
`;

export const AreaInput = styled.div`

`;

export const InfoControlador = styled.div`
    display: ${props => props.view == false ? 'none' : 'block'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }
        label{
            cursor: pointer;
        }

        audio{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const Inputs = styled.div`
    display: flex;
    width: 84%;
    margin: 2px auto; 

    @media(max-width: 580px){
        width: 95%;
        gap: 5px;
        margin-top: 15px;
        margin-bottom: 10px;
    }
`;

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    font-family: 'roboto', sans-serif;
    font-size: 13px;
    margin: 15px auto;

    @media(max-width: 420px){
        margin: 4px auto;
        width: 90%;
    }

    label {
        margin-bottom: 2px;
        @media(max-width: 420px){
            font-size: 12px;
        }
    }

    input{
        height: 30px;
        text-indent: 7px;
        outline: none;
        border-radius: 3px;
        border: 1px solid gray;
        box-sizing: border-box;

        &#modelo{
            width: 295px;
            @media(max-width: 580px){
                width: 100%;
            }
        }
        &#fases{
            width: 100px;

            @media(max-width: 580px){
                width: 100%;
            }
        }
        &#detectores{
            width: 100px;
            @media(max-width: 580px){
                width: 100%;
            }
        }

        @media(max-width: 1280px){
            height: 26px;
        }
        
        &:focus{
            border: 2px solid #001E62;
        }
    }
`;

export const AreaButtons = styled.div`
    width: 92%;
    display: flex;
    justify-content: flex-end;
    display: flex;

    @media(max-width: 580px){
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    &.disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    .alert{
        display: ${props => props.message};
        width: 255px;
        height: 10px;
        margin-right: 22px;

        .MuiAlert-root{
            padding-left: 5px !important;
            height: 20px;
            display: flex;
            align-items: center;
        }

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0 {
            height: 20px;
            width: 262px;
            display: flex;
            align-items: center;
            margin-left: -10px;
        }

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardError.MuiPaper-elevation0 {
            width: 267px;
            margin-left: -16px;
        }
    }

    button{
        border: 0;
        cursor: pointer;
        width: 70px;

        &.btnAdd{
            border: 1px solid #001E62;
            background: #FFF;
            height: 30px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: 1280px){
                height: 22px;
            }

            &:active{
                position: relative;
                top: 1px
            }
        }

        &.btnSave{
            background: #001E62;
            color: #FFF;
            max-height: 30px;
            border-radius: 5px;
            text-transform: uppercase;

            &:hover{
                background: #FFF;
                border: 1px solid #001E62;
                color: #001E62;
                transition: .5s;
            }

            &:active{
                position: relative;
                top: 1px;
                background: #001E62;
                color: #FFF;
            }
        }

        &.btnAdd{
            text-transform: uppercase;
            background: #FFF;
            color: #001E62;
            border-radius: 5px;

            &:hover{
                background: #001E62;
                border: 1px solid #001E62;
                color: #FFF;
                transition: .5s;
            }

            &:active{
                position: relative;
                top: 1px;
                background: #FFF;
                color: #001E62;
            }
        }
    }
    
`;

export const Button = styled.div`
    background: #001E62;
    border: 1px solid #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    box-sizing: border-box;
    font-size: 12px;
    &:hover{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
        transition: .3s;
    }

    @media(max-width: 1500px){
        height: 27px;
    }
    @media(max-width: 420px){
        height: 29px;
        width: 60%;
    }
    &.btnSalvar{
        width: 110px;
        font-size: 13px;
        margin-left: 15px;
        margin-right: 2px;
        @media(max-width: 580px){
            width: 100px;
        }
    }
    &#btnNovo{
        margin-top: 0;
        width: 110px;
        display: flex;
        justify-content: center;

        &.disable{
            pointer-events: none;
            opacity: 0.4;
        }

        &.enable{
            pointer-events: auto;
            opacity: 1;
        }

        @media(max-width: 580px){
            width: 100px;
        }
        
        &:hover svg path{
            fill: #001E62;
        }
        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }
    }
    &:active{
        position: relative;
        top: 1px;
    }
    svg {
        width: 65px;
        margin-top: 0;
    }
`;

export const AreaTable = styled.div`
    width: 84%;
    margin: 15px auto;
`;

export const Table = styled.table`
    font-family: 'roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;

    td{
        border: 1px solid #9e9e9e;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        height: 27px;

        &:nth-of-type(1){
            
        }
        &:nth-of-type(2){
            
        }
        &:nth-of-type(3){
            
        }
        &:nth-of-type(4){
            width: 70px;
            position: relative;
        }
        img {
            width: 16px;
            height: auto;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;

            &.disabled{ // classe para desabilitar permissões
                pointer-events: none;
                opacity: 0.4;
            }

            &:hover{
                width: 20px;
                height: auto; 
                transition: .3s;
            }
        }
    }
    th {
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
        
        &:nth-of-type(1){
            border-radius: 5px 0 0 0;
        }
        &:nth-of-type(2){
          
        }
        &:nth-of-type(3){
          
        }
        &:nth-of-type(4){
            border-radius: 0px 5px 0 0;
        }
    }

    tr{
        &:hover {
            background-color: #ddd;
        }
    }

`;