import React, { useEffect, useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Container, Close, AreaButton, Button } from './styles';
import Alert from '@material-ui/lab/Alert';
import api from "../../../services/api";
import './styles.css';
import { GrClose } from "react-icons/gr";

const permissions = [
	{
		value: '1',
		label: 'Gestor',
	},
	{
		value: '2',
		label: 'Operador',
	},
	{
		value: '3',
		label: 'Visitante',
	},
];

export default function RegisterCad(props) {

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [townhall, setTownhall] = useState('');
	const [permission, setPermission] = useState('');
	const [dadosApi, setDadosApi] = useState([]);
	const [alert, setAlert] = useState('hide');
	const [msg, setMsg] = useState('');
	const [alertMsg, setAlertMsg] = useState('block');
	const [typeAlert, setTypeAlert] = useState('');

	const handleSignUp = async (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setAlert('show')
			setTimeout(function () {
				setAlert('hide');
			}, 5000);

		} else {

			await api.post('/centraldefalhas/user', {
				'name': name,
				'townhall': townhall,
				'permission': permission,
				'email': email,
				'password': password,
				'timerMap': localStorage.getItem('@central-de-falhas-greenwave/setTimeReload'),
				'lat': localStorage.getItem('@central-de-falhas-greenwave/positionLat'),
				'long': localStorage.getItem('@central-de-falhas-greenwave/positionLng'),
				'zoom': localStorage.getItem('@central-de-falhas-greenwave/zoom')

			}).then(res => {
				setMsg('Novo cadastro realizado com sucesso!');
				setAlertMsg('block');
				setTypeAlert('success');
				setTimeout(() => {
					setAlertMsg('none');
				}, 3000);
			}).catch(err => {

				setMsg('Algo deu errado, registro não salvo"!');
				setAlertMsg('block');
				setTypeAlert('success');
				setTimeout(() => {
					setAlertMsg('none');
				}, 3000);
			});

			setName('');
			setEmail('');
			setPassword('');
			setTownhall('');
			setConfirmPassword('');
			setDadosApi([]);
		}
	}

	async function lerlicenca() {
		const response = await api.get('/centraldefalhas/licenca/list');
		setDadosApi(response.data);
	}

	useEffect(() => {

		if (props.atualizar == 'block');
		lerlicenca();

	}, [props.atualizar]);

	return (
		<Container>
			<Close onClick={props.onClose}><GrClose size={14} /></Close>
			<form onSubmit={handleSignUp}>
				<fieldset>
					<legend>Dados</legend>
					<div className="groupTop">
						<div className="group">
							<label>Nome:</label>
							<input type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
						</div>
						<div className="group">
							<label>E-mail: </label>
							<input type="email" required id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
					</div>
					<div className="groupTop">
						<div className="group">
							<label>Senha:</label>
							<input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
						</div>
						<div className="group">
							<label>Confirmar senha:</label>
							<input type="password" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
						</div>
					</div>
					<div className={alert}>
						<Alert severity="error">Senhas não coincidem - Tente novamente!</Alert>
					</div>
				</fieldset>
				<fieldset>
					<legend>Preferências</legend>
					<div className="groupBottom">
						<div className='group'>
							<label>Permissão:</label>
							<select name="permission" id="permission" onChange={(e) => setPermission(e.target.value)}>
								<option value="">Selecione...</option>
								{permissions.map(option => (
									<option key={option.value} value={option.value}>{option.label}</option>
								))}
							</select>
						</div>
						<div className='group'>
							<label>Nome da Licença:</label>
							<select name="licenca" id="licenca" onClick={lerlicenca} onChange={(e) => setTownhall(e.target.value)}>
								<option value="">Selecione...</option>
								{dadosApi.map(items => (
									<option key={items.townhall} value={items.townhall}>{items.townhall}</option>
								))}
							</select>
						</div>
					</div>
				</fieldset>
				<AreaButton view={alertMsg}>
					<div className="alert">
						<Alert severity={typeAlert}>{msg}</Alert>
					</div>
					<Button>
						Cadastrar
					</Button>
				</AreaButton>
			</form>
		</Container>
	);
}
