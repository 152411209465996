import styled from 'styled-components';

interface Props {
    openMenu: string;
}

export const Container = styled.section`
    background: #001E62;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 2px 6px 6px 0px #79797945;
`;

export const NavHorizontal = styled.nav`
    display: flex;
    align-items: center;
    width: 95%;

    @media(max-width: 414px){
        width: 99%;
        justify-content: space-between;
    }

    .MuiPaper-elevation8.MuiPaper-rounded {
        max-height: 250px !important;
        width: auto !important;
        margin-top: 30px;
    }

    .content-logo-central{
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: var(--color-button-menu);
        width: 100%;
        height: 50px;

        img{
            width: 90px;
            height: 50px;
            margin: 0px 10px 0px 15px;
            color: #fff;
        }

        h3{
            margin-right: 5px;
            color: #040a3acb;
            font-family: Roboto,Helvetica Neue,Arial,sans-serif;
            width: auto;
            position: absolute;
            color: #FFF;
            left: 150px;
        }
    }
`;

export const Links = styled.div<Props>`
    position: absolute;
    right: 2%;

        @media(max-width: 580px){
            position: absolute;
            top: 50px;
            right: 0;
            display: flex;
            flex-direction: column;
            /*width: 60%;
            height: 200px;*/
            width: 100%;
            height: ${props => props.openMenu};
            overflow: hidden;
            background: #001E62;
            transition: .3s;

            .area-btn{
                svg{
                    width: 20px;
                    height: 20px;
                }
            }

        }

        button{
            text-transform: initial !important;

            @media(max-width: 580px){
                padding: 30px;

                &:active{
                   background: #6fce72; 
                }
            }

            img {
                margin: 0px 7px;
            }

            span{
                font-family: Montserrat,Helvetica Neue,Arial,sans-serif;
                font-size: 14px;
                color: #fff;
                font-weight: 700;
                margin-right: 15px;
                font-weight: bold;
                text-decoration: none;
                line-height: 16px;
                letter-spacing: 0em;

                @media(max-width: 420px){
                    font-size: 18px;
                }
                @media(max-width: 580px){
                    font-size: 19px;
                }
            }
        }

        .icoMenu{
            margin: 0 5px;
        }

        svg{
            margin: 0 5px;
        }
`;

export const MenuToggle = styled.div`
    width: 15vw;
    height: 30px;
    right: 0;
    position: absolute;
    margin-top: -5px;
    z-index: 1;
    display: none;

    @media(max-width: 580px){
        display: block;
    }
    
    .one,
    .two,
    .three{
        background-color: #FFF;
        height: 3px;
        width: 30px;
        margin: 6px auto;
        transition-duration:0.3s;
    }

    &.on{
        .one{
        transform: rotate(45deg) translate(6px,7px);
        }
        .two{
            opacity: 0;
        }
        .three{
            transform: rotate(-45deg) translate(5px,-7px);
        }
    }
    
`;

export const InfoHelp = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    svg path {
        stroke: #FFF;
    }
`;


export const ContentBallon = styled.div`

    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        audio{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }
        }
    }
`;
