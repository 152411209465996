import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export default function AlertDialog( props ) { // Componentes para criar Modais

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir o log ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            id="Confirmar" 
            className="dialog-button" 
            variant="outlined" 
            title="Confirmar"
            color="primary"
            onClick={props.onClick}
          >
            <CheckIcon />
          </Button>
          <Button 
            id="Cancelar" 
            className="dialog-button" 
            variant="outlined" 
            type="button" 
            color="primary"
            title="Cancelar"
            onClick={props.onClose}
            autoFocus
          >
            <ClearIcon />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}