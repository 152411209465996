import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semLeftWalkGreen from '../../../img/Semaforo/semLeftWalkGreen.png';
import semLeftWalkRed from '../../../img/Semaforo/semLeftWalkRed.png';
import semLeftWalkApagado from '../../../img/Semaforo/semLeftWalkApagado.png';
import semLeftWalkRedPiscante from '../../../img/Semaforo/piscanteLeftWalk.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoWalkLeft(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo Pedestre a esquerda', image: semLeftWalkGreen, object: props.objSemaforoWalkLeft, setObject: props.setObjSemaforoWalkLeft });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforoWalkLeft.map((item, index) => {
                if (props.objSemaforoWalkLeft[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforoWalkLeft'
                            top={item.top}
                            left={item.left}
                            objSemaforoWalkLeft={props.objSemaforoWalkLeft}
                            setObjSemaforoWalkLeft={props.setObjSemaforoWalkLeft}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img
                                src={
                                    item.color_group === undefined || item.color_group === '' ? semLeftWalkApagado :
                                        item.color_group === 1 ? semLeftWalkRed :
                                            item.color_group === 4 ? semLeftWalkGreen :
                                                item.color_group === 17 ? semLeftWalkRedPiscante : semLeftWalkApagado
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforoWalkLeft(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}