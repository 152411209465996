import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import BtnNovo from '../../Icons/BtnNovo';
import api from '../../../../../services/api';
import IconRemove from '../../Icons/IconRemove';
import Parametros from '../../../../Parametros';
import { ContentTab, AreaBtn, Button, ButtonRemoveChip, AreaHidden, AreaButton, InfoHelp, AreaBallon, ContentBallon, Range } from './styles';
import { validarPermissao } from '../../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../../Balloons';
import icoInfo from '../../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../../assets/icons/icoSom.png';
import { vozInfoEquipamentoChip } from '../../../../Audio';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Chip(props) { // permissao state para definir permissões do usuário

    const [areaChip2] = useState('hide');
    const [consorcio, setConsorcio] = useState([]);
    const [instaladora, setInstaladora] = useState([]);
    const [mantenedora, setMantenedora] = useState([]);
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [idLocal, setIdLocal] = useState([]);
    const [infoId, setInfoId] = useState({
        chip1: '',
        chip2: '',
    });
    //chip1
    const [editId_Cruzamento, setEditIdCruzamento] = useState([]);
    const [editConsorcio, setEditConsorcio] = useState([]);
    const [editInstaladora, setEditInstaladora] = useState([]);
    const [editMantenedora, setEditMantenedora] = useState([]);
    const [editIccid, setEditIccid] = useState([]);
    const [editnumero, setEditNumero] = useState([]);
    const [editOperdadora, setEditOperadora] = useState([]);
    const [editPlano, setEditPlano] = useState([]);
    //chip2
    const [editConsorciochip2, setEditConsorciochip2] = useState([]);
    const [editInstaladorachip2, setEditInstaladorachip2] = useState([]);
    const [editMantenedorachip2, setEditMantenedorachip2] = useState([]);
    const [editIccidchip2, setEditIccidchip2] = useState([]);
    const [editnumerochip2, setEditNumerochip2] = useState([]);
    const [editOperdadorachip2, setEditOperadorachip2] = useState([]);
    const [editPlanochip2, setEditPlanochip2] = useState([]);
	const [ipControlador, setIpControlador] = useState('');
	const [portaControlador, setPortaControlador] = useState('');
	const [portaSSH, setPortaSSH] = useState('');
	const [tipoDeRede, setTipoDeRede] = useState('');


    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [unalterable, setUnalterable] = useState('disable');
    const [openParametros, setOpenParametros] = useState(false);


    const saveData = async () => {
        await api.post('/CentralDeFalhas/chip', {
            chip1: {
                'posicao': 1,
                'id_cruzamento': editId_Cruzamento,
                'consorcio': editConsorcio,
                'instaladora': editInstaladora,
                'mantenedora': editMantenedora,
                'iccid': editIccid,
                'telefone': editnumero,
                'operadora': editOperdadora,
                'plano': editPlano,
            },
            chip2: {
                'posicao': 2,
                'id_cruzamento': editId_Cruzamento,
                'consorcio': editConsorciochip2,
                'instaladora': editInstaladorachip2,
                'mantenedora': editMantenedorachip2,
                'iccid': editIccidchip2,
                'telefone': editnumerochip2,
                'operadora': editOperdadorachip2,
                'plano': editPlanochip2,
            }

        }).then(res => {

            setAlertMessage(`Chips ${editId_Cruzamento} cadastrado`);
            setTypeAlert('success');
            setAlertView('block');

			//temporario, salvar informaçoes do controlador
			api.post('/CentralDeFalhas/Controlador/', {
				'ipControlador': ipControlador,
				'portaControlador': portaControlador
			})


        }).catch(err => {

            setAlertMessage(`Erro ao cadastrar Chips ${editId_Cruzamento}`);
            setTypeAlert('error');
            setAlertView('block');

            setTimeout(() => {
                setAlertView('none');
            }, 7000);
        });
    }

    const listData = async () => {
        const response = await api.get('/CentralDeFalhas/local/Lista');
        setIdLocal(response.data);
    }

    const reqInfos = async (id) => {
        setAlertView('none');
        setEditConsorcio('');
        setEditInstaladora('');
        setEditMantenedora('');
        setEditIccid('');
        setEditNumero('');
        setEditOperadora('');
        setEditPlano('');
        //chip 2
        setEditConsorciochip2('');
        setEditInstaladorachip2('');
        setEditMantenedorachip2('');
        setEditIccidchip2('');
        setEditNumerochip2('');
        setEditOperadorachip2('');
        setEditPlanochip2('');
        try {
            const response = await api.get(`/CentralDeFalhas/chip/${id}`);
			const responseControlador = await api.get(`/CentralDeFalhas/Controlador/${id}`);
            setInfoId(response.data);
            //chip1
            setEditIdCruzamento(id);
            setEditConsorcio(response.data.chip1.consorcio);
            setEditInstaladora(response.data.chip1.instaladora);
            setEditMantenedora(response.data.chip1.mantenedora);
            setEditIccid(response.data.chip1.iccid);
            setEditNumero(response.data.chip1.telefone);
            setEditOperadora(response.data.chip1.operadora);
            setEditPlano(response.data.chip1.plano);
            //chip 2
            setEditConsorciochip2(response.data.chip2.consorcio);
            setEditInstaladorachip2(response.data.chip2.instaladora);
            setEditMantenedorachip2(response.data.chip2.mantenedora);
            setEditIccidchip2(response.data.chip2.iccid);
            setEditNumerochip2(response.data.chip2.telefone);
            setEditOperadorachip2(response.data.chip2.operadora);
            setEditPlanochip2(response.data.chip2.plano);


			setIpControlador(responseControlador.data.ipControlador);
			setPortaControlador(responseControlador.data.porta);
			//setPortaSSH(responseControlador.data);
			//setTipoDeRede(responseControlador.data.tipoDeRede);
            //setar button
            setUnalterable('enable');
        } catch (error) { //limpar campos, se a req estiver vazia ele cai em catch
            setEditConsorcio('');
            setEditInstaladora('');
            setEditMantenedora('');
            setEditIccid('');
            setEditNumero('');
            setEditOperadora('');
            setEditPlano('');
            //chip 2
            setEditConsorciochip2('');
            setEditInstaladorachip2('');
            setEditMantenedorachip2('');
            setEditIccidchip2('');
            setEditNumerochip2('');
            setEditOperadorachip2('');
            setEditPlanochip2('');

            setEditIdCruzamento(id);
            const dados = {
                posicao: '',
                id_cruzamento: id,
                consorcio: '',
                instaladora: '',
                mantenedora: '',
                iccid: '',
                telefone: '',
                operadora: '',
                plano: '',
            }
            setInfoId({
                chip1: dados,
                chip2: dados,
            });
            setUnalterable('enable');
        }
    }

    useEffect(() => {
        reqInfos(props.id);
    }, [props.id]);

	useEffect(() => {
        reqInfos(props.id);
    }, []);

    const deleteChip1 = async () => {
        console.log('Chamou função  ')
        await api.delete(`/CentralDeFalhas/chip/${editId_Cruzamento}`);
        const dados = {
            posicao: '',
            id_cruzamento: '',
            consorcio: '',
            instaladora: '',
            mantenedora: '',
            iccid: '',
            telefone: '',
            operadora: '',
            plano: '',
        }
        setInfoId({
            chip1: dados,
            chip2: dados,
        });

        setEditConsorcio('');
        setEditInstaladora('');
        setEditMantenedora('');
        setEditIccid('');
        setEditNumero('');
        setEditOperadora('');
        setEditPlano('');
        //chip 2
        setEditConsorciochip2('');
        setEditInstaladorachip2('');
        setEditMantenedorachip2('');
        setEditIccidchip2('');
        setEditNumerochip2('');
        setEditOperadorachip2('');
        setEditPlanochip2('');

        setAlertMessage(`Chips excluidos!`);
        setTypeAlert('success');
        setAlertView('block');
    }

    const reqConsorcio = async () => {
        const response = await api.get(`/CentralDeFalhas/consorcio`);
        setConsorcio(response.data);
    }

    const reqEmpresa = async () => {
        const response = await api.get(`/CentralDeFalhas/empresa`);
        setInstaladora(response.data);
        setMantenedora(response.data);
    }

    const menuParamentro = () => {
        setOpenParametros(true);
    }

    const handleCloseParametros = () => {
        setOpenParametros(false);
        window.location.href = "#"
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonChip = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Comunicação</h3>
                    <label onClick={vozInfoEquipamentoChip}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Area destinada ao cadastro de chip para a comunicação GPRS com a central, no cadastro devemos
                    informar o local de instalação pelo ID, informar o consórcio responsável pela região, a empresa
                    instaladora, a empresa mantenedora, número de série do sim (ICCID), número do chip (número do celular),
                    operadora do chip e por fim o tipo de plano contratado.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <ContentTab>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonChip /></BallonsInfoModal>
            </AreaBallon>
            <fieldset>
                <legend>ID do local</legend>
                <select onClick={listData} onChange={(e) => reqInfos(e.target.value)}>
                    <option value={props.id}>{props.id}</option>
                    {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(itemIdLocal => {
                        if (itemIdLocal.id_cruzamento !== props.id) {
                            return (
                                <option key={itemIdLocal.id_cruzamento} value={itemIdLocal.id_cruzamento}>{itemIdLocal.id_cruzamento}</option>
                            );
                        } else {
                            return null;
                        }
                    })}
                </select>
            </fieldset>

			<fieldset>
				<legend>Comunicação</legend>
				{/*<form>
					<div className='row'>
						<div className="group column">
							<label>IP:</label>
							<input type="text" value={ipControlador} onChange={(e) => setIpControlador(e.target.value)}/>
						</div>
						<div className="group column">
							<label>Porta Controlador:</label>
							<input type="text" value={portaControlador} onChange={(e) => setPortaControlador(e.target.value)}/>
						</div>
					</div>
					<div className='row'>
						<div className="group column">
							<label>Porta SSH:</label>
							<input type="text" />
						</div>
						<div className="group column">
							<label>Tipo de rede:</label>
							<select>
								<option value="">Selecione...</option>
								<option value="">3G</option>
								<option value="">IOT</option>
								<option value="">Gatway</option>
								<option value="">Fibra</option>
							</select>
						</div>
					</div>
				</form>*/}
			</fieldset>

            <fieldset className={unalterable}>
                <legend>CHIP 1</legend>
                <form>
                    <AreaButton className={permissao}>
                        <ButtonRemoveChip onClick={deleteChip1} title="Deletar chip">
                            <IconRemove />
                        </ButtonRemoveChip>
                    </AreaButton>
                    <div className='row'>
                        <div className="group column">
                            <label>Consórcio:</label>
                            <select name="" id="col-2" onClick={reqConsorcio} value={editConsorcio} onChange={(e) => setEditConsorcio(e.target.value)} required>
                                <option defaultValue={infoId.chip1.consorcio}>{infoId.chip1.consorcio === '' ? 'Selecione...' : infoId.chip1.consorcio}</option>
                                {consorcio.map(item => {
                                    if (item.nome !== infoId.chip1.consorcio) {
                                        return (
                                            <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </select>
                        </div>
                        <div className="group column">
                            <label>Instaladora:</label>
                            <select name="" id="col-2" onClick={reqEmpresa} value={editInstaladora} onChange={(e) => setEditInstaladora(e.target.value)} required>
                                <option defaultValue={infoId.chip1.instaladora}>{infoId.chip1.instaladora === '' ? 'Selecione...' : infoId.chip1.instaladora}</option>
                                {instaladora.map(item => (
                                    <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group column" id="campoMantenedora">
                            <label>Mantenedora:</label>
                            <select name="" id="col-2" onClick={reqEmpresa} value={editMantenedora} onChange={(e) => setEditMantenedora(e.target.value)} required>
                                <option defaultValue={infoId.chip1.mantenedora}>{infoId.chip1.mantenedora === '' ? 'Selecione...' : infoId.chip1.mantenedora}</option>
                                {mantenedora.map(item => {
                                    if (item.nome !== infoId.chip1.mantenedora) {
                                        return (
                                            <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                        )
                                    }
                                })}
                            </select>
                        </div>
                        <div className={permissao}>
                            <Button className="btnNovo" onClick={menuParamentro}>
                                <BtnNovo />NOVO
                            </Button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group column">
                            <label>ICCID:</label>
                            <input type="text" defaultValue={infoId.chip1.iccid} value={editIccid} onChange={(e) => setEditIccid(e.target.value)} required />
                        </div>
                        <div className="group column">
                            <label>Número:</label>
                            <input type="text" defaultValue={infoId.chip1.telefone} value={editnumero} onChange={(e) => setEditNumero(e.target.value)} required />
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group column">
                            <label>Operadora:</label>
                            <input type="text" defaultValue={infoId.chip1.operadora} value={editOperdadora} onChange={(e) => setEditOperadora(e.target.value)} required />
                        </div>
                        <div className="group column">
                            <label>Plano:</label>
                            <input type="text" defaultValue={infoId.chip1.plano} value={editPlano} onChange={(e) => setEditPlano(e.target.value)} required />
                        </div>
                    </div>
                </form>
            </fieldset>
            <AreaHidden id={areaChip2}>
                <fieldset className={unalterable}>
                    <legend>CHIP 2</legend>
                    <form>
                        <AreaButton className={permissao}>
                            <ButtonRemoveChip onClick={deleteChip1} title="Deletar chip" >
                                <IconRemove />
                            </ButtonRemoveChip>
                        </AreaButton>
                        <div className='row'>
                            <div className="group column">
                                <label>Consórcio:</label>
                                <select name="" id="col-2" onClick={reqConsorcio} value={editConsorciochip2} onChange={(e) => setEditConsorciochip2(e.target.value)} required>
                                    <option defaultValue={infoId.chip2.consorcio}>{infoId.chip2.consorcio === '' ? 'Selecione...' : infoId.chip2.consorcio}</option>
                                    {consorcio.map(item => {
                                        if (item.nome !== infoId.chip2.consorcio) {
                                            return (
                                                <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            <div className="group column">
                                <label>Instaladora:</label>
                                <select name="" id="col-2" onClick={reqEmpresa} value={editInstaladorachip2} onChange={(e) => setEditInstaladorachip2(e.target.value)} required>
                                    <option defaultValue={infoId.chip2.instaladora}>{infoId.chip2.instaladora === '' ? 'Selecione' : infoId.chip2.instaladora}</option>
                                    {instaladora.map(item => {
                                        if (item.nome !== infoId.chip2.instaladora) {
                                            return (
                                                <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="group column" id="campoMantenedora">
                                <label>Mantenedora:</label>
                                <select name="" id="col-2" onClick={reqEmpresa} value={editMantenedorachip2} onChange={(e) => setEditMantenedorachip2(e.target.value)} required>
                                    <option defaultValue={infoId.chip2.mantenedora}>{infoId.chip2.mantenedora === '' ? 'Selecione...' : infoId.chip2.mantenedora}</option>
                                    {mantenedora.map(item => {
                                        if (item.nome !== infoId.chip2.mantenedora) {
                                            return (
                                                <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                            );
                                        }
                                    })}
                                </select>
                            </div>
                            <div className={permissao}>
                                <Button className="btnNovo" onClick={menuParamentro}>
                                    <BtnNovo />NOVO
                                </Button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="group column">
                                <label>ICCID:</label>
                                <input type="text" defaultValue={infoId.chip2.iccid} value={editIccidchip2} onChange={(e) => setEditIccidchip2(e.target.value)} required />
                            </div>
                            <div className="group column">
                                <label>Número:</label>
                                <input type="text" defaultValue={infoId.chip2.telefone} value={editnumerochip2} onChange={(e) => setEditNumerochip2(e.target.value)} required />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="group column">
                                <label>Operadora:</label>
                                <input type="text" defaultValue={infoId.chip2.operadora} value={editOperdadorachip2} onChange={(e) => setEditOperadorachip2(e.target.value)} required />
                            </div>
                            <div className="group column">
                                <label>Plano:</label>
                                <input type="text" defaultValue={infoId.chip2.plano} value={editPlanochip2} onChange={(e) => setEditPlanochip2(e.target.value)} required />
                            </div>
                        </div>
                    </form>
                </fieldset>
            </AreaHidden>
            <AreaBtn view={alertView} className={permissao}>
                <div className="alert">
                    <Alert severity={typeAlert}>{alertMessage}</Alert>
                </div>
                <Button text="Salvar" width="92" heigth="41" className="btnSalvar" onClick={saveData}>SALVAR</Button>
            </AreaBtn>
            <Parametros open={openParametros} onClose={handleCloseParametros} />
        </ContentTab>
    )
}