import styled from 'styled-components';

export const Container = styled.section`
    position: absolute;
    //right: 0;
    right: ${props => props.slider};
    //z-index: 998;
    z-index: ${props => props.position};
    display: flex;
    transition: all .5s ease-out;
    top: 195px;
    margin-right: 5px;
    

    @media(max-width: 414px){
        right: ${props => props.slider === '-603px' ? '-125%' : ''};
    }
    @media(max-width: 380px){
        right: ${props => props.slider === '-603px' ? '-128%' : ''};
    }

`;

export const AreaClose = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 414px){
        display: block;
        margin-right: 15px;
        z-index: 1;
    }
`;

export const Ticket = styled.div`
    width: 137px;
    height: 59px;
    background-color: #001E62;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    gap: 8px;
    cursor: pointer;
    img{
        margin-right: 2px;
    }

    @media(max-width: 1280px){
        height: 40px;
        font-size: 14px;
    }
`;

export const Abas = styled.div`
    display: flex;
    font-family: 'roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    user-select: none;
`;

export const Operacional = styled.div`
    flex: 1;
    display: flex;
    height: 59px;
    align-items: center;
    justify-content: center;
    border-radius: 0 6px 6px 0;
    cursor: pointer;

    @media(max-width: 1280px){
        height: 40px;
    }

    &.active{
        background: #FFF;
        color: #001E62;
        transition: .5s;
    }

    &.deactive{
        background: #001E62;
        color: #FFF;
        transition: .5s;
    }
`;

export const Status = styled.div`
    flex: 1;
    display: flex;
    height: 59px;
    align-items: center;
    justify-content: center;
    border-radius: 6px 0 0 6px;
    cursor: pointer;

    @media(max-width: 1280px){
        height: 40px;
    }

    &.active{
        background: #FFF;
        color: #001E62;
        transition: .5s;
    }

    &.deactive{
        background: #001E62;
        color: #FFF;
        transition: .5s;
    }
`;

export const Content = styled.div`
    background: #FFF;
    box-shadow: -6px 6px 3px -1px #79797945;
    height: 735px;
    width: 500px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media(max-width: 1600px){
        height: 555px;
    }

    @media(max-width: 1500px){
        height: 422px;
    }
    @media(max-width: 1366px){
        height: 457px;
    }
    @media(max-width: 1280px){
        height: 370px;
    }
    @media(max-width: 768px){
        height: 100vh;
    }
    @media(max-width: 420px){
        height: 100vh !important;
        width: 100vw;
        margin-top: -145px;  
    }

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #717171;
		}
	}
`;

export const ContentOperacional = styled.section`
    display: ${props => props.visible};
    position: relative;
`;

export const ContentStatus = styled.section`
    display: ${props => props.visible};
    position: relative;
`;

