import styled from 'styled-components';

export const ContentTab = styled.section`
    width: 92%;
    margin: 0 auto;
    user-select: none;
    font-family: 'roboto';
    position: relative;
   

    fieldset{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: auto;
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        position: relative;
        height: auto;
        
        &:nth-child(2) {
            margin-top: 20px;

            @media(max-width: 1500){
                margin-top: 10px;
            }
        }
        &:nth-child(3) {
            &#hide{
                display: none;
            }
            &#show{
                display: block;
            }
        }

        &:first-child {
            height: 50px;
            margin: 0 auto;

            @media(max-width: 1500px){
                height: 42px;
            }
        }

        &.disable{
            pointer-events: none;
            opacity: 0.4;
            height: auto;
            margin: 20px auto;
        }

        &.enable{
            pointer-events: auto;
            opacity: 1;
            height: auto;
            margin: 0 auto;
        }

        select {
            width: 100%;
            height: 28px;
            border-radius: 2px;
            text-indent: 10px;
            border: 1px solid #A4A4A4;
            font-size: 13px;
            margin-bottom: 5px;

            @media(max-width: 1500px){
                height: 27px;
                margin-bottom: 0;
            }
        }

        legend {
            font-size: 12px;
            color: #1d1c1c;
        }

        svg{
            margin-bottom: 7px;
            margin-right: 2%;
            cursor: pointer;
        }

        form{
            width: 100%;
            .row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px auto;

                @media(max-width: 1500px){
                    margin: 0 auto;
                }
                .group {
                    display: flex;
                    flex-direction: column;
                    width: 49%;

                    #col-2{
                        width: 98%;
                    }

                    &.column input {
                        width: 95%;
                    }

                    label{
                        font-size: 13px;
                        color: #1d1c1c;

                        @media(max-width: 420px){
                            font-size: 11px;
                        }
                    }

                    select {
                        //width: 359px;
                        height: 28px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #A4A4A4;
                        margin-bottom: 5px;
                        font-size: 13px;
                        @media(max-width: 1500px){
                            height: 27px;
                        }
                        @media(max-width: 420px){
                            font-size: 11px;
                        }

						outline: none;
                    }

                    input{
                        height: 25px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #A4A4A4;
                        margin-bottom: 8px;
                        outline: none;
                        font-size: 13px;
                        @media(max-width: 1500px){
                            height: 25px;
                        }
                        @media(max-width: 420px){
                            font-size: 11px;
                        }
                    }
                }

                #campoMantenedora{
                    margin-right: 60px;

                    @media(max-width: 420px){
                        margin-right: 21px;
                    }
                }
            }
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -22px;
    right: 0;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;

        @media(max-width: 420px){
            font-size: 11px;
        }
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 12px;
            font-weight: normal;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const Button = styled.button`
    background: #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-size: 12px;
    font-weight: normal;
    user-select: none;  
    margin-right: 13px;
    border: 1px solid #001E62;
    color: #FFF;
    margin-right: 20px;

    @media(max-width: 420px){
        margin-right: 0;
        font-size: 11px;
    }

    &:hover{
        background: #FFF;
        color: #001E62;
    }

    @media(max-width: 1500px){
        height: 29px;
    }

    &.btnImportar{
        margin-top:11px;
    }

    &.btnNovo{
        margin-top: 12px;
        margin-right: 8px;
        width: 110px;

        &:hover svg path{
            fill: #001E62;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg {
        width: 65px;
        margin-top: 7px;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    cursor: pointer;    

    @media(max-width: 420px){
        margin-top: 20px;
        width: 100%;
    }

    .alert{
        margin-right: 40px;
        width: 250px;
        display: ${props => props.view};
    }
`;

export const ButtonRemove = styled.div`
    border: 1px solid #001E62;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 6px;
    pointer-events: ${props => props.enable};
    opacity: ${props => props.view};
    cursor: pointer;
    height: 34px;
    margin-bottom: 10px;
    

    svg {
        width: 23px !important;
        height: 23px !important;
        margin-top: 7px !important;        
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const ButtonRemoveChip = styled.div`
    
`;

export const AreaHidden = styled.section`
    
   /* &#hide{
        display: none;
    }
    &#show{
        display: block;
    }
    */
`;

export const AreaButton = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;

    &.disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    svg {
        width: 17px;
        height: 17px;
        border: 2px solid #001E62;
        padding: 6px;
        border-radius: 5px;

        &:active{
            position: relative;
            top:1px;
        }
    }
`;

export const BorderBtn = styled.div`
    border: 2px solid #001E62;
    border-radius: 5px;
    width: 43px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media(max-width: 1500px){
        height: 24px;
    }

    &.btnEditar{
        margin-left: -50px;
        margin-right: 15px;
        margin-top: 11px;
        svg{
            width: 18px !important;
            height: 18px !important;
            margin-bottom: 1px !important;
            margin-right: 0px !important;
        }
    }

    &.btnRemove{
        margin-right: 23px;
        svg{
            width: 21px !important;
            height: 21px !important;
            margin-bottom: 1px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;
