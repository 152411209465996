import React from 'react';

export default function IconAddInputs(){
    return(
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33329 2.49935H0.166626V10.666C0.166626 11.3077 0.691626 11.8327 1.33329 11.8327H9.49996V10.666H1.33329V2.49935ZM10.6666
            0.166016H3.66663C3.02496 0.166016 2.49996 0.691016 2.49996 1.33268V8.33268C2.49996 8.97435 3.02496 9.49935 3.66663 9.49935H10.6666C11.3083
            9.49935 11.8333 8.97435 11.8333 8.33268V1.33268C11.8333 0.691016 11.3083 0.166016 10.6666 0.166016ZM10.0833 5.41602H7.74996V7.74935H6.58329V5.41602H4.24996V4.24935H6.58329V1.91602H7.74996V4.24935H10.0833V5.41602Z" 
            fill="#001E62"/>
        </svg>
    );
}