
const tocarVozOperacional = () => {
    const audioEstatisicaOperacional = 'https://drive.google.com/u/0/uc?id=1XqVuUUpl4CGPcTRBcJZM_FMzD1uJU2s1&export=download';
    const tocarAudioEstatisticaOperacional = new Audio(audioEstatisicaOperacional);
    tocarAudioEstatisticaOperacional.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },33000); 
}

const tocarVozFiltro = () => {
    const audioFiltro = 'https://drive.google.com/u/0/uc?id=11y_ic5n2i0c2pmMVdkHMpLnd94a503qg&export=download';
    const tocarAudioFiltro = new Audio(audioFiltro);
    tocarAudioFiltro.play();
    
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    }, 32000);
}

const vozIntersecao = () => {
    const audioImposicaoIntersecao = 'https://drive.google.com/u/0/uc?id=1DR4nFNGl2QCDR8rd-FswZn2hqiysdubo&export=download';
    const tocarAudioImposicaoIntersecao = new Audio(audioImposicaoIntersecao);
    tocarAudioImposicaoIntersecao.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const vozControlador = () => {
    const audioImposicaoControlador = 'https://drive.google.com/u/0/uc?id=1tlsp1IwB2L73sZHcc_B-zlM3UlPcOLEL&export=download';
    const tocarAudioImposicaoControlador = new Audio(audioImposicaoControlador);
    tocarAudioImposicaoControlador.play();

    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const vozRota = () => {
    const audioImposicaoRota = 'https://drive.google.com/u/0/uc?id=161-h8Bx2BGY_S0rmlL-w8ws_qfBvkEsg&export=download';
    const tocarAudioImposicaoRota = new Audio(audioImposicaoRota);
    tocarAudioImposicaoRota.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozEstado = () => {
    const audioEstado = 'https://drive.google.com/u/0/uc?id=1pgl9wSCLakQ82WLTugqwsL791kp-JTCs&export=download';
    const tocarAudioEstado = new Audio(audioEstado);
    tocarAudioEstado.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    }, 47000);
}

const tocar = () => {
    const somConfiguracoesMapa = 'https://drive.google.com/u/0/uc?id=1AH-ALoKfOiBA30k3vzOIL-xcc3zpR514&export=download';
    const tocarSomConfiguracoesMapa = new Audio(somConfiguracoesMapa);
    tocarSomConfiguracoesMapa.play();

    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozParametro = () => {
    const vozParametro = 'https://drive.google.com/u/0/uc?id=1M2wBS9Kf-DnMCHSjYqHaAfOniZ9bdKiM&export=download';
    const tocarVozParametro = new Audio(vozParametro);
    tocarVozParametro.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000);    
}

const tocarVozEquipamento = () => {
    const vozEquipamento = 'https://drive.google.com/u/0/uc?id=1awp7FHKigsluz8R4h-CiQVTLCvNoOYLH&export=download';
    const tocarVozEquipamento = new Audio(vozEquipamento);
    tocarVozEquipamento.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000);  
}

const tocarVozArea = () => {
    const audioMenu_area = 'https://drive.google.com/u/0/uc?id=1wgXv5j-qR1k7eaGPzXxgd65wSSUBWTgr&export=download';
    const tocarAudioVozMenuArea = new Audio(audioMenu_area);
    tocarAudioVozMenuArea.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozRota = () => {
    const vozRota = 'https://drive.google.com/u/0/uc?id=1Z6YzTJ78IhfxijT_YwG2m2DVnTwM0CAi&export=download';
    const tocarVozRota = new Audio(vozRota);
    tocarVozRota.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarAudioCamera = () => {
    const audioCamera = 'https://drive.google.com/u/0/uc?id=1dc8M1URPnD0u4VwIq0pDhReC32AmVYNs&export=download';
    const tocar = new Audio(audioCamera);
    tocar.play();

    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarAudioParametro = () => {
    const audioConsorcio = 'https://drive.google.com/u/0/uc?id=1IptH5q-M9DA4kmuVS_aYyAeXhRVqT9OR&export=download';
    const tocarAudioConsorcio = new Audio(audioConsorcio);
    tocarAudioConsorcio.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarAudioControlador = () => {
    const audioControlador = 'https://drive.google.com/u/0/uc?id=1IptH5q-M9DA4kmuVS_aYyAeXhRVqT9OR&export=download';
    const tocarAudioControlador = new Audio(audioControlador);
    tocarAudioControlador.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarAudioEmpresa = () => {
    const audioEmpresa = 'https://drive.google.com/u/0/uc?id=14aepm-8unGmZZBb6xs1IPSN5IcZZ6Pdc&export=download';
    const tocarAudioEmpresa = new Audio(audioEmpresa);
    tocarAudioEmpresa.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozInfoControle = () => {
    const audioInfoControlador = 'https://drive.google.com/u/0/uc?id=15FKxjyzMfrb8_gG-z7KYNaSCjUyliPuk&export=download';
    const tocarAudioInfoControlador = new Audio(audioInfoControlador);
    tocarAudioInfoControlador.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    }, 14000);
}

const tocarVozInfoLocal = () => {
    const audioInfoCamera = 'https://drive.google.com/u/0/uc?id=1f_mR51FFGeNWmUpKgrWGUhCrv61SotBA&export=download';
    const tocarAudioInfoCamera = new Audio(audioInfoCamera);
    tocarAudioInfoCamera.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozInfoChip = () => {
    const audioInfoChip = 'https://drive.google.com/u/0/uc?id=1fV_Y-rBsBduV6v3b1v0OiHWWOG6zIS0o&export=download';
    const tocarAudioInfoChip = new Audio(audioInfoChip);
    tocarAudioInfoChip.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const VozInfoEquipamentoCamera = () => {
    const audioEquipamentoCamera = 'https://drive.google.com/u/0/uc?id=1RXxddwy3Wj355_inySJWh-33Ri3Fm_xX&export=download';
    const tocarAudioEquipamentoCamera = new Audio(audioEquipamentoCamera);
    tocarAudioEquipamentoCamera.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const VozInfoEquipamentoControlador = () => {
    document.querySelector('.audioEquipamentoControlador').play();
    const audioEquipamentoControlador = 'https://drive.google.com/u/0/uc?id=1HP3XH4uIQAsZXjW_Mr-ypFG5mnz3rL4d&export=download';
    const tocarAudioEquipamentoControlador = new Audio(audioEquipamentoControlador);
    tocarAudioEquipamentoControlador.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarVozLocal = () => {
    const audioInformacoesLocal = 'https://drive.google.com/u/0/uc?id=15FKxjyzMfrb8_gG-z7KYNaSCjUyliPuk&export=download';
    const tocarAudioInformacoesLocal = new Audio(audioInformacoesLocal);
    tocarAudioInformacoesLocal.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const vozInfoEquipamentoChip = () => {
    const audioEquipamentoChip = 'https://drive.google.com/u/0/uc?id=1vkyUGEhVYPEU_VTTrPpi-_XVFXfAhd_G&export=download';
    const tocarAudioEquipamentoChip = new Audio(audioEquipamentoChip);
    tocarAudioEquipamentoChip.play();

    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const vozInfoEquipamentoLocal = () => {
    const audioEquipamentoLocal = 'https://drive.google.com/u/0/uc?id=17Y2-Hwi64f96DrC70rCQWeatTE2p5nm1&export=download';
    const tocarAudioEquipamentoLocal = new Audio(audioEquipamentoLocal);
    tocarAudioEquipamentoLocal.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarInfoZoom = () => {
    const somInfoZoom = 'https://drive.google.com/u/0/uc?id=1cKO9bbCu1Z6CMMPrsbvqevAsGjgOI7m3&export=download';
    const tocarSomInfoZoom = new Audio(somInfoZoom);
    tocarSomInfoZoom.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarInfoTempo = () => {
    const somInfoTempo = 'https://drive.google.com/u/0/uc?id=1XtrY0VP6uiv_ah2VvgS5zzuTYr-Lg3hc&export=download';
    const tocarInfoTempo = new somInfoTempo(somInfoTempo);
    tocarInfoTempo.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

const tocarInfoTela = () => {
    const somInfoTela = 'https://drive.google.com/u/0/uc?id=1fy5XiwOyo12YxCq7YW0MfLRb2MUM7dfP&export=download';
    const tocarInfoTela = new Audio(somInfoTela);
    tocarInfoTela.play();
    localStorage.setItem('tocarAudio', 'ativo'); // ao dar play em audio setar localstorage informando que o audio esta ativo.
    setTimeout(() => {
        localStorage.setItem('tocarAudio', 'inativo'); // após o tempo estipulado de 14 seg setar o localStorage para audio inativo.
    },14000); 
}

export { 
    tocarVozOperacional, tocarVozFiltro, vozIntersecao, vozControlador, vozRota, tocarVozEstado, tocar, 
    tocarVozParametro, tocarVozEquipamento, tocarVozArea, tocarVozRota, tocarAudioCamera, tocarAudioParametro,
    tocarAudioControlador, tocarAudioEmpresa, tocarVozInfoControle, tocarVozInfoLocal, tocarVozInfoChip, 
    VozInfoEquipamentoCamera, VozInfoEquipamentoControlador, tocarVozLocal, vozInfoEquipamentoChip,vozInfoEquipamentoLocal,
    tocarInfoZoom, tocarInfoTempo, tocarInfoTela
}
