import styled from 'styled-components';

export const ContentTab = styled.section`
    width: 92%;
    margin: 0 auto;
    user-select: none;
    font-family: 'roboto';
    position: relative;
    @media(max-width: 420px){
        margin-left: 3%;
        width: 96%;
    }
    .disabled{
        pointer-events: none;
        opacity: 0.4;
    }
    
    fieldset{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: auto;
        border: 1px solid #a4a4a4;
        border-radius: 2px;
        position: relative;

        @media(max-width: 480px){
            flex-direction: column;
        }

        &:nth-child(2) {
            margin-top: 20px;
            @media(max-width: 1500px){
                margin-top: 10px;
            }
            @media(max-width: 420px){
                margin-top: 10px;
                width: 75vw !important;
            }
        }
        &:first-child {
            height: 50px;
            @media(max-width: 1500px){
                height: 42px;
            }
            @media(max-width: 420px){
                height: 80px;
                width: 75vw;
                display: flex;
                flex-direction: column;
            }
        }
        &.disable{
            pointer-events: none;
            opacity: 0.4;
        }
        &.enable{
            pointer-events: auto;
            opacity: 1;
        }
        select {
            width: 70%;
            height: 28px;
            border-radius: 2px;
            text-indent: 10px;
            border: 1px solid #a4a4a4;
            margin-bottom: 5px;
            font-size: 13px;
            @media(max-width: 1500px){
                height: 27px;
                margin-bottom: 0;
            }
            @media(max-width: 1500px){
                height: 25px;
                margin-bottom: 3px;
            }
            @media(max-width: 420px){
                width: 100%;
            }
        }
        legend {
            font-size: 12px;
            color: #1d1c1c;
        }
        svg{
            margin-bottom: 7px;
            margin-right: 2%;
            cursor: pointer;
        }
        form{
            width: 100%;
            #local{
                display: flex;
                gap: 15px; 

                @media(max-width: 420px){
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                }
            }
            .row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 auto;
                gap: 15px;
                @media(max-width: 1280px){
                    margin: 0px auto;
                }
                @media(max-width: 420px){
                    flex-direction: column;
                    gap: 0;
                }
                svg{
                    cursor: pointer;
                    width: 82px;
                    height: 30px;
                }
                .group {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    &.column .areaInput{
                        input{
                            border: 0;
                            height: 28px !important;
                            width: 80%;
                            @media(max-width: 1500px){
                                height: 26px !important;
                            }
                            @media(max-width: 1280px){
                                height: 22px !important;
                            }
                        }
                        height: 30px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 8px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        position: relative;
                        //margin-right: 12px;
                        @media(max-width: 1500px){
                            height: 28px;
                        }
                        @media(max-width: 1280px){
                            height: 26px;
                        }
                    }
                    &.column input {
                        width: 95%;
                    }
                    label{
                        font-size: 13px;
                        color: #1d1c1c;
                    }
                    select {
                        width: 100%;
                        height: 30px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 5px; 
                        font-size: 13px;
                        margin-right: 23px;

                        &#anel{
                            width: 100%;
                        }
                        @media(max-width: 1500px){
                            height: 27px;
                        }
                        @media(max-width: 420px){
                            width: 100%;
                            height: 31px !important;
                        }
                    }
                    input{
                        height: 25px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 8px;
                        outline: none;
                        font-size: 13px;
                        @media(max-width: 1500px){
                            height: 25px;
                        }
                        @media(max-width: 1280px){
                            font-size: 12px;
                        }
                        &:first-child{
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -22px;
    right: 0;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible == true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 30px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const ButtonEdit = styled.div`
    background: #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    cursor: pointer;
    @media(max-width: 1500px){
        height: 29px;
    }
    &.btnEditar{
        width: 33px;
        border-radius: 0 2px 2px 0;
        position: absolute;
        right: 0;
        height: 30px;
        @media(max-width: 1500px){
            height: 28px;
        }
        @media(max-width: 1280px){
            height: 27px;
        }
        
        svg {
            width: 19px !important;
            height: 19px;
            margin-top: 5px;
            &:active{
                position: relative;
                top:1px;
            }
        }
    }
`;

export const Button = styled.div`
    background: #001E62;
    border: 1px solid #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    box-sizing: border-box;
    font-size: 12px;
    &:hover{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
        transition: .3s;
    }

    @media(max-width: 1500px){
        height: 27px;
    }
    @media(max-width: 580px){
        height: 29px;
    }
    &.btnImportar{
        margin-top: 11px;
        width: 33%;
        font-size: 13px;
        margin-left: 20px;
        margin-right: 2px;
        border: 1px solid #001E62;
        @media(max-width: 420px){
            margin-top: 11px;
            width: 100%;
            font-size: 13px;
            margin-right: 2px;
            margin-left: 0;
        }
    }
    &#btnNovo{
        margin-top: -5px;
        width: 110px;
        display: flex;
        justify-content: center;

        &.disable{
            pointer-events: none;
            opacity: 0.4;
        }

        &.enable{
            pointer-events: auto;
            opacity: 1;
        }

        @media(max-width: 480px){
            width: 75vw;
            margin-top: 10px;
        }
        
        &:hover svg path{
            fill: #001E62;
        }
        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }
    }
    &:active{
        position: relative;
        top: 1px;
    }
    svg {
        width: 65px;
        margin-top: 7px;
    }
`;

export const AreaCheck = styled.div`
    border: 1px solid #a4a4a4;
    width: 100%;
    max-width: 475px;
    height: auto;
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;

    .box{
        width: 44px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 3px;
        margin-left: 23px;

        label{
            margin-top: -3.9px;
        }
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 97%;
    margin: 21px auto;
    cursor: pointer;  
    .buttons{
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
    @media(max-width: 420px){
        display: flex;
        align-items: center;
        justify-content: space-around;
        .buttons{
            margin-top: 10px;
            width: 100%;
            justify-content: flex-end;
            gap: 10px;
        }
    }
    &.disabled{
        pointer-events: none;
        opacity: 0.4;
    }
    
    span{
        &.msgErro {
        color: red;
        font-size: 17px;
        margin: 0 auto;
        }
    }
    .alert{
        margin-right: 40px;
        width: 250px;
        display: ${props => props.view};
    }
`;

export const BorderBtn = styled.div`
    border: 2px solid #001E62;
    border-radius: 5px;
    width: 39px;
    height: 27px;
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media(max-width: 1500px){
        height: 24px;
    }

    svg{
        width: 18px !important;
        height: 18px !important;
        @media(max-width: 1500px){
            width: 18px !important;
            height: 18px !important;
        }
    }
    &:active{
        position: relative;
        top:1px;
    }
    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }
    &.enable{
        pointer-events: auto;
        opacity: 1;
    }
`;

export const AreaSwitch = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;

    label{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #040404;
    }
`;

export const Switch = styled.label`
	position: relative;
	top: 2px;
	display: inline-block;
	vertical-align: top;
	width: 65px;
	height: 20px;
	padding: 3px;
	background-color: white;
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
	background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
	background-image: -o-linear-gradient(top, #eeeeee, white 25px);
	background-image: linear-gradient(to bottom, #eeeeee, white 25px);

	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		-webkit-transition: 0.15s ease-out;
		-moz-transition: 0.15s ease-out;
		-o-transition: 0.15s ease-out;
		transition: 0.15s ease-out;

        &.rede{
            background: #65e5bb;

            &:before {
                color: #195844;
                font-weight: 700;
            }
        }
	}

   
	.switch-label:before,
	.switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaa;
		text-shadow: 0 1px rgba(255, 255, 255, 0.5);
        font-weight: normal;
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: white;
		text-shadow: 0 1px rgba(0, 0, 0, 0.2);
		opacity: 0;
        font-weight: normal;
	}
	.switch-input:checked ~ .switch-label {
		background: #47a8d8;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 18px;
		height: 18px;
		background: white;
		border-radius: 10px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
		background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
		background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
		-webkit-transition: left 0.15s ease-out;
		-moz-transition: left 0.15s ease-out;
		-o-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
	}
	.switch-handle:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: #f9f9f9;
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
		background-image: -webkit-linear-gradient(top, #eeeeee, white);
		background-image: -moz-linear-gradient(top, #eeeeee, white);
		background-image: -o-linear-gradient(top, #eeeeee, white);
		background-image: linear-gradient(to bottom, #eeeeee, white);
	}
	.switch-input:checked ~ .switch-handle {
		left: 49px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-green > .switch-input:checked ~ .switch-label {
		background: #4fb845;
	}
`;