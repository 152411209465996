import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import { X , XSquare } from "react-feather";

import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}
function PageTitle({ id, text, onClick, children }) {

  const handleClick = (event) => {
    event.preventDefault();
    return onClick();
  }
  return(
    <DialogTitle id={id} className="DialogTitle" >
      {children}
      <strong>{text}</strong>
      <XSquare 
        size={21} 
        color="#fff" 
        onClick={handleClick} 
        style={{ 
          position:'absolute', 
          top: '15px',
          left: '90%', 
          cursor: 'pointer'
        }} 
      />
    </DialogTitle>
  )
}

export default PageTitle;