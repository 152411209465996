import React, { useState } from 'react';
import { toast } from "react-toastify";
import api from '../../../services/api';
import { Container, Close, IconView } from './styles';
import IconViewFalse from './icons/iconViewFalse';
import IconViewTrue from './icons/iconViewTrue';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../../services/permission';

const permissao = (validarPermissao([0,1,2,3]) ? 'enabled' : 'disabled');

export default function BodyChangeAccount(props){

    const [atual, setAtual] = useState('');
    const [nova, setNova] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');
    const [viewPassword, setViewPassword] = useState('password');
    const [viewPassword2, setViewPassword2] = useState('password');
    const [viewPassword3, setViewPassword3] = useState('password');

        
    async function saveNewPassword(){

        if(atual === ""){
            setStatus('Por favor, preencher o campo senha atual');
        }
        if(nova !== password){
            setStatus('senhas não conferem');
        }
        if(nova === password){
            await api.post('centraldefalhas/user/change/password',{
                'currentPassword': atual,
                'password': password,

            }).then( res => {
                toast.success(`Senha alterada com sucesso!`, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                setAtual('');
                setNova('');
                setPassword('');
                setNova('');

            }).catch( err => {
                toast.error('Senha incorreta, por favor tente novamente!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });                
        }         
    }

    const switchView = () => {
        if(viewPassword === 'password'){
          setViewPassword('text');
        }else{
          setViewPassword('password');
        }
    }
    const switchView2 = () => {
        if(viewPassword2 === 'password'){
          setViewPassword2('text');
        }else{
          setViewPassword2('password');
        }
    }
    const switchView3 = () => {
        if(viewPassword3 === 'password'){
          setViewPassword3('text');
        }else{
          setViewPassword3('password');
        }
    }
    
    return(
       <Container className={permissao}>
           <Close onClick={() => props.onClose()}><GrClose size={14}/></Close>
           <form>
               <div className="row">
                    <label>Senha atual:</label>
                    <div className="focus">
                        <input type={viewPassword} placeholder="Digite sua senha" value={atual} onChange={(e) => setAtual(e.target.value)} />
                        <IconView onClick={switchView}>
                            { viewPassword === 'password' ? <IconViewFalse /> : <IconViewTrue />}
                        </IconView>
                    </div>
               </div>
               <div className="row">
                    <label>Nova senha:</label>
                    <div className="focus">
                        <input type={viewPassword2} placeholder="Digite sua senha" value={nova} onChange={(e) => setNova(e.target.value)}/>
                        <IconView onClick={switchView2}>
                            { viewPassword2 === 'password' ? <IconViewFalse /> : <IconViewTrue />}
                        </IconView>
                    </div>
               </div>
               <div className="row">
                    <label>Confirmar senha: <span>{status}</span></label>
                    <div className="focus">
                        <input type={viewPassword3} placeholder="Digite sua senha" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        <IconView onClick={switchView3}>
                            { viewPassword3 === 'password' ? <IconViewFalse /> : <IconViewTrue />}
                        </IconView>
                    </div>
               </div>
               <button onClick={saveNewPassword}>SALVAR</button>
           </form>
       </Container>  
    );
}