import React from 'react';
import { Iframe } from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import PageTitle from '../../PageTitle';
import Paper from '@material-ui/core/Paper';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


export default function ModalVideo(props) {

    return (
        <Dialog
            open={props.openVideo} 
            className="Dialog" 
            onClose={props.onCloseVideo} 
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle // cabeçalho do modal
                id="draggable-dialog-title"
                text="Video ao vivo"
                onClick={props.onCloseVideo}
            >
            </PageTitle>
            <DialogContent className='content-dialog'>
                <Iframe 
                    width="560" 
                    height="315" 
                    src="https://camerasdaer.perkons.com:60000/DAER-6716?0.2612066200883876" 
                    title="Camera" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </Iframe>
            </DialogContent>
        </Dialog>
    )
}


/*Teste:
https://191.253.194.194:60000/DAER-6716?0.2612066200883876
*/

/* 
<iframe 
    width="560" height="315" src={`http://admin:admin12345@152.246.224.212:9101/cgi-bin/mjpg/video.cgi?channel=1&subtype=1`}
    title="Video ao vivo" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
</iframe>

*/