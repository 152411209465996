import React, { useEffect, useState } from 'react'
import { Col, Row, Card } from 'react-bootstrap';
import ChartLine from "./components/ChartLine";
import ChartArea from "./components/ChartArea";
import './styles.css';

/*const dataDev1 = [
  ['Hora', 'Estagio 1', 'Estagio 2', 'Estagio 3'],
  ['12:30', 25, 30, 20],
  ['12:35', 17, 46, 30],
  ['12:40', 26, 27, 50],
  ['12:45', 13, 54, 18],
]
const dataDev1Semana = [
  ['DIA', 'Estagio 1', 'Estagio 2', 'Estagio 3'],
  ['Domingo', 13, 54, 18],
  ['Segunda', 25, 30, 20],
  ['Terça', 17, 46, 30],
  ['Quarta', 26, 27, 50],
  ['Quinta', 13, 54, 18],
  ['Sexta', 30, 42, 18],
  ['Sabado', 30, 42, 18],
]
const dataDev2 = [
  ['Hora', 'Estagio 1', 'Estagio 2', 'Estagio 3'],
  ['15:30', 15, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 26, 45, 20],
  ['15:30', 50, 30, 25],
  ['18:45', 24, 30, 18],
  ['15:30', 50, 30, 25],
  ['16:35', 23, 35, 45],
  ['17:40', 80, 45, 20],
 
]*/

function Dashboard() {

  /*const [ timeInit  ] = useState('');
  const [ timeFinal ] = useState('');
  const [ equipment ] = useState('');*/
  const [valoresArea] = useState([
    ['', ' ', ' ', ' '],
    ['12:30', 40, 60, 20],
    ['12:35', 35, 20, 35],
    ['12:40', 18, 10, 20],
    ['12:45', 14, 32, 7],
  ]);
  const [valoresAreaSemana] = useState([['', ' ', ' ', ' '],
  ['12:30', 0, 0, 0],]);

  /*const convertDate = (dataBR) => {
    let dateUS =  new Date(dataBR).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute:'2-digit',
      second:'2-digit'
    });

    return Date.parse(dateUS)/1000;
  }*/

  useEffect(() => {
    document.getElementById('div-chart-one').animate([
      // keyframes
      { opacity: 0 },
      { opacity: 1 }
    ], {
      duration: 2000,
      iterations: 1,
    });
    document.getElementById('div-chart-two').animate([
      // keyframes
      { opacity: 0 },
      { opacity: 1 }
    ], {
      duration: 2000,
      iterations: 1,
    });
    document.getElementById('div-chart-tree').animate([
      // keyframes
      { opacity: 0 },
      { opacity: 1 }
    ], {
      duration: 2000,
      iterations: 1,
    });
    document.getElementById('div-chart-four').animate([
      // keyframes
      { opacity: 0 },
      { opacity: 1 }
    ], {
      duration: 2000,
      iterations: 1,
    });
  }, []);

  return (
    <div className='container-dashboard' >
      <div className="body-dashboard">
        <Row>
          <Col >
            <Card className="card-chart" id="div-chart-one">
              <Card.Body>
                <div className='chart-area'>
                  <ChartLine
                    data={[10, 120, 8, 70, 10, 80]}
                    labels={["Minuto 10", "Minuto 20", "Minuto 30", "Minuto 40", "Minuto 50", "Minuto 60"]}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col >
            <Card className="card-chart" id="div-chart-two">
              <Card.Body>
                <div className='chart-area'>
                  <ChartArea
                    title='CONTAGEM'
                    data={valoresArea}
                    labels={["Minuto 10", "Minuto 20", "Minuto 30", "Minuto 40", "Minuto 50", "Minuto 60"]}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <div className="div-grid-colunm">
            <Col className="card-chart-col">
              <Card className="card-chart" id="div-chart-tree">
                <Card.Body>
                  <ChartArea
                    title='DADOS SEMANA'
                    data={valoresAreaSemana}
                    labels={["Segunda", "Terça", "Quarta", "Quinta", "Sexta"]}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col className="card-chart-col">
              <Card className="card-chart" id="div-chart-four">
                <Card.Body>
                  <ChartLine
                    data={[10, 0, 8, 30, 40, 70, 80]}
                    labels={["Segunda", "Terça", "Quarta", "Quinta", "Sexta"]}
                  />
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Row>

      </div>
    </div>
  )
}

export default Dashboard;