import React, { useEffect, useState } from 'react';
import icoInfo from '../../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../../assets/icons/icoSom.png';
import BtnAdd from '../../Icons/BtnAdd';
import IconRemove from '../../Icons/IconRemove';
import { validarPermissao } from '../../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../../Balloons';
import Alert from '@material-ui/lab/Alert';
import { ContentTab, InfoHelp, AreaBallon, ContentBallon, AreaButtonRemove, BtnBorder, MultiplyArea, AreaBtn, Actions, BorderButton, Button } from './styles';
import api from '../../../../../services/api';
import apiMensageria from '../../../../../services/apiMensageria';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Mensageria(props) {

    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [idLocal, setIdLocal] = useState([]);
    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [editId_Cruzamento, setEditIdCruzamento] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [listEquipaments, setListEquipments] = useState([]);
    const [listNotificacoes, setListNotificacoes] = useState([]);
    const [listTipoComunicacao, setListTipoComunicacao] = useState([]);
    const [listModels, setListModels] = useState([]);
    const [typeNotification, setTypeNotification] = useState('câmera');
    const [areaMensageria, setAreaMensageria] = useState([
        { identificationType: '', handler: '', templateId: '', type: '', to: '', enabled: false, templateModel: { title: '' }, status: '' },
    ]);


    const listData = async () => {
        const response = await api.get('/CentralDeFalhas/local/Lista');
        setIdLocal(response.data);
    }

    const ContentPopBallonChip = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Mensageria</h3>
                    <label><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Area destinada ao cadastro de mensagens...
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const reqInfos = async (id) => {
        setEditIdCruzamento(id);
    }

    useEffect(() => {
        reqInfos(props.id);
        if (props.id.lenght > 0) {
            apiMensageria.get(`/api/v1/comunications?identification=${props.id}`)
                .then(res => {
                    setAreaMensageria(res.data);
                }).catch(err => {
                    alert(err.message);
                });
        }
    }, []);

    const saveData = async (index) => {

        var destinatario = '';
        if (areaMensageria[index].type === 'sms') {
            destinatario = areaMensageria[index].to.replace("(", "+55").replace(")", "").replace("-", "").replace(" ", "").replace(" ", "");
        } else {
            destinatario = areaMensageria[index].to;
        }

        await apiMensageria.post(`/api/v1/comunications`, {
            "identificationId": editId_Cruzamento,
            "identificationType": areaMensageria[index].identificationType,
            "templateId": areaMensageria[index].templateId,
            "type": areaMensageria[index].type,
            "handler": areaMensageria[index].handler,
            "to": destinatario,
            "enabled": areaMensageria[index].enabled
        }).then(res => {
            alert('Dados salvos com sucesso!')
        }).catch(err => {
            alert('Erro ao salvar dados!')
        })
    }

    const editData = async (id, index) => {
        var destinatario = '';
        if (areaMensageria[index].type === 'sms') {
            destinatario = areaMensageria[index].to.replace("(", "+55").replace(")", "").replace("-", "").replace(" ", "").replace(" ", "");
        }
        else {
            destinatario = areaMensageria[index].to;
        }

        console.log('edit destinatario', destinatario)

        await apiMensageria.put(`/api/v1/comunications/${id}`, {
            "identificationId": editId_Cruzamento,
            "identificationType": areaMensageria[index].identificationType,
            "templateId": areaMensageria[index].templateId,
            "type": areaMensageria[index].type,
            "handler": areaMensageria[index].handler,
            "to": destinatario,
            "enabled": areaMensageria[index].enabled
        })
            .then(res => {
                alert('Dados alterados com sucesso!')
            }).catch(err => {
                alert('Erro ao editar dados');
            });
    }

    const handleAddMessages = () => {
        const values = [...areaMensageria];
        values.push({ identificationType: '', handler: '', type: '', to: '', templateId: '', enabled: false, templateModel: { title: '' }, status: 'newItem' });
        setAreaMensageria(values);
    }

    const handleRemoveMessage = async (id, index) => {
        const values = [...areaMensageria];
        values.splice(index, 1);

        await apiMensageria.delete(`/api/v1/comunications/${id}`)
            .then(res => {
                setAreaMensageria(values);
            }).catch(err => {
                alert('Erro ao excluir mensagem');
            });
    }

    const handleChangeInputMessage = (e, index) => {
        const values = [...areaMensageria];

        if (e.target.name === 'identificationType') {
            values[index].identificationType = e.target.value;
            setTypeNotification(e.target.value);
        }
        if (e.target.name === 'handler') {
            values[index].handler = e.target.value;
        }
        if (e.target.name === 'type') {
            values[index].type = e.target.value;
        }
        if (e.target.name === '_id') {
            values[index].templateId = e.target.value;
        }
        if (e.target.name === 'enabled') {
            values[index].enabled = e.target.checked
            console.log('enabled', e.target.checked)
        }

        if (values[index].type === 'sms') {
            if (e.target.name === 'to') {
                values[index].to = phoneMask(e.target.value);
            }
        } else {
            if (e.target.name === 'to') {
                values[index].to = e.target.value;
            }
        }

        setAreaMensageria(values)
    }

    const getListEquipaments = async () => {
        await apiMensageria.get(`/api/v1/parameters/options/equipments`)
            .then(res => {
                setListEquipments(res.data.data);
            }).catch(err => {
                alert(err.message);
            });
    }

    const getListNotifications = async () => {
        await apiMensageria.get(`/api/v1/parameters/options/handlers?equipment=${typeNotification}`)
            .then(res => {
                setListNotificacoes(res.data.data);
            }).catch(err => {
                alert(err.message);
            });
    }

    const getListComunications = async () => {
        await apiMensageria.get(`/api/v1/parameters/options/service-notifications`)
            .then(res => {
                setListTipoComunicacao(res.data.data);
            }).catch(err => {
                alert(err.message);
            });
    }

    //----
    const getListModels = async () => {
        await apiMensageria.get(`/api/v1/template-models`)
            .then(res => {
                setListModels(res.data);

            }).catch(err => {
                alert(err.message);
            });
    }

    // verifica se o email é válido:
    const validateEmail = (emailAdress, index) => {
        if (areaMensageria[index].type === 'email' && areaMensageria[index].to !== '') {
            let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (emailAdress.match(regexEmail)) {
                return true;
            } else {
                const values = [...areaMensageria];
                values[index].to = '';
                setAreaMensageria(values);
                alert('Por favor, forneça um endereço de e-mail válido');
                return false;
            }
        } else {
            return;
        }
    }

    const phoneMask = (v) => {

        let mask = v.replace(/\D/g, "");
        mask = mask.replace(/^0/, "");

        if (mask.length > 11) {
            mask = mask.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (mask.length > 7) {
            mask = mask.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
        } else if (mask.length > 2) {
            mask = mask.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else if (v.trim() !== "") {
            mask = mask.replace(/^(\d*)/, "($1");
        }
        return mask;
    }

    useEffect(() => {
        const listMessages = () => {
            apiMensageria.get(`/api/v1/comunications?identification=${editId_Cruzamento}`)
                .then(res => {

                    const arraylist = res.data;
                    arraylist.forEach(item => {
                        item.status = 'edition'
                        item.to = item.to.replace('+55', '');
                    });

                    setAreaMensageria(arraylist);

                    console.log('resultado', arraylist)

                }).catch(err => {
                    alert(err.message);
                });
        }
        listMessages();
    }, [editId_Cruzamento]);

    return (
        <ContentTab>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonChip /></BallonsInfoModal>
            </AreaBallon>
            <fieldset>
                <legend>ID do local</legend>
                <select onClick={listData} onChange={(e) => reqInfos(e.target.value)}>
                    <option value={props.id === '' ? '' : props.id}>{props.id === '' ? 'Selecione...' : props.id}</option>
                    {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(itemIdLocal => {
                        if (itemIdLocal.id_cruzamento !== props.id) {
                            return (
                                <option key={itemIdLocal.id_cruzamento} value={itemIdLocal.id_cruzamento}>{itemIdLocal.id_cruzamento}</option>
                            );
                        }
                    })}
                </select>
            </fieldset>
            {areaMensageria.map((item, index) => (
                <MultiplyArea key={`${item}~${index}`}>
                    <fieldset>
                        <AreaButtonRemove className={permissao}>
                            <BtnBorder onClick={() => handleRemoveMessage(item._id, index)} title="Remover mensagem">
                                <IconRemove />
                            </BtnBorder>
                        </AreaButtonRemove>
                        <legend>Dados {index + 1}</legend>
                        <form>
                            <div className='row'>
                                <div className='group'>
                                    <legend>Equipamento</legend>
                                    <select id="" name='identificationType' onClick={getListEquipaments} value={item.identificationType} onChange={(e) => handleChangeInputMessage(e, index)}>
                                        <option value={item.identificationType}>{item.identificationType}</option>
                                        {listEquipaments.map(items => {
                                            if (item.identificationType !== items.name) {
                                                return (
                                                    <option value={items.name} key={items.id}>{items.name}</option>
                                                );
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </select>
                                </div>
                                <div className='group'>
                                    <legend>Evento da notificação</legend>
                                    <select id="" name='handler' onClick={getListNotifications} value={item.handler} onChange={(e) => handleChangeInputMessage(e, index)}>
                                        <option value={item.handler}>{item.handler}</option>
                                        {listNotificacoes.map(items => {
                                            if (item.handler !== items.name) {
                                                return (
                                                    <option value={items.name} key={items.id}>{items.name}</option>
                                                )
                                            }
                                            return null;
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='group'>
                                    <legend>Tipo de comunicação</legend>
                                    <select id="" name='type' onClick={getListComunications} value={item.type} onChange={(e) => handleChangeInputMessage(e, index)}>
                                        <option value={item.type}>{item.type}</option>
                                        {listTipoComunicacao.map(items => {
                                            if (items.name !== item.type) {
                                                return (
                                                    <option value={items.name} key={items.id}>{items.name}</option>
                                                );
                                            } else {
                                                return null
                                            }
                                        })}
                                    </select>
                                </div>
                                <div className='group'>
                                    <legend>Destinatário</legend>
                                    <input
                                        type={areaMensageria[index].type === 'email' ? 'email' : 'tel'}
                                        name='to'
                                        value={areaMensageria[index].type === 'sms' ? phoneMask(item.to) : item.to}
                                        onBlur={(e) => validateEmail(e.target.value, index)}
                                        onChange={(e) => handleChangeInputMessage(e, index)} />
                                </div>
                            </div>
                            <div className='row'> {/*######################*/}
                                <div className='group'>
                                    <legend>Modelo de comunicação</legend>
                                    <select id="" name='_id' onClick={getListModels} onChange={(e) => handleChangeInputMessage(e, index)}>
                                        <option value={item.templateId}>{item.templateModel.title}</option>
                                        {listModels.map(items => {
                                            if (item.templateModel.title !== items.title) {
                                                return (
                                                    <option value={items._id}>{items.title}</option>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='group checkbox'>
                                    <legend>Habilitar comunicação</legend>
                                    <input type="checkbox" name='enabled' value={item.enabled} checked={item.enabled} onChange={(e) => handleChangeInputMessage(e, index)} />
                                </div>
                            </div>
                        </form>
                        <Actions>
                            <Button width="92" heigth="41" className={areaMensageria[index].status === 'edition' ? 'btnEditarEnabled' : 'btnEditarDesabled'} onClick={() => editData(item._id, index)}>Editar</Button>
                            <Button width="92" heigth="41" className={areaMensageria[index].status === 'newItem' ? 'btnSalvarEnabled' : 'btnSalvarDesabled'} onClick={() => saveData(index)}>Salvar</Button>
                        </Actions>
                    </fieldset>
                </MultiplyArea>
            ))}
            <AreaBtn view={alertView} className={permissao}>
                <div className="alert">
                    <Alert severity={typeAlert} >{alertMessage}</Alert>
                </div>
                <Actions>
                    <BorderButton onClick={() => handleAddMessages()}><BtnAdd /></BorderButton>
                </Actions>
            </AreaBtn>
        </ContentTab>
    );
}