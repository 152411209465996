import React from 'react';
import { Container, AreaLoading } from './styles';
import loader from '../../../assets/img/loader.gif';
import { Chart, Interval, Coordinate, Tooltip } from "bizcharts";

export default function ChartBar(props) {

    //propriedades dos eixos do grafico
    const scale = {
        timestamp: {
            mask: 'DD-MM-YYYY HH:mm:ss',  //mascara dos dados
            tickCount: 6,                 //quantidade de itens
        },

        contagem: {
            type: 'linear',               //tipo do dado
            min: 0,
            range: [                      //intervalo entre os dados
                0,
                1
            ],
            tickCount: 6,                 //quantidade de itens
        }
    };

    return (
        <Container>
            <AreaLoading src={loader} loading={props.cbLoading} />
            <Chart data={props.dadosGraficos} scale={scale} autoFit className="chart">
                <span className="titulo">Contagem total de acionamentos</span>
                <span className="subTitulo">Soma dos acionamentos de cada detector no mesmo período de tempo</span>
                <Coordinate transposed />
                <Tooltip shared />
                <Interval
                    adjust={[{ type: 'stack' }]}
                    position="timestamp*contagem"
                    color={"id_detector"}
                    style={{
                        fillOpacity: 0.75
                    }}
                    // popup de legenda
                    tooltip={['contagem*id_detector', (value, name) => {
                        return {
                            value: `${value} acionamentos`,
                            name: `Detector ${name}`
                        }
                    }]}
                />
            </Chart>
        </Container>
    );
}