import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semFrontGreen from '../../../img/Semaforo/semFrontGreen.png';
import semFrontYellow from '../../../img/Semaforo/semFrontYellow.png';
import semFrontRed from '../../../img/Semaforo/semFrontRed.png';
import semFrontApagado from '../../../img/Semaforo/semFrontApagado.png';
import semFrontPiscante from '../../../img/Semaforo/piscanteSemFrontYellow.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoFront(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo de pedestre frontal', image: semFrontGreen, object: props.objSemaforo, setObject: props.setObjSemaforo });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforo.map((item, index) => {
                if (props.objSemaforo[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforo'
                            top={item.top}
                            left={item.left}
                            setObjSemaforo={props.setObjSemaforo}
                            objSemaforo={props.objSemaforo}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img
                                src={
                                    item.color_group === undefined || item.color_group === '' ? semFrontApagado :
                                        item.color_group === 1 ? semFrontRed :
                                            item.color_group === 2 ? semFrontYellow :
                                                item.color_group === 4 ? semFrontGreen :
                                                    item.color_group === 18 ? semFrontPiscante : semFrontApagado
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforo(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
