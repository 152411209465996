import styled from 'styled-components';

export const Container = styled.div`
    width: 25vw;
    background: #FFF;
    padding: 30px 30px 15px 30px;

    @media(max-width: 1280px){
        width: 30vw;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const Table = styled.table`
    font-family: 'roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;

    td{
        border: 1px solid #9e9e9e;
        padding: 8px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        min-width: 110px;
    }
    th {
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;

        &:nth-of-type(1){
            border-radius: 5px 0 0 0;
        }
        &:nth-of-type(2){
            border-radius: 0 5px 0 0;
            width: 100px;
        }
    }
`;