import styled, { keyframes } from 'styled-components';


const move = () => keyframes`
    0%{
        right: 120px; 
    }
    100%{
        right: 140px;
    }
`;

export const ContentBallon = styled.div`
    position: relative;

    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }
        label{
            cursor: pointer;
        }

        audio{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaImagem{

        img{
            width: 100%;
        }
    }

    .areaArrow{
        position: absolute;
        top: 297px;
        right: 120px;
        animation: ${move} 1s linear infinite;
        img{
            width: 100%;
        }
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;