// require('dotenv').config({path: '../.env'});
import axios from 'axios';

const tokenUser = localStorage.getItem('@central-de-falhas-greenwave/tokenAuth');

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_HOST + "/",
    headers: {
        Authorization: `Bearer ${tokenUser}`, //passar o token armazenado do usuario 
    },

});

export default api;


