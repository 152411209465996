import styled from "styled-components";

export const AreaIcon = styled.div`
    z-index: 9;
    position: absolute;
    right: 10px;
    display: flex;
    transition: .3s ease;
    transition-delay: 1s;
   
    &#parametros,&#equipamentos,&#areas,&#rotas,&#filtro{
        right: -18px;
    }

    svg {
        width: 15px;
        height: 15px;
        path {
            stroke: #FFF;
        }
    }
`;

export const ContentBallon = styled.div`

    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;
        margin-top: 5px;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }


`;

export const InfoParametro = styled.div`
    bottom: 642px;
    left: 207px;
    position: absolute;
    display: ${props => props.viewBalloon === false ? 'none' : 'block'};

    @media(max-width: 1366px){
        top: -158px;
    }
    @media(max-width: 1280px){
        top: -170px;
    }
`;

export const InfoEquipamento = styled.div`
    bottom: 590px;
    left: 207px;
    position: absolute;
    display: ${props => props.viewBalloon === false ? 'none' : 'block'};

    @media(max-width: 1366px){
        top: -120px;
    }
    @media(max-width: 1280px){
        top: -129px;
    }
`;

export const InfoAreas = styled.div`
    bottom: 539px;
    left: 207px;
    position: absolute; 
    display: ${props => props.viewBalloon === false ? 'none' : 'block'};

    @media(max-width: 1366px){
        top: -80px;
    }
    @media(max-width: 1280px){
        top: -93px;
    }
`;

export const InfoRotas = styled.div`
    bottom: 491px;
    left: 207px;
    position: absolute;
    display: ${props => props.viewBalloon === false ? 'none' : 'block'};

    @media(max-width: 1366px){
        top: -38px;
    }
    @media(max-width: 1280px){
        top: -57px;
    }
`;
