import styled from 'styled-components';

export const Container = styled.section`
    display: ${props => props.open};
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000b8;
    z-index: 0;
    cursor: pointer;
    transition: 1s;
`;

export const Modal = styled.div`
    position: absolute;
    background: #FFF;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 125px;
    border: 2px solid #DDDDDD;
    border-radius: 8px;
    font-family: sans-serif;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    button{
        width: 136px;
        height: 32px;
        border-radius: 29px;
        border: 0;
        margin-left: 10px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position:relative;
            top: 2px;
        }
    }
`;

export const Title = styled.span`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: 12px;
    font-family: 'Roboto', sans-serif;
`;