import styled from 'styled-components';

export const Container = styled.section`
    padding: 10px 0 15px 0;
    margin-bottom: 50px;
    overflow: scroll;
    height: 65vh;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        cursor: pointer;

        &-track-piece {
            background-color: #FFF;
        }

        &-thumb:vertical,
        &-thumb:horizontal {
            background-color: #062467;
            border-radius:5px;
        }

        &-thumb:vertical:hover,
        &-thumb:horizontal:hover {
            background-color: #269F2A;
        }
    }

    .alert{
        width: 50%;
        display: ${props => props.view};
        margin-top: 8px;
        margin-bottom: -13px;
        position: relative;
        left: 63%;
        right: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0,
        .MuiAlert-standardSuccess, 
        .MuiAlert-standardError,
        .MuiAlert-standardInfo {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: -20px;
        }

        @media(max-width: 480px){
            width: 100%;
        }
    }
    
`;

export const Table = styled.table`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 1200px;

    thead{
        th{
            &:nth-of-type(1){
                border-radius: 6px 0 0 6px;
            }
            &:nth-of-type(10){
                border-radius: 0px 6px 6px 0px;
            }
        }
    }

    tbody{
        tr{
            td{
                &:nth-of-type(1){
                    width: 35px;
                    max-width: 35px;
                }
                img {
                    width: 16px;
                    cursor: pointer;

                    &:hover{
                        width: 18px;
                        transition: .3s;
                    }
                }
                &:nth-of-type(5){
                    width: 170px;
                }
            }
        }
    }

    td{
        border-bottom: 1px solid #ddd;
        padding: 7px;
        height: 20px;
        color: #494747;
        text-align: center;
        font-family: 'roboto';
        font-size: 13px;
        padding: 0;
        height: 28px;
    }

    tr{
        &:hover {
            //background-color: #ddd;
        }
    }
    th{
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        width: auto;
        height: 30px;
        font-size: 13px;
    }
`;
