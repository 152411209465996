import React, { useState, useEffect } from 'react';
import { GrClose } from "react-icons/gr";
import { Container, AreaMenu, MenuTabs, Close } from './styles';

export default function Tabs({
    changeAbalocal, changeSetAbaLocal, 
    changeAbaControlador, changeSetAbaControlador,
    changeAbaChip, changeSetAbaChip,
    changeAbaCamera, changeSetAbaCamera,
    onClose, lerDados
    }){

    const [positionAba, setPositionAba] = useState('66');
    const [local, setLocal] = useState('inactive');
    const [controlador, setControlador] = useState('active');
    const [chip, setChip] = useState('inactive');
    const [camera, setCamera] = useState('inactive');
      
    useEffect(() => {
        setPositionAba('66');
        changeSetAbaControlador('block');
        changeSetAbaChip('none');
        changeSetAbaCamera('none');
        changeSetAbaLocal('none');
        setPositionAba('170');
        setLocal('inactive');
        setControlador('active');
        setChip('inactive');
        setCamera('inactive');
        lerDados();
    },[]);

    const Position1 = () => {
        setPositionAba('66');
        setLocal('active');
        setControlador('inactive');
        setChip('inactive');
        setCamera('inactive');

        changeSetAbaLocal('block');
        changeSetAbaControlador('none');
        changeSetAbaChip('none');
        changeSetAbaCamera('none');

    }
    const Position2 = () => {
        setPositionAba('170');
        setLocal('inactive');
        setControlador('active');
        setChip('inactive');
        setCamera('inactive');
        
        changeSetAbaLocal('none');
        changeSetAbaControlador('block');
        changeSetAbaChip('none');
        changeSetAbaCamera('none');

    }
    const Position3 = () => {
        setPositionAba('275');
        setLocal('inactive');
        setControlador('inactive');
        setChip('active');
        setCamera('inactive');

        changeSetAbaLocal('none');
        changeSetAbaControlador('none');
        changeSetAbaChip('block');
        changeSetAbaCamera('none');
    }
    const Position4 = () => {
        setPositionAba('379');
        setLocal('inactive');
        setControlador('inactive');
        setChip('inactive');
        setCamera('active');

        changeSetAbaLocal('none');
        changeSetAbaControlador('none');
        changeSetAbaChip('none');
        changeSetAbaCamera('block');
         
    }

    
    return(
        <Container>
            <AreaMenu>
                <MenuTabs position={positionAba}>
                    <ul>
                        <li onClick={Position1} id={local}>Local</li>
                        <li onClick={Position2} id={controlador}>Controlador</li>
                        <li onClick={Position3} id={chip}>Comunicação</li>
                        <li onClick={Position4} id={camera}>Câmera</li>
                    </ul>
                </MenuTabs>
                <Close onClick={() => onClose()}><GrClose size={14}/></Close>
            </AreaMenu>
        </Container>
    );
}