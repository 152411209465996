import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import io from 'socket.io-client';
import PageTitle from '../PageTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
//import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import TrafficIcon from '@material-ui/icons/Traffic';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import LayersIcon from '@material-ui/icons/Layers';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import DialogContentText from '@material-ui/core/DialogContentText';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { BottomNavigation, BottomNavigationAction, FormHelperText } from '@material-ui/core';
import iconFilter from '../../assets/img/filter.svg';
import api from "../../services/api";
import useForm from "../../hooks/useForm";
import { FaArrowRight } from "react-icons/fa";
import { AreaFiltro, TicketFilter, Voltar, InfoHelp, AreaBallon, ContentBallon } from './styles';
import "./styles.css";
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../Balloons';
import icoInfo from '../../assets/icons/icoInfo.png';
import icoSom from '../../assets/icons/icoSom.png';
import { tocarVozFiltro } from '../Audio';



////////////////

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

///////////////////


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
/////////////////


const falhas = [//todas as mensagens de falhas que podem ser enviadas do controlador
	{
		value: 'Normal',
		label: 'Normal',
	},
	{
		value: 'Apagado',
		label: 'Apagado',
	},
	{
		value: 'Manutenção',
		label: 'Manutenção',
	},
	{
		value: 'aberta',
		label: 'Porta aberta',
	},
	{
		value: 'Falta de Energia',
		label: 'Falta de Energia',
	},
	{
		value: 'Subtensao de Rede',
		label: 'Subtensão de Rede',
	},
	{
		value: 'Amarelo Intermitente',
		label: 'Amarelo Intermitente',
	},
	{
		value: 'Offline',
		label: 'Offline',
	},
	{
		value: 'Estacionado e Apagado',
		label: 'Estacionado e Apagado',
	},
	{
		value: 'Subtensão de Rede e Apagado',
		label: 'Subtensão de Rede e Apagado',
	},
	{
		value: 'Subtensao de Rede e Estacionado',
		label: 'Subtensão de Rede e Estacionado',
	},
	{
		value: 'Subtensao de Rede, Estacionado e Apagado',
		label: 'Subtensão de Rede, Estacionado e Apagado',
	},
];


function FilterControllers(props) {

	const [{ values }, handleChange1] = useForm();
	const [value, setValue] = useState(0);
	const [filter, setFilter] = useState('ID');
	const [modelosDeControlador, setModelosDeControlador] = useState([]);
	const [nameRoutes, setNameRoutes] = useState([]);
	const [nameZones, setNameZones] = useState([]);
	const [dataControlers, setDataControlers] = useState([]);
	const [slide, setSlide] = useState('-27');
	const [open, setOpen] = useState(false);
	const [color, setColor] = useState('#001E62');
	const [visibleBalloon, setVisibleBalloon] = useState(false)
	const { modalStatus, setModalStatus } = useContext(ModalContext);
	const { permissionRequest, setPermissionRequest } = useContext(ModalContext);
	const [valorName, setValorName] = useState([]);


	const handleChange = (event) => {

		const {
			target: { value },
		} = event;
		setValorName(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};



	const CleanFilter = (props) => {
		return (
			<svg width={props.width} height={props.height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M15 22.5C19.1421 22.5 22.5 19.1421 22.5 15C22.5 10.8579 19.1421 7.5 15 7.5C10.8579 7.5 7.5 10.8579 7.5 15C7.5 19.1421 10.8579 22.5 15 22.5Z" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<path d="M12.5 15H17.5" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
				<rect x="0.5" y="0.5" width="29" height="29" rx="5.5" stroke="#001E62" />
			</svg>
		);
	}

	const filterIntersections = (event) => {

		event.preventDefault();

		localStorage.setItem('filterOpen', 'true');//variavel para definir cor do botao se mostra setado ou não
		localStorage.setItem('typeFilterControllers', filter); //guarda o tipo do filtro que sera feito
		localStorage.setItem('valueFilterControllers', valorName.toString());//pega o valor a ser filtrado    
		if (localStorage.getItem('typeFilterControllers') === 'Rotas') {
			const socket = io(process.env.REACT_APP_SERVER_HOST, {
				query: { token: `Bearer ${localStorage.getItem('@central-de-falhas-greenwave/tokenAuth')}` }
			});

			socket.on('connect', () => console.log('[IO] Connect => New connection'));
		}
		window.location.href = "/mapa";
	}

	const resetFilterIntersections = () => {//limpar filtros
		setPermissionRequest(false);
		setFilter('');
		values.filter = '';
		setValorName("")
		localStorage.setItem('typeFilterControllers', '');
		localStorage.setItem('valueFilterControllers', '');
		localStorage.setItem('typeFilterControllers', '');
		localStorage.setItem('filterOpen', "false");
		window.location.href = "/mapa";
	}

	const defineInput = () => {
		if (filter === "Falha") {
			return (

				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{falhas.sort((falhaA, falhaB) => falhaA.label > falhaB.label ? 1 : -1).map((itens) => (
							<MenuItem key={itens.value} value={itens.value}>
								<Checkbox checked={valorName.indexOf(itens.value) > -1} />
								<ListItemText primary={itens.value} />
							</MenuItem>
						))}
					</Select>
				</FormControl>

			)
		} else if (filter === "Rotas") {
			return (

				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{nameRoutes.map((itens) => (
							<MenuItem key={itens.rota} value={itens.rota}>
								<Checkbox checked={valorName.indexOf(itens.rota) > -1} />
								<ListItemText primary={itens.rota} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		} else if (filter === "Area") {

			return (

				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{nameZones.map((itens) => (
							<MenuItem key={itens.area} value={itens.area}>
								<Checkbox checked={valorName.indexOf(itens.area) > -1} />
								<ListItemText primary={itens.area} />
							</MenuItem>
						))}
					</Select>
				</FormControl>

			)
		} else if (filter === "ID") {
			return (
				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{dataControlers.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map((itens) => (
							<MenuItem key={itens.id_cruzamento} value={itens.id_cruzamento}>
								<Checkbox checked={valorName.indexOf(itens.id_cruzamento) > -1} />
								<ListItemText primary={itens.id_cruzamento} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		} else if (filter === "Modelo") {
			return (

				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{modelosDeControlador.map((itens) => (
							<MenuItem key={itens.modelo} value={itens.modelo}>
								<Checkbox checked={valorName.indexOf(itens.modelo) > -1} />
								<ListItemText primary={itens.modelo} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		}
		else {
			return (

				<FormControl sx={{ width: 300 }}>
					<InputLabel id="demo-multiple-checkbox-label">{filter}</InputLabel>
					<Select
						labelId="demo-multiple-checkbox-label"
						id="demo-multiple-checkbox"
						multiple
						value={valorName}
						onChange={handleChange}
						input={<OutlinedInput label={filter} />}
						renderValue={(selected) => selected.join(', ')}
						MenuProps={MenuProps}
					>
						{nameZones.map((itens) => (
							<MenuItem key={itens.area} value={itens.area}>
								<Checkbox checked={valorName.indexOf(itens.area) > -1} />
								<ListItemText primary={itens.area} />
							</MenuItem>
						))}
					</Select>
				</FormControl>
			)
		}
	}

	const getAllModelosDeControlador = async () => {
		setFilter('Modelo');
		await api.get('/centraldefalhas/modelo/controlador').then(res => {
			setModelosDeControlador(res.data.sort((modeloA, modeloB) => modeloA.nome > modeloB.nome ? 1 : -1));
		}).catch(error => {
			console.log(error);
		})
	}

	const getAllNameRoutes = async () => {
		setFilter('Rotas');
		await api.get('/centraldefalhas/routes/list').then(res => {
			setNameRoutes(res.data.sort((rotaA, rotaB) => rotaA.rota > rotaB.rota ? 1 : -1));
		}).catch(error => {
			console.log(error);
		})
	}

	const handleGetDataControler = async () => {
		await api.get('/centraldefalhas/intersection/list/all').then(res => {
			setDataControlers(res.data.locaisMapa);
		}).catch(error => {
			console.log(error);
		});
	}

	const getAllNameZones = async () => {
		setFilter('Área / Subárea');
		await api.get('/centraldefalhas/zones/list').then(res => {
			setNameZones(res.data.sort((areaA, areaB) => areaA.area > areaB.area ? 1 : -1));
		}).catch(error => {
			console.log(error);
		})
	}

	useEffect(() => {
		handleGetDataControler();
	}, [props.open]);

	useEffect(() => {
		if (localStorage.getItem('filterOpen') === 'true') {
			setColor('#0c9410');
		} else {
			setColor('#001E62');
		}
	}, []);

	const MoveSlide = () => {
		if (slide === '-448') {
			setSlide('0');
			setOpen(true);
			setModalStatus('filtro');
		}

		else if (slide === '0') {
			setSlide('-513');
			setOpen(false);
			setModalStatus('');
		}
	}

	const Over = () => {
		if (open == false) {
			setSlide('-448');
		}
	}

	const Out = () => {
		if (open == false) {
			setSlide('-513');
		}
	}

	useEffect(() => {
		if (modalStatus !== 'filtro') {
			setSlide('-513');
			setOpen(false);
		}
	}, [modalStatus]);

	//se clicar no menu hamburguer recuar menus laterais: [state criado em Mapas.js]
	useEffect(() => {
		if (props.retreatModals == false) {
			setSlide('-518');
		} else {
			setSlide('-513');
		}
	}, [props.retreatModals]);

	const openPopUp = () => {
		if (visibleBalloon) {
			setVisibleBalloon(false);
		} else {
			setVisibleBalloon(true);
		}
	}

	const ContentPopBallonOperacional = () => {
		return (
			<ContentBallon>
				<div className="title">
					<img src={icoInfo} alt="" />
					<h3>Filtros</h3>
					<label onClick={tocarVozFiltro}><img src={icoSom} alt="" /></label><br />
				</div>
				<span>
					No menu lateral direito “Filtro” permite ao usuário filtrar no mapa os pontos conforme
					ID, falha, modelo, rota e subárea.
					Para filtrar algum ponto no mapa, escolha o tipo de filtro nas abas superiores,
					e após clique no botão “Filtrar”, logo o sistema irá retornar os pontos filtrados.
				</span>
				<div className="areaButton">
					<button onClick={() => openPopUp()}>Entendi</button>
				</div>
			</ContentBallon>
		);
	}

	return (
		<AreaFiltro slider={slide}>
			<TicketFilter onClick={MoveSlide} onMouseOver={Over} onMouseOut={Out} activeFilter={color}>
				<img src={iconFilter} alt="ico" />
				Filtro
			</TicketFilter>
			<BottomNavigation
				className="button-navigation"
				showLabels
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue);
				}}
			>
				<BottomNavigationAction id="ID" label="ID" icon={<NotListedLocationIcon />} onClick={() => { setFilter('ID') }} />
				<BottomNavigationAction id="Falha" label="Falha" icon={<LocationOffIcon />} onClick={() => { setFilter('Falha') }} />
				<BottomNavigationAction id="Modelo" label="Modelo" icon={<TrafficIcon />} onClick={getAllModelosDeControlador} />
				<BottomNavigationAction id="Rota" label="Rota" icon={<MoreHorizIcon />} onClick={getAllNameRoutes} />
				<BottomNavigationAction id="Area" label="Área / Subárea" icon={<LayersIcon />} onClick={() => { getAllNameZones(); setFilter('Área / Subárea') }} />
			</BottomNavigation>
			<form className="areaFiltros">
				<DialogContent className='content-dialog-filter' onClick={() => console.log('click')}>
					<DialogContentText className='content-dialog-text'>
						{defineInput()}
						<FormHelperText>Selecione acima uma opção de filtro</FormHelperText>
					</DialogContentText>
				</DialogContent>
				<DialogActions className="content-actions-button">
					<InfoHelp onClick={() => openPopUp()}>
						<GrCircleQuestion size={15} />
					</InfoHelp>
					<AreaBallon visible={visibleBalloon}>
						<BallonsInfoModal><ContentPopBallonOperacional /></BallonsInfoModal>
					</AreaBallon>
					<Voltar onClick={() => { setSlide('-513'); setOpen(false) }}>
						<FaArrowRight size={23} color="#001E62" />
					</Voltar>
					<Button
						id="limpar"
						className="dialog-button"
						type="button"
						color="primary"
						title="Limpar filtro"
						onClick={resetFilterIntersections}
					>
						<CleanFilter width="37" height="37" />
					</Button>
					<Button
						id="filtrar"
						className="dialog-button"
						onClick={(e) => { filterIntersections(e) }}
						color="primary"
					>
						Filtrar

					</Button>
				</DialogActions>
			</form>
		</AreaFiltro>
	)
}

export default FilterControllers;