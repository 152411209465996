import styled from 'styled-components';

export const Container = styled.section`
  
`;

export const AreaCharts = styled.div`

    canvas.chartjs-render-monitor {
        width: 59% !important;
        height: 59% !important;
        margin: 0 auto !important;
    }

    @media(max-width: 1366px){
        canvas.chartjs-render-monitor {
            width: 50% !important;
            height: 50% !important;
            margin: 0 auto !important;
        }

    }

    @media(max-width: 1280px){
        canvas.chartjs-render-monitor {
            width: 40% !important;
            height: 40% !important;
            margin: 0 auto !important;
        }

    }
    @media(max-width: 768px){
        canvas.chartjs-render-monitor {
            width: 400px !important;
            height: 200px !important;
            margin: 0 auto !important;
        }

    }
    @media(max-width: 420px){
        canvas.chartjs-render-monitor {
            width: 270px !important;
            height: 125px !important;
            margin: -36px auto;
            margin-bottom: -15px;

            margin-bottom: 0 !important;
            margin-top: -35px !important;
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 15px;

    svg path {
        stroke: #001E62;
    }

    @media(max-width: 420px){
        top: 80px;
        right: 20px;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaTable = styled.div`
    width: 90%;
    margin: 20px auto;

    @media(max-width: 1280px){
        margin: 8px auto;
    }

    @media(max-width: 1366px){
        margin-bottom: 180px;
    }

    @media(max-width: 420px){
        margin-bottom: 45px;
    }
    @media(max-width: 360px){
        height: 90vh !important;
    }
`;

export const Table = styled.table`
    font-family: 'roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;

    td{
        border: 1px solid #9e9e9e;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;

        @media(max-width: 1366px){
            padding: 4px;
        }

        @media(max-width: 1280px){
            font-size: 12px;
            padding: 2px;
        }
        @media(max-width: 420px){
            font-size: 12px !important;
            padding: 5px;
        }

        img {
            width: 20px;
            height: auto;
        }

        .disabled{ // classe para desabilitar permissões
            pointer-events: none;
            opacity: 0.4;
        }

        &#total{
            height: 28px;
            font-weight: 600;
            font-family: sans-serif;
        }

        &#filter{
        position: relative;
            button {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        &#modal{
            position: relative;
            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 10px;
                margin: auto;
            }
        }
            
        &#modal{
            position: relative;
        }

        button{
            border: 0;
            background: #001E62;
            width: 27px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 5px;

            &:hover{
                background: #FFF;
                border: 1px solid #001E62;
                transition: .3s;
                
                svg{
                    path{
                        stroke: #001E62;
                    }
                }
            }

            &.btnReflesh{
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 15px;
            }
            &.btnFilter{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    th {
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;

        &:nth-of-type(1){
            border-radius: 5px 0 0 0;
        }
        &:nth-of-type(4){
            border-radius: 0px 5px 0 0;
        }

        @media(max-width: 1280px){
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 12px;
        }
    }
`;