import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container, Close, AreaInput, Box, Fieldset, Legend, AreaField, AreaBtn, Button, InfoHelp, AreaBallom, ContentBallon} from './styles';
import IconMais from '../../assets/img/IconMais.svg';
import IconMenos from '../../assets/img/IconMenos.svg';
import IconCapture from '../../assets/img/capture.svg';
import IconLocal from '../../assets/img/local.png';
import api from "../../services/api";
import { GrClose } from "react-icons/gr";
import { ModalContext } from '../../contextModal';
import { BalloonsRight } from '../Balloons';
import { GrCircleQuestion } from "react-icons/gr";
import icoInfo from '../../assets/icons/icoInfo.png';
import icoSom from '../../assets/icons/icoSom.png';
import { tocarInfoZoom, tocarInfoTempo, tocarInfoTela } from '../Audio';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function Settings(props) {

    const [valueZoom, setValueZoom] = useState(parseInt(localStorage.getItem('@central-de-falhas-greenwave/zoom')));
    const [valueTime, setValueTime] = useState(localStorage.getItem('@central-de-falhas-greenwave/setTimeReload') / 1000);
    const [visibleBallonZoom, setVisibleBallonZoom] = useState('hidden');
    const [visibleBallonTempo, setVisibleBallonTempo] = useState('hidden');
    const [visibleBallonTela, setVisibleBallonTela] = useState('hidden');
    const [visibleBallonMinhaLocalizacao, setVisibleBallonMinhaLocalizacao] = useState('hidden');

    const { modalMove, setModalMove } = useContext(ModalContext);
    const { setCursor } = useContext(ModalContext);

    async function handleSettingMap(event) {
        event.preventDefault();

        if (!valueZoom || parseInt(valueZoom) <= -1) {
            toast.warn(`Zoom do mapa não pode ser menor que 0`, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            localStorage.setItem('@central-de-falhas-greenwave/zoom', valueZoom);
            toast.info(`Aguarde...`, {
                position: "top-center",
                toastId: "registerZoom",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            await api.post('/centraldefalhas/params_user', {
                "lat": localStorage.getItem('@central-de-falhas-greenwave/positionLat'),
                "long": localStorage.getItem('@central-de-falhas-greenwave/positionLng'),
                "zoom": valueZoom,
                "timerMap": localStorage.getItem('@central-de-falhas-greenwave/setTimeReload')
            }).then(res => {
                toast.update("registerZoom", {
                    render: `Zoom do mapa redefinido !`,
                    type: toast.TYPE.SUCCESS,
                    position: "top-center",
                    autoClose: 3000,
                });
                window.location.href = "/mapa"
            }).catch(err => {
                return err;
            })
        }
        if (!valueTime || parseInt(valueTime) < 4) {
            toast.warn(`Tempo mínimo: 4 segundos`, {
                position: "top-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            const timeCalc = (valueTime * 1000) //transforma em segundos

            localStorage.setItem('@central-de-falhas-greenwave/setTimeReload', `${timeCalc}`);
            toast.info(`Aguarde...`, {
                position: "top-center",
                toastId: "registerTime",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            await api.post('/centraldefalhas/params_user', {
                "lat": localStorage.getItem('@central-de-falhas-greenwave/positionLat'),
                "long": localStorage.getItem('@central-de-falhas-greenwave/positionLng'),
                "zoom": localStorage.getItem('@central-de-falhas-greenwave/zoom'),
                "timerMap": timeCalc
            }).then(res => {
                toast.update("registerTime", {
                    render: `Redefinido tempo de atualização do mapa!`,
                    type: toast.TYPE.SUCCESS,
                    position: "top-center",
                    autoClose: 3000,
                    onClose: () => window.location.href = "/mapa",
                });
            }).catch(err => {
                console.log(err)
                return err;
            })
        }
    }

    const decreaseValue = () => {
        if (valueZoom <= 0) {
            return;
        }
        setValueZoom(valueZoom - 1);
    }

    const addValue = () => {
        if (valueZoom >= 20) {
            return;
        }
        setValueZoom(valueZoom + 1);
    }

    const clickConfCenterMap = (event) => {//salva informacao e aguarda proximo click para configurar centro(funcao que configura o centro esta na pasta mapas)
        const eventType = event.type;
        if (eventType === "click") {
            localStorage.setItem('awaitNextClickConfCenterMap', 'true');

            toast.info(`Clique onde sera o novo centro do mapa!`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setCursor({ cursor: 'crosshair' });
            setModalMove({ marginTop: '665px', transition: '.5s' });
            return window.location.href = "#Definir-centro-do-mapa";
        } else {
            localStorage.setItem('awaitNextClickConfCenterMap', 'false');
        }
    }

    const saveLocalMap = () => {
        //pegar a posição aproximada 
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        //seta a latitude e longitude da minha posição
        localStorage.setItem('@central-de-falhas-greenwave/positionLat', position.coords.latitude);
        localStorage.setItem('@central-de-falhas-greenwave/positionLng', position.coords.longitude);
        //resultado
        console.log(position.coords.longitude + ' / ' + position.coords.latitude);

        toast.info(`Sua localização atual foi salva com sucesso!`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
    }

    const ContentPopBallonZoom = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Configurações de zoom</h3>
                    <label onClick={tocarInfoZoom}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Opção “zoom” é responsável pela ampliação do mapa, ao iniciar
                    o sistema, sempre carregará o zoom escolhido pelo usuário.<br/>
                    Clique nos sinais de <strong>"+"</strong> e <strong>"-"</strong> para aumentar e diminuir o zoom,
                    após selecionar o zoom clique em <strong>"Salvar"</strong>, espere o recarregamento da página, e 
                    pronto! o mapa se inicializará  de acordo com o zoom escolhido.
                </span>
                <div className="areaButton">
                    <button onClick={viewBallonZoon}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBallonTempo = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Tempo de atualização</h3>
                    <label onClick={tocarInfoTempo}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    A opção “Tempo de atualização”  é responsável pelo espaço de tempo em que o sistema ficará
                    escutando se houve ou não alguma alteração.  ou seja.  qualquer mudança no controlador
                    semafórico, como:  “Amarelo piscante, falha, sem comunicação, manutenção e etc. Ao detectar
                    qualquer alteração no controlador o sistema irá apresentar essas mudanças em tela conforme 
                    o tempo escolhido por você. Exemplo: a cada 5 segundos o controlador e atualizado,
                    refletindo o estado do controlador.
                </span>
                <div className="areaButton">
                    <button onClick={viewBallonTempo}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBallonTela = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Configuração de centro da tela</h3>
                    <label onClick={tocarInfoTela}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    A Opção “Centro da tela” é responsável por escolher em qual parte do mapa o usuário deseja visualizar assim que iniciar o sistema,
                    com isso,  o mapa sempre se abrirá no centro escolhido pelo usuário.Para realizar esse processo, você deve clicar no ícone á esquerda,
                    esperar caixa de opções descer, após isso, com a seta do mouse, clique exatamente onde você deseja que o sistema inicie exibindo o trecho escolhido 
                </span>
                <div className="areaButton">
                    <button onClick={viewBallonTela}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentPopBallonPosicaoAtual = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Minha posição atual</h3>
                    <label onClick={''}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    A Opção “Abril onde estou” é responsável por abrir o mapa referente a minha localização 
                </span>
                <div className="areaButton">
                    <button onClick={viewBallonMinhaLocalizacao}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const viewBallonZoon = () => {
        if(visibleBallonZoom === 'hidden'){
            setVisibleBallonZoom('visible');
            setVisibleBallonTempo('hidden');
            setVisibleBallonTela('hidden');
            setVisibleBallonMinhaLocalizacao('hidden');
        }else{
            setVisibleBallonZoom('hidden');
        }
    }

    const viewBallonTempo = () => {
        if(visibleBallonTempo === 'hidden'){
            setVisibleBallonZoom('hidden');
            setVisibleBallonTempo('visible');
            setVisibleBallonTela('hidden');
            setVisibleBallonMinhaLocalizacao('hidden');
        }else{
            setVisibleBallonTempo('hidden');
        }
    }

    const viewBallonTela = () => {
        if(visibleBallonTela === 'hidden'){
            setVisibleBallonZoom('hidden');
            setVisibleBallonTempo('hidden');
            setVisibleBallonMinhaLocalizacao('hidden');
            setVisibleBallonTela('visible');
        }else{
            setVisibleBallonTela('hidden');
        }
    }

    const viewBallonMinhaLocalizacao = () => {
        if(visibleBallonMinhaLocalizacao === 'hidden'){
            setVisibleBallonZoom('hidden');
            setVisibleBallonTempo('hidden');
            setVisibleBallonTela('hidden');
            setVisibleBallonMinhaLocalizacao('visible');
        }else{
            setVisibleBallonMinhaLocalizacao('hidden');
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
                style={modalMove}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Configurações do mapa"
                    onClick={props.onClose}
                ></PageTitle>
                <Container>
                    <Close onClick={props.onClose}><GrClose size={14} /></Close>

                    <Fieldset>
                        <Legend>Opções do mapa</Legend>
                        <Box>
                            <fieldset>
                                <legend>Zoom</legend>
                                <AreaInput>
                                    <button className="btnMenos" onClick={decreaseValue}><img src={IconMenos} alt="" /></button>
                                    <input type="text" value={valueZoom} />
                                    <button className="btnMais" onClick={addValue}><img src={IconMais} alt="" /></button>
                                </AreaInput>
                            </fieldset>
                            <InfoHelp onClick={viewBallonZoon}><GrCircleQuestion /></InfoHelp>
                            <AreaBallom id="zoom" view={visibleBallonZoom}>
                                <BalloonsRight><ContentPopBallonZoom /></BalloonsRight>
                            </AreaBallom>
                        </Box>
                        <Box>
                            <fieldset>
                                <legend>Tempo de atualização</legend>
                                <AreaField>
                                    <input type="number" value={valueTime} onChange={(e) => setValueTime(e.target.value)} />
                                    <span>Intervalo de tempo em segundos.</span>
                                </AreaField>
                            </fieldset>
                            <InfoHelp onClick={viewBallonTempo}><GrCircleQuestion /></InfoHelp>
                            <AreaBallom id="tempo" view={visibleBallonTempo}>
                                <BalloonsRight><ContentPopBallonTempo /></BalloonsRight>
                            </AreaBallom>
                        </Box>
                    </Fieldset>

                    <Fieldset>
                        <Legend>Abertura de mapa</Legend>
                        <Box>
                            <fieldset>
                                <legend>Centro da tela</legend>
                                <AreaField>
                                    <img className="iconCapture" src={IconCapture} alt="" onClick={clickConfCenterMap} />
                                    <span>Clique aqui e escolha o seu centro da tela</span>
                                </AreaField>
                            </fieldset>
                            <InfoHelp onClick={viewBallonTela}><GrCircleQuestion /></InfoHelp>
                            <AreaBallom id="tela" view={visibleBallonTela}>
                                <BalloonsRight><ContentPopBallonTela /></BalloonsRight>
                            </AreaBallom>
                        </Box>
                        <Box>
                            <fieldset>
                                <legend>Abrir onde estou</legend>
                                <AreaField>
                                    <img className="iconCapture" src={IconLocal} alt="" onClick={saveLocalMap} />
                                    <span>Abrir mapa no local onde eu estou</span>
                                </AreaField>
                            </fieldset>
                            <InfoHelp onClick={viewBallonMinhaLocalizacao}><GrCircleQuestion /></InfoHelp>
                            <AreaBallom id="tela" view={visibleBallonMinhaLocalizacao}>
                                <BalloonsRight><ContentPopBallonPosicaoAtual /></BalloonsRight>
                            </AreaBallom>
                        </Box>
                    </Fieldset>

                    <AreaBtn>
                        <Button onClick={handleSettingMap}>Salvar</Button>
                    </AreaBtn>
                </Container>
            </Dialog>
        </>
    )
}

export default Settings;