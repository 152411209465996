import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PageTitle from '../PageTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container } from './styles';
import Tabs from './Abas';
import Consorcio from './Abas/Consorcio';
import Empresa from './Abas/Empresas';
import Controlador from './Abas/Controlador';
import Camera from './Abas/Camera';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function Parametros(props) {

    const [abaConsorcio, setAbaConsorcio] = useState('block');
    const [abaEmpresa, setAbaEmpresa] = useState('none');
    const [abaControlador, setAbaControlador] = useState('none');
    const [abaCamera, setAbaCamera] = useState('none');
    const [positionAba, setPositionAba] = useState(1);

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Parâmetros"
                onClick={props.onClose}
            ></PageTitle>
            <Container>
                <Tabs
                    setAbaConsorcio={setAbaConsorcio}
                    setAbaEmpresa={setAbaEmpresa}
                    setAbaControlador={setAbaControlador}
                    setAbaCamera={setAbaCamera}
                    setPositionAba={setPositionAba}
                    onClose={props.onClose}
                />
                <Consorcio status={abaConsorcio} positionAba={positionAba}/>
                <Empresa status={abaEmpresa} positionAba={positionAba} />
                <Controlador status={abaControlador} positionAba={positionAba} />
                <Camera status={abaCamera} positionAba={positionAba} />
            </Container>
        </Dialog>
    );
}