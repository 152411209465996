import React from 'react';

import Toast from 'light-toast';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import UndoIcon from '@material-ui/icons/Undo';
import { IconButton, DialogActions, Button } from '@material-ui/core';

import api from "../../services/api";

interface Column {
  id: 'id_controlador' | 'descricao_falha' | 'data';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'id_controlador', label: 'Controlador', minWidth: 50 },
  { id: 'descricao_falha', label: 'Descricao falha', minWidth: 80 },
  {
    id: 'data',
    label: 'data',
    minWidth: 50,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US'),
  },
  
];

interface Data {
  id_controlador: string;
  descricao_falha: string;
  data: number;
}

interface Logs {
    data: number,
    descricao_falha: string
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '93vh',
    maxWidth: '100%',
  },
});

export default function ImprimirLogs() {

  const classes = useStyles();
  const [ logs, setLogs ] = React.useState([]);

  async function getLogsForControllers(){
    const id = localStorage.getItem('dataLogImp');
    Toast.loading("CARREGANDO LOGS")
    await api.post('/centraldefalhas/intersection/logs', {
      "id_cruzamento" : id,
    }).then(res => {        
      Toast.success("PRONTO ", 300);
      setLogs(res.data.reverse());
      logs.map(logs => {
          return logs
      })
    }).catch(err => {
        Toast.fail("ERRO AO RECEBER LOGS", 1500);
        console.log(`Error logs ${err}`);
    })
  };


  window.onload = getLogsForControllers;

  return (
    <Paper className={classes.root}>
          <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>LOGS DO CONTROLADOR</TableHead>
                  <TableHead>
                      <TableRow>
                          {columns.map((column) => (
                              <TableCell
                              key={column.id}
                              align="center"
                              style={{ minWidth: column.minWidth }}
                              >
                              {column.label}
                              </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {logs.map(logs => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={logs.id_cruzamento}>
                            <TableCell key='1' align='center'>
                                {logs.id_cruzamento}
                            </TableCell>
                            <TableCell key='1' align='center'>
                                {logs.descricao_falha}
                            </TableCell>
                            <TableCell key='1' align='center'>
                                {logs.data}
                            </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
              </Table>
          </TableContainer>
          <DialogActions>
            <IconButton aria-label="delete" className="button-delete"  >
              <LocalPrintshopIcon onClick={() => {window.print()}} />
            </IconButton>
            <Button
              className="dialog-button"
              variant="outlined"
              title="Voltar"
              color="primary"
              onClick={() => {window.location.href = "/mapa";}}
            >
              <UndoIcon />
            </Button>
          </DialogActions>
      </Paper>
  );
}