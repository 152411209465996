import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../../contextModal';
import { Container, AreaMenu, MenuTabs, Close} from './styles';
import { GrClose } from "react-icons/gr";

export default function Tabs(props){

    const [positionAba, setPositionAba] = useState('7');
    const [consorcio, setConsorcio] = useState('active');
    const [empresas, setEmpresas] = useState('inactive');
    const [controlador, setControlador] = useState('inactive');
    const [cameras, setCameras] = useState('inactive');

    const { abrirAbaParametro } = useContext(ModalContext);
   
    useEffect(() => {

        if(abrirAbaParametro === 'consorcio'){
            Position1();
        }
        if(abrirAbaParametro === 'empresas'){
            Position2();
        }
        if(abrirAbaParametro === 'controlador'){
            Position3();
        }
        if(abrirAbaParametro === 'camera'){
            Position4();
        }

    },[]);

    const Position1 = () => {
        setPositionAba('7');
        setConsorcio('active');
        setEmpresas('inactive');
        setControlador('inactive');
        setCameras('inactive');
        props.setAbaConsorcio('block');
        props.setAbaEmpresa('none');
        props.setAbaControlador('none');
        props.setAbaCamera('none');
        props.setPositionAba(1)
  
    }
    const Position2 = () => {
        setPositionAba('118');
        setConsorcio('inactive');
        setEmpresas('active');
        setControlador('inactive');
        setCameras('inactive');
        props.setAbaConsorcio('none');
        props.setAbaEmpresa('block');
        props.setAbaControlador('none');
        props.setAbaCamera('none');
        props.setPositionAba(2);

    }
    const Position3 = () => {
        setPositionAba('270');
        setConsorcio('inactive');
        setEmpresas('inactive');
        setControlador('active');
        setCameras('inactive');
        props.setAbaConsorcio('none');
        props.setAbaEmpresa('none');
        props.setAbaControlador('block');
        props.setAbaCamera('none');
        props.setPositionAba(3)

    }
    const Position4 = () => {
        setPositionAba('434');
        setConsorcio('inactive');
        setEmpresas('inactive');
        setControlador('inactive');
        setCameras('active');
        props.setAbaConsorcio('none');
        props.setAbaEmpresa('none');
        props.setAbaControlador('none');
        props.setAbaCamera('block');
        props.setPositionAba(4)
        
    }

    const closeParametros = () => {
        props.onClose();
    }

    return(
        <Container>
            <Close onClick={closeParametros}><GrClose size={25}/></Close>
            <AreaMenu>
                <MenuTabs position={positionAba}>
                    <ul>
                        <li onClick={Position1} id={consorcio} className="consorcio">Consórcio</li>
                        <li onClick={Position2} id={empresas}>Empresas</li>
                        <li onClick={Position3} id={controlador}>Modelos-Controlador</li>
                        <li onClick={Position4} id={cameras} className="cameras">Modelos-Câmera</li>
                    </ul>
                </MenuTabs>
            </AreaMenu>
        </Container>
    );
}