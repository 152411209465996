import styled from 'styled-components';

export const Modal = styled.div`

    width: 500px;
    /*height: auto;
    overflow: scroll;*/

    @media(max-width: 420px){
        width: 85vw;
        margin: 0 auto;
    }

    .activated{
        display: block;
    }
    .disabled{
        display: none;
    }

    .sc-kstrdz.bKKmUE {
        width: 80%;
        margin: 0 auto;
    }

`;

export const AreaButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 22px;
    margin: 2px 0 2px 0;

    .MuiBottomNavigationAction-label.MuiBottomNavigationAction-iconOnly {
        opacity: 1;
        transition-delay: 1s;
    }

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    .alert{
        display: ${props => props.message};
        width: 100%;
        height: 10px;
        margin-bottom: 19px;
        
        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0 {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

export const Buttons = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 12px;
    margin-top: 10px;
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }

    }
`;

export const AreaIntersecao = styled.div`
    width: 80%;
    margin: 0 auto;
    position: relative;

    @media(max-width: 420px){
        width: 93%;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
    }

    button{
        &.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.dialog-button.MuiButton-outlinedPrimary {
            margin-left: 5px;
        }
    }

    fieldset{
        legend{
            font-size: 13px;
            font-family: 'roboto', sans-serif;  
            color: #848484;
        }
    }

    .group{
        display: flex;
        align-items: center;
        height: 55px;
        
        .MuiFormControl-root.MuiTextField-root {
            width: 100% !important;
        }

        .MuiOutlinedInput-root {
            height: 33px;
        }

        .MuiInputBase-root {
            margin: 7px;
        }
    }
    .groupBtn {
        margin-top: 20px;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 175px;
        color: #FFF;
        background: #001E62;

        button{
            width: auto;
        }
    }

    label#id-label {
        font-size: 15px;
        margin-top: -3px;
    }

    label#outlined-basic-label {
        font-size: 15px;
        margin-top: -3px;
    }
`;

export const Content = styled.div`
    margin-top: 6px;
    padding: 0 5px 0 0;
    overflow-y: scroll;
    max-height: 165px;

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}
`;

export const AreaControlador = styled(AreaIntersecao)`
    //display: none; // aba de intersecao oculta até criar requisições no back-end
`;

export const AreaRota = styled.div`
    width: 80%;
    margin: 0 auto;
    position: relative;

    @media(max-width: 420px){
        width: 93%;
        margin: auto;
        left: 0;
        right: 0;
        position: absolute;
    }

    button{
        &.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.dialog-button.MuiButton-outlinedPrimary {
            margin-left: 5px;
        }
    }

    fieldset{
        legend{
            font-size: 13px;
            font-family: 'roboto', sans-serif;  
            color: #848484;
        }
    }

    .group{
        display: flex;
        align-items: center;
        height: 55px;
        
        button{
            &.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.dialog-button.MuiButton-outlinedPrimary {
                margin-left: 5px;
            }
        }

        .MuiFormControl-root.MuiTextField-root {
            width: 100% !important;
        }   
        .MuiOutlinedInput-root {
            height: 33px;
        }

        .MuiInputBase-root {
            margin: 7px;
        }
    }
    .groupBtn {
        margin-top: 20px;
        margin-bottom: 25px;
        display: flex;
        justify-content: flex-end;
        align-items: end;

        button{
            margin: 0 5px;
        }
    }

    label#id-label {
        font-size: 15px;
        margin-top: -3px;
    }

    label#outlined-basic-label {
        font-size: 15px;
        margin-top: -3px;
    }
`;

export const ButtonRemove = styled.button`
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border: 2px solid #001E62;
    border-radius: 6px;
    width: 45px;
    height: 34px !important;
    cursor: pointer;

    &:hover{
        background: #001E62;
        transition: .5s;

        svg path{
            fill: #001E62; 
            stroke: #FFF;
        }
    }
`;

export const ButtonAdd = styled.button`
    border: 2px solid #001E62;
    border-radius: 6px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 33px;
    cursor: pointer;
    margin-left: 5px;

    &:active{
        position: relative;
        top: 2px;
    }

    svg{
        fill: #001E62;
        width: 21px;
        height: 20px;
        margin-right: 2px;
    }
`;

export const ButtonSend = styled.button`
    width: 181px;
    height: 33px;
    border: 0;
    border-radius: 6px;
    background: #001E62;
    color: #FFF;
    cursor: pointer;
    pointer-events: ${props => props.status === 'disabled' ? 'none' : ''};
	opacity: ${props => props.status === 'disabled' ? '0.4' : ''};


    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        fill: #FFF;
        margin-right: 7px;
    }

    &:hover{
        background: #FFF;
        color: #001E62;
        border: .5px solid #001E62;
        transition: .5s;
        svg{
            fill: #001E62;
        }
    }
`;


export const ButtonCancel = styled.div`
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    border: 2px solid #001E62;
    border-radius: 6px;
    width: 40px;
    height: 29px !important;
    cursor: pointer;

    &:hover{
        background: #001E62;
        transition: .5s;

        svg {
            color: #FFF;
            fill: #FFF;
        }
    }
`;

