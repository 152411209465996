import React, { useState } from 'react';
import { Container, AreaContent, Header, Content, ButtonSlide, AreaInputs, Row, InputLocais, InputGrupo } from './styles';
import ArrowLeft from '../../assets/img/ArrowLeft.svg';
import ArrowRight from '../../assets/img/ArrowRight.svg';
import './styles.css'


function DetailRoute(props) {

    const [arrow, setArrow] = useState(ArrowLeft);
    const [slide, setSlide] = useState('280');
    const sliderHeader = () => {
        if (slide === '280') {
            setSlide('0');
            setArrow(ArrowRight);
        } else {
            setSlide('280');
            setArrow(ArrowLeft);
        }
    }

    return (
        <div className="div-detail-route" id="div-id-detail-route">
            <Container>
                <AreaContent open={slide}>
                    <Header>
                        <div>ID dos locais</div>
                        <div>Aneis</div>
                        <div>Grupo</div>
                    </Header>
                    <Content>
                        <AreaInputs>
                            {props.controllersRoute.map((data, idx) => (
                                <>
                                    <Row key={data.anel+idx}>
                                        <InputLocais type="text" value={data.id_cruzamento} disabled />
                                        <InputGrupo  type="text" value={data.anel} disabled />
                                        <InputGrupo  type="text" value={data.grupo} disabled />
                                    </Row>
                                    <hr />
                                </>
                            ))}
                        </AreaInputs>
                    </Content>
                </AreaContent>
                <ButtonSlide onClick={sliderHeader}><img src={arrow} alt="" /></ButtonSlide>
            </Container>
        </div>
    )
}

export default DetailRoute;