import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import GamesIcon from '@material-ui/icons/Games';
import { GrClose } from "react-icons/gr";
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Close } from './styles';
import { validarPermissao } from '../../services/permission';

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
});

function OpenPagesExternal(props) {
	const [value, setValue] = React.useState(0);
	const classes = useStyles();

	/*const openEditCroqui = () => {
		if (validarPermissao([0, 1, 2])) { 
			return window.open(`${process.env.REACT_APP_FRONT_HOST}/edicao_croqui/${props.id}`, `${props.id}-edit`, "height=660,width=1260");
		}
	};
	const openMoni = () => {
		return window.open(`${process.env.REACT_APP_FRONT_HOST}/monitoramento/${props.id}`, `${props.id}`, "height=533,width=980");
	};*/

	const openCroqui = () => {
		return window.open(`croqui/${props.id}/${props.anel}/${props.ipMbb}`, '', 'height=640,width=1260');
	}

	const openProgramador = () => { //web/index.xhtml?remotehost=0.0.0.0:80&gestor&operador&visitante

		const visitante = 'gestor&operador&visitante';
		const operador = 'gestor&operador';
		const gestor = 'gestor';

		if (validarPermissao([0, 1])) {//acesso Administrador/Gestor
			return window.open(`${process.env.REACT_APP_SERVER_PROGRAMMER}?rhost=${props.ipMbb + ':' + props.porta}&adm&${gestor}`, `${props.ipMbb}`, "height=1000,width=800",);
		}
		if (validarPermissao([2])) {//acesso operador
			return window.open(`${process.env.REACT_APP_SERVER_PROGRAMMER}?rhost=${props.ipMbb + ':' + props.porta}&adm&${operador}`, `${props.ipMbb}`, "height=1000,width=800");
		}
		if (validarPermissao([3])) {//acesso visitante
			return window.open(`${process.env.REACT_APP_SERVER_PROGRAMMER}?rhost=${props.ipMbb + ':' + props.porta}&adm&${visitante}`, `${props.ipMbb}`, "height=1000,width=800");
		}
	};

	return (
		<>
			<Dialog
				open={props.open}
				className="Dialog"
				onClose={props.onClose}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
				hideBackdrop={true}
			>
				<PageTitle
					id="draggable-dialog-title"
					text="Opções"
					onClick={props.onClose}
				> </PageTitle>
				<BottomNavigation
					value={value}
					onChange={(event, newValue) => {
						setValue(newValue);
					}}
					showLabels
					className={classes.root}
				>
					<Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
					<BottomNavigationAction id="button-moni" label="Croqui" onClick={openCroqui} icon={<GamesIcon />} />
					<BottomNavigationAction id="button-prog" label="Acesso ao programador" onClick={openProgramador} icon={<SettingsOverscanIcon />} />
				</BottomNavigation>
			</Dialog>
		</>
	)
}

export default OpenPagesExternal;