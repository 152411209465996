import React from 'react';
import { AreaButton } from './styles';
 
export default function ButtonReflesh(){

    const Icon = () => (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
            <path d="M0.625 2.5V6.25H4.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.375 12.5V8.75H10.625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8062 5.62547C12.4893 4.72972 11.9505 3.92885 11.2403 3.29761C10.5301 2.66637 9.67158 2.22533 8.74482 2.01564C7.81806 1.80594 6.85328 1.83444 5.94051 2.09846C5.02773 2.36248 4.19672 2.85342 3.525 3.52547L0.625 6.25047M14.375 8.75047L11.475 11.4755C10.8033 12.1475 9.97227 12.6385 9.05949 12.9025C8.14672 13.1665 7.18194 13.195 6.25518 12.9853C5.32842 12.7756 4.46987 12.3346 3.75967 11.7033C3.04946 11.0721 2.51073 10.2712 2.19375 9.37547" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0">
            <rect width="15" height="15" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    );

    return(
        <AreaButton>
            <Icon />
        </AreaButton>
    );
}