import React, { useState, useEffect } from 'react';
import BtnNovo from '../../../Registers/RegisterControllers/Icons/BtnNovo';
import excluir from '../../../../assets/img/excluir.png';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../services/api';
import icoSom from '../../../../assets/icons/icoSom.png';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import { Container, AreaIcon, ContentBallon, InfoParametros, AreaInput, Group, AreaButtons, Button, AreaTable, Table} from './styles';
import { validarPermissao } from '../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsParametros } from '../../../Balloons';
import { tocarAudioParametro } from '../../../Audio';

const permissao = (validarPermissao([0,1]) ? 'enabled' : 'disabled');

export default function Consorcio(props){

    const[modoOperacional, setModoOperacional] = useState([]);
    const[enabled, setEnabled] = useState('enabledInputs');
    const[msg, setMsg] = useState('');
    const[alertMsg, setAlertMsg] = useState('block');
    const[typeAlert, setTypeAlert] = useState('sucess');
    const[nome, setNome] = useState('');
    const[_id, set_id] = useState('');
    const[visibleBalloon, setVisibleBalloon] = useState(false);
    
    const lerDados = async() =>{
        const response = await api.get('/centraldefalhas/consorcio');
        setModoOperacional(response.data);
    }
   
    //Usado para verificar se mudou de aba
    //e entao limpar os campos
    useEffect(() => {
        lerDados();
        setEnabled('enabledInputs');
        setNome('');
    },[props.positionAba]);

    const addItem = () =>{
        setEnabled('desabledInputs');
        set_id('');
        setNome('');
    }

    const deleteItem = async(nome) =>{
        await api.delete(`/centraldefalhas/consorcio/${nome}`)
        .then(() =>{
            setMsg('O nome excluido com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');
            lerDados();

            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            },5000);

        }).catch(() => {
            setMsg('O nome não foi excluido!');
            setAlertMsg('block');
            setTypeAlert('warning');
        });
    }

    const saveData = async() => {
        
        if([nome].includes('')){
            setAlertMsg('block');
            setMsg('Preencha o campo nome!');
            setTypeAlert('warning');
            
           setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            },5000);

        }else{
            await api.post('/centraldefalhas/consorcio',{
                "nome": nome,
                "_id": _id
            }).then((res) =>{

                setAlertMsg('block');
                setMsg('Salvo com sucesso!');
                setTypeAlert('success');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

                lerDados();
                setNome('');

            }).catch((err) =>{
                setAlertMsg('block');
                setMsg(err.response.data.mensagem);
                setTypeAlert('error');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

            });
        }
    }

    const listDataInput = (item) => {
        setNome(item.nome);
        setEnabled('desabledInputs');
        set_id(item._id);
    }

    
    const openPopUp = () => {
        if(visibleBalloon === false){
            setVisibleBalloon(true);
        }else{
            setVisibleBalloon(false);
        }
    }

    const closePopUp = () => {
        setVisibleBalloon(false);
    }

    const ContentBallonParametro = () => {
        return(
            <ContentBallon>
                <div className="title"> 
                    <img src={icoInfo} alt="" />
                    <h3>Consórcio</h3>
                    <label onClick={tocarAudioParametro}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Nesse campo deverá ser inserido o nome de consórcios envolvidos.
                </span>
                <div className="areaButton">
                    <button onClick={() => closePopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return(
        <Container style={{display: props.status}}>
            <AreaIcon>
                <div className='icon' onClick={openPopUp}>
                    <GrCircleQuestion />
                </div>
                <InfoParametros view={visibleBalloon}>
                    <BallonsParametros>
                        <ContentBallonParametro />
                    </BallonsParametros>
                </InfoParametros>
            </AreaIcon>
            <AreaInput>
                <Group id={enabled}>
                    <label htmlFor="">Nome do consórcio:</label>
                    <input type="text" placeholder="Selecione na tabela ou adicione..." value={nome} onChange={(e) => setNome(e.target.value)}/>
                </Group>
                <AreaButtons message={alertMsg} className={permissao}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{msg}</Alert>
                    </div>
                    <Button id="btnNovo" onClick={addItem}>
                        <BtnNovo />Novo
                    </Button>
                    <Button className="btnSalvar" id={enabled} onClick={saveData}>
                        Salvar
                    </Button>
                </AreaButtons>
            </AreaInput>
            <AreaTable>
                <Table>
                    <tr>
                        <th>Nome</th>
                        <th>Excluir</th>
                    </tr>
                    {modoOperacional.map( (item,index) => (
                        <tr key={item.id} onClick={permissao === 'disabled' ? '' : () => listDataInput(item)}>
                            <td>{item.nome}</td>
                            <td><img src={excluir} alt="" className={permissao} onClick={() => deleteItem(item.nome)}/></td>
                        </tr>
                    ))}
                </Table>       
            </AreaTable>
        </Container>
    );
}