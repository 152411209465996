import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconRemove from '../Registers/RegisterRoutes/icons/IconRemove';
import "./styles.css";

function LineTable(props) {
  

  const deleteControllers = () => {
    props.deleteController(props.idCruzamento, props.anel)
  }
  /* Botão oculto até criar requisição back-end*/
  return (
        <>
        <button
          id="btnDeleteControllers"
          title="Excluir equipamento"
          onClick={deleteControllers}
        >
          <IconRemove />
        </button>
      </>
  )
}

export default LineTable;