import styled from 'styled-components';

export const Container = styled.section`
    width: 650px;

    form {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media(max-width: 680px){
            width: 90vw;
            margin: 0;
        }
    }

    fieldset{
        width: 311px;
        border: .5px solid silver;
        border-radius: 3px;

        &:nth-of-type(1) {
            margin-bottom: 21px;

            .group{
                margin: 0 5px 0 5px;
            }

            .hide{
                display: none;
                transition: .5s;
            }

            .show{
                display: block;
                transition: .5s;
            }

            .MuiPaper-root.MuiAlert-root.MuiAlert-standardError.MuiPaper-elevation0 {
                margin-top: 13px;
                height: 26px;
                display: flex;
                align-items: center;
            }

            @media(max-width: 1290px){
                margin-bottom: 7px;
            }

            @media(max-width: 360px){
                width: 85%;
            }
        }

        &:nth-of-type(2) {
            margin-bottom: 21px;
            @media(max-width: 360px){
                width: 85%;
            }
        }

        .preferencias{
            display: flex;
        }

        input{
            width: 260px;
            height: 35px;
            box-sizing: border-box;
            border: .5px solid silver;
            border-radius: 4px;
            padding-left: 10px;
            outline: none;
            border-radius: 4px;
            padding-left: 10px;
            font-size: 14px;
            font-family: 'roboto';

            &:focus{
                border: 2px solid #001E62;
            }

            @media(max-width: 1290px){
                height: 28px;
            }

            @media(max-width: 420px){
                width: 100%;
            }
        }

        select{
            width: 265px;
            height: 35px;
            border: .5px solid silver;
            border-radius: 4px;
            padding-left: 10px;
            margin-bottom: 15px;
            font-size: 14px;
            font-family: 'roboto', sans-serif;
            color: #383535;
            &:focus{
                border: 2px solid #001E62;
            }

            @media(max-width: 1290px){
                height: 28px;
            }
            @media(max-width: 580px){
                width: 100%;
                margin-bottom: 0;
            }
        }

        label{
            margin-top: 12px;
            margin-bottom: 5px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            display: block;
            font-size: 13px;
            color: #6b6a6a;
            font-family: 'roboto', sans-serif;

            @media(max-width: 1290px){
                margin-top: 7px;
            }
        }

        legend{
            font-size: 13px;
            color: #6b6a6a;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: 0em;
            font-family: 'roboto', sans-serif;
        }
    }
    .groupTop{
        margin: 0 5px 0 5px;
        display: flex;
        label {
            display: block;
        }

        @media(max-width: 720px){
            flex-direction: column;
        }
    }

    .groupBottom{
        display: flex;

        @media(max-width: 720px){
            flex-direction: column;
        }

        .group{
            margin: 0 5px 0 5px;
        }
    }

    @media(max-width: 420px){
        width: auto;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const AreaButton = styled.div`
    display: flex;
    margin-bottom: 50px;
    width: 83%;
    gap: 23px;
    justify-content: space-between;

    .alert{
        width: 100%;
        display: ${props => props.view};

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0,
        .MuiAlert-standardSuccess, 
        .MuiAlert-standardError,
        .MuiAlert-standardInfo {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: -20px;
        }
    }

`;

export const Button = styled.button`
    width: 137px;
    height: 30px;
    background: #001E62;
    color: #FFF;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    cursor: pointer;


    @media(max-width: 420px){
        margin-right: 0%;
    }

    &:hover{
        border: 1px solid #001E62;
        background: #FFF;
        color: #001E62;
        transition: .3s;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        width: 17px;
        height: 17px;
    }
`;