import styled from "styled-components";

export const Container = styled.section`
    background: transparent;
    display: flex;
    position: absolute;
    top: 55vh;
    left: -50px;
    @media(max-width: 1705px){
        top: 59vh !important;
    }
    @media(max-width: 1520px){
        top: 60vh !important;
    }
    @media(max-width: 1366px){
        top: 61.5vh !important;
    }
    @media(max-width: 414px){
        top: 70vh;
    }
`;

export const AreaContent = styled.div`
    overflow: hidden;
    width: ${props => props.open + 'px'};
    box-shadow: -23px 4px 3px -1px #79797945;
    transition: .5s;
`;

export const Header = styled.section`
    background: #062467;
    display: flex;
    align-items: center;
    color: #FFF;
    height: 37px;
    font-family: 'roboto', sans-serif;
    font-size: 14px;
    border-radius: 6px 0 0 0;
   
    @media(max-width: 1280px){
        height: 31px;
    }

    div{
        &:nth-of-type(1){
            margin-left: 42px;
            margin-right: 20px;
        }
        &:nth-of-type(2){
            margin-left: 19px;
            margin-right: -10px;
        }
        &:nth-of-type(3){
            margin-left: 29px;
            margin-right: 24px;
        }
    }
`;

export const ButtonSlide = styled.div`
    background: #062467;
    width: 50px;
    height: 37px;
    border-radius: 0 6px 6px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media(max-width: 1280px){
        height: 31px;
    }
`;

export const Content = styled.div`
    background: #ffffffd9;
    width: 260px;

    @media(max-width: 1400px){
        background: #ffffffd9;
        width: 260px;
        height: 140px;
        overflow-y: scroll;
    }
    @media(max-width: 1280px){
        background: #ffffffd9;
        width: 260px;
        height: 90px;
        overflow-y: scroll;
    }
    @media(max-width: 414px){
        height: 95px;
    }
`;

export const AreaInputs = styled.div`
    display: flex;
    flex-direction: column;
    //padding: 20px 16px 0px 26px;
    padding: 14px 6px 0px 6px;
    justify-content: space-between;
    height: 310px;
    overflow-y: scroll;

    @media(max-width: 1280px){
        overflow-y: hidden;
    }

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #062467;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #717171;
		}
	}

    hr {
        width: 90%;
        position: relative;
        top: -10px;
        left: -7px;
        border: 0;
        border-bottom: 1px solid silver;

        &:last-child {
            border: none;
        }
    }
`;

export const Row = styled.div`
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
    margin-top: -10px;
`;

export const InputLocais = styled.input`
    width: 145px;
    height: 28px;
    border: 1px solid silver;
    border-radius: 6px;
    outline: none;
    text-indent: 7px;
`;

export const InputGrupo = styled.input`
    width: 22px;
    height: 28px;
    border: 1px solid silver;
    border-radius: 6px;
    outline: none;
    text-indent: 7px;
`;