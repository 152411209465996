import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import PageTitle from '../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container, Close, ContentData, GroupInput, ButtonAdd, ButtonArea, AreaInput } from './styles';
import { GrClose } from "react-icons/gr";
import LineTable from "../../LineTable";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


export default function UpdateZone(props) {

    const [nameArea, setNameArea] = useState(props.value);
    const [dataControlers, setDataControlers] = useState([]);
    const [edit, setEdit] = useState(true);
    const [inputFields, setInputFields] = useState([
        { area: props.value, id_cruzamento: '' }
    ]);
    const [idsRoute, setIdsRoute] = useState(props.valueIds)


    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "area") {
            values[index].area = event.target.value;
        }
        if (event.target.name === "id_cruzamento") {
            values[index].id_cruzamento = event.target.value;
        }
        values[index].area = nameArea;

        setInputFields(values);
    };

    const handleSubmit = async (e) => {

        e.preventDefault();

        var res = [...idsRoute]
        var verificacao = []

        if(inputFields[0].area === nameArea && inputFields[0].id_cruzamento !== ""){
            
            if(inputFields[0].id_cruzamento !== "" || inputFields[0].id_cruzamento !== undefined ){

                    res.forEach((e) =>{
                        verificacao.push(e.id_cruzamento)
                    })

                  if(verificacao.indexOf(inputFields[0].id_cruzamento) === -1){
                 

                    await api.post('centraldefalhas/zones/register', inputFields).then(res => {
              
                        alert("Sucesso ")

                        props.onClose();
                    }).catch(error => {
                        console.log("Erro ",error)
                    })

                  }else{
                    alert("Item já esta cadastrado ")
                  }
            }
        }else{
            alert("Campos vazios")
        }

    };

    const salvarEdcaoDaAreaName = async () =>{

        if(props.value === nameArea){
            alert("Para modificar o nome da area, apenas altere o campo!")

        }else{

          await api.post('centraldefalhas/zones/updateNameZone',
                {
                    "areaAtual":  props.value ,
                    "areaNovo": nameArea
                }).then(res => {
                
                alert("Alterado com sucesso ")

                window.location.href = "/mapa";

            }).catch(error => {
                console.log("Erro ",error)
            })
        }
    };


    const deleteController = async (id) => {
  
            await api.post('centraldefalhas/zones/idRemove', {
                "area": props.value,
                "id_cruzamento": id
            }).then(res => {

                alert("Sucesso ao excluir")
                props.onClose();
                
            }).catch(error => {
                console.log("Erro ",error)
            })
    }

    const handleGetDataControler = async () => {
        await api.get('/centraldefalhas/controladores').then(res => {
            setDataControlers(res.data);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        handleGetDataControler();
        setNameArea(props.value);
        setInputFields([
            { area: props.value, id_cruzamento: '' }
        ]);
        setEdit(true);        
        setIdsRoute(props.valueIds)
   

    }, [props.open, props.value]);


    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Atualizar Área"
                onClick={props.onClose}
            ></PageTitle>
            <Container visible={edit}>
                <Close onClick={() => props.onClose()}><GrClose size={25}/></Close>
                <fieldset>
                    <legend>Nome da Área</legend>
                    <input type="text" name="area" id="area" placeholder="Digite o nome da área..." value={nameArea} onChange={e => setNameArea(e.target.value) }/>

                    
                    <ButtonArea onClick={salvarEdcaoDaAreaName}>Salvar Area</ButtonArea>
                </fieldset>
                <ContentData>
                    
                    <fieldset>
                        <legend>Id dos locais</legend>
                        {idsRoute.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    <AreaInput>
                                        <input type="text" value={data.id_cruzamento} />
                                        <LineTable idCruzamento={data.id_cruzamento} grupo={data.grupo} deleteController={deleteController}></LineTable>
                                    </AreaInput>
                                </div>
                            )
                        })}
                    </fieldset>

                    <fieldset id='controlador'>
                        <legend>Controlador</legend>
                        {inputFields.map((inputField, index) => {
                            return (
                                <GroupInput key={`${inputField}~${index}`}>
                                    <select name="id_cruzamento"
                                        value={inputField.id_cruzamento} onChange={(event) => (handleInputChange(index, event))}
                                    >
                                        <option value="">Selecione um Id...</option>
                                        {dataControlers.map((items) => {
                                            return (
                                                <option key={items.id_cruzamento} value={items.id_cruzamento}>
                                                    {items.id_cruzamento}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <ButtonAdd onClick={handleSubmit}>
                                            {"add"}
                                    </ButtonAdd>
                                </GroupInput>
                            )
                        })}
                    </fieldset>

                </ContentData>
            </Container>
        </Dialog>
    );
}

