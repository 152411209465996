import React, { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import RegisterRoutes from '../Registers/RegisterRoutes';
import InfoRoutes from '../InfoRoutes';
import LineTable from './Table';
import Alert from '@material-ui/lab/Alert';
import { Button } from './Table/styles';
import { Close } from './Table/styles';
import './styles.css';
import api from "../../services/api";
import Draggable from 'react-draggable';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../services/permission';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 430,
    maxWidth: 500,
  },
});

const IconBtn = () =>{
  return(
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 3.25H0.25V12C0.25 12.6875 0.8125 13.25 1.5 13.25H10.25V12H1.5V3.25ZM11.5 0.75H4C3.3125 0.75 2.75 1.3125 2.75 2V9.5C2.75 10.1875 3.3125 10.75 4 10.75H11.5C12.1875 10.75 12.75 10.1875 12.75 9.5V2C12.75 1.3125 12.1875 0.75 11.5 0.75ZM10.875 6.375H8.375V8.875H7.125V6.375H4.625V5.125H7.125V2.625H8.375V5.125H10.875V6.375Z" fill="white"/>
    </svg>
  );
}

const permissao = (validarPermissao([0,1,2]) ? 'enabled' : 'disabled');

function GetRoutes(props) {

  const classes = useStyles();
  const [nameRoutes, setNameRoutes] = useState([]);
  const [nameRoute, setNameRoute] = useState('');
  const [openInfoRoutes, setOpenInfoRoutes] = useState(false);
  const [openRegisterRoutes, setOpenRegisterRoutes] = useState(false);
  const {lengthRoutesFilter, setLengthRoutesFilter} = useContext(ModalContext);
  
  const getAllNameRoutes = async () => {
    await api.get('/centraldefalhas/routes/list').then(res => {
      setNameRoutes(res.data);
    }).catch(error => {
      console.log(error);
    })
  };

  const deleteRoute = async (nameRoute) => {
    if (window.confirm('Confirme para excluir rota')) {
      toast.info(`Aguarde...`, {
        position: "top-center",
        toastId: "loadingDeletedRoute",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      await api.post('/centraldefalhas/routes/remove', {
        "rota": nameRoute
      }).then(res => {
        if (res.data.status === "Route successfully deleted") {
          toast.update("loadingDeletedRoute", { 
            render: `Rota ${nameRoute} excluida !`,
            type: toast.TYPE.SUCCESS,
            position: "top-center",
            autoClose: 4000,
          })
          return getAllNameRoutes();
        }
      }).catch(error => {
        toast.update("loadingDeletedRoute", { 
          render: `Erro ao excluir rota ${nameRoute}`,
          type: toast.TYPE.ERROR,
          position: "top-center",
          autoClose: 4000,
        })
      })
    }
  }

  function getInfoRotes(nameRoute) {
    setNameRoute(nameRoute);
    setOpenInfoRoutes(true);
  };

  const closeInfoRoutes = () => {
    setOpenInfoRoutes(false);
  }

  const clickOpenRegisterRoutes = () => {
    setOpenRegisterRoutes(true);
    window.location.href = "#registro-de-rota"
  };

  const clickCloseRegisterRoutes = () => {
    getAllNameRoutes();
    setOpenRegisterRoutes(false);
  };

  useEffect(() => {
    if(props.open === false){
      clickCloseRegisterRoutes();
    }
  }, [props.open]);

  useEffect(() => {
    getAllNameRoutes();
  }, []);

  function alertaDeSemCadastro() {
    if(nameRoutes.length <= 0){
      return (
        <div className="alert">
          <Alert severity="warning" >{"Não existem rotas cadastradas"}</Alert>
        </div>
      )
    }
  }

  return (
    <>
      <Dialog 
        open={props.open} 
        className="Dialog" 
        onClose={props.onClose} 
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        hideBackdrop={true}  
      >
        <PageTitle 
          id="draggable-dialog-title"
          text="Rotas cadastradas"
          onClick={props.onClose}
        > </PageTitle>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Close onClick={() => props.onClose()}><GrClose size={25}/></Close>
            <Table stickyHeader aria-label="sticky table">
              <TableBody>
                {
                  alertaDeSemCadastro()
                }
                {
                  nameRoutes.map((data, idx) => {
                    return (
                      <LineTable className="lineTable" key={`000${idx}`} getInfo={getInfoRotes} deleteRoute={deleteRoute}>{data.rota}</LineTable>
                    )
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions>
          <Button title="Cadastrar nova rota" onClick={clickOpenRegisterRoutes} className={permissao}>
            <IconBtn />
            Cadastrar
          </Button> 
          </DialogActions>
        </Paper>
      </Dialog>
      <RegisterRoutes
        open={openRegisterRoutes}
        onClose={clickCloseRegisterRoutes}

      />
      <InfoRoutes
        open={openInfoRoutes}
        value={nameRoute}
        onClose={closeInfoRoutes}
      />
    </>
  )
}

export default GetRoutes;