import React, { useState, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import './styleMaterial.css';
import { Container, NavHorizontal, Links, MenuToggle, InfoHelp, ContentBallon} from './styles';
import logoCentral from '../../assets/IconPhor.ico';
import mapaLink from '../../assets/mapaLink.svg';
import configuracao from '../../assets/configuracao.svg';
import fechar from '../../assets/fechar.svg';
import sair from '../../assets/sair.svg';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { validarPermissao } from '../../services/permission';
import SettingUsers from '../SettingUsers';
import SettingsPage from '../Settings';
import StyleMaps from '../StyleMaps';
import RegisterLicenca from '../Registers/RegisterLicenca';
import BodyChangeAccount from './BodyChangeAccount'; 
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import imgEdit from '../../assets/img/edit.svg';
import imgKey from '../../assets/img/key.svg';
import iconLicenca from '../../assets/img/iconLicenca.svg';
import { AiOutlineBarChart } from "react-icons/ai";
import api from "../../services/api";
import { toast } from 'react-toastify';
import { GrCircleQuestion } from "react-icons/gr";
import { BalloonsUp } from '../Balloons';
import icoInfo from '../../assets/icons/icoInfo.png';
import som from '../../assets/icons/icoSom.png';
import { tocar } from '../Audio';

function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

export default function MenuHorizontal(props){

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorRelatorio, setAnchorRelatorio] = useState(null);
    const [anchorUsuario, setAncorUsuario] = useState(null);
    const [openSettingUsers, setOpenSettingUsers] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [openStyleMaps, setOpenStyleMaps] = useState(false);
    const [openLicenca, setoOpenLicenca] = useState(false);
    const [open, setOpen] = useState(false);
    const [onToggle, setOnToggle] = useState('');
    const [statusMenuResponsive, setStatusMenuResponsive] = useState('0');
    const [inactiveArea, setInactiveArea] = useState('enabled');// state para bloquear áreas não permitidas conforme usuário
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [icoSom, setIconSom] = useState(som);
    const{modalStatus, setModalStatus} = useContext(ModalContext);

    const msgPermissionDenied = () => toast.error(`USUÁRIO NÂO TEM PERMISSÂO !`, {
        position: "top-center",
        toastId:"openEditPermission",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    
    const handleClickOpen = () => {

        if(validarPermissao([0,1,2,3])){
            setOpen(true);
            setInactiveArea('disabled');

            setOnToggle('');
            setStatusMenuResponsive('0');
            props.setRetreatModals(true);
            setAnchorEl(null);
            setAncorUsuario(null);
            setAnchorRelatorio(null);

        }else{
            msgPermissionDenied()
        }
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const openMenuRelatorios = (event) => {
        setAnchorRelatorio(event.currentTarget);
    }

    const openRelatorio = () => {
        const url = "/relatorios";
        const win = window.open(url, '_blank');  
    }

    const openEstatistica = () => {
        const url = "/estatisticas";
        const win = window.open(url, '_blank');
        win.focus();
    }

    const openMenuMapas = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const openMenuUsuario = (event) => {
        setAncorUsuario(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAncorUsuario(null);
        setAnchorRelatorio(null);
    };
    
    function handleMudarMapa(mapa){
        props.mudarMapa(mapa);
        localStorage.setItem('modoMapa', mapa);

        setOnToggle('');
        setStatusMenuResponsive('0');
        props.setRetreatModals(true);
        setAnchorEl(null);
        setAncorUsuario(null);
        setAnchorRelatorio(null);
    };

    const handleClickOpenSign = () => {

        if(validarPermissao([0,1])){
            setOpenSettingUsers(true);
            window.location.href = "#sign-up";

            setOnToggle('');
            setStatusMenuResponsive('0');
            props.setRetreatModals(true);
            setAnchorEl(null);
            setAncorUsuario(null); 
            setAnchorRelatorio(null);
        }else{
            msgPermissionDenied();
        }
    }

    const handleCloseSettingUsers = () => {
        setOpenSettingUsers(false);
        window.location.href = "#"
    };

    const clickOpenSettings = () => {
        setOpenSettings(true);
        window.location.href = "#configurações-do-mapa";

        setOnToggle('');
        setStatusMenuResponsive('0');
        props.setRetreatModals(true);
        setAnchorEl(null);
        setAncorUsuario(null);
        setAnchorRelatorio(null);
    };
    const clickCloseSettings = () => {
        setOpenSettings(false);
        window.location.href = "#"
    };

    const clickOpenStyleMaps = () =>{
        setOpenStyleMaps(true);
    }

    const clickCloseStyleMaps = () =>{
        setOpenStyleMaps(false);
    }

    const logout = async () => {
        await api.get('/centraldefalhas/logout').then(res => {
            
        }).catch(error => {
            console.log(error);
        })
        
        window.location.href = "/";        
    }

    const openCardLicenca = () => {
        if(validarPermissao([0])){
            setoOpenLicenca(true);
            setOnToggle('');
            setStatusMenuResponsive('0');
            props.setRetreatModals(true);
            setAnchorEl(null);
            setAncorUsuario(null);
            setAnchorRelatorio(null);
        }else{
            //msgPermissionDenied(); 
            toast.error(`Usuário sem permissão!`, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        }
    }

    const clickCloseCardLicenca = () => {
        setoOpenLicenca(false);
    }

    const IconRelatorio = (props) => {
        return(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.33329 1.33301H3.99996C3.64634 1.33301 3.3072 1.47348 3.05715 1.72353C2.8071 1.97358 2.66663 2.31272 2.66663 2.66634V13.333C2.66663 13.6866 2.8071 14.0258 3.05715 14.2758C3.3072 14.5259 3.64634 14.6663 3.99996 14.6663H12C12.3536 14.6663 12.6927 14.5259 12.9428 14.2758C13.1928 14.0258 13.3333 13.6866 13.3333 13.333V5.33301L9.33329 1.33301Z" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.33337 1.33301V5.33301H13.3334" stroke={props.color} strokeWidth="2"strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6667 8.66699H5.33337" stroke={props.color}strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6667 11.333H5.33337" stroke={props.color}strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.66671 6H6.00004H5.33337" stroke={props.color}strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    const IconMapa = (props) =>{
        return(
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666687 4.00004V14.6667L5.33335 12L10.6667 14.6667L15.3334 12V1.33337L10.6667 4.00004L5.33335 1.33337L0.666687 4.00004Z" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.33331 1.33337V12" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10.6667 4V14.6667" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    const Polygon = (props) =>{
        return(
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.744 6.67159C5.34666 7.11401 4.65334 7.11401 4.256 6.67159L0.436019 2.41818C-0.142142 1.77442 0.314742 0.75 1.18002 0.75L8.81999 0.75C9.68526 0.75 10.1421 1.77442 9.56398 2.41818L5.744 6.67159Z" fill={props.color}/>
            </svg>
        );
    }

    const IconUser = (props) =>{
        return(
            <svg width="14" height="14" viewBox="0 0 14 14" fill={props.color} xmlns="http://www.w3.org/2000/svg">
                <path d="M12.3334 13V11.6667C12.3334 10.9594 12.0524 10.2811 11.5523 9.78105C11.0522 9.28095 10.3739 9 9.66669 9H4.33335C3.62611 9 2.94783 9.28095 2.44774 9.78105C1.94764 10.2811 1.66669 10.9594 1.66669 11.6667V13" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.99998 6.33333C8.47274 6.33333 9.66665 5.13943 9.66665 3.66667C9.66665 2.19391 8.47274 1 6.99998 1C5.52722 1 4.33331 2.19391 4.33331 3.66667C4.33331 5.13943 5.52722 6.33333 6.99998 6.33333Z" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        );
    }

    const openMenuHamburguer = () => {
        if(onToggle === ''){
            setOnToggle('on');
            setStatusMenuResponsive('100vh');
            props.setRetreatModals(false);
            setModalStatus('fecharEstatisticas');
        }else{
            setOnToggle('');
            setStatusMenuResponsive('0');
            props.setRetreatModals(true);
        }
    }

    const openPopUp = () => {
        setVisibleBalloon(true);
    }

    const closePopUp = () => {
        setVisibleBalloon(false);
    }

    const ContentPopBallonUp = () => {
        return(
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Configurações</h3>
                    <label onClick={tocar}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                    Nesta opção contém as configurações concernentes ao mapa,
                    tais como: “Zoom, Tempo de atualização e Centro de tela”.
                </span>
                <div className="areaButton">
                    <button onClick={() => closePopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }
    
    return(
        <Container>
            <NavHorizontal>
                <div id="sidebar" className="content-logo-central">
                    <img id="logoMenuBar" src={logoCentral} alt='Logo'></img>
                </div>
                <Links openMenu={statusMenuResponsive}>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorRelatorio}
                        keepMounted
                        open={Boolean(anchorRelatorio)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={openRelatorio}><img src={iconLicenca} className="icoMenu" alt="#" />Relatórios</MenuItem>
                        <MenuItem onClick={openEstatistica}><AiOutlineBarChart size={20}/>Contagem volumétrica</MenuItem>
                        {/*<MenuItem><AiOutlineBarChart size={20}/>Falhas periódica</MenuItem>*/} {/*Oculto até criar tela de falhas periódicas*/}
                    </Menu>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={clickOpenStyleMaps}><img src={mapaLink} className="icoMenu" alt="#" />Alterar tipo de mapa</MenuItem>
                        <MenuItem onClick={clickOpenSettings}><img src={configuracao} className="icoMenu" alt="#" />Configurações 
                            <InfoHelp onMouseOver={() => openPopUp()} ><GrCircleQuestion size={13}/></InfoHelp>
                        </MenuItem>
                        <BalloonsUp visible={visibleBalloon} setVisible={setVisibleBalloon}><ContentPopBallonUp/></BalloonsUp>
                    </Menu>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMenuRelatorios}>
                        <div className="area-btn">
                           <IconRelatorio color="#FFF"/>
                        </div>
                        Dados
                        <Polygon color="#FFF" className="polygon"/>
                    </Button>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMenuMapas}>
                        <div className="area-btn">
                           <IconMapa color="#FFF" className="polygon"/>
                        </div>
                        Mapas
                        <Polygon color="#FFF" className="polygon"/>
                    </Button>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={openMenuUsuario}>
                        <div className="area-btn">
                            <IconUser  color="#FFF"/>
                        </div>
                        {localStorage.getItem('@central-de-falhas-greenwave/name')}
                       <Polygon color="#FFF"/>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorUsuario}
                        keepMounted
                        open={Boolean(anchorUsuario)}
                        onClose={handleClose}
                        >
                        <MenuItem onClick={openCardLicenca}><img src={iconLicenca} className="icoMenu" alt="#" />Licença</MenuItem>
                        <MenuItem onClick={handleClickOpenSign}><img src={configuracao} className="icoMenu" alt="#" />Configurações</MenuItem>
                        <MenuItem onClick={handleClickOpen}><img src={imgKey} className="icoMenu" alt="#" />Trocar senha</MenuItem>
                        <MenuItem onClick={logout}><img src={sair} className="icoMenu" alt="#" />Sair</MenuItem>
                    </Menu>
                </Links>
                <MenuToggle onClick={openMenuHamburguer} className={onToggle}>
                    <div className="one"></div>
                    <div className="two"></div>
                    <div className="three"></div>
                </MenuToggle>
            </NavHorizontal>
            <RegisterLicenca 
                open={openLicenca}
                onClose={clickCloseCardLicenca}
            />
            <SettingUsers
                open={openSettingUsers}
                onClose={handleCloseSettingUsers}
            >
            </SettingUsers>
            <SettingsPage
                open={openSettings}
                onClose={clickCloseSettings}
                clickMapa={() => clickCloseSettings()}
            ></SettingsPage>
            <StyleMaps
                open={openStyleMaps}
                onClose={clickCloseStyleMaps}
                handleMudarMapa={handleMudarMapa}
            >
            </StyleMaps>
            <Dialog open={open} 
                BackdropProps={{ invisible: true }} 
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
                id="modalSenha"
            >
                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title" className="titleChangeAccount">
                    <img src={imgEdit} alt="edit" className="edit"/> 
                    TROCAR SENHA
                    <img src={fechar} alt="x" className="fechar" onClick={handleCloseModal}/>
                </DialogTitle>
                <DialogContent>
                    <BodyChangeAccount onClose={handleCloseModal}/>   
                </DialogContent>
            </Dialog>
        </Container>
    );
}
