import styled from 'styled-components';

export const Container = styled.div`
    width: 92%;
    margin: 0 auto;

    @media(max-width: 580px){
        width: 97%;
        margin: 0 auto;
    }

    .alert{
        width: 92%;
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        display: ${props => props.visible};
    }
    
    fieldset{
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        margin-bottom: 4px;
        position: relative;

        @media(max-width: 1280px){
            margin-bottom: 9px;
        }

        @media(max-width: 420px){
            padding: 0 5% 3% 3%;
        }
    }
    legend{
        font-size: 13px;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        color: #848484;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 12px;
        }
    }

    label{
        font-size: 13px;
        color: #6b6a6a;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 11px;
        }
    }
`;

export const AreaSwitch = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;

    label{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #040404;
    }
`;

export const Switch = styled.label`
	position: relative;
	top: 2px;
	display: inline-block;
	vertical-align: top;
	width: 56px;
	height: 20px;
	padding: 3px;
	background-color: white;
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
	background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
	background-image: -o-linear-gradient(top, #eeeeee, white 25px);
	background-image: linear-gradient(to bottom, #eeeeee, white 25px);

	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		-webkit-transition: 0.15s ease-out;
		-moz-transition: 0.15s ease-out;
		-o-transition: 0.15s ease-out;
		transition: 0.15s ease-out;
	}
	.switch-label:before,
	.switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaa;
		text-shadow: 0 1px rgba(255, 255, 255, 0.5);
        font-weight: normal;
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: white;
		text-shadow: 0 1px rgba(0, 0, 0, 0.2);
		opacity: 0;
        font-weight: normal;
	}
	.switch-input:checked ~ .switch-label {
		background: #47a8d8;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 18px;
		height: 18px;
		background: white;
		border-radius: 10px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
		background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
		background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
		-webkit-transition: left 0.15s ease-out;
		-moz-transition: left 0.15s ease-out;
		-o-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
	}
	.switch-handle:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: #f9f9f9;
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
		background-image: -webkit-linear-gradient(top, #eeeeee, white);
		background-image: -moz-linear-gradient(top, #eeeeee, white);
		background-image: -o-linear-gradient(top, #eeeeee, white);
		background-image: linear-gradient(to bottom, #eeeeee, white);
	}
	.switch-input:checked ~ .switch-handle {
		left: 40px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-green > .switch-input:checked ~ .switch-label {
		background: #4fb845;
	}
`;


export const AreaInputs = styled.div`
      
    input{
        width: 100%;
        height: 29px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
        @media(max-width: 1500px){
            margin-bottom: 2px;
            height: 26px;
        }
        @media(max-width: 1280px){
            margin-bottom: 2px;
            height: 22px;
            font-size: 13px;
        }
    }
    legend{
        padding-bottom: 3px;
    }

    .groupone {
        display: flex;
        gap: 13px;

        @media(max-width: 420px){
            width: 100%;
            gap: 10px;
        }
    }
`;
export const Group = styled.div`
    width: 100%;
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 15px;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaSignal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
   
    .sinal{
        width: 30px;
        height: 30px;
        position: relative;

        img{
            width: 100%;
        }

        span{
            color: #16D859;
            font-size: 12px;
            position: absolute;
            top: 15px;
            font-family: 'Roboto';
            left: 22px;

            @media(max-width: 420px){
                left: 9px;
                top: 11px;
            }
        }
    }

    .potencia{
        width: 54px;
        height: auto;
        margin-left: 26px;
        img{
            width: 100%;
        } 
        @media(max-width: 390px){
            width: 40px;
            margin-left: 0;
        }
    }

    .status{
        font-size: 13px;
        width: 140px;
        margin-left: 13px;
        display: block;
        background: #75727224;
        text-align: center;
        height: 18px;
        font-weight: 600;
        font-family: 'Roboto';
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 580px){
            width: 80px;
            font-size: 9px;
        }
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;

    @media(max-width: 1280px) and (max-height: 496px){
        margin-bottom: 50px;
    }


    .btnEditInfo{
        cursor: pointer;

        img{
            width: 35px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
    }
   
    button{
        border: 0;
        width: 115px;
        height: 25px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }
`;