import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalContext } from '../../../../contextModal';
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BoxMenu, Title, AreaInput, Search, CloseFilter, BoxFilter, AreaButtons, Button } from '../styles';

export default function FiltroAcao(props) {

    const [pesquisa, setPesquisa] = useState('');  //guarda resultado da pesquisa
    const [resultado, setResultado] = useState([]); //guarda resultado do filtro 
    const [selecao, setSelecao] = useState([]); // estados para guardar a seleção de items do filtro
    const [enabled, setEnabled] = useState(true);
    const { setStatusFilterAcao } = useContext(ModalContext);

    const myRef = useRef();

    const getIdentificacao = (e, index) => {

        if (e.target.checked) {
            const values = [...selecao];
            values[index] = e.target.value;
            setSelecao(values);
        } else {
            const values = [...selecao];
            values[index] = '';
            setSelecao(values);
        }
    }

    const filtrarAcao = () => {
        const filtered = selecao.filter(Boolean);
        props.setParamAcao(filtered);
        props.toogle(false);

        if (filtered.length > 0) {
            setStatusFilterAcao('Dados Filtrados');
        } else {
            setStatusFilterAcao('Filtro Limpo');
        }
    }

    useEffect(() => {
        if (props.acao == undefined) {
            return
        } else {
            const results = props.acao.filter(palavra => palavra.toLowerCase().includes(pesquisa));
            if (results == '') {
                setResultado(['Nenhuma correspondência']);
                setEnabled(false);
            } else {
                setResultado(results);
                setEnabled(true);
            }
        }
    }, [pesquisa]);

    useEffect(() => {
        if (props.acao == undefined) {
            return
        } else {
            const results = props.acao.filter(palavra => palavra.toLowerCase().includes(pesquisa));
            if (results == '') {
                setResultado(['Nenhuma correspondência']);
                setEnabled(false);
            } else {
                setResultado(results);
                setEnabled(true);
            }
        }
    }, [props.acao]);

    return (
        <BoxMenu className='agente' ref={myRef} display={props.open}>
            <Title>
                <h2>Filtrar agente
                    <CloseFilter onClick={() => props.onClose(false)}>
                        <MdClose size="18" />
                    </CloseFilter>
                </h2>
                <span>Selecionar item:</span>
                <AreaInput>
                    <input type="text" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
                    <Search>
                        <FaSearch />
                    </Search>
                </AreaInput>
            </Title>
            <BoxFilter>
                <ul>
                    {resultado === '' ?
                        props.acao.map((items, index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)} /><span>{items}</span></li>
                        )) :
                        resultado.map((items, index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)} /><span>{items}</span></li>
                        ))
                    }
                </ul>
            </BoxFilter>
            <AreaButtons status={enabled}>
                <Button onClick={filtrarAcao}>Ok</Button>
                <Button onClick={() => { props.onClose(false); props.setParamAcao([]) }}>Cancelar</Button>
            </AreaButtons>
        </BoxMenu>
    );
}