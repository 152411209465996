import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Close } from './Table/styles';
import RegisterArea from '../Registers/RegisterArea';
import InfoAreas from './InfoAreas';
import LineTable from './Table';
import api from "../../services/api";
import Draggable from 'react-draggable';
import Alert from '@material-ui/lab/Alert';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../services/permission';
import './styles.css'


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const IconBtn = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 3.25H0.25V12C0.25 12.6875 0.8125 13.25 1.5 13.25H10.25V12H1.5V3.25ZM11.5 0.75H4C3.3125 0.75 2.75 1.3125 2.75 2V9.5C2.75 10.1875 3.3125 10.75 4 10.75H11.5C12.1875 10.75 12.75 10.1875 12.75 9.5V2C12.75 1.3125 12.1875 0.75 11.5 0.75ZM10.875 6.375H8.375V8.875H7.125V6.375H4.625V5.125H7.125V2.625H8.375V5.125H10.875V6.375Z" fill="white" />
        </svg>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
        maxWidth: 800,
    },
});

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

function GetRoutes(props) {

    const classes = useStyles();
    const [nameZones, setNameZones] = useState([]);
    const [nameZone, setNameZone] = useState('');
    const [openInfoZones, setOpenInfoZones] = useState(false);
    const [openRegisterArea, setOpenRegisterArea] = useState(false);
    const [equipmentsRegistred, setEquipmentsRegistred] = useState([]);

    const getAllNameZones = async () => {
        await api.get('/centraldefalhas/zones/list').then(res => {
            setNameZones(res.data);

        }).catch(error => {
            console.log(error);
        })
    };

    const deleteZone = async (nameZone) => {
        if (window.confirm('Confirme para excluir área')) {
            await api.post('/centraldefalhas/zones/remove', {
                "area": nameZone
            }).then(res => {
                if (res.data.status === "Zone successfully deleted") {
                    toast.success(`Àrea ${nameZone} excluida!`, {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getAllNameZones()
                }
            }).catch(error => {
                toast.error(`Erro ao excluir área !`, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }
    }

    const getInfoRotes = (nameZone) => {
        setNameZone(nameZone);
        setOpenInfoZones(true);
    };

    const duplicateRotes = (nameZone) => {
        setNameZone(nameZone);
    }

    const clickOpenRegisterArea = () => {
        setOpenRegisterArea(true);
        window.location.href = "#registro-de-área";
    }

    const closeInfoRoutes = () => {
        setOpenInfoZones(false);
    }

    const clickCloseRegisterArea = () => {
        setOpenRegisterArea(false);
        window.location.href = "#"
    };


    function alertaDeSemCadastro() {
        if(nameZones.length <= 0){
          return (
            <div className="alert">
              <Alert severity="warning" >{"Não existem areas cadastradas"}</Alert>
            </div>
          )
        }
      }

    useEffect(() => {
        setOpenRegisterArea(true);
    }, [equipmentsRegistred]);

    useEffect(() => {
        if (props.open === false) {
            return clickCloseRegisterArea();
        }
    }, [props.open]);

    useEffect(() => {
        getAllNameZones();
    }, [props.open, openRegisterArea]);

    return (
        <>
            <Dialog
                open={props.open}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Áreas / Subáreas cadastradas"
                    onClick={props.onClose}
                > </PageTitle>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <Close onClick={() => props.onClose()}><GrClose size={25}/></Close>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {
                                    alertaDeSemCadastro()
                                }
                                {
                                    nameZones.map((data, idx) => {
                                        return (
                                            <LineTable className="lineTable" key={`000${idx}`}
                                                getInfo={getInfoRotes}
                                                deleteZone={deleteZone}
                                                duplicateRoute={duplicateRotes}
                                            >
                                                {data.area}
                                            </LineTable>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <DialogActions>
                        <Button title="Cadastrar nova área" onClick={clickOpenRegisterArea} className={permissao}>
                            <IconBtn />
                            Cadastrar
                        </Button>
                    </DialogActions>
                </Paper>
            </Dialog>
            <RegisterArea
                open={openRegisterArea}
                onClose={clickCloseRegisterArea}
                className={permissao}
            >
                Cadastrar nova área
            </RegisterArea>
            <InfoAreas
                open={openInfoZones}
                value={nameZone}
                onClose={closeInfoRoutes}
            />
        </>
    )
}

export default GetRoutes;