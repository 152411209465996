import React from 'react';
import image404 from '../../assets/404.png';
import { Container, Banner } from './styles';
import { useHistory } from "react-router-dom";


export default function NotFound(){
    
    const history = useHistory();

    const voltar = () => {
        history.push('/');
    }

    return(
        <Container>
            <Banner>
                <img src={image404} alt="" />
            </Banner>
            <span>Ops...! A página não foi encontrada, verifique sua conexão com a internet ou corrija o endereço da página.</span>
            <button onClick={voltar}>Voltar</button>
        </Container>
    );
}