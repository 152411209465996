import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import walkRight from '../../../img/walkRight.gif';
import walkPauseRight from '../../../img/WalkPauseRight.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveWalkRight, Options, ButtonSettings } from './styles';

export default function WalkRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'pedestre', title: 'Pedestre à direita', image: walkPauseRight, object: props.walkRight, setObject: props.setWalkRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.walkRight.map((item, index) => {
                if (props.walkRight[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='walkRight'
                            top={item.top}
                            left={item.left}
                            setWalkRight={props.setWalkRight}
                            walkRight={props.walkRight}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveWalkRight
                                src={
                                    item.color_group === undefined || item.color_group === '' ? walkPauseRight :
                                        item.color_group === 4 ? walkRight : walkPauseRight
                                }
                                alt=""
                                move={
                                    item.color_group === undefined || item.color_group === '' ? 'pause' :
                                        item.color_group === 1 ? 'pause' :
                                            item.color_group === 2 ? 'pause' :
                                                item.color_group === 17 ? 'pause' :
                                                    item.color_group === 4 ? true : props.moveWalkRight
                                }
                                tamanho={item.tamanho}
                                velocidade={item.velocidade}
                                percurso={item.percurso}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeWalkRight(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
