import React, { useState, useContext, useEffect } from 'react';
import { ModalContext } from '../../contextModal';
import iconBars from '../../assets/img/iconBars.png';
import ChartOperacional from './Charts/ChartOperacional';
import ChartStatus from './Charts/ChartStatus';
import { GrClose } from "react-icons/gr";
import { Container, Ticket, Content, Abas, Operacional, Status, ContentOperacional, ContentStatus, AreaClose, Close } from './styles';


export default function DashBoard(props){

    const[abaOperacional, setAbaOperacional] = useState('block');
    const[abaStatus, setAbaStatus] = useState('none');
    const[OperacionalActive, setOperacionalActive] = useState('active');
    const[StatusActive, setStatusActive] = useState('deactive');
    const[slide, setSlide] = useState('-603px');
    const[open, setOpen] = useState(false);
    const{modalStatus, setModalStatus} = useContext(ModalContext);
    const[zIndex, setZindex] = useState('998');
    const[dadosEstatisticas, setDadosEstatisticas] = useState([]);
    const[locaisMapa, setLocaisMapa] = useState([]);
       

    useEffect(() => {
        setDadosEstatisticas(props.dadosEstatisticas);
        setLocaisMapa( props.locaisMapa);
    }, [props.dadosEstatisticas, props.locaisMapa]);

    const viewOperacional = () => {
        setAbaOperacional('block');
        setAbaStatus('none');
        setOperacionalActive('active');
        setStatusActive('deactive');
    }

    const viewStatus = () => {
        setAbaOperacional('none');
        setAbaStatus('block');
        setOperacionalActive('deactive');
        setStatusActive('active');
    }

    const MoveSlide = () => {
        if(slide === '-505px'){
          setSlide('-6px');
          setOpen(true);
          setModalStatus('dashboard')
          setZindex('1002');
        }
        
        else if(slide === '-6px'){
          setSlide('-603px');
          setOpen(false);
          setZindex('998');
        }
    }
      
    const Over = () => {        
        if(open == false){
        setSlide('-505px');
        }
    }
    
    const Out = () => {
        if(open == false){
        setSlide('-603px');
        setZindex('998');
        }
    }

    useEffect(()=> {
        if(modalStatus !== 'dashboard'){
          setSlide('-603px');
          setOpen(false);
          setZindex('998');
        }
    },[modalStatus]);

    const closeStatisticas = () => {
        setSlide('-603px');
        setOpen(false);
        setZindex('998');
    }

    return(
        <Container slider={slide} position={zIndex}>
            <Ticket onClick={MoveSlide} onMouseOver={Over} onMouseOut={Out}>
                <img src={iconBars} alt="" />
                Estatísticas
            </Ticket>
            <Content>
                <Abas>
                    <Operacional onClick={viewOperacional} className={OperacionalActive}>
                        <span>Modo Operacional</span>
                    </Operacional>
                    <Status onClick={viewStatus} className={StatusActive}>
                        <span>Estado</span>
                    </Status>
                </Abas>
                <AreaClose>
                    <Close onClick={closeStatisticas}><GrClose size={25}/></Close>
                </AreaClose>
                <ContentOperacional visible={abaOperacional}>
                    <ChartOperacional dadosEstatisticas={dadosEstatisticas}/>
                </ContentOperacional>
                <ContentStatus visible={abaStatus}>
                    <ChartStatus dadosEstatisticas={dadosEstatisticas} locaisMapa={locaisMapa}/>
                </ContentStatus>
            </Content>
        </Container>
    );
}