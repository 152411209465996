import React from 'react';

export default function IconPendente(){
    return(
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 13.75C10.9518 13.75 13.75 10.9518 13.75 7.5C13.75 4.04822 10.9518 1.25 7.5 1.25C4.04822 1.25 1.25 4.04822 1.25 7.5C1.25 10.9518 4.04822 13.75 7.5 13.75Z" stroke="#C9CC1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 5V7.5" stroke="#C9CC1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.5 10H7.50625" stroke="#C9CC1F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}