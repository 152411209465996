import React from 'react';
import { Container, Title, AreaLoading } from './styles';
import loader from '../../../assets/img/loader.gif';
import { Chart, Geom, Axis, Tooltip, Legend } from 'bizcharts';

export default function ChartLine(props) {

	//propriedades dos eixos do grafico
	const scale = {

		timestamp: {
			tickCount: 6,                 //quantidade de itens
			mask: 'DD-MM-YYYY HH:mm:ss',  //mascara dos dados
		},

		contagem: {
			type: 'linear-strict',               //tipo do dado
			min: 0,
			range: [                      //intervalo entre os dados
				0,
				1
			],
			tickCount: 6,                 //quantidade de itens
		}

	};

	const detectoresOrdemCrescente = props.dadosGraficos.length > 0 ? props.dadosGraficos.sort((a, b) => parseInt(a.id_detector) - parseInt(b.id_detector)) : [];

	return (
		<Container>
			<AreaLoading src={loader} loading={props.cbLoading} />
			<Title>Contagem de acionamentos x Período de tempo</Title>
			{/* grafico */}
			<Chart data={detectoresOrdemCrescente} scale={scale} autoFit className="chart">
				{/* legenda */}
				<Legend />
				{/* eixo x */}
				<Axis name="timestamp" />
				{/* eixo y */}
				<Axis
					name="contagem"
					label={{
						formatter: val => `${val}`,
					}}
				/>
				{/* eventos */}
				<Tooltip
					showCrosshairs
					shared
				/>
				{/* estilizacao da linha*/}
				<Geom type="line-advance" tooltip={['contagem*id_detector', (value, name) => {
					// popup de legenda
					return {
						value: `${value} acionamentos`,
						name: `Detector ${name}`
					}
				}]} position="timestamp*contagem" size={2} color={'id_detector'} />

			</Chart>
		</Container>
	);
}