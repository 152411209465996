import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ItemDraggable from '../../../ItemDraggable';
import close from '../../../img/close.png';
import icoPlug from '../../../img/plug.png';
import settings from '../../../img/tool.png';
import click from '../../../img/click.mp3';
import { Container, Img, Options, ButtonSettings } from './styles';
import axios from 'axios';

export default function Plug(props) {

    const param = useParams();

    const [total, setTotal] = useState(3);

    setTimeout(() => {
        if (total === 3) {
            setTotal(1);
            return;
        }
        if (total === 1) {
            setTotal(2);
            return;
        }
        if (total === 2) {
            setTotal(3);
            return;
        }
    }, 3000);

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'box', title: 'Plug', image: icoPlug, object: props.objPlug, setObject: props.setObjPlug });
        props.setOpenSettings(true);
    }

    const acionarPlug = async () => {
        const som = new Audio(click);
        som.play();
        //http://192.168.1.157/web/swrst.dgv?WAC=12345
        axios.get(`http://${param.ipmbb}/web/swrst.dgv?WAC=12345`, {
            headers: {
                'Content-Type': 'text/html',
                "Accept": 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
            },
        })
            .then(res => {
                console.log(res.data);
            }).catch(err => {
                return;
            })
    }

    return (
        <Container>
            {props.objPlug.map((item, index) => {
                if (props.objPlug[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='objPlug'
                            top={item.top}
                            left={item.left}
                            setObjPlug={props.setObjPlug}
                            objPlug={props.objPlug}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img src={icoPlug} alt="" onDoubleClick={acionarPlug} tamanho={item.tamanho} angulo={item.angulo} />
                            <Options remove={props.remove} onClick={() => props.removePlug(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
