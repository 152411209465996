import React, { useEffect, useState } from 'react';
import { Container, Title, Fieldset, Titulo, Select, Option, AreaLoading } from './styles';
import loader from '../../../assets/img/loader.gif';
import LiquidFillGauge from 'react-liquid-gauge';
import { color } from 'd3-color';

const SelectDetector = (props) => {
    return (
        <Fieldset>
            <Titulo>Detector</Titulo>
            <Select onChange={(e) => { props.cbSetId(e.target.value); props.cbSetVisibleChart(true) }}>
                <Option>Selecione um detector</Option>
                {props.requisicao.map(items => (
                    <Option key={items._id}>{items._id}</Option>
                ))}
            </Select>
        </Fieldset>
    );
}

export default function ChartFill(props) {

    const [id, setId] = useState('');
    const [dados, setDados] = useState({
        value: 0,
        startColor: '#6495ed',
        endColor: '#dc143c'
    });

    useEffect(() => {
        props.dadosMedia.map(item => {
            if (item._id === id) {
                setDados({ value: item.mediaOcupacao });
            }
        })
    }, [id, props.dadosMedia]);

    const mediaQuery = window.innerWidth;
    const radius = mediaQuery > 420 ? 100 : 70; //tamanho do gráfico
    const fillColor = '#178BCA';// cor do gráfico
    const gradientStops = [
        {
            key: '0%',
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: '0%'
        },
        {
            key: '50%',
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: '50%'
        },
        {
            key: '100%',
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: '100%'
        }
    ];

    return (
        <Container>
            <AreaLoading src={loader} loading={props.cbLoading} />
            <Title>Média de ocupação por minuto</Title>
            <SelectDetector requisicao={props.dadosMedia} cbSetId={setId} cbSetVisibleChart={props.cbSetVisibleChart} />
            <LiquidFillGauge
                style={{ margin: '16% auto', display: props.cbVisibleChart === false ? 'none' : 'block' }}
                width={radius * 2}
                height={radius * 2}
                value={dados.value}
                percent="%"
                textSize={1}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={(props) => {
                    const value = Math.round(props.value);
                    const radius = Math.min(props.height / 2, props.width / 2);
                    const textPixels = (props.textSize * radius / 2);
                    const valueStyle = {
                        fontSize: textPixels
                    };
                    const percentStyle = {
                        fontSize: textPixels * 0.6
                    };
                    return (
                        <tspan>
                            <tspan className="value" style={valueStyle}>{value}</tspan>
                            <tspan style={percentStyle}>{props.percent}</tspan>
                        </tspan>
                    );
                }}
                riseAnimation
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                    fill: fillColor
                }}
                waveStyle={{
                    fill: fillColor
                }}
                textStyle={{
                    fill: color('#178BCA').toString(), // cor do valor no gráfico
                    fontFamily: 'Arial'
                }}
                waveTextStyle={{
                    fill: color('#A4DBF8').toString(), // cor do valor dentro das ondas
                    fontFamily: 'Arial'
                }}
            />
        </Container>
    );
}

