import React from 'react';
import { Container, Title, AreaCards } from './styles';
import PageTitle from '../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import padrao from '../../assets/icons/mapaPadrao.png';
import mapaDiurno from '../../assets/icons/mapaDiurno.png';
import mapaClaro from '../../assets/icons/mapaClaro.png';
import mapaNoturno from '../../assets/icons/mapaNoturno.png';
import mapaDestacado from '../../assets/icons/mapaDestacado.png';
import mapaSatelite from '../../assets/icons/mapaSatelite.png';
import mapaEscuro from '../../assets/icons/mapaEscuro.png';
import mapaTrafego from '../../assets/icons/mapaTrafego.png';

import { GrClose } from "react-icons/gr";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function StyleMaps(props) {

    const selected = localStorage.getItem('modoMapa');

    return (
        <>
            <Dialog
                open={props.open}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Estilo do mapa"
                    onClick={props.onClose}
                ></PageTitle>
                <Container>
                    <div className="close" onClick={props.onClose}><GrClose size={19} /></div>
                    <Title>Escolha o tipo do mapa</Title>
                    <hr />
                    <AreaCards>

                        <div className='cards' onClick={() => props.handleMudarMapa('padrao')}>
                            <label htmlFor="padrao">
                                <img src={padrao} alt="padrao" />Padrão
                            </label>
                            <input type="radio" name='mapas' id="padrao" checked={selected === 'padrao' ? true : false} />
                        </div>


                        <div className='cards' onClick={() => props.handleMudarMapa('destacado')}>
                            <label htmlFor="destacado">
                                <img src={mapaDestacado} alt="destacado" />Destacado
                            </label>
                            <input type="radio" name='mapas' id="destacado" checked={selected === 'destacado' ? true : false} />
                        </div>

                        <div className='cards' onClick={() => props.handleMudarMapa('diurno')}>
                            <label htmlFor="diurno">
                                <img src={mapaDiurno} alt="diurno" />Diurno
                            </label>
                            <input type="radio" name='mapas' id="diurno" checked={selected === 'diurno' ? true : false} />
						</div>

                        <div className='cards' onClick={() => props.handleMudarMapa('claro')}>
                            <label htmlFor="claro">
                                <img src={mapaClaro} alt="claro" />Claro
                            </label>
                            <input type="radio" name='mapas' id="claro" checked={selected === 'claro' ? true : false} />
					</div>

                        <div className='cards' onClick={() => props.handleMudarMapa('dark')}>
                            <label htmlFor="noturno">
                                <img src={mapaNoturno} alt="noturno" />Noturno
                            </label>
                            <input type="radio" name='mapas' id="noturno" checked={selected === 'dark' ? true : false} />
                        </div>

                        <div className='cards' onClick={() => props.handleMudarMapa('dark2')}>
                            <label htmlFor="escuro">
                                <img src={mapaEscuro} alt="escuro" />Escuro
                            </label>
                            <input type="radio" name='mapas' id="escuro" checked={selected === 'dark2' ? true : false} />
                        </div>

                        <div className='cards' onClick={() => props.handleMudarMapa('trafego')}>
                            <label htmlFor="trafego">
                                <img src={mapaTrafego} alt="trafego" />Trafego
                            </label>
                            <input type="radio" name='mapas' id="trafego" checked={selected === 'trafego' ? true : false} />
                        </div>

                        <div className='cards' onClick={() => props.handleMudarMapa('satelite')}>
                            <label htmlFor="satelite">
                                <img src={mapaSatelite} alt="satelite" />Satélite
                            </label>
                            <input type="radio" name='mapas' id="satelite" checked={selected === 'satelite' ? true : false} />
						</div>


						{/*<span style={{ fontSize: '10px', color: '#3a2e2e', fontWeight: '600', marginLeft: '15px'}}>* Mapas em manuntenção!</span>*/}
                    </AreaCards>
                </Container>
            </Dialog>
        </>
    );
}