import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import PageTitle from '../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container, Close, ContentData, GroupInput, AreaButtons, Button, ButtonRemove, ButtonSave, ButtonArea } from './styles';
import { GrClose } from "react-icons/gr";
import IconAddInputs from './icons/IconAddInputs';
import IconRemove from './icons/IconRemove';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function RegisterRoutes(props) {

    const [nameArea, setNameArea] = useState(props.value);
    const [dataControlers, setDataControlers] = useState([]);
    const [edit, setEdit] = useState(true);
    const [enabledButton, setEnabledButton] = useState(false);
    const [inputFields, setInputFields] = useState([
        { area: props.value, id_cruzamento: '' }
    ]);

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ area: nameArea, id_cruzamento: '' });
        setInputFields(values);
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1)
        setInputFields(values);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "area") {
            values[index].area = event.target.value;
        }
        if (event.target.name === "id_cruzamento") {
            values[index].id_cruzamento = event.target.value;
        }
        values[index].area = nameArea;

        setInputFields(values);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!nameArea) {
            return toast.warn(`Nome da área está vazio !`, {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            toast.info(`Aguarde...`, {
                position: "top-center",
                toastId: "registerArea",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            await api.post('centraldefalhas/zones/register', inputFields).then(res => {
                toast.update("registerArea", {
                    render: `Área salva com sucesso !`,
                    type: toast.TYPE.SUCCESS,
                    position: "top-center",
                    autoClose: 4000,
                })
                props.onClose();
            }).catch(error => {
                toast.update("registerRouter", {
                    render: `Erro no salvar area!`,
                    type: toast.TYPE.ERROR,
                    position: "top-center",
                    autoClose: 4000,
                    onClose: () => props.onClose(),
                })
            })
        }
    };

    const handleGetDataControler = async () => {
        await api.get('/centraldefalhas/controladores').then(res => {
            setDataControlers(res.data);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        handleGetDataControler();
        setNameArea(props.value);
        setInputFields([
            { area: props.value, id_cruzamento: '' }
        ]);
        setEdit(true);
        setEnabledButton(false);

    }, [props.open, props.value]);


    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Cadastro de Área"
                onClick={props.onClose}
            ></PageTitle>
            <Container visible={edit}>
                <Close onClick={() => props.onClose()}><GrClose size={25}/></Close>
                <fieldset>
                    <legend>Nome da Área</legend>
                    <input type="text" name="area" id="area" placeholder="Digite o nome da área..." value={nameArea} onChange={e => {setNameArea(e.target.value); setEnabledButton(true)} }/>
                    <ButtonArea enabled={enabledButton} onClick={() => setEdit(false)}>Salvar Area</ButtonArea>
                </fieldset>
                <ContentData>
                    <fieldset id='controlador'>
                        <legend>Controlador</legend>
                        {inputFields.map((inputField, index) => {
                            return (
                                <GroupInput key={`${inputField}~${index}`}>
                                    <select name="id_cruzamento"
                                        value={inputField.id_cruzamento} onChange={event => handleInputChange(index, event)}
                                    >
                                        <option value="">Selecione um Id...</option>
                                        {dataControlers.map((items) => {
                                            return (
                                                <option key={items.id_cruzamento} value={items.id_cruzamento}>
                                                    {items.id_cruzamento}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <ButtonRemove onClick={() => handleRemoveFields(index)}>
                                        <IconRemove />
                                    </ButtonRemove>
                                </GroupInput>
                            )
                        })}
                    </fieldset>
                </ContentData>
                <AreaButtons>
                    <Button onClick={() => handleAddFields()}>
                        <IconAddInputs />
                    </Button>
                    <ButtonSave onClick={handleSubmit}>Salvar</ButtonSave>
                </AreaButtons>
            </Container>
        </Dialog>
    );
}

