import styled from 'styled-components';

export const Container = styled.nav`
    animation-play-state: paused;
    position: absolute;
    right: 0;
    z-index: ${props => props.position};
    display: flex;
    right: ${props => props.slider};
    transition: all .5s ease-out;
    top: 268px;
    margin-right: 5px;
    display: none;// oculto até inserir as informações de Eventos
       
    @media(max-width: 1280px){
        top: 258px;
    }

    @media(max-width: 420px){
        overflow: scroll;
        max-width: 128vw;
        right: ${props => props.slider === '-921px' ? '-118%' : ''};
        //height: 111vw;
    }

    @media(max-width: 360px){
        overflow: scroll;
        max-width: 128vw;
        right: ${props => props.slider === '-921px' ? '-118%' : ''};
        height: 111vw;
    }

`;

export const Voltar = styled.div`
     display: none;
`;

export const Ticket = styled.div`
    width: 105px;
    height: 59px;
    background-color: #001E62;
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    z-index: 999;

    .disabled{
        background: #5d5d5dd4;
    }
        
    svg{
        width: 20px;
        height: 20px;
        margin-right: 7px;
        margin-left: 11px;
        margin-top: -2px;
        user-select: none;
        pointer-events: none;
    }

    @media(max-width: 1280px){
        height: 50px;
    }
`;

export const BodyTable = styled.div`
    background: #FFF;
    padding: 0 0 20px 20px;
    height: 517px;
    overflow-y: scroll;
    box-shadow: -6px 6px 3px -1px #79797945;
        
    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

    @media(max-width: 420px){
        width: 715px;
        height: 59vh;
    }
`;

export const Table = styled.table`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    background: #FFF;
    display: flex;
    flex-direction: column;

    thead{
        th{
            &:nth-of-type(1){
                width: 5px;
            }
            &:nth-of-type(2){
                width: 145px;
            }
            &:nth-of-type(3){
                //width: 160px;
                width: 100px;
            }
            &:nth-of-type(4){
                //width: 190px;
                width: 130px;
            }
            &:nth-of-type(5){
                //width: 190px;
                width: 105px;
            }
            &:nth-of-type(6){
                //width: 190px;
                width: 188px;
            }
            &:nth-of-type(7){
                //width: 190px;
                width: 165px;
            }
        }
    }

    tbody{
        tr{
            td{
                font-family: 'roboto';
                font-size: 14px;
                &:nth-of-type(1){
                    margin-left: -20px;
                    width: 20px;
                }
                &:nth-of-type(3){
                    width: 135px;
                }
                &:nth-of-type(4){
                    width: 60px;
                }
                &:nth-of-type(5){
                    width: 145px;
                }
                &:nth-of-type(6){
                    width: 145px;
                }
                &:nth-of-type(7){
                    width: 145px;
                }
            }
        }
    }

    td{
        border-bottom: 1px solid #ddd;
        padding: 8px;
        color: #494747;
        text-align: center;

        svg {
            margin-left: 10px;
            cursor: pointer;
        }
    }
    tr{
        /*&:nth-child(even){ // linha sim e linha não
            background-color: #f2f2f2;
        }*/
        &:hover {
            background-color: #ddd;
        }
    }
    th{
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        width: 160px;
        height: 35px;

        @media(max-width: 1280px){
            height: 26px;
        }
    }
`;


