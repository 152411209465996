import styled from "styled-components";

export const Container = styled.div`
    width: 400px;

    @media(max-width: 580px){
        width: 365px;

        .close{
            display: flex !important;
            position: absolute;
            right: 4%;
            top: 13px;
            border: 1px solid #FFF;
            display: flex;
            padding: 1px;
            width: 14px;
            height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .close svg path{
            stroke: #FFF;
            stroke-width: 3px;
        }
    }

    hr{
        border: 0;
        border-top: 1px solid #c7cacf;
        width: 90%;
    }

    .close {
    display: none;
}

`;

export const Title = styled.h2`
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    padding-left: 50px;
`;

export const AreaCards = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 420px){

    }

    @media(max-width: 360px){
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cards{
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: 5px 0;
        min-width: 170px;

        @media(max-width: 420px){
            min-width: 163px;
            margin: 0;
        }

        img{
            cursor: pointer;
        }

        input[type="radio"] {
            width: 17px;
            height: 17px;
            cursor: pointer;
        }

        label{
            font-size: 13px;
            font-weight: 600;
            font-family: 'Roboto';
            padding-left: 10px;
            display: flex;
            align-items: center;
            min-width: 128px;
            gap: 5px;

            @media(max-width: 420px){
                padding-left: 7px;
            }
        }
    }
`;