import React from 'react';

export default function IconExcluir(){
    return(
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.375 3.75H3.625H13.625" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5.5 3.75V2.5C5.5 2.16848 5.6317 1.85054 5.86612 1.61612C6.10054 1.3817 6.41848 1.25 6.75 1.25H9.25C9.58152 1.25 9.89946 1.3817 10.1339 1.61612C10.3683 1.85054 10.5 2.16848 10.5 2.5V3.75M12.375 3.75V12.5C12.375 12.8315 12.2433 13.1495 12.0089 13.3839C11.7745 13.6183 11.4565 13.75 11.125 13.75H4.875C4.54348 13.75 4.22554 13.6183 3.99112 13.3839C3.7567 13.1495 3.625 12.8315 3.625 12.5V3.75H12.375Z" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.75 6.875V10.625" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.25 6.875V10.625" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}