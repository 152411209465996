import styled from 'styled-components';

export const Container = styled.form`
    width: 800px;
    height: auto;
    max-height: 600px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

    @media(max-width: 420px){
        width: 100vw;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const AreaSelect = styled.div`
   margin-top: 25px;
`;

export const Select = styled.select`
    height: 29px;
    width: 75px;
    display: block;
    border: 1px solid silver;
    border-radius: 3px;
    font-family: 'Roboto';
    font-size: 12px;
    margin: 0 auto;
    user-select: none;
    margin-top: 9px;

    &:focus {
        border: 1px solid #001E62;
    }

    &.area{
        width: 86%;
        margin: 0 auto;
        color: #464242;
        font-size: 13px;
        font-family: 'roboto',sans-serif;

        @media(max-width: 420px){
            margin: 0 0 0 2%;
        }
    }

    &.planos{
        width: 100px;
    }

    @media(max-width: 420px){
        width: 23vw;
    }
    @media(max-width: 360px){
        width: 22.5vw;
    }
`;

export const Fieldset = styled.fieldset`
    border: 1px solid silver;
    width: 83.5%;
    margin: 0 auto;
    border-radius: 3px;
    font-family: 'Roboto';
    margin-top: 20px;

    legend {
        font-size: 12px;
        user-select: none;
    }

    &.inputsWeek{
        margin-bottom: 20px;
        height: 60px;
    }

    &.alert{
        margin-bottom: 20px;
    }

    &#desabledBtn{
        pointer-events: none;
        opacity: 0.4;
    }

    &#enabled{
        pointer-events: auto;
        opacity: 1;
    }

    @media(max-width: 420px){
        margin: 1% 0 0 2%;
        width: 79.5%;

        &.inputsWeek{
            height: 165px;
        }
    }

    @media(max-width: 375px){//iphone X
        &.inputsWeek{
            height: 226px;
        }
    }
`;

export const ButtonsTab = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0 10px 0;
    .line{
        background: #B3B3B3;
        display: block;
        width: 87px;
        height: 1px;
        margin-top: 12px;

        @media(max-width: 420px){
            width: 5%;
        }
    }
`;

export const Btn = styled.div`
    font-size: 13px;
    width: 130px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    cursor: pointer;
    user-select: none;
    position: relative;

    &.active{
        background: #001E62;
        border: 1px solid #001E62;
        color: #FFF;
        transition: .5s;
    }

    &.deactive{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
        transition: .5s;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    @media(max-width: 420px){
        width: 85px;
    }
`;

export const Ico = styled.img`
    width: 14px;
    position: absolute;
    left: 15px;

    @media(max-width: 420px){
        left: 3px;
    }
`;

export const AreaAgenda = styled.div`
    
`;

export const Table1 = styled.div`
    display: ${props =>  props.visible};
    flex-direction: column;
    

    &.active{
        pointer-events: auto;
	    opacity: 1;
    }

    &.deactive{
        pointer-events: none;
        opacity: 0.4;
    }
`;

export const Table2 = styled(Table1)`
`;

export const Table3 = styled(Table1)`
`;

export const Input = styled.input`
    border: 1px solid silver;
    border-radius: 3px;
    width: 130px;
    height: 27px;
    text-indent: 10px;
    font-family: 'roboto', sans-serif;

    &:focus {
        border: 1px solid #001E62;
    }
`;

export const Button = styled.div`
    width: 30px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 2px solid #001E62;
    cursor: pointer;

    &#desabledBtn{
        pointer-events: none;
        opacity: 0.4;
    }

    &#enabled{
        pointer-events: auto;
        opacity: 1;
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const RowTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 4px 0 4px 0;
`;

export const MenuButtons = styled.div`
    display: flex;
    gap: 38px;
`;

export const RowBottom = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 38px;
    margin-bottom: 10px;

    @media(max-width: 420px){
        padding-right: 6px;
    }
`;

export const AreaWeek = styled.div`
    display: flex;
    gap: 20px;

    &.active{
        pointer-events: auto;
	    opacity: 1;
    }

    &.deactive{
        pointer-events: none;
        opacity: 0.4;
    } 
    
    @media(max-width: 420px){
        flex-wrap: wrap;
        gap: 18px;
    }
`;

export const AreaButtons = styled.div`
    margin-bottom: 20px;
    margin-right: 51px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    .buttonColumn {
        display: flex;
        gap: 11px;
        justify-content: end;
    }

    .alert{
        width: auto;
        display: ${props => props.view};

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardWarning.MuiPaper-elevation0,
        .MuiAlert-standardSuccess, 
        .MuiAlert-standardError,
        .MuiAlert-standardInfo {
            height: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-left: -20px;
        }
    }

    .salvar{
        text-transform: uppercase;
        background: #001E62;
        width: 110px;
        height: 29px;
        border: 0;
        color: #FFF;
        border-radius: 6px;
        cursor: pointer;
        user-select: none;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #FFF;  
            color: #001E62;
            border: 1px solid #001E62;
            transition: .3s;
        }

        &#desabledBtn{
            pointer-events: none;
            opacity: 0.4;
        }

        &#enabled{
            pointer-events: auto;
            opacity: 1;
        }
    }

    @media(max-width: 420px){
        flex-direction: column;
        margin-top: -17px;

        .buttonColumn {
            justify-content: end;
            margin-top: -16px;
        }

        .alert{
            width: 90%;
            display: block;
            margin: auto;
            margin-left: 8%;
        }
    }
`;

export const Legend = styled.div`
    position: relative;

    span{
        font-size: 10px;
        position: absolute;
        top: 2px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        background: #FFF;
        width: 45px;
        user-select: none;
        color: #001E62;
    }
`;