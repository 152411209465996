import styled from 'styled-components';

export const Container = styled.section`
    padding: 20px 48px 22px;

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    fieldset{
        font-family: 'roboto';
        border: 1px solid #c1bdbd;
        border-radius: 4px;
        margin-bottom: 15px;
        legend{
            font-size: 13px;
        }
    }

    @media(max-width: 420px){
        padding: 20px 48px 22px 8px;

        fieldset{
            width: 103%;
        }
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const AreaInput = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #c1bdbd;
    border-radius: 6px;
    margin: 5px 0 15px 0;
    
    input{
        font-family: 'roboto';
        font-size: 14px;
        outline: none;
        border: 0;
        text-indent: 7px;
        width: 300px;
        height: 28px;
        border-radius: 6px;

        @media(max-width: 420px){
            width: 100%;
        }
    }
`;

export const AreaButton = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const Button = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
   

    &:active{
        position: relative;
        top: 1px;
    }
    svg {
        width: 29px;
        height: 29px;
    }
`;


export const ButtonAdd = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 50px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    cursor: pointer;
    user-select: none;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }

    &:hover{
        
        border: 1px solid #001E62;
        box-sizing: border-box;
        transition: .3s;
        svg{
            g{
                path{
                    stroke: #062467;
                    color: #FFF;
                }
            }
        }
    }

    svg {
        width: 20px;
        height: 22px;
    }

    @media(max-width: 420px){
        margin-left: 0;
    }
`;