import styled from "styled-components";

export const ContentTab = styled.section`
   
    margin: 0 auto;
    width: 93%;
    user-select: none;
    font-family: 'roboto';
    position: relative;
   

    fieldset{

        border: 1px solid #a4a4a4;
        border-radius: 2px;
       
        &.disable{
            pointer-events: none;
            opacity: 0.4;
            height: auto;
            margin: 20px auto;
        }

        &.enable{
            pointer-events: auto;
            opacity: 1;
            height: auto;
            margin: 0 auto;
        }

        select {
            border: 1px solid #a4a4a4;
            border-radius: 2px;
            height: 28px;
            width: 100%;
            outline: none;
            text-indent: 5px;

            @media(max-width: 1500px){
                height: 27px;
                margin-bottom: 0;
            }
        }

        legend {
            font-size: 12px;
            color: #1d1c1c;
        }

        form{
           
            .row{
                display: flex;
                gap: 5px;
                margin: 5px auto;
                align-items: center;
                justify-content: center;
            }

            @media(max-width: 1500px){
                margin: 0 auto;
            }
            .group {
                width: 100%;

                &.column input {
                    width: 95%;
                }

                label{
                    font-size: 13px;
                    color: #1d1c1c;

                    @media(max-width: 420px){
                        font-size: 11px;
                    }
                }

                select {
                    
                    @media(max-width: 1500px){
                        height: 27px;
                    }
                    @media(max-width: 420px){
                        font-size: 11px;
                    }
                }

                input{
                    height: 25px;
                    width: 99%;
                    border-radius: 2px;
                    text-indent: 10px;
                    border: 1px solid #A4A4A4;
                    outline: none;
                    font-size: 13px;
                    @media(max-width: 1500px){
                        height: 25px;
                    }
                    @media(max-width: 420px){
                        font-size: 11px;
                    }
                }
            }

            .checkbox{
                display: flex;
                align-items: center;
                height: 25px;
            
                input[type="checkbox"] {
                    width: 17px;
                    margin-top: 9px;
                }
            }
        }
    }
    
`;

export const AreaButtonRemove = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const BtnBorder = styled.div`
    width: 17px;
    height: 17px;
    display: flex;
    margin-right: 3px;
    border: 2px solid #001E62;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        width: 20px;
        height: 20px;
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -22px;
    right: 0;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;

        @media(max-width: 420px){
            font-size: 11px;
        }
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 12px;
            font-weight: normal;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaButon = styled.div`

`;

export const ButtonAdd = styled.div`
    border: 2px solid #001E62;
    width: 30px;
    height: 25px;
    border-radius: 5px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:active{
        position: relative;
        top: 1px;
    }

    svg {
        width: 14px;
        height: 14px;
    }
`;

export const MultiplyArea = styled.div`

`;

export const Button = styled.button`
    background: #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    transition: .3s;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;

    @media(max-width: 1280px){
        height: 28px;
    }

    &:hover{
        background: #FFF;
        border: 1px solid #001E62;
        color: #001E62;
    }
    
    &.btnImportar{
        margin-top:11px;
        font-size: 12px;
        font-weight: normal;
    }

    &.btnNovo{
        margin-top: 12px;
        margin-right: 5px;
        width: 110px;
        font-size: 12px;
        font-weight: normal;

        &:hover svg path{
            fill: #001E62;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }

        @media(max-width: 1280px){
            height: 28px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &.btnSalvarDesabled{
        display:none;
    }

    &.btnEditarDesabled{
        display: none;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    cursor: pointer;
    gap: 7px;
   
    .alert{
        margin-right: 40px;
        width: 250px;
        display: ${props => props.view};
    }
`;


export const BorderButton = styled.div`
    border: 2px solid #001E62;
    background: #FFF;
    width: 39px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px; 
    
    svg {
        width: 18px;
        height: 18px;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enable{
        pointer-events: auto;
        opacity: 1;
    }
`;

export const Actions = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;

    @media(max-width: 420px){
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
`;