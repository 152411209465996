import styled from 'styled-components';

export const Container = styled.div`
    width: 94%;
    margin: 0 auto;

    @media(max-width: 580px){
        width: 97%;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
    }

    .alert{
        width: 92%;
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        display: ${props => props.visible};
    }
    
    fieldset{
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        margin-bottom: 4px;
        position: relative;

        @media(max-width: 1280px){
            margin-bottom: 3px;
        }
    }
    legend{
        font-size: 13px;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        color: #848484;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 11px;
        }

    }

    label{
        font-size: 13px;
        color: #6b6a6a;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 11px;
        }
    }
`;

export const AreaInputs = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;

    @media(max-width: 1280px){
        margin-top: 0;
    }
    
    input{
        width: 100%;
        height: 29px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
        margin-bottom: 6px;

        @media(max-width: 1500px){
            margin-bottom: 2px;
            height: 26px;
        }
        @media(max-width: 1280px){
            margin-bottom: 2px;
            height: 22px;
            font-size: 12px;
        }
        @media(max-width: 360px){
            font-size: 11px;
            text-indent: 1px;
        }

    }
    legend{
        padding-bottom: 3px;
    }

    .groupone{
        width: 50%;
        padding-right: 14px;

        .inputTwo{
            display: flex;
            flex-direction: column;
            margin-top: 8px;
        }
    }

    .groupGeral{
        width: 100%;
    }
    .grouptwo{
        width: 45%;

        #btnVersao{
            border-radius: 22px;
            background: #001E62;
            color: #FFF;
            height: 33px;
            outline: none;
            width: 100%;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
        

            &:hover{
                border: 1px solid #001E62;
                color: #001E62;
                background: #FFF;
                transition: .5s;
            }

            &:active{
                background: #1d45a1;
                position: relative;
                top: 1px;
            }

            @media(max-width: 580px){
                height: 27px;
            }
        }
    }

    .bloco2{
        width: 99%;
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 15px;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    @media(max-width: 1280px){
        position: inherit;
    }

    @media(max-width: 580px){
        bottom: -55px;
    }

    .btnEditInfo{
        cursor: pointer;

        img{
            width: 35px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
    }

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }
   
    button{
        border: 0;
        width: 115px;
        height: 25px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }
`;