import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import carRed from '../../../img/carRed - Copia.png';
import carYellow from '../../../img/carYellow - Copia.png';
import carGrey from '../../../img/carGrey - Copia.png';
import carBlue from '../../../img/carBlue - Copia.png';
import carBrown from '../../../img/carBrown - Copia.png';
import motoRed from '../../../img/motoRed - Copia.png';
import busGrey from '../../../img/busGrey.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Content, MoveRight, Options, ButtonSettings } from './styles';

export default function CarRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'car', title: 'Carro horizontal direita', image: carRed, object: props.carRight, setObject: props.setCarRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.carRight.map((item, index) => {
                if (props.carRight[index] !== '') {
                    return (
                        <Content key={index}>
                            <ItemDraggable
                                key={index}
                                index={index}
                                object='carRight'
                                top={item.top}
                                left={item.left}
                                setCarRight={props.setCarRight}
                                carRight={props.carRight}
                                classeObjeto={item.classeObjeto}
                            >
                                <MoveRight src={
                                    props.changeCars === true && item.ocupacao === 33 ? motoRed :
                                        props.changeCars === true && item.ocupacao === 80 ? busGrey :
                                            props.carRight[index].color === 'red' ? carRed :
                                                props.carRight[index].color === 'grey' ? carGrey :
                                                    props.carRight[index].color === 'yellow' ? carYellow :
                                                        props.carRight[index].color === 'blue' ? carBlue :
                                                            props.carRight[index].color === 'brown' ? carBrown : ''
                                }
                                    alt=""
                                    move={
                                        item.color_group === undefined || item.color_group === '' ? 'pause' :
                                            item.color_group === 1 ? 'pause' :
                                                item.color_group === 2 ? 'pause' :
                                                    item.color_group === 4 ? true : props.moveRight
                                    }
                                    tamanho={item.tamanho}
                                    velocidade={item.velocidade}
                                    percurso={item.percurso}
                                    angulo={item.angulo}
                                    taxaOcupacao={item.ocupacao}
                                    changeCars={props.changeCars}
                                />
                                <Options remove={props.remove} onClick={() => props.removeCarRight(index, item)}>
                                    <img src={close} alt="" />
                                </Options>
                                <ButtonSettings remove={props.remove}>
                                    <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                                </ButtonSettings>
                            </ItemDraggable>
                        </Content>
                    )
                } else {
                    return '';
                }
            })}
        </Container>
    );
}
