import styled, { css } from "styled-components";
 
 const dragActive = css`
   border-color: #78e5d5;
 `;
 
 const dragReject = css`
   border-color: #e57878;
 `;
 
 type IDropContainer = {
   isDragActive?: boolean;
   isDragReject?: boolean;
 };
 
 export const DropContainer = styled.div<IDropContainer>`
   border: 1px dashed #ddd;
   border-radius: 4px;
   cursor: pointer;
 
   transition: height 0.2s ease;
 
   ${(props: any) => props.isDragActive && dragActive};
   ${(props: any) => props.isDragReject && dragReject};
 `;
 
 const messageColors = {
   default: "#999",
   error: "#e57878",
   success: "#78e5d5",
 };
 
 interface ITypeMessageColor {
   type?: "default" | "error" | "success";
 }
 
 export const UploadMessage = styled.p<ITypeMessageColor>`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const Button = styled.div`
  background: #001E62;
  width: 110px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  border: 0;
  color: #FFF;
  font-weight: 550;
  border: 1px solid #001E62;
  user-select: none;
  font-family: 'roboto';
  text-transform: uppercase;
  margin: 0 16px 15px 0;

  &:hover{
    background: #FFF;
    border: 1px solid #001E62;
    color: #001E62;
    transition: .5s;
  }

  &:active{
    position: relative;
    top: 1px;
  }
  
`;