import styled from 'styled-components';

export const AreaButton = styled.button`
    border: 0;
    background: #001E62;
    width: 27px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #FFF;
    margin: 0 auto;

    &:hover{
        background: #FFF;
        border: 1px solid #001E62;
        transition: .3s;
        
        svg{
            path{
                stroke: #001E62;
            }
        }
    }
`;