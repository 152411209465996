import styled from 'styled-components';

export const Container = styled.section`
    padding: 14px 29px;

    fieldset{
        border: 1px solid #c1bdbd;
        border-radius: 4px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 5px;

    }

    legend{
        font-size: 13px;
        font-family: 'roboto', sans-serif;
    }
    input{
        width: 99%;
        height: 30px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
 
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 420px){
        display: block;
        z-index: 1;
        text-align: end;
        margin-right: -6%;
        margin-top: -6px;
        margin-bottom: -4px;
    }
`;

export const ContentData = styled.div`

    fieldset{
        border: 1px solid #c1bdbd;
        border-radius: 4px;
        margin-bottom: 15px;
        max-height: 218px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
		    width: 8px;
            height: 8px;

            &-track-piece {
                background-color: #FFF;
            }

            &-thumb:vertical,
            &-thumb:horizontal {
                background-color: #001E62;
                border-radius:5px;
            }

            &-thumb:vertical:hover,
            &-thumb:horizontal:hover {
                background-color: #269F2A;
            }
	    }
    }

    legend {
        font-family: 'roboto';
        font-size: 13px;
    }

    input{
        width: 235px;
        height: 30px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;
    }

    @media(max-width: 420px){
        fieldset{
            max-height: 305px;
        }
    }
`;

export const GroupInput = styled.div`
    display: flex;
    align-items: center;

    select {
        width: 243px;
        height: 32px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
    }

    input{
        width: 235px;
        height: 30px;
        border: 1px solid #c1bdbd;
        border-radius: 7px;
        outline: none;
        text-indent: 7px;
        margin-top: 10px;
    }

    @media(max-width: 420px){
        display: flex;
        justify-content: space-between;

        select{
            width: 70%;
        }
    }
`;

export const Button = styled.div`
    cursor: pointer;
    margin-top: 4px;
    &:active{
        position: relative;
        top: 1px;
    }  
    svg {
        width: 29px;
        height: 30px;
    }
`;  

export const ButtonAdd = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 50px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    cursor: pointer;
    user-select: none;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }

    &:hover{
        
        border: 1px solid #001E62;
        box-sizing: border-box;
        transition: .3s;
        svg{
            g{
                path{
                    stroke: #062467;
                    color: #FFF;
                }
            }
        }
    }

    svg {
        width: 20px;
        height: 22px;
    }

    @media(max-width: 420px){
        margin-left: 0;
    }
`;

export const ButtonSave = styled.div`
    background: #001E62;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 86px;
    font-size: 14px;
    border-radius: 7px;
    margin-left: 25px;
    cursor: pointer;
    font-family: 'roboto';
    font-size: 14px;
    border: 1px solid #001E62;

    &:active{
        position: relative;
        top:1px;
        background: #30509b;
    }

    &:hover{
        color: #001E62;
        border: 1px solid #001E62;
        background: #FFF;
        transition: .3s;
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const ButtonArea = styled.div`
    width: 100px;
    height: 32px;
    background: #001E62;
    border: 1px solid #001E62;
    color: #FFF;
    font-size: 14px;
    font-family: 'roboto';
    font-size: 12px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
  

    &:active{
        position: relative;
        top: 1px;
    }

    &:hover{
        background: #FFF;
        color: #001E62;
        border: 1px solid #001E62;
    }
`;


export const AreaInput = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #c1bdbd;
    border-radius: 6px;
    margin: 5px 0 15px 0;
    
    input{
        font-family: 'roboto';
        font-size: 14px;
        outline: none;
        border: 0;
        text-indent: 7px;
        width: 300px;
        height: 28px;
        border-radius: 6px;

        @media(max-width: 420px){
            width: 100%;
        }
    }
`;
