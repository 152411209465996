import React from 'react';
import { Header, Voltar } from './styles';
import logoCentral from '../../assets/IconPhor.ico';
import { FaArrowRight } from "react-icons/fa";

export default function MenuBlank(){

    const redirect = () =>{
       window.location.href = "/mapa"
    }

    return(
        <Header>
            <img src={logoCentral} alt="" onClick={redirect} />
            <Voltar onClick={redirect}>
                <FaArrowRight size={25} color="#FFF"/>
            </Voltar>
        </Header>
    );
}