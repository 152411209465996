import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Img, Options, ButtonSettings } from './styles';
import ItemDraggable from '../../../ItemDraggable';
import botoeira from '../../../img/botoeira.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import audioBotoeira from '../../../img/audioBotoeira.mp3';
import axios from 'axios';


export default function Botoeira(props) {

    const param = useParams();

    const acionarBotoeira = () => {
        var config = {
            method: 'get',
            url: `http://${param.ipmbb}/web/gw2/bot1.dgv?WAC=12345`,
            headers: {
                'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
                'Accept-Language': 'pt-PT,pt;q=0.9,en-US;q=0.8,en;q=0.7',
                'Cache-Control': 'max-age=0',
                'Connection': 'keep-alive',
                'Upgrade-Insecure-Requests': '1',
                'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36',
                'Access-Control-Allow-Origin': '*'
            }
        };

        const audio = new Audio(audioBotoeira);
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                audio.play();
                alert('atravessia solicitada')
            })
            .catch(function (error) {
                console.log(error);
                return;
            });

    }

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Botoeira', image: botoeira, object: props.objBotoeira, setObject: props.setObjBotoeira });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objBotoeira.map((item, index) => {
                if (props.objBotoeira[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='objBotoeira'
                            top={item.top}
                            left={item.left}
                            setObjBotoeira={props.setObjBotoeira}
                            objBotoeira={props.objBotoeira}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img src={botoeira} alt="" onDoubleClick={acionarBotoeira} tamanho={item.tamanho} angulo={item.angulo} />
                            <Options remove={props.remove} onClick={() => props.removeBotoeira(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}

