import React, { useState, useEffect, useRef, useContext } from 'react';
import { ModalContext } from '../../../../contextModal';
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BoxMenu, Title, AreaInput, Search, CloseFilter, BoxFilter, AreaButtons, Button } from '../styles';


//temporario
//const agente   = ['controlador', 'outros'];

export default function FiltroAgente(props){

    const [pesquisa, setPesquisa] = useState('');  //guarda resultado da pesquisa
    const [resultado, setResultado] = useState([]); //guarda resultado do filtro 
    const [selecao, setSelecao] = useState([]); // estados para guardar a seleção de items do filtro
    const [enabled, setEnabled] = useState(true);

    const { setStatusFilterAgente } = useContext(ModalContext);

    const myRef = useRef();

    const getIdentificacao = (e,index) => {
 
        if(e.target.checked){
            const values = [...selecao];
            values[index] = e.target.value;
            setSelecao(values);
        }else{
            const values = [...selecao];
            values[index] = '';
            setSelecao(values);
        }
    }

    const filtrarAgente = () => {
        const filtered = selecao.filter(Boolean);
        props.setParamAgente(filtered);
        props.toogle(false);

        if(filtered.length > 0){
            setStatusFilterAgente('Dados Filtrados');
        }else{
            setStatusFilterAgente('Filtro Limpo');
        }
    }

    /*function myFunctionClear() {
        for (i = 0; i < document.selecao.elements.length; i++)
          if (document.f1.elements[i].type == "checkbox")
            document.f1.elements[i].removeAttribute("checked");
      }*/

    useEffect(() => {
        const results = props.agente.filter( palavra => palavra.toLowerCase().includes(pesquisa));
        if(results == ''){
            setResultado(['Nenhuma correspondência']);
            setEnabled(false);
        }else{
            setResultado(results);
            setEnabled(true);
        }
    },[pesquisa]);

    useEffect(() => {
        const results = props.agente.filter( palavra => palavra.toLowerCase().includes(pesquisa));
        if(results == ''){
            setResultado(['Nenhuma correspondência']);
            setEnabled(false);
        }else{
            setResultado(results);
            setEnabled(true);
        }
    },[props.agente]);

    return(
        <BoxMenu className='agente' ref={myRef} display={props.open}>
            <Title>
                <h2>Filtrar agente
                    <CloseFilter onClick={() => props.onClose(false)}>
                        <MdClose size="18"/>
                    </CloseFilter>
                </h2>
                <span>Selecionar item:</span>
                <AreaInput>
                    <input type="text" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)}/>
                    <Search>
                        <FaSearch />
                    </Search>
                </AreaInput>
            </Title>
            <BoxFilter>
                <ul>
                    {resultado === '' ? 
                        props.agente.map( (items,index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)}/>{items}</li>   
                        )):
                        resultado.map( (items,index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)}/>{items}</li>   
                        ))
                    }
                </ul> 
            </BoxFilter>
            <AreaButtons status={enabled}>
                <Button onClick={filtrarAgente}>Ok</Button>
                <Button onClick={() => props.onClose(false) }>Cancelar</Button>
            </AreaButtons>
        </BoxMenu>
    );
}