import styled from 'styled-components';

export const Container = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10vh;

    span{
        font-family: 'Roboto', sans-serif;
        font-weight: 600;
        font-size: 25px;
        @media(max-width: 800px){
            text-align: center;
        }
    }

    button{
        width: 170px;
        height: 32px;
        border: 0;
        border-radius: 4px;
        background: #001E62;
        color: #FFF;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        margin-top: 25px;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #FFF;
            color: #001E62;
            border: 1px solid #001E62;
            transition: .3s;
        }
    }
`;

export const Banner = styled.figure`
    @media(max-width: 1366px){
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 65%;
        }
    }

    @media(max-width: 400px){

    }
`;