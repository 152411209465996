import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from 'react-toastify';
import PageTitle from '../../PageTitle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import { DropContainer, Button } from "./styles.ts";

import api from '../../../services/api';

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

const useStyles = makeStyles({
	root: {
		width: '100%',
	},
	container: {
		maxHeight: 430,
		maxWidth: 520,
	},
	tableCell: {
		display: 'flex',
		alignItems: 'center',
	},
	section: {
		background: '#e4e0e0',
		border: '3px dashed #979696',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#fff'
	}
});

const thumbsContainer = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box'
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden'
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%'
};

function RegisterCSV(props) {
	const formData = new FormData();
	const classes = useStyles();
	const [files, setFiles] = useState([]);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: acceptedFiles => {
			setFiles(acceptedFiles.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			})));
		}
	});


	const thumbs = files.map(file => (
		<div style={thumb} key={file.name}>
			<div style={thumbInner}>
				<img
					src={file.preview}
					style={img}
					alt=""
				/>
			</div>
		</div>
	));

	const handleUploadFile = async () => {
		let type
		files.map(file => {
			type = file.type.split('/');
			type = type[1];
			formData.append('file', file)
		})

		if (type === 'vnd.ms-excel') {
			toast.info(`Cadastrando Locais do CSV`, {
				position: "top-center",
				toastId: "importcsv",
				autoClose: true,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			await api.post("/centraldefalhas/local/csv", formData, {
				headers: {
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
				}
			}).then(res => {
				const locaisInvalidos = res.data.dadosInvalidos;
				const locaisNaoCriados = res.data.locaisNaoCriados;
				let mensagem = '';

				if (locaisInvalidos.length !== 0) {
					mensagem += `Locais com dados inválidos: ${JSON.stringify(locaisInvalidos)}, `
				}
				if (locaisNaoCriados.lengt !== 0) {
					mensagem += `Locais não cadastrados: ${JSON.stringify(locaisNaoCriados)}`
				}
				if (mensagem === '') {
					mensagem += 'Locais cadastrados com sucesso'
				}

				toast.update("importcsv", {
					render: mensagem,
					type: toast.TYPE.SUCCESS,
					position: "top-center",
				});

				setFiles([]);

			}).catch(error => {

				if (error.response.data.mensagem === 'Número maximo de locais atingido') {
					toast.error(`Número máximo de locais da licença atingidos`, {
						position: "top-center",
						toastId: "errorcsv",
						hideProgressBar: false,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				} else {

					toast.error(`Erro ao cadastrar locais do arquivo CSV`, {
						position: "top-center",
						toastId: "errorcsv",
						hideProgressBar: false,
						closeOnClick: false,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});

				}

				setFiles([]);

			})

		} else {
			toast.error(`Formato do aquivo invalido`, {
				position: "top-center",
				toastId: "errorcsv",
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});

			setFiles([]);

		}
	}

	useEffect(() => () => {
		files.forEach(file => URL.revokeObjectURL(file.preview));

	}, [files]);

	return (
		<Dialog
			open={props.open}
			className="Dialog"
			onClose={props.onClose}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
			hideBackdrop={true}
		>
			<PageTitle
				id="draggable-dialog-title"
				text="Importar CSV"
				onClick={props.onClose}
			> </PageTitle>
			<DialogContent className='content-dialog' >
				<DropContainer className={classes.section}>
					<section className="container">
						<div {...getRootProps({ className: 'dropzone' })}>
							<input {...getInputProps()} />
							<p>Solte o arquivo aqui ...</p>
						</div>
						<aside style={thumbsContainer}>
							{thumbs}
						</aside>
					</section>
				</DropContainer>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleUploadFile}>
					Importar
				</Button>
			</DialogActions>
			{/* </Paper> */}
		</Dialog>
	);
}


export default RegisterCSV;