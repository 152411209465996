import styled from 'styled-components';

export const AreaFiltro = styled.div`
    position: absolute;
    right: 0;
    z-index: 1000;
    top: 260px;  // 339px qnd o "Eventos estiver display block"
    height: 230px;
    right: ${props => props.slider + 'px'};
    //transition: all .5s ease-out;
    background: #FFF;
    box-shadow: -6px 6px 3px -1px #79797945;

    @media(max-width: 420px){
        right: ${ props => props.slider + 'px' === '-513px' ? '-115%' : ''}
    }
    @media(max-width: 380px){
        right: ${ props => props.slider + 'px' === '-513px' ? '-118%' : ''}
    }
    
    &:hover{
        transition: all .5s ease-out;
    }
    &:active{
        transition: all .5s ease-out;
    }

    @media(max-width: 1280px){
        top: 243px; // 321px quando o "Eventos" estiver display: block
    }
`;

export const TicketFilter = styled.div`
    width: 105px;
    height: 59px;
    background-color: ${props => props.activeFilter}; // #0c9410
    color: #FFF;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    position: absolute;
    left: -105px;
    top: 1px;
    z-index: 1000;
   
    img{
        width: 20px;
        height: 20px;
        margin-right: 17px;
        margin-left: -7px;
        margin-top: -2px;
    }

    @media(max-width: 1280px){
        height: 40px;
    }
`;

export const Container = styled.section`
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        width: 380px;
        height: 40px;
    }
`;

export const Select = styled.select`
    height: 36px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #c3c1c2;
    color: #656063;
    font-size: 15px;
    text-indent: 10px;

    &:focus{
        border: 2px solid #3951b2;
    }
`;

export const Option = styled.option`

`;

export const LabelFloat = styled.div`
    position: relative;
    padding-top: 13px;
    margin-top: -13px;

    input{
        &:required:invalid + label{
            color: red;
        }

        &:focus:required:invalid{
            border-bottom: 2px solid red;
        }

        &:required:invalid + label:before{
            content: '*';
        }
        &:focus + label{
            font-size: 13px;
            margin-top: 0;
            color: #3951b2;
        }
        &:not(:placeholder-shown) + label{
            font-size: 13px;
            margin-top: 0;
            color: #3951b2;
        }
    }
`;

export const Input = styled.input`
    border: 0;
    border: 1px solid #c3c1c2;
    color: #656063;
    border-radius: 5px !important;
    height: 32px;
    outline: none;
    min-width: 180px;
    width: 98%;
    font-size: 16px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -webkit-appearance:none;
    border-radius: 0;
    text-indent: 10px;

    &:focus{
        border: 2px solid #3951b2;
    }

    &::placeholder{
        color:transparent;
    }
`;

export const Label = styled.label`
   pointer-events: none;
    position: absolute;
    top: -4px;
    left: 0;
    margin-top: 24px;
    margin-left: 10px;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
`;

export const AreaButton = styled.button`
    border: 0;
    background: transparent;

    &:active{
        position: relative;
        top: 1px;
    }
    svg{
        width: 40px;
        height: 40px;
    }
`;

export const AreaButtonFilter = styled.button`
    border: 0;
    background: blue;

    &:active{
        position: relative;
        top: 1px;
    }
    svg{
        width: 40px;
        height: 40px;
    }
`;

export const Voltar = styled.div`
    display: none;
    @media(max-width: 420px){
        display: block;
        margin-right: 33%;
        margin-top: 10px;
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -74px;
    right: 15px;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;
