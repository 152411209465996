import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";

export const Container = styled.section`
    background: #E5E5E5;
    overflow: auto;
    height: 100vh;
    //margin-top: 60px;

    @media(max-width: 420px){
        overflow-y: scroll;
        overflow-x: hidden;
    }
`;

export const AreaMenu = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    width: 95%;
    margin: 80px auto;

    @media(max-width: 1020px){
        flex-direction: column;
    }

    fieldset{
        font-family: 'roboto',sans-serif;
        font-weight: lighter;
        font-size: 11px;
        align-items: center;
        border: .5px solid #b1b1b1;
        border-radius: 3px;

        @media(max-width: 1020px){
            width: 96%;
        }

        @media(max-width: 420px){
            width: 93%;
            height: auto;
            display: flex;
            flex-direction: row;
        }
    }

    legend{
        color: #5f5d5d;
        font-weight: 400;
    }
    
    select{
        width: 100%;
        height: 32px;
        border: 1px solid silver;
        border-radius: 3px;
    }
`;

export const Title = styled.div`
    display: flex;
    position: relative;   

    @media(max-width: 1280px){
        left: 0;
    }

    h1{
        font-family: 'Roboto',sans-serif;
        font-size: 18px;
        font-weight: 900;
        user-select: none; 
        margin-top: -23px;
        margin-bottom: 8px;
    }
`;

export const AreaInputs = styled.div`
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 70%;
    padding: 22px;

    @media(max-width: 1020px){
        width: 93.5%;
    }
    @media(max-width: 420px){
        width: 89.5%;
    }

    
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    position: relative;

    &.data{
        @media(max-width: 1020px){
            display: flex;
            flex-direction: column;
        }
    }
`;

export const Span = styled.span`
    font-family: 'roboto', sans-serif;
    color: #808080;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    position: absolute;
    right: 20px;
    top: 50px;

    @media(max-width: 1020px){
        top: -15px;
    }

    @media(max-width: 420px){
        font-size: 11px;
        width: 100%;
        top: -19px;
        left: 1%;
    }

    @media(max-width: 397px){
        font-size: 10px;
    }

    @media(max-width: 360px){
        font-size: 9px;
        text-align: center;
    }
`;

export const FieldsetSmall = styled.fieldset`
    width: 20%;
    height: 55px;
    justify-content: center;
    display: flex;

    select{
        font-family: 'roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;

        @media(max-width: 1280px){
            font-size: 12px;
        }
    }
`;

export const FieldsetMedium = styled.fieldset`
    width: 32%;
    display: flex;
    height: 55px;
    justify-content: center;
    gap: 10px;


    input{
        height: 29px;
        border: 1px solid silver;
        border-radius: 3px;
        width: 100%;
        text-indent: 5px;
        font-family: 'roboto', sans-serif;
        font-size: 12px;

        @media(max-width: 420px){
            width: 44%;
            text-indent: 2px;
            font-family: 'roboto',sans-serif;
            font-size: 9px;

        }
    }

    select{
        font-family: 'roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;

        @media(max-width: 1280px){
            font-size: 12px;
        }
    }
`;

export const FieldsetLarge = styled.fieldset`
    width: 100%;
    display: flex;    

`;

export const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 400
    },
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: 2
    },
    noLabel: {
        marginTop: theme.spacing(3)
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const AreaButton = styled.div`
    width: 40%;
    display: flex;
    justify-content: flex-end;
    gap: 23px;
    margin-right: 20px;

    @media(max-width: 1020px){
        width: 50%;
    }

    @media(max-width: 420px){
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
`;

export const BtnExportar = styled.button`
    border: 0;
    height: 30px;
    width: 150px;
    border-radius: 6px;
    background: #FFF;
    color: #001E62;
    cursor: pointer;
    text-transform: uppercase;
    border: 1px solid #001E62;

    @media(max-width: 360px){
        font-size: 11px;
    }

    //pointer-events: none; // bloqueado temporariamente até inseir função
    //opacity: 0.4;

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    &:hover{
        border: 1px solid #001E62; 
        background: #001E62;
        color: #FFF;
        transition: .3s;
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const BtnCarregar = styled.button`
    border: 0;
    height: 30px;
    width: 150px;
    border-radius: 6px;
    background: #001E62;
    color: #FFF;
    cursor: pointer;
    text-transform: uppercase;

    @media(max-width: 360px){
        font-size: 11px;
    }

    &:hover{
        border: 1px solid #001E62; 
        background: #FFF;
        color: #001E62;
        transition: .3s;
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const AreaCharts = styled.section`
  
`;

export const ChartsCircles = styled.div`
    display: flex;
    justify-content: space-between;
    width: 95.5%;
    height: 380px;
    margin: -60px auto;
    margin-bottom: 80px;

    @media(max-width: 420px){
        height: auto;
    }
`;