import React from 'react';

export default function IconDesbloqueado(){
    return(
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M10.5 13.125V11.875C10.5 11.212 10.2366 10.5761 9.76777 10.1072C9.29893 9.63839 8.66304 9.375 8 9.375H3.625C2.96196 9.375 2.32607 9.63839 1.85723 10.1072C1.38839 10.5761 1.125 11.212 1.125 11.875V13.125" stroke="#328C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.8125 6.875C7.19321 6.875 8.3125 5.75571 8.3125 4.375C8.3125 2.99429 7.19321 1.875 5.8125 1.875C4.43179 1.875 3.3125 2.99429 3.3125 4.375C3.3125 5.75571 4.43179 6.875 5.8125 6.875Z" stroke="#328C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.125 6.875L12.375 8.125L14.875 5.625" stroke="#328C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}