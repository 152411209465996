import styled from 'styled-components';

export const Container = styled.figure`
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 49.3%;
    padding: 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        margin-top: 14%;
    }

    @media(max-width: 420px){
        height: auto;

        svg{
            margin-top: 25%;
        }
    }
`

export const Title = styled.span`
    position: absolute;
    top: 15px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'roboto', sans-serif;
    font-weight: 600;

    @media(max-width: 580px){
        font-size: 12px;
        text-align: center;
    }
`;

export const Legenda = styled.div`
    position: absolute;
    bottom: 53px;
    font-family: 'Roboto';
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;

    &::before {
        content: '';
        background: #6193F9;
        width: 12px;
        height: 12px;
        display: block;
        border-radius: 50%;
    }
`;

export const Fieldset = styled.fieldset`
    font-family: 'roboto',sans-serif;
    font-weight: lighter;
    font-size: 11px;
    align-items: center;
    border: .5px solid #b1b1b1;
    border-radius: 3px;
    position: absolute;
    top: 35px;
    width: 35%;
    right: 0;
    left: 0;
    margin: auto;

    @media(max-width: 420px){
        width: 80%;
        margin-top: 12px;
    }

`;

export const Titulo = styled.legend`
    color: #5f5d5d;
    font-weight: 400;
`;

export const Select = styled.select`
    width: 100%;
    height: 32px;
    border: 1px solid silver;
    border-radius: 3px;

    @media(max-width: 420px){
        font-size: 11px;
    }
`;

export const Option = styled.option`

`;

export const AreaLoading = styled.img`
    width: 20%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: ${props => props.loading === false ? 'none' : 'block'};
`;


