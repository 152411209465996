import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStart from '../../../img/Setas/arrowStart.png';
import arrowRightPause from '../../../img/Setas/arrowPause.png';
import arrowRightStop from '../../../img/Setas/arrowStop.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveRight, Options, ButtonSettings } from './styles';

export default function ArrowRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Segue a direita', image: arrowStart, object: props.arrowRight, setObject: props.setArrowRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowRight.map((item, index) => {
                if (props.arrowRight[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowRight'
                            top={item.top}
                            left={item.left}
                            setArrowRight={props.setArrowRight}
                            arrowRight={props.arrowRight}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveRight
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowRightStop :
                                        item.color_group === 1 ? arrowRightStop :
                                            item.color_group === 2 ? arrowRightPause :
                                                item.color_group === 4 ? arrowStart : ''
                                }
                                alt=""
                                move={
                                    item.color_group === undefined || item.color_group === '' ? 'pause' :
                                        item.color_group === 1 ? 'pause' :
                                            item.color_group === 2 ? 'pause' :
                                                item.color_group === 4 ? true : props.props.moveArrowRight
                                }
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowRight(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
