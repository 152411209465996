import React, { useState, useEffect } from "react";
import { DonutChart } from "bizcharts";
import { Container, Title, AreaLoading } from './styles';
import loader from '../../../assets/img/loader.gif';

export default function ChartCircle(props) {

    const [dados, setDados] = useState([])

    useEffect(() => {

        const detectoresOrdemCrescente = props.dadosMedia.length > 0 ? props.dadosMedia.sort((a, b) => a._id - b._id) : [];
        detectoresOrdemCrescente.map(item => {
            return item._id = item._id.toString();
        })

        setDados(detectoresOrdemCrescente);
    }, [props.dadosMedia]);

    return (
        <Container>
            <AreaLoading src={loader} loading={props.cbLoading} />
            <Title>Média de acionamentos por minuto</Title>
            <DonutChart
                data={dados || []}
                title={{
                    visible: false,
                    text: "Média de acionamentos por período de tempo",
                }}
                autoFit
                height={290}
                radius={0.8}
                padding="auto"
                angleField="mediaContagem"
                colorField="_id"
                pieStyle={{ stroke: "white", lineWidth: 5 }}
                className='chart'
                statistic={false}
                meta={{
                    mediaContagem: {
                        formatter: (v) => { return v.toFixed(2) }
                    }
                }}
            />
        </Container>
    );
}

