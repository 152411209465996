import styled from 'styled-components';

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;