import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../../../contextModal';
import BtnNovo from '../../../Registers/RegisterControllers/Icons/BtnNovo';
import excluir from '../../../../assets/img/excluir.png';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../services/api';
import icoSom from '../../../../assets/icons/icoSom.png';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import { Container, AreaIcon, AreaInput, ContentBallon, InfoCamera, Group, AreaButtons, AreaTable, Table, Inputs, Button } from './styles';
import { validarPermissao } from '../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsParametros } from '../../../Balloons';
import { tocarAudioCamera } from '../../../Audio';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Camera(props) {

    const [modelo, setModelo] = useState('');
    const [fabricante, setFabricante] = useState('');
    const [camera, setCamera] = useState([]);
    const [enabledInput, setEnabledInput] = useState('enabled');
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');
    const [_id, set_id] = useState('');
    const [visibleBallonCamera, setVisibleBallonCamera] = useState(false);
    const [enabled, setEnabled] = useState('enabled');
    const { abrirAbaParametro } = useContext(ModalContext);

    
    const addItem = () => {
        setEnabledInput('desabled');
        setEnabled('disabled')
        set_id('');
        
    }

    const lerDados = async () => {
        const response = await api.get('centraldefalhas/modelo/camera');
        setCamera(response.data);
    }

    const deleteItem = async (modelo) => {
        await api.delete(`centraldefalhas/modelo/camera/${modelo}`)
        .then(() =>{
            setMsg('O nome excluido com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');
            lerDados();

            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            },5000);

        }).catch(() => {
            setMsg('O nome não foi excluido!');
            setAlertMsg('block');
            setTypeAlert('warning');
        });
    }

    const saveData = async () => {
        if ([fabricante, modelo].includes('')) {
            setAlertMsg('block');
            setTypeAlert('warning');
            setMsg('Preencha os campos nome e fases!');

            /*setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            },5000);*/
            
        } else {
            await api.post('centraldefalhas/modelo/camera', {
                "fabricante": fabricante.toLocaleLowerCase(),
                "modelo": modelo.toLocaleLowerCase(),
                "_id": _id
            }).then((res) =>{

                setAlertMsg('block');
                setMsg('Salvo com sucesso!');
                setTypeAlert('success');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

                lerDados();
                setFabricante('');
                setModelo('');
                
            }).catch((err) =>{
                setAlertMsg('block');
                setMsg(err.response.data.mensagem);
                setTypeAlert('error');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

            });
        }
    }

    //Usado para verificar se mudou de aba
    //e entao limpar os campos
    useEffect(() => {
        lerDados();
        setEnabledInput('enabled');
        setFabricante('');
        setCamera([]);
        //limpar campos
    }, [props.positionAba]);

    const listInputData = (item) => {
        setModelo(item.modelo);
        setFabricante(item.fabricante);
        setEnabledInput('desabled');
        set_id(item._id);
    }

    const closePopUp = () => {
        if(visibleBallonCamera){
            setVisibleBallonCamera(false);
        }else{
            setVisibleBallonCamera(true);
        }
    }

    const ContentBallonCamera = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Câmera</h3>
                    <label onClick={tocarAudioCamera}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Deve-se especificar o modelo e fabricante da câmera a ser implementada.
                </span>
                <div className="areaButton">
                    <button onClick={() => closePopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <Container style={{ display: props.status }}>
            <AreaIcon>
                <div className='icon' onClick={closePopUp}>
                    <GrCircleQuestion />
                </div>
            </AreaIcon>
            <InfoCamera view={visibleBallonCamera}>
                <BallonsParametros>
                    <ContentBallonCamera />
                </BallonsParametros>
            </InfoCamera>
            <AreaInput>
                <Inputs id={abrirAbaParametro === 'camera' ? 'desabled' : enabled} className={enabledInput}>{/* caso abrirAbaParametro (setado na aba de camera em Equipamentos) então abrir habilitado, caso não então desabilitado*/}
                    <Group>
                        <label htmlFor="">Fabricante:</label>
                        <input type="text" placeholder="" value={fabricante} onChange={(e) => setFabricante(e.target.value.toLocaleLowerCase())} />
                    </Group>
                    <Group>
                        <label htmlFor="">Modelo:</label>
                        <input type="text" placeholder="" value={modelo} onChange={(e) => setModelo(e.target.value)} />
                    </Group>
                </Inputs>
                <AreaButtons message={alertMsg} className={permissao}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{msg}</Alert>
                    </div>
                    <Button id="btnNovo" onClick={addItem}>
                        <BtnNovo />Novo
                    </Button>
                    <Button className="btnSalvar" id={abrirAbaParametro === 'camera' ? 'desabled' : enabled} onClick={saveData}>{/* caso abrirAbaParametro (setado na aba de camera de Equipamentos) então abrir habilitado, caso não então desabilitado*/}
                        Salvar
                    </Button>
                </AreaButtons>
            </AreaInput>
            <AreaTable>
                <Table>
                    <tr>
                        <th>Modelo</th>
                        <th>Fabricante</th>
                        <th>Excluir</th>
                    </tr>
                    {camera.map(item => (
                        <tr key={item.id} onClick={permissao === 'disabled' ? '' : () => listInputData(item)}>
                            <td>{item.modelo}</td>
                            <td>{item.fabricante}</td>
                            <td><img src={excluir} alt="" className={permissao} onClick={() => deleteItem(item.modelo)} /></td>
                        </tr>
                    ))}
                </Table>
            </AreaTable>
        </Container>
    );
}