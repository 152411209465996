import React from "react";
import { Chart } from "react-google-charts";

  function ChartLine(props) {

    return(
      <Chart
        width={'100%'}
        height={'245px'}
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            'Hora',
            'Bolivia',
            'Ecuador',
          ],
          ['12:30', 165, 200],
          ['12:30', 135, 126],
          ['12:30', 157, 307],
          ['12:30', 139, 168],
          ['12:30', 136, 102],
        ]}
        options={{
          title: 'MÉDIA DE OCUPAÇÂO',
          backgroundColor:'#fafaf4',
          vAxis: { title: 'Cups' },
          hAxis: { title: 'Month' },
          seriesType: 'bars',
          series: { 1: { type: 'line' } },
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    )
  }

  export default ChartLine;