import styled from 'styled-components';

export const AreaPhotos = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding: 15px 15px 0 15px;
    overflow-y: scroll;
    height: 72vh;
    

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;
        cursor: pointer; 

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}
`;

export const Photo = styled.img`
    width: 100%;
    height: auto;
    user-select: none;
    cursor: pointer;
`;

export const Settings = styled.div`
    position: relative;
    height: auto;

    .menu{
        background: #040404;
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 99.6%;
        height: ${props => props.status + 'px'};
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        padding: 0px 12px;
        box-sizing: border-box;
        transition: .3s;
        opacity: 0.7;

        span{
            color: #FFF;
            font-family: 'roboto';
            font-size: 12px;
        }

        .options{
            display: flex;
            gap: 17px;
            margin-top: 4px;

            .optionZoom,.optionClose{
                cursor: pointer;

                svg{
                    &:active{
                        position: relative;
                        top: 1px;
                    }
                }
            }
        }

        #disabled{
            pointer-events: none;
            opacity: 0.4;
        }
        #enabled{
            pointer-events: auto;
            opacity: 1;
        }
    }
`;

export const CloseModal = styled.div`
    position: absolute;
    top: 151px;
    right: 430px;
    cursor: pointer;
    svg{

    }
`;

export const OverlayCarousel = styled.div`
    display: ${props => props.status};
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000b8;
    cursor: pointer;
    transition: opacity 1s ease-in-out;
`;

export const ContainerCarousel = styled.div`
    display: ${props => props.status};
    height: 560px;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 4px;
    padding: 0px 0 0 3.5%;
    box-sizing: border-box;
`;

export const AreaLoad = styled.div`
    margin: 130px auto;
    position: absolute;
    right: 5%;
`;

export const Load = styled.img`
    width: 40%;
    height: auto;
    user-select: none;
    position: relative;
    z-index: 1;
`;

export const Overlay = styled.div`
    display: ${props => props.open};
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000b8;
    cursor: pointer;
`;

export const ModalZoom = styled.div`
    display: ${props => props.open};
    width: 100%;
    height: auto;
    background: #FFF;
    position: absolute;
    border-radius: 4px;
    margin-left: 25%;
    margin-right: 25%;
    @media(max-width: 1920px){
        top: -35px;
    }
    @media(max-width: 1280px){
        top: -90px;
    }
    @media(max-width: 1500px){
        top: -100px;
    }
`;