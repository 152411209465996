import styled from 'styled-components';

export const MenuHeader = styled.div`
    .areaMensagem{
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .alert{
            display: ${props => props.visible === 'hidden' ? 'none' : 'block'};
            margin-left: 20px;

            @media(max-width: 420px){
                margin: 14px 0 0 157px;
                width: 85vw;
            }
        }

        @media(max-width: 920px){
            gap: 8px;
            margin-top: 8px;
            flex-direction: column-reverse;
            margin-top: -34px;
            margin-left: -119px;
        }

        @media(max-width: 420px){
            margin-left: -163px;
        }

    }
`;

export const AreaInputs = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;

    @media(max-width: 420px){
        margin-right: 3%;
    }

    .areaButtons {
        display: flex;

        @media(max-width: 920px){
            flex-direction: column;
            align-items: flex-start;
        }

        .inputsData{
            display: flex;
            align-items: center;

            @media(max-width: 420px){
                justify-content: center;
            }

            input[type="datetime-local"],input[type="number"]{
                margin: 0 7px;
                padding: 2px;
                height: 25px;
                width: 180px;
                border-radius: 4px;
                border: 1px solid #001E62;
                text-indent: 3px;

                @media(max-width: 420px){
                    width: 156px;
                    font-size: 11px;
                }
                @media(max-width: 360px){
                    width: 140px;
                }
            }
        }
    }

    .buttonPages{
        display: flex;
        align-items: center;
        justify-content: center;

        input[type="number"]{
            margin: 0 7px;
            padding: 2px;
            height: 25px;
            width: 60%;
            border-radius: 4px;
            border: 1px solid #001E62;
            text-indent: 3px;

            @media(max-width: 420px){
               width: 60%;  
            }
        }

        label{
            display: flex;
            flex-direction: column;
            font-size: 11px;
            font-family: 'roboto';
            text-align: center;
            align-items: center;
            margin-top: -14px;

            @media(max-width: 920px){
                align-items: flex-start;
                margin-top: 1px;
            }
            @media(max-width: 420px){
                margin-top:8px;
            }
        }

        .btnCarregar{
            z-index: 1;
            border: 0;
            width: 100px;
            height: 30px;
            padding: 6px;
            box-sizing: border-box;
            border-radius: 6px;
            background: #001E62;
            color: #FFF;
            margin-left: 10px;
            cursor: pointer;
            border: 1px solid #001E62;

            @media(max-width: 920px){
                margin-top: 12px;
                margin-left: -43px;
            }

            @media(max-width: 420px){
                margin-top: 22px;
            }
        }
        .btnCarregar:hover{
            background: #FFF;
            border: 1px solid #001E62;
            color: #001E62;
            transition: .3s;
        }
        .btnCarregar:active{
            position:relative;
            top: 1px;
        }

        @media(max-width: 1365px){
            width: 50%;
        }
        @media(max-width: 1200px){
            width: 75%;
        }
        @media(max-width: 1122px){
            width: 75%;
        }
    }

    @media(max-width: 1122px){
        flex-direction: column;

        .buttonPages{
            width: 43%;
            justify-content: space-around;
        }
    }

    @media(max-width: 920px){
        .buttonPages{
            width: 65%;
            z-index: 2; 
        }
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`
export const AreaButtons = styled.div`
    display: flex;
    margin-left: 20px;

    @media(max-width: 920px){
        width: 100%;
        justify-content: flex-end;
    }

    @media(max-width: 820px){
        margin: -20px 0 20px 0;
    }

    @media(max-width: 420px){
        margin: -33px 0 22px 0;
    }
`;

export const Button = styled.button`
    border: 1px solid #001E62;
    background: transparent;
    width: 50px;
    height: 35px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;

    &:hover{
        background: #001E62;
        transition: .5s;

        svg path{
            fill: #001E62; 
            stroke: #FFF;
        }
    }

    svg {
        width: 21px;
        height: 20px;
    }
`;


export const Container = styled.div`
    width: 95%;
    margin: 25px auto;
    margin-left: 4%;
    height: 470px;
`;

export const AreaTable = styled.div`
    overflow-x: hidden;
    overflow-y: scroll;
    height: 475px;

    @media(max-width: 1366px){
        overflow-x: scroll;
        overflow-y: scroll;
        height: 500px;
    }

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;


		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

    @media(max-width: 1400px){
        height: 362px;
    }
    @media(max-width: 1280px){
        height: 315px;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-family: 'roboto';
    font-size: 13px;

    @media(max-width: 420px){
        width: 1400px;
    }

    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }

    thead {
        height: 30px;
        text-indent: 12px;

        tr{
            &:nth-child(even){background-color: #f2f2f2;}
            &:hover {background-color: #ddd;}
        }
        th {
            text-align: left;
            background-color: #001E62;
            color: white;
            position: relative;
            border-right: 1px solid #FFF;

            &:first-child{border-radius: 5px 0 0 0}
            &:last-of-type{border-radius: 0 5px 0 0; border: none;} //width: 25px; padding-right: 12px;
        }
    }

    tbody{
        td{
            width: 1px;
            white-space: nowrap;
        }
        td, th {
            border: 1px solid #ddd; 
            padding: 8px 10px;
            width: 100px;

        }

        tr{
            /*td:nth-child(7){
                text-align: center;
                svg{
                    cursor: pointer;
                }
            }*/
        }
    }
`;

export const Filter = styled.div`
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 10px;

    svg{
        fill: ${props => props.status === 'Filtro limpo' ? 'transparent' : props.status === 'Dados Filtrados' ? '#0c9410' : ''};
        stroke:${props => props.status === 'Filtro limpo' ? '#FFF' : props.status === 'Dados Filtrados' ? '#0c9410' : ''};
        &:hover{
            fill: #f2f2f2;
        }
    }
`;

export const AreaLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${props => props.status} !important;
    
    img{
        width: 20%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
`;

export const AreaPagination = styled.div`
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;

    nav.MuiPagination-root {
        margin-right: 4%;
    }
`;

