import React from 'react';

export default function IconRelatorio(){
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6667 1.66602H5C4.55797 1.66602 4.13405 1.84161 3.82149 2.15417C3.50893 2.46673 3.33334 2.89065 3.33334 3.33268V16.666C3.33334 17.108 3.50893 17.532 3.82149 17.8445C4.13405 18.1571 4.55797 18.3327 5 18.3327H15C15.442 18.3327 15.866 18.1571 16.1785 17.8445C16.4911 17.532 16.6667 17.108 16.6667 16.666V6.66602L11.6667 1.66602Z" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.6667 1.66602V6.66602H16.6667" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3333 10.834H6.66666" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.3333 14.166H6.66666" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.33333 7.5H7.5H6.66666" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}