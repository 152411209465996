import React from 'react';

export default function IconAutenticado(){
    return(
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M14.25 6.92467V7.49967C14.2492 8.84744 13.8128 10.1588 13.0058 11.2383C12.1989 12.3178 11.0646 13.1075 9.7721 13.4896C8.47964 13.8717 7.09829 13.8259 5.83404 13.3588C4.5698 12.8917 3.49041 12.0285 2.75685 10.8978C2.02329 9.76719 1.67487 8.42971 1.76355 7.08487C1.85223 5.74002 2.37325 4.45988 3.24892 3.43534C4.1246 2.41081 5.30799 1.69679 6.62262 1.39976C7.93725 1.10274 9.31267 1.23863 10.5438 1.78717" stroke="#328C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.25 2.5L8 8.75625L6.125 6.88125" stroke="#328C2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white" transform="translate(0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}