import styled from 'styled-components';

export const Container = styled.section`
    //overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
    margin-top: 50px;
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        &-track-piece {
            background-color: #FFF;
        }
        &-thumb:vertical,
        &-thumb:horizontal {
            background-color: #001E62;
            border-radius:5px;
        }
        &-thumb:vertical:hover,
        &-thumb:horizontal:hover {
            background-color: #269F2A;
        }
    }
    
    h1{
        font-family: 'Roboto',sans-serif;
        margin-left: 42px;
        font-size: 18px;
        font-weight: 900;
        user-select: none; 
        margin: 5px 0 5px 17px;
    }
    .alert{
        margin-left: 4%;
        display: ${props => props.view === 'none' ? 'none' : 'block'};
     
        @media(max-width: 420px){
            margin-left: 1%;
            margin-bottom: -12px;
            width: 98%;
        }
    }
    @media(max-width: 420px){
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            &-track-piece {
                background-color: #FFF;
            }
            &-thumb:vertical,
            &-thumb:horizontal {
                background-color: #001E62;
                border-radius:5px;
            }
            &-thumb:vertical:hover,
            &-thumb:horizontal:hover {
                background-color: #e2d4d4;
            }
        }
    }
`;

export const AreaMenu = styled.section`
    display: flex;
    align-items: center;
    //justify-content: center;
    gap: 1%;
    margin-left: -55px;
    @media(max-width: 1270px){
        flex-direction: column-reverse;
    }
    @media(max-width: 580px){
        margin-left: 0;
    }
`;

export const ColButtons = styled.div`
    display: flex;
    gap: 10px;
    width: 84%;
    display: flex;
    justify-content: space-between;
    @media(max-width: 420px){
        width: 93%;
    }
    @media(max-width: 413px){
        width: 86%;
    }
    @media(max-width: 411px){
        width: 93%;
    }
    @media(max-width: 360px){
        width: 94%;
    }
`;


export const Tabs = styled.ul`
    position: relative;
    
    ul{
        display: flex;
        &:before {
            content: '';
            width: 115px;
            height: 3px;
            background: #062467;
            position: absolute;
            bottom: -5px;
            left: ${props => props.position + 'px'};
            transition: .3s ease-out;
            @media(max-width: 565px){
                width: 95px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '180px' : 
                    props.position + 'px' === '322px' ? '280px' :
                    props.position + 'px' === '442px' ? '383px' :
                    props.position + 'px' === '562px' ? '479px' : ''
                };
            }
            @media(max-width: 413px){
                width: 70px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '150px' : 
                    props.position + 'px' === '322px' ? '224px' :
                    props.position + 'px' === '442px' ? '293px' :
                    props.position + 'px' === '562px' ? '363px' : ''
                }; 
            }
            @media(max-width: 411px){   
                width: 70px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '149px' : 
                    props.position + 'px' === '322px' ? '220px' :
                    props.position + 'px' === '442px' ? '292px' :
                    props.position + 'px' === '562px' ? '362px' : ''
                }; 
            }
            @media(max-width: 393px){   
                width: 63px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '147px' : 
                    props.position + 'px' === '322px' ? '215px' :
                    props.position + 'px' === '442px' ? '283px' :
                    props.position + 'px' === '562px' ? '349px' : ''
                }; 
            }
            @media(max-width: 375px){   
                width: 60px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '144px' : 
                    props.position + 'px' === '322px' ? '207px' :
                    props.position + 'px' === '442px' ? '272px' :
                    props.position + 'px' === '562px' ? '335px' : ''
                }; 
            }
            @media(max-width: 360px){   
                width: 59px;
                left: ${props => 
                    props.position + 'px' === '200px' ? '147px' : 
                    props.position + 'px' === '322px' ? '214px' :
                    props.position + 'px' === '442px' ? '282px' :
                    props.position + 'px' === '562px' ? '350px' : ''
                }; 
            }
        }
    }
    
    li{
        list-style: none;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        color: #5f5d5d;
        height: 23px;
        font-size: 14px;
        user-select: none;
        @media(max-width: 360px){
            font-size: 13px;
        }
        &#inactive{
            font-weight: normal;
        }
        &#active{
            transition: .3s ease-out;
            color: #001E62;
            font-weight: 900;
            letter-spacing: .5px;
        }
        @media(max-width: 565px){
            width: 100px;
        }
        @media(max-width: 411px){
            width: 82px;
            margin-left: -1%;
        }
        @media(max-width: 360px){
            width: 75px;
            margin-left: -2%;
        }
    }
    @media(max-width: 768px){
        left: -5%;
    }
    @media(max-width: 413px){
        left: -9%;
        width: 96%;
    }
    @media(max-width: 360px){
        left: -10%;
        width: 104%;
    }
`;

export const AreaFilterDate = styled.div`
    display: flex;
    gap: 5px;
    fieldset {
        display: flex;
        font-family: 'roboto',sans-serif;
        font-weight: lighter;
        font-size: 11px;
        width: 300px;
        gap: 6px;
        justify-content: space-around;
        align-items: center;
        height: 55px;
        border: .5px solid #b1b1b1;
        border-radius: 3px;
        input{
            outline: none;
        }
        .borderInput{
            position: relative;
            
            input[type="datetime-local"] {
                border: 1px solid #b1b1b1;
                color: #545454;
                border-radius: 3px;
                height: 29px;
                width: 95%;
                text-indent: 3px;
                box-sizing: border-box;
                background: #FFF;
                font-size: 12px;
                
                &:focus{
                    border: 2px solid #062467;
                }
                @media(max-width: 635px){
                    width: 95%;
                }
                @media(max-width: 420px){
                    width: 97%;
                    font-size: 12px;
                }
                @media(max-width: 413px){
                    width: 95%;
                }
                @media(max-width: 390px){
                    width: 99%;
                    font-size: 10px;
                }
                
            }
            label{
                position: absolute;
                top: -6px;
                left: 8px;
                background: #FFF;
                color: #867c7c;
                font-size: 10px;
                font-weight: 500;
            }
            #number{
                width: 60px;
                border: 1px solid #b1b1b1;
                color: #545454;
                border-radius: 3px;
                height: 22px;
                text-indent: 3px;
                                
                &:focus{
                    border: 2px solid #062467;
                }
                @media(max-width: 635px){
                    width: 100%;
                }
                @media(max-width: 420px){
                    margin-top: -8px;
                }
            }
            @media(max-width: 635px){
                position: relative;
                width: 95%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @media(max-width: 420px){
                margin-top: 5px;
            }
        }
        &.numberLines{
            width: 95px;
            @media(max-width: 635px){
                width: 80%;
            }
        }
        @media(max-width: 635px){
            width: 80%;
        }
        @media(max-width: 390px){
            width: 87%;
        }
    }
    legend{
        color: #5f5d5d;
        font-weight: 400;
    }
    @media(max-width: 635px){
        flex-direction: column;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media(max-width: 393px){ //Xiaomi Redmi Note 8 Pro
       width: 105%;
    }
    
`;

export const AreaTabela = styled.section`
    margin-top: 15px;
`;


export const AreaButton = styled.div`
    margin-right: 4%;
    margin-top: 35px;
    display: flex;
    gap: 15px;
    button{
        border: 0;
        width: 115px;
        height: 32px;
        border-radius: 6px;
        background: #062467;
        color: #FFF;
        cursor: pointer;

        @media(max-width: 1280px){
            width: 75px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
        &:hover{
            background: #FFF;
            border: 1px solid #062467;
            color: #062467;
            transition: .3s;
        }
    }

    @media(max-width: 1280px){
        margin-top: 0;
        margin-right: 4%;
        display: flex;
        gap: 7px;
        flex-direction: column-reverse;
    }
`;

export const Export = styled.div`
    border: 1px solid #062467;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 40px;
    cursor: pointer;
    &:active{
        position: relative;
        top: 1px;
    }
    &:hover{
        background: #062467;
        border: 1px solid #062467;
        transition: .3s;
        svg{
            path{
                stroke: #FFF;
            }
        }
    }

    @media(max-width: 1280px){
        width: 73px;
        height: 26px;
    }
`;

export const AreaPagination = styled.div`
    margin-bottom: 50px;
    display: flex;
    justify-content: flex-end;
    nav.MuiPagination-root {
        margin-right: 4%;
        margin-bottom: 15px;
    }
`;