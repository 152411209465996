import React, { useState, useEffect, useContext } from 'react';
import { Container, AreaSwitch, Switch, AreaInputs, Group, AreaSignal, AreaButtons, InfoHelp, AreaBallon, ContentBallon } from './styles';
import { ModalContext } from '../../../../contextModal';
import editInfo from '../../../../assets/editInfo.svg';
import api from '../../../../services/api';
import Alert from '@material-ui/lab/Alert';
import icoSemSinal from '../../../../assets/icoSinais/sem sinal.png';
import icoSinalMuitoFraco from '../../../../assets/icoSinais/sinal muito fraco.png';
import icoSinalFraco from '../../../../assets/icoSinais/sinal fraco.png';
import icoSinalRegular from '../../../../assets/icoSinais/sinal regular.png';
import icoSinalBom from '../../../../assets/icoSinais/sinal bom.png';
import icoSinalExcelente from '../../../../assets/icoSinais/sinal excelente.png';
import icoSemPotencia from '../../../../assets/icoSinais/sem potencia.png';
import icoPotenciaMuitoFraca from '../../../../assets/icoSinais/potencia muito fraca.png';
import icoPotenciaFraca from '../../../../assets/icoSinais/potencia fraca.png';
import icoPotenciaRegular from '../../../../assets/icoSinais/potencia regular.png';
import icoPotenciaBoa from '../../../../assets/icoSinais/potencia bom.png';
import icoPotenciaExelente from '../../../../assets/icoSinais/potencia excelente.png';
import loader from '../../../../assets/icoSinais/loader.gif';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../Balloons';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import { tocarVozInfoChip } from '../../../Audio';

export default function Chip(props) {

    const [info, setInfo] = useState('');
    const [visibleAlert, setVisibleAlert] = useState('none');
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [logs, setLogs] = useState(false);
    const { setAbaEquipamento } = useContext(ModalContext);
    const { setIdGlobal } = useContext(ModalContext);
    const [dados, setDados] = useState(
        {
            chip1: {
                consorcio: '',
                iccid: '',
                id_cruzamento: '',
                instaladora: '',
                mantenedora: '',
                operadora: '',
                plano: '',
                posicao: '',
                telefone: '',
                townhall: ''
            },
            chip2: {
                consorcio: '',
                iccid: '',
                id_cruzamento: '',
                instaladora: '',
                mantenedora: '',
                operadora: '',
                plano: '',
                posicao: '',
                telefone: '',
                townhall: ''
            }
        }
    );

    const lerDados = async () => {
        try {
            const response = await api.get(`/centraldefalhas/chip/${props.id}`);
            setDados(response.data);
            setInfo('');
        } catch (error) {
            setInfo('Equipamento não cadastrado')
            setVisibleAlert('flex');
        }
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonChip = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Local</h3>
                    <label onClick={tocarVozInfoChip}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nesta opção estão listadas todas as informações referentes ao chip do ponto escolhido,
                    um controlador pode ter até 2 chips inseridos.
                    Na parte superior esquerdo, o icone demonstra a potencia do sinal 4G.
                    Na parte superior direito, o icone demonstra a força do sinal em decibel miliwatts.
                    Também é listado todas as informações referentes ao chip, para realizar qualquer alteração
                    nos dados de chip, clique no botão abaixo "Editar".
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    useEffect(() => {
        lerDados();
    }, []);

    useEffect(() => {
        lerDados();
    }, [props.status]);


    const SinalChip1 = () => {
        if (props.id === dados.chip1.id_cruzamento) {

            if (props.sinal.slot === '0' || props.sinal.slot === undefined) {
                return (
                    <AreaSignal>
                        <div className="sinal">
                            <img
                                src={
                                    props.sinal.length === 0 ? loader :
                                        props.sinal.CSQ_Wifi === 'Sem informação' ? icoSemSinal :
                                            props.sinal.CSQ_Wifi === '99' ? icoSemSinal : // sem informação
                                                props.sinal.CSQ_Wifi === '5' || props.sinal.CSQ_Wifi === '6' || props.sinal.CSQ_Wifi === '7' ? icoSinalMuitoFraco :
                                                    props.sinal.CSQ_Wifi === '4' ? icoSinalFraco :
                                                        props.sinal.CSQ_Wifi === '3' ? icoSinalRegular :
                                                            props.sinal.CSQ_Wifi === '2' ? icoSinalBom :
                                                                props.sinal.CSQ_Wifi === '1' || props.sinal.CSQ_Wifi === '0' ? icoSinalExcelente : icoSemSinal

                                }
                                alt="sinal"
                            />
                            <span className="tipoSinal">
                                {
                                    props.sinal.length === 0 ? '' :
                                        props.sinal.Cops_rede === 'Sem informação' ? '' :
                                            props.sinal.Cops_rede === '0' ? '2G' :
                                                props.sinal.Cops_rede === '1' ? '2G' :
                                                    props.sinal.Cops_rede === '2' ? '2G' :
                                                        props.sinal.Cops_rede === '3' ? '2G' :
                                                            props.sinal.Cops_rede === '4' ? '3G' :
                                                                props.sinal.Cops_rede === '5' ? '3G' :
                                                                    props.sinal.Cops_rede === '6' ? '3G' :
                                                                        props.sinal.Cops_rede === '7' ? '4G' : ''
                                }
                            </span>
                        </div>
                        <div className="status">
                            <span>
                                {
                                    props.sinal.length === 0 ? 'Carregando...' :
                                        props.sinal.CSQ_Wifi === 'Sem informação' ? 'Sem informação' :
                                            props.sinal.CSQ_Wifi === '99' ? 'Sem informação' :
                                                props.sinal.CSQ_Wifi === '5' || props.sinal.CSQ_Wifi === '6' || props.sinal.CSQ_Wifi === '7' ? 'Sinal muito fraco' :
                                                    props.sinal.CSQ_Wifi === '4' ? 'Sinal fraco' :
                                                        props.sinal.CSQ_Wifi === '3' ? 'Sinal regular' :
                                                            props.sinal.CSQ_Wifi === '2' ? 'Sinal bom' :
                                                                props.sinal.CSQ_Wifi === '1' || props.sinal.CSQ_Wifi === '0' ? 'Sinal excelente' : ''
                                }
                            </span>
                        </div>

                        <div className="potencia">
                            <img
                                src={
                                    props.sinal.length === 0 ? loader :
                                        props.sinal.Csq_sinal === 'Sem informação' ? icoSemPotencia :
                                            props.sinal.Csq_sinal === 'Sem resposta de sinal' ? icoSemPotencia :
                                                parseInt(props.sinal.Csq_sinal) >= 99 ? icoSemPotencia :
                                                    parseInt(props.sinal.Csq_sinal) >= -109 && props.sinal.Csq_sinal <= -100 ? icoPotenciaMuitoFraca :
                                                        parseInt(props.sinal.Csq_sinal) >= -99 && parseInt(props.sinal.Csq_sinal) <= -92 ? icoPotenciaFraca :
                                                            parseInt(props.sinal.Csq_sinal) >= -91 && parseInt(props.sinal.Csq_sinal) <= -82 ? icoPotenciaRegular :
                                                                parseInt(props.sinal.Csq_sinal) >= -81 && parseInt(props.sinal.Csq_sinal) <= -70 ? icoPotenciaBoa :
                                                                    parseInt(props.sinal.Csq_sinal) >= -69 ? icoPotenciaExelente : icoSemPotencia
                                }
                                alt="potencia"
                            />
                        </div>
                        <div className="status">
                            <span>
                                {
                                    props.sinal.length === 0 ? 'Carregando...' :
                                        props.sinal.Cops_rede === "Sem informação" ? 'Sem informação' :
                                            props.sinal.Cops_rede === "Sem resposta de sinal" ? 'Sem informação' : `Força do sinal: ${props.sinal.Csq_sinal} dBm` //
                                }
                            </span>
                        </div>
                    </AreaSignal>
                );
            } else {
                return (
                    <AreaSignal>
                        <div className="sinal">
                            <img src={icoSemSinal} alt="sinal" />
                            <span className="tipoSinal" style={{ display: 'none' }}>3G</span> {/*aguardadando requisição */}
                        </div>
                        <div className="status">
                            <span>Sem informação</span>
                        </div>
                        <div className="potencia">
                            <img src={icoSemPotencia} alt="potencia" />
                        </div>
                        <div className="status">
                            <span>Sem informação</span>
                        </div>
                    </AreaSignal>
                );
            }
        } else {
            return null
        }
    }

    const SinalChip2 = () => {
        if (props.id === dados.chip2.id_cruzamento) {
            if (props.sinal.slot === '1' || props.sinal.slot === undefined) {
                return (
                    <AreaSignal>
                        <div className="sinal">
                            <img
                                src={
                                    props.sinal.length === 0 ? loader :
                                        props.sinal.CSQ_Wifi === 'Sem informação' ? icoSemSinal :
                                            props.sinal.CSQ_Wifi === '99' ? icoSemSinal : // sem informação
                                                props.sinal.CSQ_Wifi === '5' || props.sinal.CSQ_Wifi === '6' || props.sinal.CSQ_Wifi === '7' ? icoSinalMuitoFraco :
                                                    props.sinal.CSQ_Wifi === '4' ? icoSinalFraco :
                                                        props.sinal.CSQ_Wifi === '3' ? icoSinalRegular :
                                                            props.sinal.CSQ_Wifi === '2' ? icoSinalBom :
                                                                props.sinal.CSQ_Wifi === '1' || props.sinal.CSQ_Wifi === '0' ? icoSinalExcelente : icoSemSinal
                                }
                                alt="sinal"
                            />
                            <span className="tipoSinal">
                                {
                                    props.sinal.length === 0 ? '' :
                                        props.sinal.Cops_rede === 'Sem informação' ? '' :
                                            props.sinal.Cops_rede === '0' ? '2G' :
                                                props.sinal.Cops_rede === '1' ? '2G' :
                                                    props.sinal.Cops_rede === '2' ? '2G' :
                                                        props.sinal.Cops_rede === '3' ? '2G' :
                                                            props.sinal.Cops_rede === '4' ? '3G' :
                                                                props.sinal.Cops_rede === '5' ? '3G' :
                                                                    props.sinal.Cops_rede === '6' ? '3G' :
                                                                        props.sinal.Cops_rede === '7' ? '4G' : ''
                                }
                            </span>
                        </div>
                        <div className="status">
                            <span>
                                {
                                    props.sinal.length === 0 ? 'Carregando...' :
                                        props.sinal.CSQ_Wifi === 'Sem informação' ? 'Sem informação' :
                                            props.sinal.CSQ_Wifi === '99' ? 'Sem informação' :
                                                props.sinal.CSQ_Wifi === '5' || props.sinal.CSQ_Wifi === '6' || props.sinal.CSQ_Wifi === '7' ? 'Sinal muito fraco' :
                                                    props.sinal.CSQ_Wifi === '4' ? 'Sinal fraco' :
                                                        props.sinal.CSQ_Wifi === '3' ? 'Sinal regular' :
                                                            props.sinal.CSQ_Wifi === '2' ? 'Sinal bom' :
                                                                props.sinal.CSQ_Wifi === '1' || props.sinal.CSQ_Wifi === '0' ? 'Sinal excelente' : ''
                                }
                            </span>
                        </div>

                        <div className="potencia">
                            <img
                                src={
                                    props.sinal.length === 0 ? loader :
                                        props.sinal.Csq_sinal === 'Sem informação' ? icoSemPotencia :
                                            props.sinal.Csq_sinal === 'Sem resposta de sinal' ? icoSemPotencia :
                                                parseInt(props.sinal.Csq_sinal) >= 99 ? icoSemPotencia :
                                                    parseInt(props.sinal.Csq_sinal) >= -109 && props.sinal.Csq_sinal <= -100 ? icoPotenciaMuitoFraca :
                                                        parseInt(props.sinal.Csq_sinal) >= -99 && parseInt(props.sinal.Csq_sinal) <= -92 ? icoPotenciaFraca :
                                                            parseInt(props.sinal.Csq_sinal) >= -91 && parseInt(props.sinal.Csq_sinal) <= -82 ? icoPotenciaRegular :
                                                                parseInt(props.sinal.Csq_sinal) >= -81 && parseInt(props.sinal.Csq_sinal) <= -70 ? icoPotenciaBoa :
                                                                    parseInt(props.sinal.Csq_sinal) >= -69 ? icoPotenciaExelente : icoSemPotencia
                                }
                                alt="potencia"
                            />
                        </div>
                        <div className="status">
                            <span>
                                {
                                    props.sinal.length === 0 ? 'Carregando...' :
                                        props.sinal.Cops_rede === "Sem informação" ? 'Sem informação' :
                                            props.sinal.Cops_rede === "Sem resposta de sinal" ? 'Sem informação' : `Força do sinal: ${props.sinal.Csq_sinal} dBm` //
                                }
                            </span>
                        </div>
                    </AreaSignal>
                );
            } else {
                return (
                    <AreaSignal>
                        <div className="sinal">
                            <img src={icoSemSinal} alt="sinal" />
                            <span className="tipoSinal" style={{ display: 'none' }}>3G</span> {/*aguardadando requisição */}
                        </div>
                        <div className="status">
                            <span>Sem informação</span>
                        </div>
                        <div className="potencia">
                            <img src={icoSemPotencia} alt="potencia" />
                        </div>
                        <div className="status">
                            <span>Sem informação</span>
                        </div>
                    </AreaSignal>
                );
            }
        }
        else {
            return null
        }
    }

    const sendLog = async (boolean) => {
        await api.put(`/centraldefalhas/chip/estatisticas/logs`, {
            'id_cruzamento': props.id,
            'chipEstatistica': boolean
        }).then(res => {
            if (boolean) {
                alert(`Estatística de chip ativado com sucesso!`);
            } else {
                alert(`Estatística de chip desativado com sucesso!`);
            }
        }).catch(err => {
            alert(`Erro ao enviar Log!`)
        });
    }

    const handleSwitch = () => {
        if (logs) {
            setLogs(false);
            sendLog(false);
        } else {
            setLogs(true);
            sendLog(true);
        }
    }

    return (
        <Container style={{ display: props.status }} visible={visibleAlert}>
            <AreaSwitch style={{ display: 'none' }}> {/*display none temporariamente*/}
                <label>Ativar Logs de chip</label>
                <Switch>
                    <input type="checkbox" className="switch-input" value={logs} onChange={(e) => handleSwitch(e.target.value)} checked={logs} />
                    <span className="switch-label" data-on="On" data-off="Off"></span>
                    <span className="switch-handle"></span>
                </Switch>
            </AreaSwitch>
            <Alert severity="error" className="alert">{info}</Alert>
            <fieldset>
                <legend>CHIP 1</legend>
                <AreaInputs>
                    <InfoHelp onClick={() => openPopUp()}>
                        <GrCircleQuestion size={15} />
                    </InfoHelp>
                    <AreaBallon visible={visibleBalloon}>
                        <BallonsInfoModal><ContentPopBallonChip /></BallonsInfoModal>
                    </AreaBallon>

                    <SinalChip1 />

                    <div className='groupone'>
                        <Group>
                            <label htmlFor="">Consórcio:</label>
                            <input type="text" value={dados.chip1.consorcio} disabled />
                        </Group>
                        <Group>
                            <label htmlFor="">Instaladora:</label>
                            <input type="text" value={dados.chip1.instaladora} disabled />
                        </Group>
                    </div>
                    <div>
                        <label htmlFor="">Mantenedora:</label>
                        <input type="text" value={dados.chip1.mantenedora} disabled />
                    </div>
                </AreaInputs>
                <AreaInputs>
                    <div className='groupone'>
                        <Group>
                            <label htmlFor="">ICCID:</label>
                            <input type="text" value={dados.chip1.iccid} disabled />
                        </Group>
                        <Group>
                            <label htmlFor="">Número:</label>
                            <input type="text" value={dados.chip1.telefone} disabled />
                        </Group>
                    </div>
                    <div className='groupone'>
                        <Group>
                            <label htmlFor="">Operadora:</label>
                            <input type="text" value={dados.chip1.operadora} disabled />
                        </Group>
                        <Group>
                            <label htmlFor="">Plano:</label>
                            <input type="text" value={dados.chip1.plano} disabled />
                        </Group>
                    </div>
                </AreaInputs>
            </fieldset>

            <fieldset>
                <legend>CHIP 2</legend>

                <SinalChip2 />

                <AreaInputs>
                    <div className='groupone'>
                        <div>
                            <label htmlFor="">Consórcio:</label>
                            <input type="text" value={dados.chip2.consorcio} disabled />

                        </div>
                        <div>
                            <label htmlFor="">Instaladora:</label>
                            <input type="text" value={dados.chip2.instaladora} disabled />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="">Mantenedora:</label>
                        <input type="text" value={dados.chip2.mantenedora} disabled />
                    </div>
                </AreaInputs>
                <AreaInputs>
                    <div className='groupone'>
                        <Group>
                            <label htmlFor="">ICCID:</label>
                            <input type="text" value={dados.chip2.iccid} disabled />
                        </Group>
                        <Group>
                            <label htmlFor="">Número:</label>
                            <input type="text" value={dados.chip2.telefone} disabled />
                        </Group>
                    </div>
                    <div className='groupone'>
                        <Group>
                            <label htmlFor="">Operadora:</label>
                            <input type="text" value={dados.chip2.operadora} disabled />
                        </Group>
                        <Group>
                            <label htmlFor="">Plano:</label>
                            <input type="text" value={dados.chip2.plano} disabled />
                        </Group>
                    </div>
                </AreaInputs>
            </fieldset>

            <AreaButtons>
                <div className="btnEditInfo" onClick={() => {
                    props.openEquipamento(true); setAbaEquipamento(2); setIdGlobal(props.id);
                    props.closeModal(false)
                }} title="Editar">
                    <img src={editInfo} alt="edit" />
                </div>
            </AreaButtons>
        </Container>
    );
}