import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartLeftToTop from '../../../img/Setas/arrowStartLeftToTop.png';
import arrowStopLeftToTop from '../../../img/Setas/arrowStopLeftToTop.png';
import arrowPauseLeftToTop from '../../../img/Setas/arrowPauseLeftToTop.png';
import animateArrowLeftToTop from '../../../img/Setas/animateArrowLeftToTop.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveLeftToTop, Options, ButtonSettings } from './styles';

export default function ArrowLeftToTop(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'À esquerda vira a direita', image: arrowStartLeftToTop, object: props.arrowLeftToTop, setObject: props.setArrowLeftToTop });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowLeftToTop.map((item, index) => {
                if (props.arrowLeftToTop[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowLeftToTop'
                            top={item.top}
                            left={item.left}
                            setArrowLeftToTop={props.setArrowLeftToTop}
                            arrowLeftToTop={props.arrowLeftToTop}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveLeftToTop
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartLeftToTop :
                                        item.color_group === 1 ? arrowStopLeftToTop :
                                            item.color_group === 2 ? arrowPauseLeftToTop :
                                                item.color_group === 4 ? animateArrowLeftToTop : ''
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowLeftToTop(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
