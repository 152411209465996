import React, { createContext, useState } from 'react';

export const ModalContext = createContext({});

export default function ModalContextProvider(props) {
    const [modalStatus, setModalStatus] = useState('');
    const [modalInfo, setModalInfo] = useState({ marginTop: '0', transition: '1s' });
    const [coordenadasClickLocal, setCoordenadasClickLocal] = useState('false');
    const [openQuickLog, setOpenQuickLog] = useState(false);
    const [inactiveTime, setInactiveTime] = useState('none');
    const [modalMove, setModalMove] = useState({ marginTop: '0', transition: '.5s' });
    const [cursor, setCursor] = useState({});
    const [somEnabled, setSomEnabled] = useState(true);
    const [idGlobal, setIdGlobal] = useState('');
    const [abaEquipamento, setAbaEquipamento] = useState(0);
    const [lengthRoutesFilter, setLengthRoutesFilter] = useState('');
    const [anelGlobal, setAnelGlobal] = useState('1');
    const [typeRequest, setTypeRequest] = useState('Controlador');
    const [permissionRequest, setPermissionRequest] = useState(false);
    const [statusFilterIdentificacao, setStatusFilterIdentificacao] = useState('Filtro limpo');
    const [statusFilterEquipamento, setStatusFilterEquipamento] = useState('Filtro limpo');
    const [statusFilterNivel, setStatusFilterNivel] = useState('Filtro limpo');
    const [statusFilterAcao, setStatusFilterAcao] = useState('Filtro limpo');
    const [statusFilterAgente, setStatusFilterAgente] = useState('Filtro limpo');
    const [statusFilterDescricao, setStatusFilterDescricao] = useState('Filtro limpo');
    const [abrirAbaParametro, setAbrirAbaParametro] = useState('consorcio');

    const [contentData, setContentData] = useState({
        dados: [],
        identificacao: [],
        equipamento: [],
        nivel: [],
        acao: [],
        pagina: '',
        quantidadeTotal: ''
    });

    return (
        <ModalContext.Provider
            value={{
                modalStatus, setModalStatus,
                modalInfo, setModalInfo,
                coordenadasClickLocal, setCoordenadasClickLocal,
                openQuickLog, setOpenQuickLog,
                inactiveTime, setInactiveTime,
                modalMove, setModalMove,
                cursor, setCursor,
                somEnabled, setSomEnabled,
                idGlobal, setIdGlobal,
                abaEquipamento, setAbaEquipamento,
                lengthRoutesFilter, setLengthRoutesFilter,
                anelGlobal, setAnelGlobal,
                typeRequest, setTypeRequest,
                contentData, setContentData,
                permissionRequest, setPermissionRequest,
                statusFilterIdentificacao, setStatusFilterIdentificacao,
                statusFilterEquipamento, setStatusFilterEquipamento,
                statusFilterNivel, setStatusFilterNivel,
                statusFilterAcao, setStatusFilterAcao,
                statusFilterAgente, setStatusFilterAgente,
                statusFilterDescricao, setStatusFilterDescricao,
                abrirAbaParametro, setAbrirAbaParametro
            }}>
            {props.children}
        </ModalContext.Provider>
    );
}

