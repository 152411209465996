import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import UpdateRoutes from '../UpdateInfos/UpdateRoutes';
import api from "../../services/api";
import LineTable from "../LineTable";
import Draggable from 'react-draggable';
import { Container, Close, GroupInput, AreaButtons, ButtonAdd } from './styles';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../services/permission';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

function InfoRoutes(props) {

    const [controllersRoute, setControllersRoute] = useState([]);
    const [openRegisterRoute, setOpenRegisterRoute] = useState(false);
    const value = props.value;

    const getInfoRoutes = async () => {
        api.post('centraldefalhas/routes/get', {
            "rota": value
        }).then(res => {
            if (typeof res.data.error != 'undefined') {
                return
            }
            if (res.status === 200) {
                setControllersRoute(res.data[0].infos.reverse());
            }
        }).catch(error => {
            console.log(error);
        })
    }

    //centraldefalhas/routes/idRemove'

    useEffect(() => {
        getInfoRoutes();
    }, [props.open, openRegisterRoute]);

    const deleteController = async (id, anel) => {

        if (window.confirm('Confirme para tirar o equipamento da rota')) {
            toast.info(`Aguarde...`, {
                position: "top-center",
                toastId: "deletedContRoute",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            await api.post('centraldefalhas/routes/idRemove', {
                "rota": value,
                "id_cruzamento": id,
                "anel": anel
            }).then(res => {
                ;
                if (res.data.status === "Sucess") {
                    toast.update("deletedContRoute", {
                        render: `Equipamento retirado da rota ${props.value}`,
                        type: toast.TYPE.SUCCESS,
                        position: "top-center",
                        autoClose: 4000,
                    });
                    return getInfoRoutes();
                } else {
                    toast.update("deletedContRoute", {
                        render: `Erro ao apagar equipamento da rota ${props.value} !`,
                        type: toast.TYPE.ERROR,
                        position: "top-center",
                        autoClose: 4000,
                    });
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }

    const editRoute = () => {
        setOpenRegisterRoute(true);
    }

    const handleCloseRegisterRoute = () => {
        setOpenRegisterRoute(false);
    }

    return (
        <>
            <Dialog
                key={props.key}
                open={props.open}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Informações da Rota"
                    onClick={props.onClose}
                ></PageTitle>
                <Container className={permissao}>
                    <Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
                    <fieldset>
                        <legend>Nome da Rota</legend>
                        <GroupInput>
                            <input type="text" className="infoArea" value={props.value} />

                        </GroupInput>
                    </fieldset>
                    <fieldset>
                        <legend className="idLocais">ID Cruzamento</legend>
                        {controllersRoute.map((data, idx) => {

                            return (
                                <>
                                    <GroupInput key={idx} >
                                        <input type="text" id="locais" value={data.id_cruzamento} />
                                        <input type="text" id="anel" value={data.anel} />
                                        <input type="text" id="monitor" value={data.grupo} />
                                        <LineTable
                                            key={idx}
                                            idCruzamento={data.id_cruzamento}
                                            anel={data.anel}
                                            grupo={data.grupo}
                                            deleteController={deleteController}>
                                        </LineTable>
                                    </GroupInput>
                                </>
                            );
                        })}
                    </fieldset>
                    <AreaButtons>
                        <ButtonAdd onClick={editRoute}>
                            {"Editar"}
                        </ButtonAdd>
                    </AreaButtons>
                </Container>
            </Dialog>
            <UpdateRoutes
                open={openRegisterRoute}
                value={value}
                onClose={handleCloseRegisterRoute}
                valueIds={controllersRoute}
            >
                {"Adicionar"}
            </UpdateRoutes>
        </>
    );
}

export default InfoRoutes;