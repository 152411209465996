import React, { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../../contextModal';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Local from './Tabs/Local';
import Controle from './Tabs/Controle';
import Chip from './Tabs/Chip';
import Camera from './Tabs/Camera';
import Mensageria from './Tabs/Mensageria';
import './style.css';
import { GrClose } from "react-icons/gr";
import RegisterCSV from '../../Registers/RegisterCSV';
import Parametros from '../../Parametros';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'} >
            <Paper {...props} />
        </Draggable>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

function RegisterControllers(props) {

    const [modalMove, setModalMove] = useState({ marginTop: '0', transition: '.5s' });
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [openParametros, setOpenParametros] = useState(false);
    const [idCruzamento, setIdCruzamento] = useState('');
    const { idGlobal } = useContext(ModalContext);
    const { abaEquipamento } = useContext(ModalContext);


    const handleChangeTabs = (event, newValue) => {
        setValue(newValue);
    };
    const [openImportCSV, setOpenImportCSV] = useState(false);

    const clickCloseCardCSV = () => {
        setOpenImportCSV(false);
    }

    const show = {
        marginTop: '0',
        transition: '.5s'
    }

    const handleCloseParametros = () => {
        setOpenParametros(false);
        window.location.href = "#"
    }

    useEffect(() => {
        setIdCruzamento(idGlobal);
        setValue(abaEquipamento); //escolhe a aba a ser aberta
    }, [props.open, idGlobal, abaEquipamento]);

    return (
        <>
            <Dialog
                open={props.open}
                name={props.key}
                className="Dialog"
                onClose={props.onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
                style={localStorage.getItem('awaitNextClick') === 'true' ? modalMove : show}
                xs={12}
            >
                <PageTitle
                    id="draggable-dialog-title"
                    text="Equipamentos"
                    onClick={props.onClose}
                >
                </PageTitle>
                <div className={classes.root} id="tabs">
                    <AppBar position="static">
                        <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                            <Tab label="Local" {...a11yProps(0)} />
                            <Tab label="Controlador" {...a11yProps(1)} />
                            <Tab label="Comunicação" {...a11yProps(2)} />
                            <Tab label="Câmera" {...a11yProps(3)} />
                            <Tab label="Mensageria" {...a11yProps(4)} />
                        </Tabs>
                        <div className="close" onClick={props.onClose}><GrClose size={19} /></div>
                    </AppBar>
                    <TabPanel value={value} id="scroll-bar" index={0} style={{ height: '60vh', overflowY: 'scroll' }}>
                        <Local moveInitialModal={modalMove} moveModalStatus={setModalMove} statusCSV={openImportCSV} setStatusCSV={setOpenImportCSV}
                            id={idCruzamento} setId={setIdCruzamento} numeroAnel={props.numeroAnel}
                        />
                    </TabPanel>
                    <TabPanel value={value} id="scroll-bar" index={1} style={{ height: '60vh', overflowY: 'scroll' }}>
                        <Controle setOpenParametros={setOpenParametros} id={idCruzamento} setId={setIdCruzamento} />
                    </TabPanel>
                    <TabPanel value={value} id="scroll-bar" index={2} style={{ height: '60vh', overflowY: 'scroll' }}>
                        <Chip id={idCruzamento} setId={setIdCruzamento} />
                    </TabPanel>
                    <TabPanel value={value} id="scroll-bar" index={3} style={{ height: '60vh', overflowY: 'scroll' }}>
                        <Camera setOpenParametros={setOpenParametros} id={idCruzamento} setId={setIdCruzamento} />
                    </TabPanel>
                    <TabPanel value={value} id="scroll-bar" index={4} style={{ height: '60vh', overflowY: 'scroll' }}>
                        <Mensageria setOpenParametros={setOpenParametros} id={idCruzamento} setId={setIdCruzamento} />
                    </TabPanel>
                </div>
            </Dialog>
            <RegisterCSV
                open={openImportCSV}
                onClose={clickCloseCardCSV}
            />
            <Parametros open={openParametros} onClose={handleCloseParametros} />
        </>
    )
}

export default RegisterControllers;
