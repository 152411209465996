import styled from 'styled-components';

export const ContentTab = styled.section`
    
    margin: 0 auto;
    user-select: none;
    font-family: 'roboto';
    width: 92%;
    position: relative;

    @media(max-width: 420px){
        margin-left: 2%;
    }
    
    fieldset{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: auto;
        border: 1px solid #a4a4a4;
        border-radius: 2px;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;

        &.disable{
            pointer-events: none;
            opacity: 0.4;
            width: 95%;
            margin: 0 auto;
        }

        &.enable{
            pointer-events: auto;
            opacity: 1;
        }

        &.content{
            margin-top: 25px;

            @media(max-width: 1500px){
                margin-top: 10px;
            }
        }

        .legend{
            font-size: 12px;
            position: absolute;
            top: -18px;
            right: 18px;
            background: #FFF;
            padding: 0 2px;
        }
            
        select {
            width: 100%;
            height: 28px;
            border-radius: 2px;
            text-indent: 10px;
            border: 1px solid #a4a4a4;
            margin-bottom: 5px;
            font-size: 13px;

            &#anel{
                
            }

            @media(max-width: 1500px){
                height: 28px;
            }
            @media(max-width: 1280px){
                height: 26px;
            }
        }

        input{
            height: 25px;
            border-radius: 2px;
            text-indent: 10px;
            border: 1px solid #a4a4a4;
            outline: none;
            font-size: 13px;
            width: 99%;
            margin-right: 5px;

            @media(max-width: 1500px){
                height: 25px;
                margin-right: 5px;
            }
        }

        legend {
            font-size: 12px;
            color: #1d1c1c;
        }

        form{
            width: 100%;
            .row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 5px auto;
                gap: 15px;

                .group {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .col-2{
                        //width: 95%;
                    }

                    .minutes{
                        width: 92%;
                        margin-left: 20px;
                        margin-top: 9px;
                    }

                    .referencia{
                        width: 75%;
                        margin-left: 20px;
                    }

                    &.column input {
                       // width: 95%;
                    }

                    label{
                        font-size: 13px;
                        color: #1d1c1c;
                    }

                    select {
                       // width: 214px;
                        height: 28px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 5px;
                        font-size: 13px;

                        @media(max-width: 1500px){
                            height: 28px;
                        }
                    }

                    input{
                        height: 25px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        outline: none;
                        font-size: 13px;
                        width: 99%;
                        margin-right: 5px;

                        @media(max-width: 1500px){
                            height: 25px;
                            margin-right: 5px;
                        }
                    }

                    span{
                        font-size: 13px;
                        color: #a4a4a4;
                        margin-left: 21px;
                    }
                }

                input[type="checkbox"] {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -22px;
    right: 0;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
            font-size: 12px;
            font-weight: normal;
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaInput = styled.div`
    display: flex;
    width: 100%;
    gap: 15px;
`;

export const AreaButtonRemove = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const BtnBorder = styled.div`
    width: 17px;
    height: 17px;
    margin-right: 3px;
    border: 2px solid #001E62;
    padding: 6px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        width: 20px;
        height: 20px;
    }
`;

export const MultiplyArea = styled.section`
    
    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enable{
        pointer-events: auto;
        opacity: 1;
    }

    h5 {
        margin: 32px 0 -14px 0;
        color: #333232;
        font-size: 13px;
    }
`;


export const Button = styled.button`
    background: #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    transition: .3s;
    font-size: 12px;
    font-weight: normal;

    @media(max-width: 1280px){
        height: 28px;
    }

    &:hover{
        background: #FFF;
        border: 1px solid #001E62;
        color: #001E62;
    }
    
    &.btnImportar{
        margin-top:11px;
        font-size: 12px;
        font-weight: normal;
    }

    &.btnNovo{
        margin-top: 12px;
        margin-right: 5px;
        width: 110px;
        font-size: 12px;
        font-weight: normal;

        &:hover svg path{
            fill: #001E62;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }

        @media(max-width: 1280px){
            height: 28px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    cursor: pointer;
    margin-right: -5px;
    gap: 7px;
   
    .alert{
        margin-right: 40px;
        width: 250px;
        display: ${props => props.view};
    }
`;


export const BorderButton = styled.div`
    border: 2px solid #001E62;
    background: #FFF;
    width: 39px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px; 
    

    svg {
        width: 18px;
        height: 18px;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enable{
        pointer-events: auto;
        opacity: 1;
    }
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media(max-width: 420px){
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
`;