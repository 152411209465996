import React from 'react';

export default function Lixeira(){
    return(
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M1.875 4.66211H3.125H13.125" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 4.66211V3.41211C5 3.08059 5.1317 2.76265 5.36612 2.52823C5.60054 2.29381 5.91848 2.16211 6.25 2.16211H8.75C9.08152 2.16211 9.39946 2.29381 9.63388 2.52823C9.8683 2.76265 10 3.08059 10 3.41211V4.66211M11.875 4.66211V13.4121C11.875 13.7436 11.7433 14.0616 11.5089 14.296C11.2745 14.5304 10.9565 14.6621 10.625 14.6621H4.375C4.04348 14.6621 3.72554 14.5304 3.49112 14.296C3.2567 14.0616 3.125 13.7436 3.125 13.4121V4.66211H11.875Z" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.25 7.78711V11.5371" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8.75 7.78711V11.5371" stroke="#B91C1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white" transform="translate(0 0.912109)"/>
                </clipPath>
            </defs>
        </svg>
    );
}