import React, { useEffect, useState, useContext } from 'react';
import { ModalContext } from '../../../../../contextModal';
import { ContentTab, AreaBtn, Button, BorderBtn, Divider, InfoHelp, AreaBallon, ContentBallon, Actions, AreaSwitch, Switch } from './styles';
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import BtnNovo from '../../Icons/BtnNovo';
import IconRemove from '../../Icons/IconRemove';
import api from '../../../../../services/api';
import AlertDialog from '../../../../AlertDialog';
import { validarPermissao } from '../../../../../services/permission';
import { GrCircleQuestion } from 'react-icons/gr';
import { BallonsInfoModal } from '../../../../Balloons';
import icoInfo from '../../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../../assets/icons/icoSom.png';
import { VozInfoEquipamentoControlador } from '../../../../Audio';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Controle(props) {
    const [unalterable, setUnalterable] = useState('disable');
    const [idLocal, setIdLocal] = useState([]);
    const [infoId, setInfoId] = useState({ consorcio: 'Selecione...', instaladora: 'Selecione...', mantenedora: 'Selecione...', modelo: 'Selecione...', ipControlador: 'Selecione...',portaSsh: 22, porta: 80 });
    const [consorcio, setConsorcio] = useState([]);
    const [instaladora, setInstaladora] = useState([]);
    const [mantenedora, setMantenedora] = useState([]);
    const [modelo, setModelo] = useState([]);
    const [porta, setPorta] = useState('');
    const [editId_cruzamento, setEditIdCruzamento] = useState([]);
    const [editConsorcio, setEditConsorcio] = useState([]);
    const [editInstaladora, setEditInstaladora] = useState([]);
    const [editMantenedora, setEditMantenedora] = useState([]);
    const [editModelo, setEditModelo] = useState([]);
    const [editIp, setEditIp] = useState('');
    const [editPorta, setEditPorta] = useState('');
    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [grupos, setGrupos] = useState([]);
    const [detectores, setDetectores] = useState([]);
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [typeRequest, setTypeRequest] = useState('save');
    const [controladorEstatisticas, setControladorEstatisticas] = useState(false);
    const [rede, setRede] = useState(false);
    const [iot, setIot] = useState(false);
    const [portaSsh, setPortaSsh] = useState(false)
    const { setAbrirAbaParametro } = useContext(ModalContext);


    /* const checkRequest = () => {
         if(typeRequest === 'save'){
             console.log('caiu no save')
             saveData();
         }else{
             console.log('cau no edit')
             editData();
         }
     }*/

    const saveData = async () => {
        if ([editConsorcio, editInstaladora, editMantenedora, editModelo, editIp].includes('')) {
            setAlertMessage('Ainda há campos vazios!');
            setTypeAlert('error');
            setAlertView('block');

            setTimeout(() => {
                setAlertView('');
            }, 5000);
        } else {
            await api.post('/CentralDeFalhas/Controlador/', {
                'id_cruzamento': editId_cruzamento,
                'consorcio': editConsorcio,
                'instaladora': editInstaladora,
                'mantenedora': editMantenedora,
                'modelo': editModelo,
                'ipControlador': editIp,
                'porta': editPorta,
                'grupos': grupos,
                'detectores': detectores,
                'type': typeRequest,
                'canEstatistica': controladorEstatisticas,
                "tipoDeRede": rede,
                "statusIot": iot
            }).then(res => {
                setAlertMessage(`Controlador ${editId_cruzamento.substring(0, 10).concat('...')} cadastrado`);
                setTypeAlert('success');
                setAlertView('block');
                enableData();
                setTimeout(() => {
                    setAlertMessage('');
                    setTypeAlert('');
                    setAlertView('none');
                }, 5000);
                console.log(controladorEstatisticas, rede, iot)


            }).catch(err => {
                setAlertMessage(`Erro ao cadastrar Controlador ${editId_cruzamento.substring(0, 10).concat('...')}`);
                setTypeAlert('error');
                setAlertView('block');
                enableData();
                setTimeout(() => {
                    setAlertMessage('');
                    setTypeAlert('');
                    setAlertView('none');
                }, 5000);
            });
        }
    }

    const editFlagEstatistica = async (boolean) => {
        if (typeRequest === 'update') {
            await api.put('/centraldefalhas/controlador/detectores', {
                'id_cruzamento': editId_cruzamento,
                'canEstatistica': boolean
            }).then(res => {
                setAlertMessage(`Estatística habilitada com sucesso`);
                setTypeAlert('success');
                setAlertView('block');
                setTimeout(() => {
                    setAlertMessage('');
                    setTypeAlert('');
                    setAlertView('none');
                }, 5000);
            }).catch(err => {
                setAlertMessage(`Erro ao habilitar estatísticas`);
                setTypeAlert('error');
                setAlertView('block');
                setTimeout(() => {
                    setAlertMessage('');
                    setTypeAlert('');
                    setAlertView('none');
                }, 5000);
            });
        }
    }

    const enableData = () => {
        setUnalterable('enable');

        //limpar requisição
        setInfoId({ consorcio: '', instaladora: '', mantenedora: '', modelo: '', ipControlador: '', porta: '' });
        //limpar campos:
        setEditIdCruzamento('');
        setEditConsorcio('');
        setEditInstaladora('');
        setEditMantenedora('');
        setEditModelo('');
        setEditIp('');
        setEditPorta('');

        setGrupos([]);
        setDetectores([]);
    }

    async function handleRemoveControlador() {
        await api.delete('/centraldefalhas/controlador/' + editId_cruzamento).then(res => {
            toast.info(`Controlador ${editId_cruzamento} excluido`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            enableData();
            setInfoId({ consorcio: '', instaladora: '', mantenedora: '', modelo: '', ipControlador: '' });
            setPorta('');
            setEditIp('');
            setGrupos([]);
            setDetectores([]);
            setAlertOpen(false);
        }).catch(err => {

            toast.error(`Falha ao excluir Controlador ${editId_cruzamento}`, {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        });
    }

    const listData = async () => {
        const response = await api.get('/CentralDeFalhas/local/Lista');
        setIdLocal(response.data);
    }

    const reqInfos = async (id) => {
        setEditIdCruzamento(id);
        setAlertView('none');
        //setInfoId({ consorcio: '', instaladora: '', mantenedora: '', modelo: '', ipControlador: '' });
        try {

            const response = await api.get(`/CentralDeFalhas/Controlador/${id}`);
            console.log('response', response.data)
            const listDetectores = [];
            const listGrupos = [];
            response.data.detectores.forEach(detector => {
                listDetectores.push(detector);
            });

            response.data.grupos.forEach(detector => {
                listGrupos.push(detector)
            });

            console.log('ver -> ', response.data)

            const detectoresOrdemCrescente = listDetectores.sort((a, b) => {
                return a.id_detector == null ? a.id_detector + listDetectores.length + 1 : a.id_detector - b.id_detector
            });

            const gruposOrdemCrescente = listGrupos.sort((a, b) => {
                return a.id_grupo == null ? a.id_grupo + listGrupos.length + 1 : a.id_grupo - b.id_grupo
            });

            setInfoId(response.data);
            setEditConsorcio(response.data.consorcio);
            setEditInstaladora(response.data.instaladora);
            setEditMantenedora(response.data.mantenedora);
            setEditModelo(response.data.modelo);
            getModelo(response.data.modelo);
            setEditIp(response.data.ipControlador);
            setEditPorta(response.data.porta);
            setUnalterable('enable');
            setGrupos(gruposOrdemCrescente);
            setDetectores(detectoresOrdemCrescente);
            setControladorEstatisticas(response.data.canEstatistica);
            setRede(response.data.tipoDeRede);
            setIot(Boolean(response.data.statusIot));
            setPortaSsh(response.data.portaSsh)
            //Caso encontrar o id no controlador então é uma edição e sera um put
            setTypeRequest('update');
        } catch (error) {
            setUnalterable('enable');
            setEditConsorcio('');
            setEditInstaladora('');
            setEditMantenedora('');
            setEditModelo('');
            setEditIp('');
            //caso não encontrar o id no controlador então é um novo cadastro e sera um post
            setTypeRequest('save');
        }
    }

    useEffect(() => {
        reqInfos(props.id);
    }, []);

    const reqConsorcio = async () => {
        const response = await api.get(`/CentralDeFalhas/consorcio`);
        setConsorcio(response.data);
    }

    const reqEmpresa = async () => {
        const response = await api.get(`/CentralDeFalhas/empresa`);
        setInstaladora(response.data);
        setMantenedora(response.data);
    }

    const reqModeloControlador = async () => {
        const response = await api.get(`/CentralDeFalhas/modelo/controlador`);
        setModelo(response.data);
    }

    const getModelo = (modelo) => {
        try {
            let modeloDescricao = modelo;
            let ArraymodeloDescricao = modeloDescricao.split("-")
            const modeloSlice = ArraymodeloDescricao.slice(ArraymodeloDescricao.length - 2); // corta o array deixando somente os dois ultimos numeros
            const splitDeFases = modeloSlice[0].split('F');  // fase
            const splitDeDetectores = modeloSlice[1].split('D');  // detectores
            handleAddGrupos(parseInt(splitDeFases[0]));
            handleAddDetectores(parseInt(splitDeDetectores[0]));


        } catch (error) {
            setAlertMessage(`Erro ao listar o modelo ${modelo}!`);
            handleAddGrupos(0);
            handleAddDetectores(0);
            setTypeAlert('error');
            setAlertView('block');
        }
    }

    const handleAddDetectores = (quantidadeDetectores) => {
        const values = [];

        for (let i = 0; i < quantidadeDetectores; i++) {
            values.push({ descricao: '', id_detector: i + 1, id_grupo: null });
            setDetectores(values);
        }

    };

    const handleAddGrupos = (quantidadeGrupos) => {
        const values = [];

        for (let i = 0; i < quantidadeGrupos; i++) {
            values.push({ descricao: '', id_grupo: i + 1 });
            setGrupos(values);
        }

    };

    const handleDetectorChange = (index, event) => {
        const values = [...detectores];

        if (event.target.name === "descricao") {
            values[index].descricao = event.target.value;
        }
        if (event.target.name === "id_detector") {
            values[index].id_detector = parseInt(event.target.value);
        }
        if (event.target.name === "id_grupo") {
            values[index].id_grupo = parseInt(event.target.value);
        }
        setDetectores(values);

    };

    const handleGrupoChange = (index, event) => {
        const values = [...grupos];

        if (event.target.name === "descricao") {
            values[index].descricao = event.target.value;
        }
        setGrupos(values);
    };

    const openModal = () => {
        setAlertOpen(true);
    }

    const closeModal = () => {
        setAlertOpen(false);
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonControlador = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Controlador</h3>
                    <label onClick={VozInfoEquipamentoControlador}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nessa aba de "Controlador" serão armazenadas as informações complementares a respeito
                    do local/controlador, são elas: os responsáveis, dados do hardware do controlador
                    (modelo, IP de acesso, disposição dos grupos focais e laços).
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const handleSwitch = () => {
        if (controladorEstatisticas) {
            setControladorEstatisticas(false);
            editFlagEstatistica(false);
        } else {
            setControladorEstatisticas(true);
            editFlagEstatistica(true);
        }
    }

    const handleSwitchRede = () => {
        if (rede) {
            setRede(false);
        } else {
            setRede(true);
        }
    }

    const handleSwitchIot = () => {
        if (iot) {
            setIot(false);
        } else {
            setIot(true);
        }
    }

    return (
        <ContentTab>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonControlador /></BallonsInfoModal>
            </AreaBallon>
            <fieldset>
                <legend>ID do local</legend>
                <select name="" id="" onClick={listData} onChange={(e) => reqInfos(e.target.value)}>
                    <option value={props.id}>{props.id === '' ? 'Selecione...' : props.id}</option>
                    {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(item => {
                        if (item.id_cruzamento !== props.id) {
                            return (
                                <option key={item.id_cruzamento} defaultValue={item.id_cruzamento}>{item.id_cruzamento}</option>
                            );
                        }
                    })}
                </select>
            </fieldset>
            <fieldset className={unalterable}>
                <legend>Responsáveis</legend>
                <form>
                    <div className='row'>
                        <div className="group column">
                            <label>Consórcio:</label>
                            <select name="" id="col-2" onClick={reqConsorcio} value={editConsorcio} onChange={(e) => setEditConsorcio(e.target.value)}>
                                <option value={infoId.consorcio}>{infoId.consorcio}</option>
                                {consorcio.map(item => {
                                    if (item.nome !== infoId.consorcio) {
                                        return (
                                            <option key={item.nome} defaultValue={item.nome}>{item.nome}</option>
                                        );
                                    }
                                })}
                            </select>
                        </div>
                        <div className="group column">
                            <label>Instaladora:</label>
                            <select name="" id="col-2" onClick={reqEmpresa} value={editInstaladora} onChange={(e) => setEditInstaladora(e.target.value)}>
                                <option value={infoId.instaladora}>{infoId.instaladora}</option>
                                {instaladora.map(item => {
                                    if (item.nome !== infoId.instaladora) {
                                        return (
                                            <option key={item.nome} value={item.nome}>{item.nome}</option>
                                        );
                                    }
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="group column" id="campoMantenedora">
                            <label>Mantenedora:</label>
                            <select name="" id="col-1" onClick={reqEmpresa} value={editMantenedora} onChange={(e) => setEditMantenedora(e.target.value)}>
                                <option value={infoId.mantenedora}>{infoId.mantenedora}</option>
                                {mantenedora.map(item => {
                                    if (item.nome !== infoId.mantenedora) {
                                        return (
                                            <option key={item.nome} value={item.nome}>{item.nome}</option>
                                        );
                                    }
                                })}
                            </select>
                        </div>
                        <Button className="btnNovo" onClick={() => props.setOpenParametros(true)}>
                            <BtnNovo />NOVO
                        </Button>
                    </div>
                </form>
            </fieldset>
            <fieldset className={unalterable}>
                <legend>Dados</legend>
                <form>
                    <div className='row'>
                        <div className="group">
                            <label>Modelo:</label>
                            <select name="" id="col-1" onClick={reqModeloControlador} value={editModelo} onChange={(e) => { setEditModelo(e.target.value); getModelo(e.target.value) }}>
                                <option value={infoId.modelo}>{infoId.modelo}</option>
                                {modelo.map(item => {
                                    if (item.modelo !== infoId.modelo) {
                                        return (
                                            <option key={item.modelo} defaultValue={item.modelo}>{item.modelo}</option>
                                        );
                                    }
                                })}
                            </select>
                        </div>
                        <Button className="btnNovo" onClick={() => { props.setOpenParametros(true); setAbrirAbaParametro('controlador') }}>
                            <BtnNovo />NOVO
                        </Button>
                    </div>
                    <div className='row'>
                        <div className="group column">
                            <label>IP:</label>
                            <input type="text" defaultValue={infoId.ipControlador} value={editIp} onChange={(e) => setEditIp(e.target.value)} />
                        </div>
                        <div className="group column">
                            <label>Porta:</label>
                            <input type="text" defaultValue={infoId.porta} value={editPorta} onChange={(e) => setEditPorta(e.target.value)} /> {/* value={editPorta}, substituido por infoId.porta = 80 temporariamente*/}
                        </div>
                        <div className="group column">
                            <label>Porta ssh:</label>
                            <input type="text" defaultValue={infoId.portaSsh} value={portaSsh} onChange={(e) => setEditPorta(e.target.value)} /> {/* value={editPorta}, substituido por infoId.porta = 80 temporariamente*/}
                        </div>
                    </div>
                </form>
            </fieldset>

            {/* Novos campos */}
            <fieldset className={unalterable}>
                <legend>Conf. dos Grupos</legend>
                <form>
                    {grupos.map((items, index) => (
                        <div className='row'>
                            <div className="conf">
                                <label>Descrição do Grupo {items.id_grupo} :</label>
                                <input name="descricao" type="text" value={items.descricao} onChange={(event) => handleGrupoChange(index, event)} />
                            </div>
                        </div>
                    ))}
                </form>
            </fieldset>

            <fieldset className={unalterable}>
                <legend>Conf. dos Detectores</legend>
                <form>
                    {detectores.map((itemDetector, indexDetector) => (
                        <Divider key={indexDetector}>
                            <div className='row'>
                                <div className="groupSelect">
                                    <label className='label_detector'>Detector:</label>
                                    <input name="detector" className='detector' type="text" value={itemDetector.id_detector} />
                                </div>
                                <div className="groupSelect">
                                    <label id="grupo">Grupo:</label>
                                    <select name="id_grupo" id="selectGrupo" className="col-3" onChange={event => handleDetectorChange(indexDetector, event)}>
                                        <option value='' selected>-----</option>
                                        {grupos.map((items, index) => {
                                            if (itemDetector.id_grupo === items.id_grupo) {
                                                return (<option value={items.id_grupo} selected>{items.id_grupo}</option>)
                                            } else {
                                                return (<option value={items.id_grupo}>{items.id_grupo}</option>)
                                            }
                                        })}
                                    </select>
                                </div>
                                <div className="conf detectores">
                                    <label>Descrição do detector:</label>
                                    <input name="descricao" type="text" value={itemDetector.descricao} onChange={event => handleDetectorChange(indexDetector, event)} />
                                </div>
                            </div>
                            <hr />
                        </Divider>
                    ))}
                    <AreaSwitch>
                        <label>Estatísticas</label>
                        <Switch>
                            <input type="checkbox" className="switch-input"
                                value={controladorEstatisticas}
                                onChange={(e) => handleSwitch(e.target.value)}
                                checked={controladorEstatisticas}
                            />
                            <span className="switch-label" data-on="On" data-off="Off"></span>
                            <span className="switch-handle"></span>
                        </Switch>
                        <AreaSwitch>
                            <label>Tipo de rede</label>
                            <Switch>
                                <input type="checkbox" className="switch-input"
                                    value={rede}
                                    onChange={(e) => handleSwitchRede(e.target.value)}
                                    checked={rede}
                                />
                                <span className="switch-label rede" data-on="Fibra" data-off="3G"></span>
                                <span className="switch-handle"></span>
                            </Switch>
                            <label>IOT</label>
                            <Switch>
                                <input type="checkbox" className="switch-input"
                                    value={iot}
                                    onChange={(e) => handleSwitchIot(e.target.value)}
                                    checked={iot}
                                />
                                <span className="switch-label" data-on="true" data-off="false"></span>
                                <span className="switch-handle"></span>
                            </Switch>
                        </AreaSwitch>
                    </AreaSwitch>
                </form>
            </fieldset>

            <AreaBtn view={alertView} className={permissao}>
                <div className="alert">
                    <Alert severity={typeAlert}>{alertMessage === '' ? '' : alertMessage}</Alert>
                </div>
                <Actions>
                    <BorderBtn id="btnRemove" className={unalterable} title="Excluir Controlador" onClick={openModal}>
                        <IconRemove />
                    </BorderBtn>
                    <Button width="92" text="Salvar" heigth="41" className="btnSalvar" onClick={saveData}>SALVAR</Button>
                </Actions>
            </AreaBtn>
            <AlertDialog open={alertOpen} onClick={handleRemoveControlador} onClose={closeModal}>
                Deseja realmente excluir o controlador?
            </AlertDialog>
        </ContentTab>
    );
}