import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semFrontWalkGreen from '../../../img/Semaforo/semFrontWalkGreen.png';
import semFrontWalkRed from '../../../img/Semaforo/semFrontWalkRed.png';
import semFrontWalkApagado from '../../../img/Semaforo/semFrontWalkApagado.png';
import semFrontWalkPiscante from '../../../img/Semaforo/piscanteFrontWalkRed.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoWalkFront(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo Pedestre frontal', image: semFrontWalkGreen, object: props.objSemaforoWalk, setObject: props.setObjSemaforoWalk });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforoWalk.map((item, index) => {
                if (props.objSemaforoWalk[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforoWalk'
                            top={item.top}
                            left={item.left}
                            objSemaforoWalk={props.objSemaforoWalk}
                            setObjSemaforoWalk={props.setObjSemaforoWalk}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img
                                src={
                                    item.color_group === undefined || item.color_group === '' ? semFrontWalkApagado :
                                        item.color_group === 1 ? semFrontWalkRed :
                                            item.color_group === 4 ? semFrontWalkGreen :
                                                item.color_group === 17 ? semFrontWalkPiscante : semFrontWalkApagado
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforoWalk(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}