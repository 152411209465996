import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartLeftToBottom from '../../../img/Setas/arrowStartLeftToBottom.png';
import arrowPauseLeftToBottom from '../../../img/Setas/arrowPauseLeftToBottom.png';
import arrowStopLeftToBottom from '../../../img/Setas/arrowStopLeftToBottom.png';

import animateArrowLeftToBottom from '../../../img/Setas/animateArrowLeftToBottom.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveLeftToBottom, Options, ButtonSettings } from './styles';

export default function ArrowLeftToBottom(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Seta subindo vira a esquerda', image: arrowStartLeftToBottom, object: props.arrowLeftToBottom, setObject: props.setArrowLeftToBottom });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowLeftToBottom.map((item, index) => {
                if (props.arrowLeftToBottom[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowLeftToBottom'
                            top={item.top}
                            left={item.left}
                            setArrowLeftToBottom={props.setArrowLeftToBottom}
                            arrowLeftToBottom={props.arrowLeftToBottom}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveLeftToBottom
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartLeftToBottom :
                                        item.color_group === 1 ? arrowStopLeftToBottom :
                                            item.color_group === 2 ? arrowPauseLeftToBottom :
                                                item.color_group === 4 ? animateArrowLeftToBottom : ''
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowLeftToBottom(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
