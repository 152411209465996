import React from 'react';

export default function IconCamera(){
    return(
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2V0.5H2.5V2H4V3H2.5V4.5H1.5V3H0V2H1.5ZM3 5V3.5H4.5V2H8L8.915 3H10.5C11.05 3 11.5 3.45 11.5 
            4V10C11.5 10.55 11.05 11 10.5 11H2.5C1.95 11 1.5 10.55 1.5 10V5H3ZM6.5 9.5C7.88 9.5 9 8.38 9 7C9 5.62 7.88 
            4.5 6.5 4.5C5.12 4.5 4 5.62 4 7C4 8.38 5.12 9.5 6.5 9.5ZM4.9 7C4.9 7.885 5.615 8.6 6.5 8.6C7.385 8.6 8.1 7.885 
            8.1 7C8.1 6.115 7.385 5.4 6.5 5.4C5.615 5.4 4.9 6.115 4.9 7Z" fill="white"/>
        </svg>
    );
}