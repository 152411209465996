import styled from 'styled-components';

export const Container = styled.section`
    .element {
        position: absolute;
        top: ${props => props.top + 'vh'};
        left: ${props => props.left + 'vw'};
        z-index: ${props =>
        props.classeObjeto === 'semaforo' ? 10 :
            props.classeObjeto === 'carro' || props.classeObjeto === 'pedestre' ? 9 :
                props.classeObjeto === 'seta' ? 8 : 4
    };
        text-align: center;
        width: 3.7%;
    }

    .elementHeader {
        cursor: grab;
        z-index: 3;
        user-select: none;
		height: 0;
    	width: 7.5vw;
        &:active{
            cursor: grabbing;
        }
    }
`;


