import styled from 'styled-components';

export const Container = styled.section`
    height: 250px;
    margin-top: 20px;
    font-family: 'Roboto', sans-serif;

    &.disabled{
        pointer-events: none;
        opacity: 0.4;
    }
    
    form{
        width: 100%;

        .row{
            display: flex;
            flex-direction: column;

            .focus{
                border: 1px solid #7e7a7a;
                border-radius: 4px;
                height: 32px;
                display: flex;
                align-items: center;
                margin: 7px 0 7px 0;

                &:focus-within {
                    border: 2px solid #001E62;
                }
            }

            label{
                font-family: 'Roboto', sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                letter-spacing: 0em;
            }

            input{
                width: 82%;
                height: 23px;
                border: none;
                outline: none;
                border: 0;
                text-indent: 5px;
            }
        }

        span{
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 14px;
            letter-spacing: 0em; 
            color: red;
        }
        button{
            border: 0;
            width: 100%;
            height: 32px;
            background: #001E62;
            color: #FFF;
            border-radius: 29px;
            margin-top: 16px;
            font-weight: 700;
            font-size: 12px;
            cursor: pointer;
            outline: none;

            &:active{
                position: relative;
                top: 1px;
            }

            &:hover{
                background: #06297c;  
            }
        }
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const IconView = styled.div`
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:active{
    position: relative;
    top:.5px;
  }

  svg{
    path{
      fill: #7d7d7d;
    }
  }
`;
