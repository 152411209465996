import React, {useState, useEffect} from 'react';
import { toast } from "react-toastify";
import {Container, Table, Close} from './styles';
import IconAutenticado from './icons/IconAutenticado';
import IconBloqueado from './icons/IconBloqueado';
import IconDesbloqueado from './icons/IconDesbloqueado';
import IconExcluir from './icons/IconExcluir';
import IconPendente from './icons/IconPendente';
import AlertDialog from '../../AlertDialog';
import { GrClose } from "react-icons/gr";
import api from '../../../services/api';

export default function RegisterUsers(props){

    const [dados, setDados] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [propsEmail, setProsEmail] = useState('');

    async function receberUsuarios(){
        const response = await api.get('/centraldefalhas/user/list');
        setDados(response.data); 
    }
    
    useEffect(() => {
        if(props.atualizar == 'block'){
            receberUsuarios();
        }
        
    },[props.atualizar]);

        const excluirDados = async(itemEmail) =>{
          await api.post('/centraldefalhas/remove_user',{
            'email': itemEmail
          }).then( res => {
            toast.success(`Aguarde... excluíndo... !`, {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });   
            receberUsuarios();
            closeModal();
          }).catch(err => {
            toast.update('Erro ao excluir o usuário!', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
      }

    const salvarPermissao = async (permissao, email) =>{
        await api.put(`/centraldefalhas/user/permissao`,{
            'email': email,
            'permission': permissao
        });
    }

    const closeModal = () =>{
        setOpenModal(false);
    }

    const elementosPermissoes = [{key: 0, value: 0, text:"Administrador"}, {key: 1, value: 1, text:"Gestor"}, {key: 2, value: 2, text:"Operador"}, {key: 3, value: 3, text:"Visitante"}];

    return(
        <Container>
            <Close onClick={props.onClose}><GrClose size={14}/></Close>
            <Table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Licença</th>
                        <th>Bloqueado</th>
                        <th>Autenticado</th>
                        <th>Permissão</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                {dados.map( (item, index) => (
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.townhall}</td>
                        <td>{item.user_block == false ? <IconDesbloqueado/> : <IconBloqueado/> }</td>
                        <td>{item.email_auth == false ? <IconPendente /> :  <IconAutenticado /> }</td>
                        <td>
                            <select name="permissao" id="" onChange={(e) => salvarPermissao(e.target.value, item.email)}>
                                <option value={item.permission}>{
                                    item.permission == 0 ? 'Administrador' :
                                    item.permission == 1 ? 'Gestor':
                                    item.permission == 2 ? 'Operador':  
                                    item.permission == 3 ? 'Visitante' : ''
                                }</option>
                                {
                                    elementosPermissoes.map(elemento => {
                                        if(elemento.value != item.permission){
                                            return <option key={elemento.key} value={elemento.value}>{elemento.text}</option>
                                        }
                                    })
                                }
                            </select>
                        </td>
                        <td><div id="excluir" onClick={() => (setOpenModal(true), setProsEmail(item.email))}><IconExcluir /></div></td>
                    </tr>
                    ))}
                </tbody>
                <AlertDialog
                    open={openModal}
                    onClick={() => excluirDados(propsEmail)}
                    onClose={closeModal}
                >
                    Confirma exclusão de dados ? {propsEmail}
                </AlertDialog>
            </Table>
        </Container>
    );
}
