import styled from "styled-components";

export const Container = styled.div`
    width: 90%;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

`;

export const MultiplyArea = styled.div`

    .group{
        .areaCharacters {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            span{
                font-size: 12px;
                font-family: 'Roboto', sans-serif;
                margin-bottom: 5px;
            }
        }
    }

    fieldset{
        border: 1px solid silver;
        border-radius: 3px;
        margin-bottom: 10px;
    }
    legend{
        font-size: 13px;
        color: #6b6a6a;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        font-family: 'roboto',sans-serif;
        user-select: none;
    }

    label{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        display: block;
        font-size: 13px;
        color: #6b6a6a;
        font-family: 'roboto',sans-serif;
    }

    input{
        width: 100%;
        height: 35px;
        text-indent: 10px;
        margin-bottom: 12px;
        box-sizing: border-box;
        border: 0.5px solid silver;
        border-radius: 4px;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        font-family: 'roboto';
        color: #605959;
    }

    textarea{
        width: 100%;
        height: 90px;
        max-width: 511px;
        text-indent: 10px;
        padding-top: 7px;
        box-sizing: border-box;
        border: 0.5px solid silver;
        border-radius: 4px;
        outline: none;
        border-radius: 4px;
        font-size: 14px;
        font-family: 'roboto';
        color: #605959;
    }
`;

export const AreaButtonRemove = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const BtnBorder = styled.div`
    width: 17px;
    height: 17px;
    display: flex;
    margin-right: 3px;
    border: 2px solid #001E62;
    padding: 6px;
    border-radius: 5px;
    cursor: pointer;

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        width: 20px;
        height: 20px;
    }
`;

export const AreaButton = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 15px;

    .alert{
        margin-right: 40px;
        width: 100%;
        display: ${props => props.view};

        .MuiPaper-root.MuiAlert-root.MuiAlert-standardSuccess.MuiPaper-elevation0{
            height: 19px;
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
`;

export const Button = styled.button`
    background: #001E62;
    width: 110px;
    min-width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-weight: 550;
    transition: .3s;
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;

    @media(max-width: 1280px){
        height: 28px;
    }

    &:hover{
        background: #FFF;
        border: 1px solid #001E62;
        color: #001E62;
    }
    
    &.btnImportar{
        margin-top:11px;
        font-size: 12px;
        font-weight: normal;
    }

    &.btnNovo{
        margin-top: 12px;
        margin-right: 5px;
        width: 110px;
        font-size: 12px;
        font-weight: normal;

        &:hover svg path{
            fill: #001E62;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }

        @media(max-width: 1280px){
            height: 28px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &.btnSalvarDesabled{
        display:none;
    }

    &.btnEditarDesabled{
        display: none;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    cursor: pointer;
    gap: 7px;
`;


export const BorderButton = styled.div`
    border: 2px solid #001E62;
    background: #FFF;
    width: 39px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px; 
    
    svg {
        width: 18px;
        height: 18px;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enable{
        pointer-events: auto;
        opacity: 1;
    }
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media(max-width: 420px){
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
`;