import React, { useEffect, useState } from "react";
import Alert from '@material-ui/lab/Alert';
import PageTitle from '../../components/PageTitle';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Draggable from 'react-draggable';
import { AiFillControl } from "react-icons/ai";
import { FaMapMarkerAlt } from "react-icons/fa";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
    Modal, AreaButtons, Buttons, Close, AreaIntersecao, AreaControlador, AreaRota, ButtonRemove, ButtonAdd, ButtonSend, ButtonCancel,
    Content, InfoHelp, AreaBallon, ContentBallon
} from './styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import IconRemove from './icons/IconRemove';
import { RiCloseCircleLine } from "react-icons/ri";
import api from '../../services/api';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { validarPermissao } from "../../services/permission";
import './style.css';
import { GrClose } from "react-icons/gr";
import { vozIntersecao, vozControlador, vozRota } from '../Audio';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../Balloons';
import icoInfo from '../../assets/icons/icoInfo.png';
import icoSom from '../../assets/icons/icoSom.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            //width: '150',
        },
        width: '100%',
        flexGrow: 1,
        maxWidth: 500,
    },
    container: {
        maxHeight: 430,
        maxWidth: 520,
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        background: '#e4e0e0',
        border: '3px dashed #979696',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
    }
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

export default function ImposicaoDePlano(props) {

    const classes = useStyles();

    let ids = [];
    let rotas = [];
    let idsIntersecoes = [];
    let aneis = [];
    const [intersecaoActive, setInterseccaoActive] = useState('activated');
    const [idActive, setIdActive] = useState('disabled');
    const [rotaActive, setRotaActive] = useState('disabled');
    const [nameRoute, setNameRoute] = useState([]);
    const [dataControlers, setDataControlers] = useState([]);

    const [timeImposicao, setTimeImposicao] = useState('');
    const [timeId, setTimeId] = useState('');
    const [timeRota, setTimeRota] = useState('');

    const [namePlanIntersecao, setNamePlanIntersecao] = useState('');
    const [namePlanId, setNamePlanId] = useState('');
    const [namePlanRota, setNamePlanRota] = useState('');


    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [inputIntersecao, setInputIntersecao] = useState([
        { id: '', anel: '' },
    ]);
    const [inputFields, setInputFields] = useState([
        { id: '' }
    ]);
    const [inputRotas, setInputRotas] = useState([
        { id: '' }
    ]);
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');

    const setBtnIntersecao = () => {
        if (intersecaoActive === 'disabled') {
            setInterseccaoActive('activated');
            setIdActive('disabled');
            setRotaActive('disabled');
        }
    }
    const setBtnId = () => {
        if (idActive === 'disabled') {
            setInterseccaoActive('disabled');
            setIdActive('activated');
            setRotaActive('disabled')
        }
    }
    const setBtnRota = () => {
        if (rotaActive === 'disabled') {
            setInterseccaoActive('disabled');
            setIdActive('disabled');
            setRotaActive('activated');
        }
    }
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        async function lerControlers() {
            const response = await api.get('/centraldefalhas/controladores');
            setDataControlers(response.data);
        }
        lerControlers();
    }, []);

    async function lerRoutes() {
        const response = await api.get('/centraldefalhas/routes/list');
        setNameRoute(response.data);
    }

    useEffect(() => {
        lerRoutes();
    }, []);

    useEffect(() => {
        lerRoutes();
    }, [props.open]);


    //handleAddFields / handleRemoveFields adição e remoção de campos
    const handleAddIntersecao = () => {
        const values = [...inputIntersecao];
        values.push({ id: '', anel: '' });
        setInputIntersecao(values);
    };

    const handleRemoveIntersecao = index => {
        const values = [...inputIntersecao];
        values.splice(index, 1);
        setInputIntersecao(values);
    };

    /*verificação simples para o campo de entrada com base no nameatributo desse campo. Em seguida, fornecemos o valor para o índice fornecido. O índice é derivado da map*/
    const handleIntersecaoChange = (index, event) => {
        const values = [...inputIntersecao];
        if (event.target.name === 'id') {
            values[index].id = event.target.value;
        }
        if (event.target.name === 'anel') {
            values[index].anel = event.target.value;
        }
        setInputIntersecao(values);
    };



    //handleAddFields / handleRemoveFields adição e remoção de campos
    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ id: '' });
        setInputFields(values);
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    /*verificação simples para o campo de entrada com base no nameatributo desse campo. Em seguida, fornecemos o valor para o índice fornecido. O índice é derivado da map*/
    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === 'id') {
            values[index].id = event.target.value;
            setInputFields(values);
        }
    };

    async function handleSubmitIntersecao() {
        inputIntersecao.map(d => {
            idsIntersecoes.push(`${d.id}`);
            aneis.push(`${d.anel}`)
        });

        setMsg('Solicitando imposição nos Equipamentos...');
        setAlertMsg('block');
        setTypeAlert('success');

        setTimeout(() => {
            setMsg('');
            setAlertMsg('none');
        }, 2000);

        await api.post('/centraldefalhas/imposicao-de-plano-anel/id', {
            "id": `${idsIntersecoes.join()}`,
            "plan": namePlanIntersecao,
            "time": timeImposicao,
            "anel": `${aneis.join()}`,
        }).then(res => {
            setMsg('Imposição solicitada com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

            setNamePlanIntersecao('');
            setTimeImposicao('');
            //setInputIntersecao([{id: '', anel: ''}]);

        }).catch(error => {

            setMsg('Erro ao solicitar imposição de plano...');
            setAlertMsg('block');
            setTypeAlert('error');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);
        })
    }

    async function handleSubmit() {
        inputFields.map(d => {
            ids.push(`${d.id}`);
        });

        setMsg('Solicitando imposição nos Equipamentos...');
        setAlertMsg('block');
        setTypeAlert('success');

        setTimeout(() => {
            setMsg('');
            setAlertMsg('none');
        }, 2000);

        await api.post('/centraldefalhas/imposicao-de-plano/id', {
            "id": `${ids.join()}`,
            "plan": namePlanId,
            "time": timeId
        }).then(res => {

            setMsg('Imposição solicitada com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

            setNamePlanId('');
            setTimeId('');
            console.log('imposição:' + ids.join())

        }).catch(error => {
            setMsg('Erro ao solicitar imposição de plano...');
            setAlertMsg('block');
            setTypeAlert('error');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);
        })
    }

    async function handleSubmitRotas() {
        inputRotas.map(r => {
            rotas.push(`${r.id}`);
        });

        setMsg('Solicitando imposição nos Equipamentos...');
        setAlertMsg('block');
        setTypeAlert('success');

        setTimeout(() => {
            setMsg('');
            setAlertMsg('none');
        }, 2000);

        await api.post('/centraldefalhas/imposicao-de-plano/rota', {
            "rota": `${rotas.join()}`,
            "plan": namePlanRota,
            "time": timeRota
        }).then(res => {

            setMsg('Imposição solicitada com sucesso');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

            setNamePlanId('');
            setTimeId('');
        }).catch(error => {

            setMsg('Erro ao solicitar imposição de plano');
            setAlertMsg('block');
            setTypeAlert('error');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

        })
    }

    const handleAddRota = () => {
        const values = [...inputRotas];
        values.push({ id: '' });
        setInputRotas(values);
    }

    const handleRemoveInputs = (index) => {
        const values = [...inputRotas];
        values.splice(index, 1);
        setInputRotas(values);
    }

    const handleRotaChange = (index, event) => {
        const values = [...inputRotas];
        if (event.target.name === 'id') {
            values[index].id = event.target.value;
            setInputRotas(values);
        }
    }

    //const { openQuickLog, setOpenQuickLog } = useContext(ModalContext)//futuramente irá abrir o menu lateral de logs

    const cancelarInposicaoRotas = async () => {
        //props.cbSetOpenImposicao(false); // futuramente irá abrir o menu lateral de logs
        //setOpenQuickLog(true);
        inputRotas.map(r => {
            rotas.push(`${r.id}`);
        });

        setMsg('Solicitando cancelamento de imposição...');
        setAlertMsg('block');
        setTypeAlert('info');

        setTimeout(() => {
            setMsg('');
            setAlertMsg('none');
        }, 2000);

        await api.post('centraldefalhas/cancelamento-de-imposicao/rota', {
            "rota": `${rotas.join()}`,
            "plan": namePlanRota,
            "time": timeRota
        }).then(res => {

            setMsg('Imposição cancelada com sucesso');
            setAlertMsg('block');
            setTypeAlert('info');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);


            setNamePlanId('');
            setTimeId('');
        }).catch(error => {

            setMsg('Erro ao cancelar imposição de plano');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);
        })
    }

    const cancelarInposicaoIds = async () => {
        inputIntersecao.map(d => {
            ids.push(`${d.id}`);
        });

        console.log('cancelar', inputFields)

        setMsg('Solicitando cancelamento de imposição ...');
        setAlertMsg('block');
        setTypeAlert('info');

        setTimeout(() => {
            setMsg('');
            setAlertMsg('none');
        }, 2000);

        await api.post('centraldefalhas/cancelamento-de-imposicao/id', {
            "id": `${ids.join()}`,
            "plan": namePlanId,
            "time": timeId
        }).then(res => {

            setMsg('Imposição cancelada com sucesso');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

            setNamePlanId('');
            setTimeId('');

        }).catch(error => {

            setMsg('Erro ao cancelar imposição de plano');
            setAlertMsg('block');
            setTypeAlert('success');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            }, 2000);

        });
    }


    // abre e fecha popup de ajuda
    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentIntersecao = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Interseção</h3>
                    <label onClick={vozIntersecao}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    A entidade iterseção é composta pelos grupos semafóricos, veiculares e de pedestres,
                    existentes em um cruzamento de duas ou mais vias, ou em uma travessia de pedestres de
                    meio de quadra. Cada interseção é controlada por um único controlador.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentControlador = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Controlador</h3>
                    <label onClick={vozControlador}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    A entidade controlador compreende um conjunto  de até 4 interseções semaforizadas,
                    que operam de forma coordenada entre sí durante a operação rotineira, mas que
                    podem passar a operar em uma programação especial em função de comando operacional da
                    Central. A relação das interseções pertencentes a um controlador não é parametrizavél.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const ContentRota = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Rota</h3>
                    <label onClick={vozRota}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    A entidade de rota compreende um conjunto de interseções semaforizadas, que não
                    precisam operar de forma coordernada durante a operação rotineira, mas que podem
                    passar a operar em uma programação espercial em função de um comando operacional da Central.
                    A relação das interseções pertencentes a uma Rota são parametrizáveis, isto é, a associação
                    entre as interseções e rotas são configuráveis.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
            id="imposicao"
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Imposição de plano"
                onClick={props.onClose}
            ></PageTitle>
            <Modal>
                <AreaButtons>
                    <Close onClick={() => props.onClose()}><GrClose size={25} /></Close>
                    <Paper square className={classes.root}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label=""
                        >
                            <Tab icon={<FaMapMarkerAlt size={18} />} id="Interseccao" label="Interseção" onClick={setBtnIntersecao} />
                            <Tab icon={<AiFillControl size={20} />} id="Id" label="Controlador" onClick={setBtnId} />
                            <Tab icon={<MoreHorizIcon />} id="Rota" label="Rota" onClick={setBtnRota} />
                        </Tabs>
                    </Paper>
                </AreaButtons>
                <AreaIntersecao>
                    <div className={intersecaoActive}>
                        <InfoHelp onClick={() => openPopUp()}>
                            <GrCircleQuestion size={15} />
                        </InfoHelp>
                        <AreaBallon visible={visibleBalloon}>
                            <BallonsInfoModal><ContentIntersecao /></BallonsInfoModal>
                        </AreaBallon>
                        <fieldset style={{ marginTop: '0', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Seleção</legend>
                            <Content>
                                {inputIntersecao.map((inputIntersecao, index) => {
                                    return (
                                        <div className="group" key={`${inputIntersecao}~${index}`}>
                                            {/* Atribuir value para funcionar corretamente a exclusão / onChange Atualize os campos de entrada para 
                                                incluir um manipulador de alterações para atender à ação de entrada do usuário*/
                                            }
                                            <TextField id="outlined-basic" label="Id" variant="outlined" name="id" select
                                                onChange={event => handleIntersecaoChange(index, event)}
                                            >
                                                {dataControlers.map((items) => {
                                                    return (
                                                        <MenuItem key={items.id_cruzamento} value={items.id_cruzamento}>
                                                            {items.id_cruzamento}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <TextField id="outlined-basic" label="Anel" variant="outlined" name="anel" select size="medium"
                                                onChange={event => handleIntersecaoChange(index, event)}
                                            >
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>

                                            </TextField>
                                            <ButtonRemove
                                                className="dialog-button"
                                                title="Retirar equipamento da imposição"
                                                variant="outlined"
                                                color="primary"
                                                type="button"
                                                style={{ "height": "43px" }}
                                                onClick={() => handleRemoveIntersecao(index)}
                                            >
                                                <IconRemove />
                                            </ButtonRemove>
                                        </div>
                                    );
                                })}
                            </Content>
                            <ButtonAdd title="Novo Equipamento" onClick={() => handleAddIntersecao()}>
                                <AddToPhotosIcon className="btnAdd"></AddToPhotosIcon>
                            </ButtonAdd>
                        </fieldset>
                        <fieldset style={{ marginTop: '15px', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Configurações</legend>
                            <div className="group">
                                <TextField type="number" id="outlined-basic" label="Plano:" variant="outlined" value={namePlanIntersecao} onChange={e => setNamePlanIntersecao(e.target.value)} />
                                <TextField type="number" id="outlined-basic" label="Minutos:" variant="outlined" value={timeImposicao} onChange={e => setTimeImposicao(e.target.value)} />
                            </div>
                        </fieldset>
                        <AreaButtons message={alertMsg} className={permissao}>
                            <div className="alert">
                                <Alert severity={typeAlert}>{msg}</Alert>
                            </div>
                            <Buttons>
                                <ButtonCancel
                                    title="Cancelar Imposição"
                                    onClick={cancelarInposicaoIds}
                                >
                                    <RiCloseCircleLine size={21} color='#001E62' />
                                </ButtonCancel>
                                <ButtonSend status={permissao} onClick={handleSubmitIntersecao}>
                                    ENVIAR IMPOSIÇÃO
                                </ButtonSend>
                            </Buttons>
                        </AreaButtons>
                    </div>
                </AreaIntersecao>
                <AreaControlador>
                    <div className={idActive}>
                        <InfoHelp onClick={() => openPopUp()}>
                            <GrCircleQuestion size={15} />
                        </InfoHelp>
                        <AreaBallon visible={visibleBalloon}>
                            <BallonsInfoModal><ContentControlador /></BallonsInfoModal>
                        </AreaBallon>
                        <fieldset style={{ marginTop: '0', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Seleção</legend>
                            <Content>
                                {inputFields.map((inputField, index) => {
                                    return (
                                        <div className="group" key={`${inputField}~${index}`}>
                                            {/* Atribuir value para funcionar corretamente a exclusão / onChange Atualize os campos de entrada para 
                                    incluir um manipulador de alterações para atender à ação de entrada do usuário*/
                                            }
                                            <TextField id="outlined-basic" label="Id" variant="outlined" name="id" select
                                                value={inputField.id} onChange={event => handleInputChange(index, event)}
                                            >
                                                {dataControlers.map((items) => {
                                                    return (
                                                        <MenuItem key={items.id_cruzamento} value={items.id_cruzamento} >
                                                            {items.id_cruzamento}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <ButtonRemove
                                                className="dialog-button"
                                                title="Retirar equipamento da imposição"
                                                variant="outlined"
                                                color="primary"
                                                type="button"
                                                style={{ "height": "43px" }}
                                                onClick={() => handleRemoveFields(index)}
                                            >
                                                <IconRemove />
                                            </ButtonRemove>
                                        </div>
                                    );
                                })}
                            </Content>
                            <ButtonAdd title="Novo Equipamento" onClick={() => handleAddFields()}>
                                <AddToPhotosIcon className="btnAdd"></AddToPhotosIcon>
                            </ButtonAdd>
                        </fieldset>
                        <fieldset style={{ marginTop: '15px', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Configurações</legend>
                            <div className="group">
                                <TextField type="number" id="outlined-basic" label="Plano:" variant="outlined" value={namePlanId} onChange={e => setNamePlanId(e.target.value)} />
                                <TextField type="number" id="outlined-basic" label="Minutos:" variant="outlined" value={timeId} onChange={e => setTimeId(e.target.value)} />
                            </div>
                        </fieldset>
                        <AreaButtons message={alertMsg} className={permissao}>
                            <div className="alert">
                                <Alert severity={typeAlert}>{msg}</Alert>
                            </div>
                            <Buttons>
                                <ButtonCancel
                                    title="Cancelar Imposição"
                                    onClick={cancelarInposicaoIds}
                                >
                                    <RiCloseCircleLine size={21} color='#001E62' />
                                </ButtonCancel>
                                <ButtonSend status={permissao} onClick={handleSubmit}>
                                    ENVIAR IMPOSIÇÃO
                                </ButtonSend>
                            </Buttons>
                        </AreaButtons>
                    </div>
                </AreaControlador>
                <AreaRota>
                    <div className={rotaActive}>
                        <InfoHelp onClick={() => openPopUp()}>
                            <GrCircleQuestion size={15} />
                        </InfoHelp>
                        <AreaBallon visible={visibleBalloon}>
                            <BallonsInfoModal><ContentRota /></BallonsInfoModal>
                        </AreaBallon>
                        <fieldset style={{ marginTop: '0', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Seleção</legend>
                            <Content>
                                {inputRotas.map((inputs, index) => {
                                    return (
                                        <div className="group" key={inputs}>
                                            <TextField id="outlined-basic" label="" variant="outlined" select name="id"
                                                value={inputs.id} onChange={event => handleRotaChange(index, event)}
                                            >
                                                {nameRoute.map((items, index) => {
                                                    return (
                                                        <MenuItem key={index} value={items.rota}>{items.rota}</MenuItem>
                                                    );
                                                })}
                                            </TextField>
                                            <ButtonRemove
                                                className="dialog-button"
                                                title="Retirar rota da imposição"
                                                variant="outlined"
                                                color="primary"
                                                type="button"
                                                style={{ "height": "43px" }}
                                                onClick={() => handleRemoveInputs(index)}
                                            >
                                                <IconRemove />
                                            </ButtonRemove>
                                        </div>
                                    );
                                })}
                            </Content>
                            <ButtonAdd title="Nova rota" onClick={() => handleAddRota()}>
                                <AddToPhotosIcon></AddToPhotosIcon>
                            </ButtonAdd>
                        </fieldset>
                        <fieldset style={{ marginTop: '15px', borderRadius: '4px', border: '1px solid silver' }}>
                            <legend>Configurações</legend>
                            <div className="group">
                                <TextField type="number" id="outlined-basic" label="Plano:" variant="outlined" defaultValue={namePlanRota} onChange={e => setNamePlanRota(e.target.value)} />
                                <TextField type="number" id="outlined-basic" label="Minutos:" variant="outlined" defaultValue={timeRota} onChange={e => setTimeRota(e.target.value)} />
                            </div>
                        </fieldset>
                        <AreaButtons message={alertMsg} className={permissao}>
                            <div className="alert">
                                <Alert severity={typeAlert}>{msg}</Alert>
                            </div>
                            <Buttons>
                                <ButtonCancel
                                    title="Cancelar Imposição"
                                    onClick={cancelarInposicaoRotas}
                                >
                                    <RiCloseCircleLine size={21} color='#001E62' />
                                </ButtonCancel>
                                <ButtonSend status={permissao} onClick={handleSubmitRotas}>
                                    ENVIAR IMPOSIÇÃO
                                </ButtonSend>
                            </Buttons>
                        </AreaButtons>
                    </div>
                </AreaRota>
            </Modal>
        </Dialog>
    );
}
