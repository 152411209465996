import React, { useState, useEffect } from 'react';
import { Container, AreaCharts, AreaTable, Table, InfoHelp, AreaBallon, ContentBallon } from './styles';
import { Doughnut } from 'react-chartjs-2';
import ModalStatus from './ModalStatus';
import ButtonReflesh from '../../ButtonReflesh';
import ButtonFilter from '../../ButtonFilter';
import io from 'socket.io-client';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../Balloons';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import { validarPermissao } from '../../../../services/permission';
import { tocarVozEstado } from '../../../Audio';

const permissao = ( //0,1,2
    validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled'
);

export default function ChartStatus(props) {

    const [openModalStatus, setOpenModalStatus] = useState(false);
    const [status, setStatus] = useState([]);
    const [statusNormal, setStatusNormal] = useState(0);
    const [statusApagado, setStatusApagado] = useState(0);
    const [statusFaltaDeEnergia, setStatusFaltaDeEnergia] = useState(0);
    const [statusSubtensao, setStatusSubtensao] = useState(0);
    const [statusPerdaDeComunicacao, setStatusPerdaDeComunicacao] = useState(0);
    const [statusAmareloIntermitente, setStatusAmareloIntermitente] = useState(0);
    const [statusEstacionadoApagado, setStatusEstacionadoApagado] = useState(0);
    const [statusSubtensaoApagado, setStatusSubtensaoApagado] = useState(0);
    const [statusSubtensaoEstacionado, setStatusSubtensaoEstacionado] = useState(0);
    const [statusEstacionado, setStatusEstacionado] = useState(0);
    const [statusAmareloIntermitenteSubtensao, setStatusAmareloIntermitenteSubtensao] = useState(0);
    const [statusOffline, setStatusOffline] = useState(0);
    const [porcentagemNormal, setPorcentagemNormal] = useState(parseFloat(0).toFixed(2));
    const [porcentagemApagado, setPorcentagemApagado] = useState(0);
    const [porcentagemFaltaDeEnergia, setPorcentagemFaltaDeEnergia] = useState(0);
    const [porcentagemSubtensao, setPorcentagemSubtensao] = useState(0);
    const [porcentagemPerdaDeComunicacao, setPorcentagemPerdaDeComunicacao] = useState(0);
    const [porcentagemAmareloIntermitente, setPorcentagemAmareloIntermitente] = useState(0);
    const [porcentagemSubtensaoApagado, setPorcentagemSubtensaoApagado] = useState(0);
    const [porcentagemSubtensaoEstacionado, setPorcentagemSubtensaoEstacionado] = useState(0);
    const [porcentagemEstacionado, setPorcentagemEstacionado] = useState(0);
    const [porcentagemAmareloIntermitenteSubtensao, setPorcentagemAmareloIntermitenteSubtensao] = useState(0);
    const [porcentagemOffline, setPorcentagemOffline] = useState(0);
    const [totalEquipamentos, setTotalEquipamentos] = useState(0);
    const [visibleBalloon, setVisibleBalloon] = useState(false);

    const data = {
        labels: ['Normal', 'Apagado', 'Falta de Energia', 'Subtensão', 'Perda de Comunicação', 'Amarelo Intermitente', 'Estacionado e Apagado', 'Subtensão e Apagado', 'Subtensão e Estacionado', 'Estacionado', 'Amarelo Intermitente e Subtensão', 'Offline'],
        datasets: [
            {
                label: '# of Votes',
                data: [statusNormal, statusApagado, statusFaltaDeEnergia, statusSubtensao, statusPerdaDeComunicacao, statusAmareloIntermitente, statusEstacionadoApagado, statusSubtensaoApagado, statusSubtensaoEstacionado, statusEstacionado, statusAmareloIntermitenteSubtensao, statusOffline],
                backgroundColor: [
                    '#2b72ce',              //Normal
                    '#802447',              //Apagado
                    '#aa3c3c',              //Falta de energia
                    'rgb(218,165,32)',      //Subtensao
                    '#0a0a0a',              //Perda de Comunicacao
                    'rgb(255,255,0)',       //Amarelo Intermitente
                    'rgb(47,79,79)',        //Estacionado e Apagado
                    'rgb(255,192,203)',     //Subtensao e Apagado
                    'rgb(75,0,130)',        //Subtensao e Estacionado
                    'rgb(0,0,205)',         //Estacionado
                    '#f87851',              //Amarelo Intermitente e Subtensao
                    '#474545',              //Offline

                ],
                /*borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,*/
            },
        ],
    }

    useEffect(() => {

        if (typeof props.dadosEstatisticas.status != 'undefined') {

            const estatisticas = props.dadosEstatisticas;
            const status = estatisticas.status;
            const porcentagemStatus = estatisticas.porcentagemStatus;
            //const modoOperacionalOffline =  // revisão futura, a propriedade offline de status não esta trazendo as informações, usando no momente as informações geradas em modoOperacional
            const totalSemComunicacao = (props.dadosEstatisticas.modosOperacionais.offline - status.perdaDeComunicacao)


            setStatusNormal(status.normal);
            setStatusApagado(status.apagado);
            setStatusFaltaDeEnergia(status.faltaDeEnergia);
            setStatusSubtensao(status.subtensao);
            setStatusPerdaDeComunicacao(status.perdaDeComunicacao);
            setStatusAmareloIntermitente(status.amareloIntermitente);
            setStatusEstacionadoApagado(status.estacionadoApagado);
            setStatusSubtensaoApagado(status.subtensaoApagado);
            setStatusSubtensaoEstacionado(status.subtensaoEstacionado);
            setStatusEstacionado(status.estacionado);
            setStatusAmareloIntermitenteSubtensao(status.amareloIntermitenteSubtensao);
            setStatusOffline(totalSemComunicacao);
            setPorcentagemNormal(porcentagemStatus.normal);
            setPorcentagemApagado(porcentagemStatus.apagado);
            setPorcentagemFaltaDeEnergia(porcentagemStatus.faltaDeEnergia);
            setPorcentagemSubtensao(porcentagemStatus.subtensao);
            setPorcentagemPerdaDeComunicacao(porcentagemStatus.perdaDeComunicacao);
            setPorcentagemAmareloIntermitente(porcentagemStatus.amareloIntermitente);
            setPorcentagemSubtensaoApagado(porcentagemStatus.subtensaoApagado);
            setPorcentagemSubtensaoEstacionado(porcentagemStatus.subtensaoEstacionado);
            setPorcentagemEstacionado(porcentagemStatus.estacionado);
            setPorcentagemAmareloIntermitenteSubtensao(porcentagemStatus.amareloIntermitenteSubtensao);
            setPorcentagemOffline(porcentagemStatus.offline);
            setTotalEquipamentos(estatisticas.total);
        }

    }, [props]);

    //salvar no localStorage o tipo de filtro, para quando for realizar o filtro escolher por estado
    const setModoFilter = () => {
        localStorage.setItem('ModoFiltro', 'estado');
    }

    const closeModalStatus = () => {
        setOpenModalStatus(false);
    }

    const openModal = () => {
        setOpenModalStatus(true);
    }

    const options = {
        legend: {
            display: false
        }
    };

    const filterIntersections = (event) => {
        event.preventDefault();

        localStorage.setItem('filterOpen', 'true');//variavel para definir cor do botao se mostra setado ou não
        localStorage.setItem('typeFilterControllers', 'Falha'); //guarda o tipo do filtro que sera feito
        localStorage.setItem('valueFilterControllers', status);//pega o valor a ser filtrado    
        if (localStorage.getItem('typeFilterControllers') === 'Rotas') {
            const socket = io(process.env.REACT_APP_SERVER_HOST, {
                query: { token: `Bearer ${localStorage.getItem('@central-de-falhas-greenwave/tokenAuth')}` }
            });

            socket.on('connect', () => console.log('[IO] Connect => New connection'));
        }
        window.location.href = "/mapa";
    }

    /**
     * @autor Felipe alves
     * @param infos  valor do input selecionado
     * @descrição função criada para poder selecionar mais de um filtro
     * @returns apenas acrescenta o novo valor a variavel (status) 
     */

    function valueFilters(infos, validacao) {

        var res = [...status];

        if (infos !== "" || infos !== undefined) {

            if (validacao === true) {
                res.push(infos)
            } else {
                res.splice(res.indexOf(infos), 1)
            }
        }

        var novaArr = res.filter(function (este, i) {
            return res.indexOf(este) === i;
        });
        return setStatus(novaArr)
    }




    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonOperacional = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Estado</h3>
                    <label onClick={tocarVozEstado}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    A tela de “Estado” consiste em apresentar dados estatísticos conforme todos os estados de ponto, são eles:
                    normal, apagado, falta de energia, subtensão, amarelo intermitente, perda de comunicação, estacionado,
                    subtensão e apagado, subtensão e estacionado.
                    No final da tela também há um botão com a opção de filtro, ao selecionar os estados requeridos clique em
                    filtrar, a aplicação retornará os dados filtrados na tela do mapa.
                    Na linha “Amarelo intermitente” há um botão, ele é responsável por resetar o controlador, pois se num estado
                    em que o semáforo está em amarelo piscante, as vezes o reset do controlador pode fazê-lo voltar ao normal.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return (
        <Container>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonOperacional /></BallonsInfoModal>
            </AreaBallon>
            <AreaCharts>
                <Doughnut data={data} options={options} />
            </AreaCharts>
            <AreaTable>
                <Table>
                    <thead>
                        <tr>
                            <th>Estado</th>
                            <th>Qntd</th>
                            <th>%</th>
                            <th>Filtrar</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Normal</td>
                            <td>{statusNormal}</td>
                            <td>{parseFloat(porcentagemNormal).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Normal" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Falta de energia</td>
                            <td>{statusFaltaDeEnergia}</td>
                            <td>{parseFloat(porcentagemFaltaDeEnergia).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Falta de Energia" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Subtensão</td>
                            <td>{statusSubtensao}</td>
                            <td>{parseFloat(porcentagemSubtensao).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Subtensao de Rede" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Apagado</td>
                            <td>{statusApagado}</td>
                            <td>{parseFloat(porcentagemApagado).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Apagado" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Apagado e Subtensão</td>
                            <td>{statusSubtensaoApagado}</td>
                            <td>{parseFloat(porcentagemSubtensaoApagado).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Subtensão de Rede e Apagado" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td id="modal">Amarelo intermitente <div onClick={openModal}><div className={permissao} title="Resetar controlador"><ButtonReflesh /></div></div></td>
                            <td>{statusAmareloIntermitente}</td>
                            <td>{parseFloat(porcentagemAmareloIntermitente).toFixed(1) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Amarelo Intermitente" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Amarelo intermitente e Subtensão</td>
                            <td>{statusAmareloIntermitenteSubtensao}</td>
                            <td>{parseFloat(porcentagemAmareloIntermitenteSubtensao).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Subtensao de Rede, Amarelo intermitente" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Estacionado</td>
                            <td>{statusEstacionado}</td>
                            <td>{parseFloat(porcentagemEstacionado).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Estacionado" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Estacionado e Subtensão</td>
                            <td>{statusSubtensaoEstacionado}</td>
                            <td>{parseFloat(porcentagemSubtensaoEstacionado).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Subtensao de Rede e Estacionado" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Perda de comunicação</td>
                            <td>{statusPerdaDeComunicacao}</td>
                            <td>{parseFloat(porcentagemPerdaDeComunicacao).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Perda de Comunicação" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td>Sem Comunicação</td>
                            <td>{statusOffline}</td>
                            <td>{parseFloat(porcentagemOffline).toFixed(2) + "%"}</td>
                            <td><input type="checkbox" name="groupRadio" value="Servidor Reiniciado,Offline" onClick={(e) => { valueFilters(e.target.value, e.target.checked); setModoFilter() }} /></td>
                        </tr>
                        <tr>
                            <td colSpan="3" id="total">Total de equipamentos: {totalEquipamentos}</td>
                            <td id="filter"><div onClick={filterIntersections} title="Filtrar por estado"><ButtonFilter /></div></td>
                        </tr>
                    </tbody>
                </Table>
            </AreaTable>
            <ModalStatus
                open={openModalStatus}
                onClose={closeModalStatus}
                locaisMapa={props.locaisMapa}
            >
            </ModalStatus>
        </Container>
    );
}
