import styled, { keyframes } from "styled-components";

const emerge = keyframes`
    0% { opacity: 0;}
    50% { opacity: 0.4;}
    100% { opacity: 1;}
`;

export const BalloonUp = styled.div`
    background: #FFF;
    color: #040404;
    border-radius: 15px;
    width: 325px;
    height: 130px;
    padding: 0 16px;
    position: fixed;
    top: 143px;
    right: 150px;
    box-shadow: 5px 5px 7px -1px #646060;
    display: ${props => props.visible === true ? 'block' : 'none'};
    animation: ${emerge} 1s linear;
    z-index: 1;


    &:after {
        content: "";
        width: 0;
        height: 0px;
        position: absolute;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #FFF;
        top: -22px;
        right: 11px;
    }
`;

export const BalloonRight = styled(BalloonUp)`
    position: absolute;
    display: block;
    top: -35px;
    right: -388px;
    height: auto;
    padding: 0 16px 15px 20px;

    @media(max-width: 480px){
        width: 90%;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0; 
        height: 0; 
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; 
        border-right: 30px solid #13A5DA; 
        top: 45px;
        left: -45px;
    }
`;

export const BalloonMenuLateral = styled(BalloonUp)`
    position: absolute;
    display: block;
    right: -388px;
    height: auto;
    padding: 0 16px 15px 20px;

    @media(max-width: 480px){
        width: 90%;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0; 
        height: 0; 
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; 
        border-right: 30px solid #13A5DA; 
        top: 45px;
        left: -45px;
    }

`;

export const BallonParametros = styled(BalloonUp)`
    position: fixed;
    display: block;
    top: 44px;
    right: -317px;

    @media(max-width: 480px){
        width: 90%;
        top: 160px;
        right: 7px;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0; 
        height: 0; 
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; 
        border-right: 30px solid #13A5DA; 
        top: 45px;
        left: -45px;
    }
`;

export const BallonInfoModal = styled(BalloonUp)`
    position: absolute;
    display: block;
    top: 45px;
    right: -7px;
    height: auto;
    padding: 0 16px 15px 20px;
    z-index: 2;

    @media(max-width: 480px){
        width: 90%;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 30px solid #13A5DA;
        top: -29px;
        right: 0;
        z-index: 2;
    }
`;

export const BalloonCroqui = styled(BalloonUp)`
    display: block;
    height: auto;
    padding: 0 16px 15px 20px;
    top: 0px;
    right: 314px;
    z-index: 15;

    @media(max-width: 480px){
        width: 90%;
    }

    &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        width: 0; 
        height: 0; 
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent; 
        border-left: 30px solid #13A5DA; 
        top: 20px;
        right: -60px;
    }
`;
