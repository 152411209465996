import React, { useState, useEffect, useRef, useContext} from 'react';
import { ModalContext } from '../../../../../contextModal';
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BoxMenu, Title, AreaInput, Search, CloseFilter, BoxFilter, AreaButtons, Button } from '../styles';


export default function FiltroAcao(props){

    const [pesquisa, setPesquisa] = useState('');  //guarda resultado da pesquisa
    //const [resultado, setResultado] = useState([]); //guarda resultado do filtro 
    const [selecao, setSelecao] = useState([]); // estados para guardar a seleção de items do filtro
    const [enabled, setEnabled] = useState(true);
   
    const myRef = useRef();

    const { setStatusFilterAcao } = useContext(ModalContext);

    const getIdentificacao = (e,index) => {
 
        if(e.target.checked){
            const values = [...selecao];
            values[index] = e.target.value;
            setSelecao(values);
        }else{
            const values = [...selecao];
            values[index] = '';
            setSelecao(values);
        }
    }

    const filtrarIdentificacao = () => {
        const filtered = selecao.filter(Boolean);
        props.setParamAcao(filtered);
        props.toggle();

        
        if(filtered.length > 0){
            setStatusFilterAcao('Dados Filtrados');
        }else{
            setStatusFilterAcao('Filtro Limpo');
        }

    }

  
    window.onload = props.cbPesquisar; // aliemntar os boxes de filtro ao abrir página, por algum motivo useEffect não esta funcionando

    useEffect(() => {
        const results = props.dados.filter( palavra => palavra.toLowerCase().includes(pesquisa));
        if(results === ''){
            //setResultado(['Nenhuma correspondência']);
            setEnabled(false);
        }else{
            //setResultado(results);
            setEnabled(true);
        }
    },[pesquisa, props.dados]);

   
    //capturar evento do click fora do filtro para fechar caixa
    useEffect(() => {
        document.addEventListener('mousedown', (e) =>{
            if(!myRef.current.contains(e.target)){
                props.onClose(false);
            }
        });
    },[]);

    return(
        <BoxMenu className='identificacao' ref={myRef} display={props.open}>
            <Title>
                <h2>Filtrar
                    <CloseFilter onClick={() => props.onClose(false)}>
                        <MdClose size="18"/>
                    </CloseFilter>
                </h2>
                <span>Selecionar item:</span>
                <AreaInput>
                    <input type="text" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)}/>
                    <Search>
                        <FaSearch />
                    </Search>
                </AreaInput>
            </Title>
            <BoxFilter>
                <ul>
                    {props.dados !== undefined || props.dados !== ""  ? 
                        props.dados.map( (items,index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)}/>{items}</li>   
                        )):
                        props.dados.map( (items,index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)}/>{items}</li>   
                        ))
                    }
                </ul>
            </BoxFilter>
            <AreaButtons status={enabled}>
                <Button onClick={filtrarIdentificacao}>Ok</Button>
                <Button onClick={() => props.onClose(false)}>Cancelar</Button>
            </AreaButtons>
        </BoxMenu>
    );
}