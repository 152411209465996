import React from 'react';
import Mapas from '../../components/Mapas';
import MenuHorizontal from '../../components/MenuHorizontal';

import { SearchControl, OpenStreetMapProvider } from 'react-leaflet-geosearch';
import "./react-leaflet-geosearch.css";
import './style.css';

const prov = OpenStreetMapProvider();
const GeoSearchControlElement = SearchControl;

//<MenuHorizontal /> ante s de Mapas

function MapaPage(){
    return(
      <div>
        <Mapas id="map-main">
          <GeoSearchControlElement
            position='topright'
            expanded={true}
            provider={prov}
            showMarker={false}
            showPopup={false}
            popupFormat={({ query, result }) => result.label}
            maxMarkers={1}
            retainZoomLevel={false}
            animateZoom={true}
            autoClose={true}
            searchLabel={"Digite o endereço desejado"}
            keepResult={true}
            autoComplete= {true}
          />
        </Mapas>
      </div>
    );  
}
export default MapaPage;