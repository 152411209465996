import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Dialog from '@material-ui/core/Dialog';
import PageTitle from '../PageTitle';
import IconRemove from './icons/Remove';
import Save from './icons/Save';
import { AiOutlineClear } from "react-icons/ai";
import { MdAddToPhotos } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import api from '../../services/api';
import 'react-toastify/dist/ReactToastify.css';
import tick from '../../assets/img/tick.png';
import info from '../../assets/img/info.png';
import {
    Container, Close, AreaSelect, Select, Fieldset, ButtonsTab, Btn, Ico, AreaAgenda, Table1, Table2, Table3,
    Button, MenuButtons, RowTop, RowBottom, AreaWeek, AreaButtons, Legend
} from './styles';
import { GrClose } from "react-icons/gr";
import { validarPermissao } from '../../services/permission';
import Alert from '@material-ui/lab/Alert';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function RegisterScheduleArea(props) {

    const [tab1, setTab1] = useState('active');
    const [tab2, setTab2] = useState('deactive');
    const [tab3, setTab3] = useState('deactive');
    const [viewTab1, setViewTab1] = useState('block');
    const [viewTab2, setViewTab2] = useState('none');
    const [viewTab3, setViewTab3] = useState('none');
    const [statusTab1, setStatusTabs1] = useState('active');
    const [statusTab2, setStatusTabs2] = useState('active');
    const [statusTab3, setStatusTabs3] = useState('active');
    const [statusWeek, setStatusWeek] = useState('active');
    const [nameZone, setNameZone] = useState('');
    const [nameZones, setNameZones] = useState([]);
    const [domingo, setDomingo] = useState('');
    const [segunda, setSegunda] = useState('');
    const [terca, setTerca] = useState('');
    const [quarta, setQuarta] = useState('');
    const [quinta, setQuinta] = useState('');
    const [sexta, setSexta] = useState('');
    const [sabado, setSabado] = useState('');
    const [tables, setTables] = useState([]);
    const [tableOneInfo, setTableOneInfo] = useState([]);
    const [tableTwoInfo, setTableTwoInfo] = useState([]);
    const [tableTreeInfo, setTableTreeInfo] = useState([]);
    const [tableOne, setTableOne] = useState([]);
    const [tableTwo, setTableTwo] = useState([]);
    const [tableTree, setTableTree] = useState([]);
    const [infoTab1, setInfoTab1] = useState('');
    const [infoTab2, setInfoTab2] = useState('');
    const [infoTab3, setInfoTab3] = useState('');
    const [desabledBtn, setDesabledBtn] = useState('desabledBtn')
    const [inputFieldsTab1, setInputFieldsTab1] = useState([{ planos: '', time: '' }]);
    const [inputFieldsTab2, setInputFieldsTab2] = useState([{ planos: '', time: '' }]);
    const [inputFieldsTab3, setInputFieldsTab3] = useState([{ planos: '', time: '' }]);
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');


    const handleAddFieldsTab1 = () => {
        const values = [...inputFieldsTab1];
        values.push({ planos: '', time: '' });
        setInputFieldsTab1(values);
    }

    const handleRemoveFieldsTab1 = index => {
        const values = [...inputFieldsTab1];
        values.splice(index, 1);
        setInputFieldsTab1(values);
    };

    const handleInputChangeTab1 = (index, event) => {
        const values = [...inputFieldsTab1];
        if (event.target.id === "planos") {
            values[index].planos = event.target.value;
        }
        if (event.target.id === "time") {
            values[index].time = event.target.value;
        }
        setInputFieldsTab1(values);
    };

    //inputs tab 2
    const handleAddFieldsTab2 = () => {
        const values = [...inputFieldsTab2];
        values.push({ planos: '', time: '' });
        setInputFieldsTab2(values);
    }

    const handleRemoveFieldsTab2 = index => {
        const values = [...inputFieldsTab2];
        values.splice(index, 1);
        setInputFieldsTab2(values);
    };

    const handleInputChangeTab2 = (index, event) => {
        const values = [...inputFieldsTab2];
        if (event.target.id === "planos") {
            values[index].planos = event.target.value;
        }
        if (event.target.id === "time") {
            values[index].time = event.target.value;
        }
        setInputFieldsTab2(values);
    };

    //inputs tab 3
    const handleAddFieldsTab3 = () => {
        const values = [...inputFieldsTab3];
        values.push({ planos: '', time: '' });
        setInputFieldsTab3(values);
    }

    const handleRemoveFieldsTab3 = index => {
        const values = [...inputFieldsTab3];
        values.splice(index, 1);
        setInputFieldsTab3(values);
    };

    const handleInputChangeTab3 = (index, event) => {
        const values = [...inputFieldsTab3];
        if (event.target.id === "planos") {
            values[index].planos = event.target.value;
        }
        if (event.target.id === "time") {
            values[index].time = event.target.value;
        }
        setInputFieldsTab3(values);
    };


    const selectTab1 = () => {
        setTab1('active');
        setTab2('deactive');
        setTab3('deactive');
        setViewTab1('block');
        setViewTab2('none');
        setViewTab3('none');
    }
    const selectTab2 = () => {
        setTab1('deactive');
        setTab2('active');
        setTab3('deactive');
        setViewTab1('none');
        setViewTab2('block');
        setViewTab3('none');
    }
    const selectTab3 = () => {
        setTab1('deactive');
        setTab2('deactive');
        setTab3('active');
        setViewTab1('none');
        setViewTab2('none');
        setViewTab3('block');
    }

    useEffect(() => {
        getAllNameZones();
        setNameZone('');
        setInputFieldsTab1([{ planos: '', time: '' }]);
        setInputFieldsTab2([{ planos: '', time: '' }]);
        setInputFieldsTab3([{ planos: '', time: '' }]);
        setDomingo('');
        setSegunda('');
        setTerca('');
        setQuarta('');
        setQuinta('');
        setSexta('');
        setSabado('');
        setInfoTab1(info);
        setInfoTab2(info);
        setInfoTab3(info);
    }, [props.open]);

    const getAllNameZones = async () => {
        await api.get('/centraldefalhas/zones/list').then(res => {
            setNameZones(res.data);
        }).catch(error => {
            console.log(error);
        });
    }

    const saveTab1 = () => {
        let createNewMenu = true;
        for (let i = 0; i < inputFieldsTab1.length; i++) {
            if ([inputFieldsTab1[i].planos].includes('')) {
                setMsg('Existem planos vazios!');
                setAlertMsg('block');
                setTypeAlert('error');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
            if ([inputFieldsTab1[i].time].includes('')) {
                setMsg('Um plano não possui horário!');
                setAlertMsg('block');
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
        }
        if (createNewMenu) {
            setTables([...tables, 'tab1']);
            setStatusTabs1('deactive');
            setInfoTab1(tick);
            setMsg('Tabela 1 validada!');
            setAlertMsg('block');
            setTypeAlert('info');
            setTimeout(() => {
                setAlertMsg('none');
            }, 2000);

        } else {
            return 0
        }
    }

    const saveTab2 = () => {
        let createNewMenu = true;
        for (let i = 0; i < inputFieldsTab2.length; i++) {
            if ([inputFieldsTab2[i].planos].includes('')) {
                setMsg('Existem planos vazios!');
                setAlertMsg('block');
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
            if ([inputFieldsTab2[i].time].includes('')) {
                setMsg('Um plano não possui horário!');
                setAlertMsg('block');
                setTypeAlert('info');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
        }
        if (createNewMenu) {
            setTables([...tables, 'tab2']);
            setStatusTabs2('deactive');
            setInfoTab2(tick);
            setMsg('Tabela 2 validada!');
            setAlertMsg('block');
            setTypeAlert('info');
            setTimeout(() => {
                setAlertMsg('none');
            }, 2000);
        } else {
            return 0
        }
    }
    const saveTab3 = () => {
        let createNewMenu = true;
        for (let i = 0; i < inputFieldsTab3.length; i++) {
            if ([inputFieldsTab3[i].planos].includes('')) {
                setMsg('Existem planos vazios!');
                setAlertMsg('block');
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
            if ([inputFieldsTab3[i].time].includes('')) {
                setMsg('Um plano não possui horário!');
                setAlertMsg('block');
                setTypeAlert('warning');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                return createNewMenu = false;
            }
        }
        if (createNewMenu) {
            setTables([...tables, 'tab3']);
            setStatusTabs3('deactive');
            setInfoTab3(tick);
            setMsg('Tabela 3 validada!');
            setAlertMsg('block');
            setTypeAlert('info');
            setTimeout(() => {
                setAlertMsg('none');
            }, 2000);
        } else {
            return 0
        }
    }

    const editCalendar = () => {
        limparDias();
        setTables([]);
        setStatusTabs1('active');
        setStatusTabs2('active');
        setStatusTabs3('active');
        setStatusWeek('active');
        setInfoTab1(info);
        setInfoTab2(info);
        setInfoTab3(info);
    }

    const validarDiasDaSemana = (e) => {
        e.preventDefault();
        const semana = [domingo, segunda, terca, quarta, quinta, sexta, sabado];
        if ((semana).includes('')) {
            setMsg('Existem dias da semana não preenchidos!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
        }
        if (nameZone === '') {
            setMsg('Preencher nome da área!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
        }
        else {
            getControllersRoute();
        }
    }

    const getControllersRoute = async () => {

        let dadosTab1Concatenados = '';
        let dadosTab2Concatenados = '';
        let dadosTab3Concatenados = '';

        inputFieldsTab1.forEach(element => {
            dadosTab1Concatenados += `${element.time}-${element.planos},`;
        });

        inputFieldsTab2.forEach(element => {
            dadosTab2Concatenados += `${element.time}-${element.planos},`;
        });

        inputFieldsTab3.forEach(element => {
            dadosTab3Concatenados += `${element.time}-${element.planos},`;
        });

        await api.post('/centraldefalhas/schedule/register', {
            "zone": nameZone,
            "_0": domingo,                          //defineTable(domingo),
            "_1": segunda,                          //defineTable(segunda)
            "_2": terca,                           //defineTable(terca)
            "_3": quarta,                           //defineTable(quarta)
            "_4": quinta,                           //defineTable(quinta)
            "_5": sexta,                            //defineTable(sexta)
            "_6": sabado,                           //defineTable(sabado)
            "tab1": dadosTab1Concatenados,
            "tab2": dadosTab2Concatenados,
            "tab3": dadosTab3Concatenados

        }).then(res => {

            setMsg('Agenda cadastrada com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');
            setTimeout(() => {
                setAlertMsg('none');
            }, 2000);

        }).catch(error => {
            setMsg('Erro ao cadastrar agenda!');
            setAlertMsg('block');
            setTypeAlert('success');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
        })
    }

    const getInfoScheduleZones = async () => {//pega informaçoes da agenda de uma Àrea
        if (nameZone !== "") {
            setTables([]);
            setTableOne([]);
            setTableTwo([]);
            setTableTree([]);
            setTableOneInfo([]);
            setTableTwoInfo([]);
            setTableTreeInfo([]);
            limparDias()
            setInputFieldsTab1([{ planos: '', time: '' }]);
            setInputFieldsTab2([{ planos: '', time: '' }]);
            setInputFieldsTab3([{ planos: '', time: '' }]);

            setMsg('Verificando se tem agenda cadastrada...');
            setAlertMsg('block');
            setTypeAlert('success');
            setTimeout(() => {
                setAlertMsg('none');
            }, 4000);

            api.post('centraldefalhas/schedule/get', {
                "zone": nameZone
            }).then(res => {
                if (res.data.status === "Failed to find schedule") {
                    setInfoTab1(info);
                    setInfoTab2(info);
                    setInfoTab3(info);

                    setMsg('Área sem agenda cadastrada!');
                    setAlertMsg('block');
                    setTypeAlert('error');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 3000);


                } else {
                    setInfoTab1(tick);
                    setInfoTab2(tick);
                    setInfoTab3(tick);

                    setMsg('Carregando agenda cadastrada!');
                    setAlertMsg('block');
                    setTypeAlert('info');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 2000);

                    setTables(['tab1', 'tab2', 'tab3']);
                    setDomingo(`${res.data._0}`);
                    setSegunda(`${res.data._1}`);
                    setTerca(`${res.data._2}`);
                    setQuarta(`${res.data._3}`);
                    setQuinta(`${res.data._4}`);
                    setSexta(`${res.data._5}`);
                    setSabado(`${res.data._6}`);
                    setTableOneInfo(res.data.tab1.split(','));
                    setTableTwoInfo(res.data.tab2.split(','));
                    setTableTreeInfo(res.data.tab3.split(','));

                }
            }).catch(error => {
                setInfoTab1(info);
                setInfoTab2(info);
                setInfoTab3(info);
                setMsg('Erro ao verificar se tem agenda na àrea!');
                setAlertMsg('block');
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
            })
        } else {
            setInputFieldsTab1([{ planos: '', time: '' }]);
            setInputFieldsTab2([{ planos: '', time: '' }]);
            setInputFieldsTab3([{ planos: '', time: '' }]);
            setDomingo('');
            setSegunda('');
            setTerca('');
            setQuarta('');
            setQuinta('');
            setSexta('');
            setSabado('');
            return 0
        }
    }

    function addInfosTableEdit(valor, tabela) {

        if (valor.length > 0) {
            let volume = []

            for (let i = 0; i < valor.length - 1; i++) {
                let quardaValor = [valor[i].split('-')];
                let quardaValor2 = { planos: quardaValor[0][1], time: quardaValor[0][0] }
                volume.push(quardaValor2);
            };

            if (tabela == 1) {
                setInputFieldsTab1(volume);
            }
            if (tabela == 2) {
                setInputFieldsTab2(volume);
            }
            if (tabela == 3) {
                setInputFieldsTab3(volume);
            }
        }
    }

    useEffect(() => {
        addInfosTableEdit(tableOneInfo, 1);
    }, [tableOneInfo])

    useEffect(() => {
        addInfosTableEdit(tableTwoInfo, 2);
    }, [tableTwoInfo])

    useEffect(() => {
        addInfosTableEdit(tableTreeInfo, 3);
    }, [tableTreeInfo])

    useEffect(() => {
        getInfoScheduleZones();
        //setNameZone(props.nameArea);

    }, [nameZone]);

    const desabledTabs = () => {
        setStatusTabs1('deactive');
        setStatusTabs2('deactive');
        setStatusTabs3('deactive');
        setStatusWeek('deactive');
    }

    const clearData = () => {
        setInputFieldsTab1([{ planos: '', time: '' }]);
        setInputFieldsTab2([{ planos: '', time: '' }]);
        setInputFieldsTab3([{ planos: '', time: '' }]);
    }

    const limparDias = () => {
        setDomingo('');
        setSegunda('');
        setTerca('');
        setQuarta('');
        setQuinta('');
        setSexta('');
        setSabado('');
    }

    const listaTabelasConfirmadas = (dia) => (
        tables.map((table) => {
            if (table == dia) {
                return (<option key={table} value={table} selected>{table}</option>)
            } else {
                return (<option key={table} value={table}>{table}</option>)
            }
        })
    )

    const preencheTabelaRecebida = (dia) => {
        if (tables.includes(dia)) {
            return (<option key='0' value="" >----</option>)
        } else {
            return (<option key='0' value="" selected>----</option>)
        }
    }

    const deleteSchedule = async () => {
        if (window.confirm('Confirme para excluir agenda dessa área')) {
            await api.post('/centraldefalhas/schedule/remove', {
                "zone": nameZone
            }).then(res => {
                if (res.data.status === "Schedule successfully deleted ") {
                    setMsg('Agenda excluída com sucesso!');
                    setAlertMsg('block');
                    setTypeAlert('success');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 2000);
                    getAllNameZones();
                } else if (res.data.error === "Failed to Schedule") {
                    setMsg('Área não tem agenda!');
                    setAlertMsg('block');
                    setTypeAlert('info');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 2000);
                }
                getInfoScheduleZones();
                setNameZone('');

            }).catch(error => {
                setMsg('Erro ao excluir agenda!');
                setAlertMsg('block');
                setTypeAlert('error');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
            })
        }
    }

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Agenda"
                onClick={props.onClose}
            ></PageTitle>
            <Container onSubmit={validarDiasDaSemana}>
                <Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
                <AreaSelect>
                    <Select className='area' value={nameZone} onChange={(e) => { setNameZone(e.target.value); desabledTabs(); setDesabledBtn('enabled'); }}>
                        <option value="">Nome da área</option>
                        {nameZones.map((data) => (
                            <option key={data.area} value={data.area}>{data.area}</option>
                        ))}
                    </Select>
                    <Fieldset id={desabledBtn}>
                        <legend>Selecione uma tabela</legend>
                        <ButtonsTab>
                            <Btn className={tab1} onClick={selectTab1}>{infoTab1 === '' ? '' : <Ico src={infoTab1} alt="!" />}Tabela1</Btn>
                            <div className="line"></div>
                            <Btn className={tab2} onClick={selectTab2}>{infoTab2 === '' ? '' : <Ico src={infoTab2} alt="!" />}Tabela2</Btn>
                            <div className="line"></div>
                            <Btn className={tab3} onClick={selectTab3}>{infoTab3 === '' ? '' : <Ico src={infoTab3} alt="!" />}Tabela3</Btn>
                        </ButtonsTab>
                        <AreaAgenda>
                            <Table1 visible={viewTab1} className={statusTab1}>
                                {inputFieldsTab1.map((inputField, index) => (
                                    <RowTop key={`${inputField}~${index}`}>
                                        <TextField type='number' id="planos" label="Plano" variant="outlined" size="small" value={inputField.planos} onChange={event => handleInputChangeTab1(index, event)} />
                                        <TextField type='time' id="time" label="Horário" variant="outlined" size="small" value={inputField.time} onChange={event => handleInputChangeTab1(index, event)} />
                                        <Button onClick={() => handleRemoveFieldsTab1(index)} title="Excluir tabela"><IconRemove /></Button>
                                    </RowTop>
                                ))}
                                <RowBottom>
                                    <MenuButtons>
                                        <Button onClick={clearData} title="Limpar"><AiOutlineClear color="#001E62" /></Button>
                                        <Button onClick={handleAddFieldsTab1} title="habilitar edição"><MdAddToPhotos color="#001E62" size={18} /></Button>
                                        <Button onClick={saveTab1} title="Salvar dados"><Save /></Button>
                                    </MenuButtons>
                                </RowBottom>
                            </Table1>
                            <Table2 visible={viewTab2} className={statusTab2}>
                                {inputFieldsTab2.map((inputField, index) => (
                                    <RowTop key={`${inputField}~${index}`}>
                                        <TextField type='number' id="planos" label="Plano" variant="outlined" size="small" value={inputField.planos} onChange={event => handleInputChangeTab2(index, event)} />
                                        <TextField type='time' id="time" label="Horário" variant="outlined" size="small" value={inputField.time} onChange={event => handleInputChangeTab2(index, event)} />
                                        <Button onClick={() => handleRemoveFieldsTab2(index)}><IconRemove /></Button>
                                    </RowTop>
                                ))}
                                <RowBottom>
                                    <MenuButtons>
                                        <Button title="Limpar"><AiOutlineClear color="#001E62" /></Button>
                                        <Button onClick={handleAddFieldsTab2} title="habilitar edição"><MdAddToPhotos color="#001E62" size={18} /></Button>
                                        <Button onClick={saveTab2} title="Salvar dados"><Save /></Button>
                                    </MenuButtons>
                                </RowBottom>
                            </Table2>
                            <Table3 visible={viewTab3} className={statusTab3}>
                                {inputFieldsTab3.map((inputField, index) => (
                                    <RowTop key={`${inputField}~${index}`}>
                                        <TextField type='number' id="planos" label="Plano" variant="outlined" size="small" value={inputField.planos} onChange={event => handleInputChangeTab3(index, event)} />
                                        <TextField type='time' id="time" label="Horário" variant="outlined" size="small" value={inputField.time} onChange={event => handleInputChangeTab3(index, event)} />
                                        <Button onClick={() => handleRemoveFieldsTab3(index)}><IconRemove /></Button>
                                    </RowTop>
                                ))}
                                <RowBottom>
                                    <MenuButtons>
                                        <Button title="Limpar"><AiOutlineClear color="#001E62" /></Button>
                                        <Button onClick={handleAddFieldsTab3} title="Habilitar edição"><MdAddToPhotos color="#001E62" size={18} /></Button>
                                        <Button onClick={saveTab3} title="Salvar dados"><Save /></Button>
                                    </MenuButtons>
                                </RowBottom>
                            </Table3>
                        </AreaAgenda>
                    </Fieldset>
                    <Fieldset className="inputsWeek" id={desabledBtn}>
                        <legend>Semana</legend>
                        <AreaWeek className={statusWeek}>
                            <Legend>
                                <span>Domingo</span>
                                <Select name="domingo" onChange={e => { setDomingo(e.target.value) }} required>
                                    {listaTabelasConfirmadas(domingo)}
                                    {preencheTabelaRecebida(domingo)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Segunda</span>
                                <Select name="segunda" onChange={e => setSegunda(e.target.value)} required>
                                    {listaTabelasConfirmadas(segunda)}
                                    {preencheTabelaRecebida(segunda)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Terça</span>
                                <Select name="terca" onChange={e => setTerca(e.target.value)} required>
                                    {listaTabelasConfirmadas(terca)}
                                    {preencheTabelaRecebida(terca)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Quarta</span>
                                <Select name="quarta" onChange={e => setQuarta(e.target.value)} required>
                                    {listaTabelasConfirmadas(quarta)}
                                    {preencheTabelaRecebida(quarta)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Quinta</span>
                                <Select name="quinta" onChange={e => setQuinta(e.target.value)} required>
                                    {listaTabelasConfirmadas(quinta)}
                                    {preencheTabelaRecebida(quinta)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Sexta</span>
                                <Select name="sexta" onChange={e => setSexta(e.target.value)} required>
                                    {listaTabelasConfirmadas(sexta)}
                                    {preencheTabelaRecebida(sexta)}
                                </Select>
                            </Legend>
                            <Legend>
                                <span>Sábado</span>
                                <Select name="sabado" onChange={e => setSabado(e.target.value)} required>
                                    {listaTabelasConfirmadas(sabado)}
                                    {preencheTabelaRecebida(sabado)}
                                </Select>
                            </Legend>
                        </AreaWeek>
                    </Fieldset>
                    <AreaButtons className={permissao} view={alertMsg}>
                        <div className="alert">
                            <Alert severity={typeAlert}>{msg}</Alert>
                        </div>
                        <div className="buttonColumn">
                            <Button title="Excluir agenda" id={desabledBtn} onClick={deleteSchedule}><RiDeleteBin6Line color="#001E62" size={18} /></Button>
                            <Button title="Editar agenda" id={desabledBtn} onClick={editCalendar}><FiEdit color="#001E62" size={18} /></Button>
                            <button type="submit" className="salvar" title="Salvar agenda" id={desabledBtn}>Salvar</button>
                        </div>
                    </AreaButtons>
                </AreaSelect>
            </Container>
        </Dialog>
    );
}