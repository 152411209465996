/**
 * Função responsável por encriptografia de dados
 * @param {*} dados 
 * A função encripta recebe como parametro o dado que deseja ser encriptografado
 * @returns 
 * Retorna dado encriptografado
 */

function encripta(dados) {
    var mensx = "";
    var l;
    var i;
    var j = 0;
    var chave;
    chave = process.env.REACT_APP_CHAVE_PERMISSION;
    for (i = 0; i < dados.length; i++) {
        j++;
        l = (Asc(dados.substring(i, i+1)) + (Asc(chave.substring(j, j+1))));
        if (j === 50) {
            j = 1;
        }
        if (l > 255) {
            l -= 256;
        }
        mensx += (Chr(l));
    }
    return (geraStringAleatoria(20) + mensx + geraStringAleatoria(20));
}

/**
 * A função descripta recebe como parametro o dado que deseja ser descriptografado
 * @param {*} dados 
 * @returns 
 */
function descripta(dados) {
    if(dados === undefined){
        return;
    }else{
        const removeRandons = (dados.length - 20);
        const aux = dados.substring(20,removeRandons);
        var mensx = "";
        var l;
        var i;
        var j = 0;
        var chave;
        chave = process.env.REACT_APP_CHAVE_PERMISSION;
        //chave = "abcd123";
        for (i = 0; i < aux.length; i++) {
            j++;
            l = (Asc(aux.substring(i, i+1)) - (Asc(chave.substring(j, j+1))));
            if (j === 50) {
                j = 1;
            }
            if (l < 0) {
                l += 256;
            }
            mensx += (Chr(l));
        }
        return mensx;
    }
}
function Asc(String) {
    return String.charCodeAt(0);
}

function Chr(AsciiNum) {
    return String.fromCharCode(AsciiNum)
}

function geraStringAleatoria(tamanho) {
    var stringAleatoria = '';
    var caracteres = process.env.REACT_APP_CHAVE_RANDOM;
    for (var i = 0; i < tamanho; i++) {
        stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
}

export { encripta, descripta, geraStringAleatoria }