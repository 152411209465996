import React, { useState } from 'react';
import { Container, Table, AreaAlert, Close } from './styles';
import PageTitle from '../../../components/PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import ButtonReflesh from '../ButtonReflesh';
import Alert from '@material-ui/lab/Alert';
import { GrClose } from "react-icons/gr";
import api from '../../../services/api';


function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function ModalFotoManual(props) {


    const [novaFoto, setNovaFoto] = useState([]);
    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const solicitarFoto = async (id_cruzamento, id_camera, descricao) => {
        const response = await api.get(`/CentralDeFalhas/cameras/fotoManual?id_cruzamento=${id_cruzamento}&id_camera=${id_camera}&descricao=${descricao}`)
            .then((response) => {
                setNovaFoto(response.data);
                setAlertView('block');
                setAlertMessage(`Imagem solicitada pela câmera: ${descricao}`);
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertView('none');
                }, 10000);
            })
            .catch((err) => {
                setAlertView('block');
                setAlertMessage(` erro ao solicitar câmera : ${descricao}`);
                setTypeAlert('warning');
                setTimeout(() => {
                    setAlertView('none');
                }, 5000);
            });
    }

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Câmera"
                onClick={props.onClose}
            ></PageTitle>
            <Container>
                <Close onClick={() => props.onClose()}><GrClose size={25} /></Close>
                <Table>
                    <thead>
                        <tr>
                            <th>Cameras</th>
                            <th>Solicitar foto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.dados.map(item => (
                            <tr key={item.refAnel}>
                                <td>{item.descricao}</td>
                                <td><div onClick={() => solicitarFoto(item.id_cruzamento, item._id, item.descricao)}><ButtonReflesh /></div></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <AreaAlert view={alertView}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{alertMessage}</Alert>
                    </div>
                </AreaAlert>
            </Container>
        </Dialog>
    );
}