import React, { useState } from 'react';
import ButtonNav from '../ButtonNav';
import { ChevronRight, ChevronDown, Menu } from 'react-feather';

import './styles.css';
import { useEffect } from 'react';



function MenuNavButton(props){ 

  const [openMenu , setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    const divMenu = document.getElementById(`div-itens-menu${props.title}`).style;
  
    if(openMenu){
      divMenu.display = '';
      return setOpenMenu(false);
    }else{
      divMenu.display = 'flex';
      return setOpenMenu(true);
    }
  }

  /*useEffect(()=>{
    if(props.showText == false){
      handleOpenMenu();
    }    
  }, [props.showText])*/

  return (
    <div className="button-menu-nav">
      <div className="div-button">
        <ButtonNav showText={props.showText} icon={props.icon} onClick={handleOpenMenu} title={props.title}>{props.title}</ButtonNav>
        {openMenu ? <ChevronDown className="img-arrow" onClick={handleOpenMenu}/> : <ChevronRight className="img-arrow" onClick={handleOpenMenu} />}
      </div>
      <div className='div-itens-menu' id={`div-itens-menu${props.title}`}>
        {props.children}
      </div>
    </div>
  );
}

export  default MenuNavButton;