import styled from 'styled-components';

export const Container = styled.section`
  
`;

export const AreaMenu = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 20px 0;
`;

export const Tabs = styled.ul`
    position: relative;
    margin: 0;
    padding: 0;

    ul{
        display: flex;
        padding: 0;
        margin: 0;
        &:before {
        content: '';
        width: 151px;
        height: 3px;
        background: #062467;
        position: absolute;
        bottom: -5px;
        left: ${props => props.position + 'px'};
        transition: .3s ease-out;
        }
    }
    
    li{
        list-style: none;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        color: #001E62;
        height: 23px;
        font-size: 14px;
        user-select: none;

        &#inactive{
            font-weight: normal;
        }

        &#active{
            transition: .3s ease-out;
            color: #001E62;
            font-weight: 900;
            letter-spacing: .5px;
        }
    }
`;

export const VisibleRegister = styled.div`
    display: ${props => props.status};
`;

