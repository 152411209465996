import React, { useState, useContext, useEffect } from 'react';
import { ModalContext } from '../../contextModal';
import { FaArrowRight } from "react-icons/fa";
import { Container, Voltar, Table, Ticket, BodyTable } from './styles';
import { validarPermissao } from '../../services/permission';

const permissao = (validarPermissao([0]) ? 'enabled' : 'disabled');

export default function Quicklog(props) {

    const [slide, setSlide] = useState('-921px');
    const [open, setOpen] = useState(false);
    const { modalStatus, setModalStatus } = useContext(ModalContext);
    const { openQuickLog, setOpenQuickLog } = useContext(ModalContext);
    const [zIndex, setZindex] = useState('999');
   
    const IconAlert = () => {
        return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path d="M6.43123 2.41226L1.13748 11.2498C1.02834 11.4388 0.970587 11.6531 0.969976 11.8713C0.969364 12.0896 1.02591 12.3042 1.134 12.4938C1.24208 12.6835 1.39794 12.8415 1.58605 12.9522C1.77416 13.0629 1.98798 13.1224 2.20623 13.1248H12.7937C13.012 13.1224 13.2258 13.0629 13.4139 12.9522C13.602 12.8415 13.7579 12.6835 13.866 12.4938C13.9741 12.3042 14.0306 12.0896 14.03 11.8713C14.0294 11.6531 13.9716 11.4388 13.8625 11.2498L8.56873 2.41226C8.45731 2.22858 8.30043 2.07671 8.11323 1.97131C7.92603 1.86592 7.71482 1.81055 7.49998 1.81055C7.28515 1.81055 7.07394 1.86592 6.88674 1.97131C6.69953 2.07671 6.54265 2.22858 6.43123 2.41226V2.41226Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 5.625V8.125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 10.625H7.50625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="15" height="15" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }

    const IconCancel = () => {
        return (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.9125 1.25H10.0875L13.75 4.9125V10.0875L10.0875 13.75H4.9125L1.25 10.0875V4.9125L4.9125 1.25Z" stroke="#CF1F1F" strokeOpacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.375 5.625L5.625 9.375" stroke="#CF1F1F" strokeOpacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.625 5.625L9.375 9.375" stroke="#CF1F1F" strokeOpacity="0.8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        );
    }
    const MoveSlide = () => {
        if (permissao === 'enabled') {
            if (slide === '-857px') {
                setSlide('-6px');
                setOpen(true);
                setModalStatus('quicklog');
                setZindex('1002');
            }
        }
        if (permissao === 'disabled') {
            return
        }

        else if (slide === '-6px') {
            setSlide('-921px');
            setOpen(false);
            setModalStatus('');
            setZindex('999');
            setOpenQuickLog(false);
        }
    }

    const Over = () => {
        if (open === false) {
            setSlide('-857px');
        }
    }

    const Out = () => {
        if (open === false) {
            setSlide('-921px');
            setOpenQuickLog(false);
        }
    }

    useEffect(() => {
        if (modalStatus !== 'quicklog') {
            setSlide('-921px');
            setOpen(false);
            setOpenQuickLog(false);
            setZindex('999');
        }
    }, [modalStatus, openQuickLog]);

    useEffect(() => {
        if (openQuickLog === true) {
            setSlide('-6px');
            setOpen(true);
            setZindex('1002');
        }
    }, [openQuickLog]);

    //se clicar no menu hamburguer recuar menus laterais: [state criado em Mapas.js]
    useEffect(() => {
        if(props.retreatModals === false){
            setSlide('-900vw');
        }else{
            setSlide('-921px');
        }
        
    },[props.retreatModals]);

    return (
        <Container slider={slide} position={zIndex}>
            <Ticket onClick={MoveSlide} onMouseOver={Over} onMouseOut={Out} className={permissao}>
                <IconAlert />
                Eventos
                <Voltar>
                    <FaArrowRight size={23} color="#001E62" />
                </Voltar>
            </Ticket>
            <Table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Equipamento</th>
                        <th>Agente</th>
                        <th>Nível</th>
                        <th>Identificação</th>
                        <th>Descrição</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <BodyTable>
                    <tbody>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td><IconCancel /></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>bar-0015</td>
                            <td>Eric Polini</td>
                            <td>Rota</td>
                            <td>Nome da área</td>
                            <td>Imposição de Plano</td>
                            <td>09/06/2021 14:03</td>
                        </tr>
                    </tbody>
                </BodyTable>
            </Table>
        </Container>
    );
}