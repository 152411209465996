import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import semRightGreen from '../../../img/Semaforo/semRightGreen.png';
import semRightYellow from '../../../img/Semaforo/semRightYellow.png';
import semRightRed from '../../../img/Semaforo/semRightRed.png';
import semRigthApagado from '../../../img/Semaforo/semRigthApagado.png';
import piscanteSemRightYellow from '../../../img/Semaforo/piscanteSemRightYellow.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, Img, Options, ButtonSettings } from './styles';

export default function SemaforoRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'others', title: 'Semaforo à direita', image: semRightGreen, object: props.objSemaforoRight, setObject: props.setObjSemaforoRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objSemaforoRight.map((item, index) => {
                if (props.objSemaforoRight[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='obj-semaforoRight'
                            top={item.top}
                            left={item.left}
                            setObjSemaforoRight={props.setObjSemaforoRight}
                            objSemaforoRight={props.objSemaforoRight}
                            classeObjeto={item.classeObjeto}
                        >
                            <Img src={

                                item.color_group === undefined || item.color_group === '' ? semRigthApagado :
                                    item.color_group === 1 ? semRightRed :
                                        item.color_group === 2 ? semRightYellow :
                                            item.color_group === 4 ? semRightGreen :
                                                item.color_group === 18 ? piscanteSemRightYellow : semRigthApagado
                            }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeSemaforoRight(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
