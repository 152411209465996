import React, { useEffect, useState, memo, useContext } from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AlertCircle, ThumbsUp } from "react-feather";
import PageTitle from '../PageTitle';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import "./styles.css";
import api from '../../services/api';
import AlertDialog from '../AlertDialog';
import OpenPagesExternal from '../OpenPagesExternal';
import Paper from '@material-ui/core/Paper';
import AlertDiaLog from "../AlertDialog";
import reset from '../../assets/reset.svg';
import IconbtnLogs from '../../assets/btnLogs.svg';
import maisOpcoes from '../../assets/maisOpcoes.svg';
import editInfo from '../../assets/editInfo.svg';
import iconeSombra from '../../assets/marker-shadow.png';
import iconePortaAberta from '../../assets/openDoor.png';
import iconePortaFechada from '../../assets/door-close.png';
import iconePortaAbertaIntermitente from '../../assets/openAmareloPiscante.gif';
import iconeIntermitente from '../../assets/falhaPiscante.gif';
import iconeManutencao from '../../assets/manutencaoGif.gif';
import iconeSemaforoFalha from '../../assets/falhaAnimado.gif';
import iconeSemaforoNormal from '../../assets/controlNormal.png';
import iconeSemaforoNormalAzul from '../../assets/controlNormal_.png';
import iconeSemaforoApagado from '../../assets/semaforoOff.png';
import iconeSemaforoFalhaPortaAberta from '../../assets/falhaPortaAberta.gif';
import iconeSemaforoOffilinePortaAberta from '../../assets/portaAbertaOff.png';
import iconeSemaforoNormalPortaAberta from '../../assets/controlNormalPortaAberta.png';
import iconeSemaforoSupervisaoPortaAberta from '../../assets/controlSupervisaoPortaAberta.png';
import iconeSemaforoNaoCadastrado from '../../assets/naoCadastrado.png';
import iconeSemaforoNaoCadastradoPortaAberta from '../../assets/naoCadastradoPortaAberta.png';
import iconeManual from '../../assets/Manual.png';
import iconeControlCentral from '../../assets/controlCentral.png';
import RegisterControllers from '../Registers/RegisterControllers';
import ModalVideo from './ModalVideo';
import { DropContainer, Modal, ContentModal, AreaInputs, AreaButtons, AreaControlador, ViewId, InfoHelp, AreaBallon, ContentBallon } from './styles';
import Tabs from './Tabs';
import { ModalContext } from '../../contextModal';
import Local from './Tabs/Local';
import Chip from './Tabs/Chip';
import Camera from './Tabs/Camera';
import Alert from '@material-ui/lab/Alert';
import som from '../../assets/sounds/alert.wav';
import { validarPermissao } from '../../services/permission';
import geocodingClient from '@mapbox/mapbox-sdk/services/geocoding';
import mbxClient from '@mapbox/mapbox-sdk';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../Balloons';
import icoInfo from '../../assets/icons/icoInfo.png';
import icoSom from '../../assets/icons/icoSom.png';
import { tocarVozInfoControle } from '../Audio';
import axios from 'axios'

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

let latInit;
let lngFinal;
let latNew;
let lngNew;

// trocar icones do mapa
const IconeSemaforoFalha = L.icon({
    iconUrl: iconeSemaforoFalha,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location 
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82], // the same for the shadow
});
const IconeSemaforoManutencao = L.icon({
    iconUrl: iconeManutencao,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],      // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location 
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82], // the same for the shadow
});
const IconePortaAbertaIntermitente = L.icon({
    iconUrl: iconePortaAbertaIntermitente,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location 
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeIntermitente = L.icon({
    iconUrl: iconeIntermitente,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location 
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoApagado = L.icon({
    iconUrl: iconeSemaforoApagado,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location 
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoNormal = L.icon({
    iconUrl: iconeSemaforoNormal,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoNormalAzul = L.icon({
    iconUrl: iconeSemaforoNormalAzul,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoPortaAberta = L.icon({
    iconUrl: iconeSemaforoNormalPortaAberta,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoFalhaPortaAberta = L.icon({
    iconUrl: iconeSemaforoFalhaPortaAberta,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeSemaforoOffilinePortaAberta = L.icon({
    iconUrl: iconeSemaforoOffilinePortaAberta,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeManual = L.icon({
    iconUrl: iconeManual,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});
const IconeControlCentral = L.icon({
    iconUrl: iconeControlCentral,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});

const IconeSupervisaoPortaAberta = L.icon({
    iconUrl: iconeSemaforoSupervisaoPortaAberta,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});

const IconeNaoCadastrado = L.icon({
    iconUrl: iconeSemaforoNaoCadastrado,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow

});

const IconeNaoCadastradoPortaAberta = L.icon({
    iconUrl: iconeSemaforoNaoCadastradoPortaAberta,
    shadowUrl: iconeSombra,
    iconSize: [25, 36],   // size of the icon
    shadowSize: [82, 82],   // size of the shadow
    iconAnchor: [12.5, 36], // point of the icon which will correspond to marker's location
    popupAnchor: [-1, -31],
    shadowAnchor: [30, 82],    // the same for the shadow
});

let iconePorta;
let iconeCruzamento = IconeSemaforoApagado;

export function UseImperativeHandle(ref) {
    if (ref) {
        ref.Dialog.open();
    }
}

const dialogref = React.createRef()

const socket = io(process.env.REACT_APP_SERVER_HOST + "/", {
    query: { token: `Bearer ${localStorage.getItem('@central-de-falhas-greenwave/tokenAuth')}` }
});


const ReferenceController = ({ intersection, move, infoRoute, rotas, viewIds, ...props }) => {

    const [openDescriptionIntersection, setOpenDescriptionIntersection] = useState(false);
    const [alertResetController, setAlertResetController] = useState(false);
    const [alertDeleteController, setAlertDeleteController] = useState(false);
    const [openOptionsPages, setOpenOptionsPages] = useState(false);
    const [planosVigente, setPlanosVigente] = useState(['']);
    const [id, setId] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [protocolo, setProtocolo] = useState('');
    const [modo_operacao, setModo_operacao] = useState('');
    const [status, setStatus] = useState('');
    const [porta, setPorta] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [foco, setFoco] = useState('grupoFilterBlack');
    const [focoAnel2, setFocoAnel2] = useState('grupoFilterBlack');
    const [focoAnel3, setFocoAnel3] = useState('grupoFilterBlack');
    const [focoAnel4, setFocoAnel4] = useState('grupoFilterBlack'); //aguardando arrumar filtro de rotas para excluir states 
    const [anel, setAnel] = useState(100);
    const [anelDeslocamento, setAnelDeslocamento] = useState('');
    const [abaLocal, setAbaLocal] = useState('block');
    const [abaControlador, setAbaControlador] = useState('none');
    const [abaChip, setAbaChip] = useState('none');
    const [abaCamera, setAbaCamera] = useState('none');
    const [ipMbb, setIpMbb] = useState('');
    const [idCruzamento, setIdCruzamento] = useState('');
    const [aneis, setAneis] = useState([]);
    const [info, setInfo] = useState('');
    const [dados, setDados] = useState({
        consorcio: '',
        id_cruzamento: '',
        instaladora: '',
        ipControlador: '',
        mantenedora: '',
        modelo: '',
        porta: '',
        townhall: '',
        ipControlador: ''
    });
    const [numeroAnel, setNumeroAnel] = useState('');
    const [infoAnel, setInfoAnel] = useState('');
    const [dadosAnel, setDadosAnel] = useState('');
    const [typeAnel, setTypeAnel] = useState('');
    const [btn1, setBtn1] = useState('deactive');
    const [btn2, setBtn2] = useState('deactive');
    const [btn3, setBtn3] = useState('deactive');
    const [btn4, setBtn4] = useState('deactive');
    const [openModalEquipamento, setOpenModalEquipamento] = useState(false);
    const [openModalVideo, setOpenModalVideo] = useState(false);
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [logradouro, setLogradouro] = useState('');
    const [sinal, setSinal] = useState('');
    const [dataAtualizacao, setDataAtualizacao] = useState('');
    const [rotaComAnel1, setRotaComAnel1] = useState(true);
    const { setCoordenadasClickLocal } = useContext(ModalContext);
    const { modalInfo, setModalInfo } = useContext(ModalContext);
    const { somEnabled } = useContext(ModalContext);
    const { setIdGlobal } = useContext(ModalContext);
    const { setAnelGlobal } = useContext(ModalContext);
    const { setAbaEquipamento } = useContext(ModalContext);
    const { lengthRoutesFilter } = useContext(ModalContext); // excluir após arrumar filtro de rotas
    const { setTypeRequest } = useContext(ModalContext);

    const typeFilter = localStorage.getItem('typeFilterControllers');

    const clickCloseModalEquipamento = () => {
        setOpenModalEquipamento(false);
    }

    const clickCloseModalVideo = () => {
        setOpenModalVideo(false);
    }

    const activeBtn1 = () => {
        setBtn1('active');
        setBtn2('deactive');
        setBtn3('deactive');
        setBtn4('deactive');
    }
    const activeBtn2 = () => {
        setBtn1('deactive');
        setBtn2('active');
        setBtn3('deactive');
        setBtn4('deactive');
    }
    const activeBtn3 = () => {
        setBtn1('deactive');
        setBtn2('deactive');
        setBtn3('active');
        setBtn4('deactive');
    }
    const activeBtn4 = () => {
        setBtn1('deactive');
        setBtn2('deactive');
        setBtn3('deactive');
        setBtn4('active');
    }

    async function lerDados() {
        await api.get(`/centraldefalhas/controlador/${idCruzamento}`).then((res) => {
            setDados(res.data);

            // esperando timestamp vir do back-end... arrumar
            const dataHora = res.data.logs.data.split('T');
            const data = dataHora[0];
            const hora = dataHora[1];
            const data_reverse = data.split('-').reverse().join('/');
            const resultadofinal = data_reverse + ' ' + hora;
            setDataAtualizacao(resultadofinal.slice(0, 19));

        }).catch((err) => {
            console.log('erro');
            setDados({
                consorcio: '',
                id_cruzamento: '',
                instaladora: '',
                ipControlador: '',
                mantenedora: '',
                modelo: '',
                porta: '',
                townhall: '',
                ipControlador: ''
            });

        });
    }

    useEffect(() => {
        if (abaControlador === 'block') {
            lerDados();
        }
    }, [abaControlador]);

    useEffect(() => { // abrir conexão com informações de chip, quando clicado na aba de chip
        socket.emit('monitoramento', {
            id_cruzamento: idCruzamento,
            type_message: "connect"
        });

        socket.on('message', (data) => {
            if (data.id_cruzamento === idCruzamento) {
                setSinal(data);
            }
        });
    }, [abaChip]);

    const transformarPlanos = () => {
        try {
            if (!intersection.controlador_plano_atual) {
                return;
            } else {
                setPlanosVigente(JSON.parse(intersection.controlador_plano_atual))
            }
        } catch (error) {
            console.log("erro ao ler plano atual")
        }
    }

    const style = {
        display: 'flex',
        alignItems: 'center',
        opacity: '0.1'
    };

    function definirIconePorta(status) { // define icon door 
        if (status === "aberta") {
            return iconePorta = iconePortaAberta;
        } else {
            return iconePorta = iconePortaFechada;
        }
    };

    function definirIcon(statusController, statusDoor, falha) {
        //define icon of intersection, alteração, edição e inserção de icones no mapa
        //console.log('statusController', statusController)

        if (statusDoor === "aberta") {
            if (statusController === "Supervisão" && falha === 'Não existe anel cadastrado') {
                return iconeCruzamento = IconeNaoCadastradoPortaAberta; //esse
            }
            if (statusController === "Normal" && falha === "Amarelo Intermitente") {
                return iconeCruzamento = IconeSemaforoPortaAberta;
            }
            if (statusController === "Supervisão" && falha === "Amarelo Intermitente") {
                return iconeCruzamento = IconeSupervisaoPortaAberta;
            }
            if (falha === "Amarelo Intermitente") {
                if (statusController === "Manutenção") {
                    return iconeCruzamento = IconeSemaforoManutencao;
                } else {
                    return iconeCruzamento = IconePortaAbertaIntermitente;
                }
            } else if (statusController === "Offline") {
                return iconeCruzamento = IconeSemaforoOffilinePortaAberta;
            } else if (statusController === "Manutenção") {
                return iconeCruzamento = IconeSemaforoManutencao;
            }
            else if (statusController === "Manual") {
                return iconeCruzamento = IconeManual;
            } else if (statusController === "Falha") {
                return iconeCruzamento = IconeSemaforoFalhaPortaAberta;
            } else if (statusController === "Supervisão") {
                return iconeCruzamento = IconeSupervisaoPortaAberta;
            }
            else {
                return iconeCruzamento = IconeSemaforoPortaAberta;
            }

        }
        if (statusController === "Supervisão" && falha === 'Não existe anel cadastrado') {
            return iconeCruzamento = IconeNaoCadastrado; //esse
        }
        if (statusController === 'Imposição') {
            return iconeCruzamento = IconeSemaforoNormal
        }
        if (statusController === 'Normal' && falha === 'Amarelo Intermitente') {
            return iconeCruzamento = IconeSemaforoNormalAzul;
        }
        if (statusController === 'Supervisão' && falha === 'Amarelo Intermitente') {
            return iconeCruzamento = IconeSemaforoNormal;
        }
        if (statusController === 'Supervisão' && falha === 'Normal') {
            return iconeCruzamento = IconeSemaforoNormal;
        }


        else {
            if (falha === "Amarelo Intermitente") {
                if (statusController === "Manutenção") {
                    return iconeCruzamento = IconeSemaforoManutencao;
                } else {
                    return iconeCruzamento = IconeIntermitente;
                }
            }

            else if (statusController === "Offline") {
                return iconeCruzamento = IconeSemaforoApagado;
            } else if (statusController === "Manutenção") {
                return iconeCruzamento = IconeSemaforoManutencao;
            } else if (statusController === "Falha") {
                return iconeCruzamento = IconeSemaforoFalha;
            } else if (statusController === "Manual") {
                return iconeCruzamento = IconeManual;
            } else if (statusController === "Central") {
                return iconeCruzamento = IconeControlCentral; // criar condição no back-end (trocar icone verde pelo azul, azul será o normal)
            } else if (statusController === "Normal" && intersection.conexao_controlador === "Desconectado") {
                return iconeCruzamento = IconeSemaforoNormalAzul;
            }
            else if (statusController === "Normal" && falha === 'Normal' && intersection.conexao_controlador === "Conectado") {
                return iconeCruzamento = IconeSemaforoNormalAzul;
            }
            else {
                return iconeCruzamento = IconeSemaforoApagado;
            }
        }
    };

    async function resetController(idController, status) {
        //Reseta o controlador quando o status nao estiver offline
        //recebe como propiedade o id do equipamento e o ultimo status

        if (validarPermissao([0, 1, 2])) {
            if (status !== "Offline") {
                let id = idController;
                await api.post('/centraldefalhas/intersection/reset', {
                    "id_cruzamento": id,
                }).then(res => {
                    toast.success(`Solicitação de reset enviado!`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch(err => {
                    toast.error(`Erro ao solicitar reset !`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
            } else {
                toast.warn(`Equipamento està desligado, reset não solicitado !`, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    async function deleteController() {
        if (validarPermissao([0, 1])) {
            let id = idCruzamento;
            await api.post('/centraldefalhas/intersection/remove', {
                "id_cruzamento": id,
            }).then(res => {
                if ("Failed to delete Intersections" === res.data.error) {
                    toast.info(`Equipamento esta fora de sua jurisdição !`, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.success(`Equipamento ${id} excluido com sucesso!`, {
                        position: "top-center",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setOpenDescriptionIntersection(false);
                    setAlertDeleteController(false);
                }
            }).catch(err => {
                toast.error(`Erro ao excluir o equipamento ${id} !`, {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
        }
    };

    const closeOptionsPages = () => {
        setOpenOptionsPages(false);
    }
    const handleOpenDescriptionIntersection = (anel, numeroAnel, items, type) => { //nome do anel, os dados referente anel, e o tipo se é mestre ou os demais aneis     
        setAnelGlobal(numeroAnel);
        setInfoAnel(anel)
        setDadosAnel(items);


        if (numeroAnel !== '1') { // caso o alfinete clicado seja diferente do anel1 então muda somente o modo operacional e estado do controlador
            setModo_operacao(items.modo_operacional);
            setStatus(items.estado)

        } else { // caso seja o anel 1 então chama a requisição do anel master
            setModo_operacao(intersection.modo_operacao);
            setStatus(intersection.descricao_falha);
        }

        setNumeroAnel(numeroAnel); // state para enviar o numero do anel para componente de cameras
        setTypeAnel(type);
        setOpenDescriptionIntersection(true);
        setCoordenadasClickLocal('true');
        //para nao abrir o registro de controlador apos abrir a tela de informaçoes do controlador
        localStorage.setItem('awaitNextClick', 'false');
        //tira cor onde informa que esta selecionado 
        document.getElementById("iconRegisterController").style.background = '';

        switch (numeroAnel) {
            case '1':
                setAnel(0); activeBtn1()
                break;
            case '2':
                setAnel(1); activeBtn2()
                break;
            case '3':
                setAnel(2); activeBtn3()
                break;
            case '4':
                setAnel(3); activeBtn4()
            default:
                break;
        }
    };

    useEffect(() => { // limpa os icones de sinais na aba chip após fechar o modal
        if (!openDescriptionIntersection) {
            setSinal('');
        }
    }, [openDescriptionIntersection]);

    const handleCloseDescriptionIntersection = () => {
        setOpenDescriptionIntersection(false);
    };

    const handleOpenAlertResetController = () => {
        setAlertResetController(true);
    };

    const handleCloseAlertResetController = () => {
        setAlertResetController(false);
    };

    const handleCloseAlertDeleteController = () => {
        setAlertDeleteController(false);
    };

    const clickReset = () => {
        resetController(intersection.id_cruzamento, intersection.modo_operacao);
        setAlertResetController(false);
    };

    const initMarker = ref => {
        //funcao para iniciar os popups em aberto
        //bolinha que tem ao lado do icone de equipamento que informa sobre a comunicação com a cpu
        if (ref) {
            ref.leafletElement.openPopup();
        }
    };

    const popupMarker = (anel, referencia) => {
        // Referente a rota do anel mestre
        //define qual sera a cor do badge com relacao ao estatos que chega do controlador
        //a conexao ao socket esta no filterController /* obs. bolinha maior*/ 
        //const typeFilter = localStorage.getItem('typeFilterControllers');

        if (intersection.conexao_controlador === "Conectado" || intersection.conexao_controlador === "Falha") {// definir condição das cores do badge
            if (typeFilter === 'Rotas') {

                socket.emit('monitoramento', {
                    id_cruzamento: `${intersection.id_cruzamento}`,
                    type_message: "connect"
                });


                var grupoindex = '';
                rotas.forEach(rota => {
                    if (rota.anel === anel && rota.id_cruzamento === intersection.id_cruzamento) {
                        grupoindex = (rota.grupo - 1);
                    }
                });

                socket.on('message', (data) => {
                    if (data.type_message === "setMoni") {
                        var arrayColorGroups = data.color_group.split('[');
                        arrayColorGroups = arrayColorGroups[1].split(']');
                        arrayColorGroups = arrayColorGroups[0].split(', ');

                        if (data.id_cruzamento === intersection.id_cruzamento) { /* Representação do grupo de cada controlador */
                            if (anel === '1') {
                                /*console.log('anel1', (arrayColorGroups[grupoindex]))
                                console.log( ' \n\n arrayColorGroups' + arrayColorGroups)
                                console.log( ' \n\n grupoindex   ' + grupoindex)
                                console.log('intersection.idCruzamento',intersection.id_cruzamento)*/
                                if (arrayColorGroups[grupoindex] == '1') {
                                    return setFoco('grupoFilterRed');
                                } else if (arrayColorGroups[grupoindex] == '0') {
                                    return setFoco('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '2') {
                                    return setFoco('grupoFilterYelow');
                                } else if (arrayColorGroups[grupoindex] == '4') {
                                    return setFoco('grupoFilterGreen');
                                } else if (arrayColorGroups[grupoindex] == '7') {
                                    return setFoco('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '17') {
                                    return setFoco('grupoFilterRedPisc');
                                } else if (arrayColorGroups[grupoindex] == '18') {
                                    return setFoco('grupoFilterYellowPisc');
                                }
                            }
                            if (anel === '2') {
                                if (arrayColorGroups[grupoindex] == '1') {
                                    return setFocoAnel2('grupoFilterRed');
                                } else if (arrayColorGroups[grupoindex] == '0') {
                                    return setFocoAnel2('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '2') {
                                    return setFocoAnel2('grupoFilterYelow');
                                } else if (arrayColorGroups[grupoindex] == '4') {
                                    return setFocoAnel2('grupoFilterGreen');
                                } else if (arrayColorGroups[grupoindex] == '7') {
                                    return setFocoAnel2('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '17') {
                                    return setFocoAnel2('grupoFilterRedPisc');
                                } else if (arrayColorGroups[grupoindex] == '18') {
                                    return setFocoAnel2('grupoFilterYellowPisc');
                                }
                            }
                            if (anel === '3') {
                                //console.log('anel3', (arrayColorGroups[grupoindex]+ '\n', ' arrayColorGroups' +  arrayColorGroups))
                                if (arrayColorGroups[grupoindex] == '1') {
                                    return setFocoAnel3('grupoFilterRed');
                                } else if (arrayColorGroups[grupoindex] == '0') {
                                    return setFocoAnel3('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '2') {
                                    return setFocoAnel3('grupoFilterYelow');
                                } else if (arrayColorGroups[grupoindex] == '4') {
                                    return setFocoAnel3('grupoFilterGreen');
                                } else if (arrayColorGroups[grupoindex] == '7') {
                                    return setFocoAnel3('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '17') {
                                    return setFocoAnel3('grupoFilterRedPisc');
                                } else if (arrayColorGroups[grupoindex] == '18') {
                                    return setFocoAnel3('grupoFilterYellowPisc');
                                }
                            }
                            if (anel === '4') {
                                /*console.log('anel4', (arrayColorGroups[grupoindex]))
                                console.log( ' \n\n arrayColorGroups' + arrayColorGroups)
                                console.log( ' \n\n grupoindex   ' + grupoindex)
                                console.log('intersection.idCruzamento',intersection.id_cruzamento)*/
                                if (arrayColorGroups[grupoindex] == '1') {
                                    return setFocoAnel4('grupoFilterRed');
                                } else if (arrayColorGroups[grupoindex] == '0') {
                                    return setFocoAnel4('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '2') {
                                    return setFocoAnel4('grupoFilterYelow');
                                } else if (arrayColorGroups[grupoindex] == '4') {
                                    return setFocoAnel4('grupoFilterGreen');
                                } else if (arrayColorGroups[grupoindex] == '7') {
                                    return setFocoAnel4('grupoFilterBlack');
                                } else if (arrayColorGroups[grupoindex] == '17') {
                                    return setFocoAnel4('grupoFilterRedPisc');
                                } else if (arrayColorGroups[grupoindex] == '18') {
                                    return setFocoAnel4('grupoFilterYellowPisc');
                                }
                            }
                        }
                    }
                });

                return (
                    <Popup
                        key={foco + anel}
                        className={
                            anel === '1' ? foco :
                                anel === '2' ? focoAnel2 :
                                    anel === '3' ? focoAnel3 :
                                        anel === '4' ? focoAnel4 : 'grupoFilterBlack'
                        }
                        popupopen={true}
                        autoClose={false}
                        closeOnClick={false}
                        closeButton={false}
                        closeOnEscapeKey={false}
                        autoPan={false}
                    >
                        {popupTitle(referencia)}
                    </Popup>
                )


            } else {
                if (intersection.conexao_controlador === "Conectado") {
                    return (
                        <Popup
                            key="popupBadge"
                            id="popupBadge"
                            className="popupBadge" /* bolinha azul - com comunicação com a CPU - sob supervisão da central */
                            popupopen={true}
                            autoClose={false}
                            closeOnClick={false}
                            closeButton={false}
                            closeOnEscapeKey={false}
                            autoPan={false}
                        >
                            {popupTitle(referencia)}
                        </Popup>
                    )
                }

                else if (intersection.conexao_controlador === "Falha") {
                    return (
                        <Popup
                            key="popupBadgeRed"
                            id="popupBadgeRed"
                            className="popupBadgeRed" /* bolinha vermelha - com comunicação com a CPU - sob falha de conexão upload da central */
                            popupopen={true}
                            autoClose={false}
                            closeOnClick={false}
                            closeButton={false}
                            closeOnEscapeKey={false}
                            autoPan={false}
                        >
                            {popupTitle(referencia)}
                        </Popup>)
                }

                /*else if(){
                    // Criar novas condições
                    //======================================
                     
                        se for conectado e imposição
                        bolinha verde
                    
                }

                else if(){
                    //======================================
                     
                        se tiver falha no detector
                        bolinha laranja
                    
                    //=======================================
                    
                }*/

                /*if (intersection.descricao_falha === "Normal" && intersection.modo_operacao === 'Normal') {
                    return (
                        <Popup
                            key="popupBadgePurple"
                            id="popupBadgePurple"
                            className="popupBadgePurple" //bolinha roxa - desatualizado
                            popupopen={true}
                            autoClose={false}
                            closeOnClick={false}
                            closeButton={false}
                            closeOnEscapeKey={false}
                            autoPan={false}
                        >
                            {popupTitle(referencia)}
                        </Popup>
                    )
                } else {
                    return (
                        <Popup
                            key="popupBadge"
                            id="popupBadge"
                            className="popupBadge" //bolinha azul - com comunicação com a CPU - sob supervisão da central
                            popupopen={true}
                            autoClose={false}
                            closeOnClick={false}
                            closeButton={false}
                            closeOnEscapeKey={false}
                            autoPan={false}
                        >
                            {popupTitle(referencia)}
                        </Popup>
                    )
                }*/
            }
        }
        else {
            return (
                <Popup
                    id="badge"
                    className="badge" /* bolinha preta - sem comunicação com a CPU - fora do modo central */
                    popupopen={true}
                    autoClose={false}
                    closeOnClick={false}
                    closeButton={false}
                    closeOnEscapeKey={false}
                    autoPan={false}
                >
                    {popupTitle(referencia)}
                </Popup>
            )
        }
    }

    const popupTitle = (referencia) => {
        if (viewIds.view === 'true') {
            return (
                <Popup
                    id="badgeTitle"
                    className="badgeTitle"
                    popupopen={true}
                    autoClose={false}
                    closeOnClick={false}
                    closeButton={true}
                    closeOnEscapeKey={false}
                    autoPan={false}
                >
                    <ViewId>
                        <span>{referencia}</span>
                    </ViewId>
                </Popup>
            );
        } else {
            return (
                <Popup
                    id="badgeTitleNone"
                    className="badgeTitleNone"
                    popupopen={true}
                    autoClose={false}
                    closeOnClick={false}
                    closeButton={false}
                    closeOnEscapeKey={false}
                    autoPan={false}
                >
                    {intersection.id_cruzamento}
                </Popup>
            );
        }
    }

    const openAlertEditLatLng = async (event) => {
        console.log('chamou função de logradouro')
        const typeEvent = event.type;
        if (typeEvent === "mousedown") {
            latInit = event.latlng.lat;
            lngFinal = event.latlng.lng;

            latNew = '';
            lngNew = '';
        } else if (typeEvent === "mouseup") {
            latNew = event.latlng.lat;
            lngNew = event.latlng.lng;
        }

        if ((latInit === latNew && lngNew === lngFinal) || !latInit || !latNew || !lngNew || !lngFinal) {
            return
        } else {


        /*const baseClient = mbxClient({ accessToken: 'pk.eyJ1IjoidmluaWNpdXNndyIsImEiOiJjbGRhNmc0azcwNW1oM25wazFxcHRob3NtIn0.17CN4GHd_uQJur5A5Wxshg' });
            const mbxGeocodingService = geocodingClient(baseClient);

            mbxGeocodingService.reverseGeocode({
                query: [lngNew, latNew],
                mode: "mapbox.places",
                types: ["address"]
            }).send().then(response => {
                const features = response.body.features;
                if (features != '') {
                    const logradouroAtual = features[0].place_name;
                    setLogradouro(logradouroAtual);
                    console.log('logradouro', logradouroAtual)
                }
				else{
					setLogradouro('Sem dados');
				}
            });
            setAlertOpen(true);
        }*/

		await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latNew}+${lngNew}&key=81c50dac012a45e2814cca9629173d0b`)
			
				.then(res => {
					const logradouro = res.data.results[0].formatted
					setLogradouro(logradouro)
				}).catch(err =>  setLogradouro('Sem acesso no momento...'))
			}
		setAlertOpen(true);
    }

    const handleCloseAlert = () => {
        setAlertOpen(false);
    }

    const editLatLng = async (event) => {
        const response = await api.get(`/CentralDeFalhas/Local/${intersection.id_cruzamento}`);
        const eventType = event.type;

        if (eventType === "click") {
            localStorage.setItem('longitude', '');
            localStorage.setItem('latitude', '');
        }
        var latitudeAnterior;
        var longitudeAnterior;

        if (anelDeslocamento !== 1) {
            response.data.anel.forEach(item => {
                if (item.anel.slice(-1) === anelDeslocamento) {
                    latitudeAnterior = item.latitude;
                    longitudeAnterior = item.longitude;
                }
            });
        } else {
            latitudeAnterior = response.data.local.longitude;
            longitudeAnterior = response.data.local.latitude;
        }

        const newLat = latNew;
        const newLng = lngNew;

        setAlertOpen(false);
        await api.post('/centraldefalhas/local', {

            "id_cruzamento": intersection.id_cruzamento,
            "anel": intersection.id_cruzamento + '_anel-0' + anelDeslocamento,
            "logradouro": logradouro,
            "referencia": response.data.local.referencia,
            "latitude": `${newLat}`,
            "longitude": `${newLng}`,
            "latitudeAnterior": latitudeAnterior,
            "longitudeAnterior": longitudeAnterior,

        }).then(res => {

            if (res.data.status === "Intersection att successfully") {
                toast.success('Informações editadas com sucesso!', {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location.href = "/mapa";
                if (res.data.error === " Token invalid") {
                    window.location.href = "/";
                }

            } else {
                return toast.error(res.data.error, {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.error('Erro ao editar tente novamente!', {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        window.location.reload()
    }

    const moveModal = () => {
        setModalInfo({ marginTop: '80vh', transition: '1s' });
        setTimeout(() => {
            setModalInfo({ marginTop: '0', transition: '1s' })
        }, 5000);
    }

    const DivAlert = (id, falha, data) => {
        //componente que fica dentro do toast alert
        return (
            <div style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                margin: 'none'
            }}>
                <AlertCircle />
                <div style={{
                    display: "flex",
                    width: "100%",
                    alignItems: 'center',
                    marginLeft: "5px",
                    justifyContent: 'space-between'
                }}>
                    {id}:<br /> {falha}
                    <span>{data.getHours()}:{data.getMinutes()}</span>
                </div>
                <audio className="som" src={som}></audio>
            </div>
        )
    }

    useEffect(() => { popupTitle() }, [viewIds])
    useEffect(() => { transformarPlanos() }, [intersection.controlador_plano_atual]);
    useEffect(() => {
        //verifico se a falha mudou para trocar o toast
        var data = new Date();
        const tocar = () => {
            if (somEnabled) { // verificar se usuário optou por ouvir o alerta
                document.querySelector('.som').play();
            }
            else {
                return
            }
        }

        if (intersection.descricao_falha !== "Offline" && intersection.descricao_falha !== "Normal") {
            if (!toast.isActive(intersection.id_cruzamento)) {
                (intersection.descricao_falha === "Não existe anel cadastrado" ? console.log('Não existe anel cadastrado') :

                    intersection.descricao_falha === "Amarelo Intermitente" ?
                        toast.warning(() =>
                            DivAlert(intersection.id_cruzamento, intersection.descricao_falha, data),
                            {
                                toastId: `${intersection.id_cruzamento}`,
                                position: "bottom-right",
                                autoClose: false,
                                containerId: 'toast-container',
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                onClick: () => { setOpenDescriptionIntersection(true); move(`${intersection.latitude}`, `${intersection.longitude}`); moveModal() },
                                onOpen: () => tocar()
                            }
                        )
                        :
                        intersection.descricao_falha === "Perda de Comunicação"
                            ?
                            toast.dark(() =>
                                DivAlert(intersection.id_cruzamento, intersection.descricao_falha, data),
                                {
                                    toastId: `${intersection.id_cruzamento}`,
                                    position: "bottom-right",
                                    autoClose: false,
                                    containerId: 'toast-container',
                                    hideProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    onClick: () => { setOpenDescriptionIntersection(true); move(`${intersection.latitude}`, `${intersection.longitude}`); moveModal() },
                                    onOpen: () => tocar()
                                }
                            )

                            :

                            toast.error(() =>
                                DivAlert(intersection.id_cruzamento, intersection.descricao_falha, data),
                                {
                                    toastId: `${intersection.id_cruzamento}`,
                                    position: "bottom-right",
                                    autoClose: false,
                                    containerId: 'toast-container',
                                    hideProgressBar: false,
                                    closeOnClick: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    onClick: () => { setOpenDescriptionIntersection(true); move(`${intersection.latitude}`, `${intersection.longitude}`); moveModal() },
                                    onOpen: () => tocar()
                                }
                            )
                )
            } else {
                toast.update(intersection.id_cruzamento, {
                    render: () => DivAlert(intersection.id_cruzamento, intersection.descricao_falha, data),
                    type: (intersection.descricao_falha === "Amarelo Intermitente" ? toast.TYPE.WARNING : toast.TYPE.ERROR),
                    autoClose: false,
                    containerId: 'toast-container',
                    onOpen: () => tocar()

                });
            }
        } else if (intersection.descricao_falha === "Normal") {
            if (toast.isActive(intersection.id_cruzamento)) {
                toast.update(intersection.id_cruzamento, {
                    render: () =>
                        <div style={{
                            display: "flex",
                            width: "200px",
                            alignItems: "center"
                        }}>
                            <ThumbsUp />
                            <div style={{
                                display: "flex",
                                width: "175px",
                                alignItems: 'center',
                                marginLeft: "5px",
                                justifyContent: 'space-between'
                            }}>
                                {intersection.id_cruzamento}: <br />Normalizado
                                <span>{data.getHours()}:{data.getMinutes()}{tocar()}</span>
                            </div>
                        </div>,
                    type: toast.TYPE.SUCCESS,
                    autoClose: 10000,
                    position: "bottom-right",
                    hideProgressBar: false,
                    containerId: 'toast-container',
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onOpen: () => tocar()
                });
            }
        }
        else if (intersection.descricao_falha === "Normal" && intersection.modo_operacao === "Supervisão") {
            // lógicca
        }
    }, [intersection.descricao_falha]);

    const verificacaoPlanoVigente = () => {
        if (anel == 100) {
            return 'Selecione um anel'
        }
        if (planosVigente[anel] == null || planosVigente[anel] == '') {
            return 'Anel sem plano no momento';
        } else {
            return planosVigente[anel];
        }
    }

    const ContentPopBallonLocal = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Controlador</h3>
                    <label onClick={tocarVozInfoControle}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nesta opção estão listadas todas as informações concernentes ao controlador, para edição
                    das informações listadas clique no botão "Editar". Esse é o segundo botão.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    // props intersection vindo de Mapas.jsx
    useEffect(() => { definirIconePorta(intersection.porta) }, [intersection.porta]) // setPortaControlador
    useEffect(() => { setId(intersection._id) }, [intersection._id]);
    useEffect(() => { setIdCruzamento(intersection.id_cruzamento) }, [intersection.id_cruzamento]);
    useEffect(() => { setLatitude(intersection.latitude) }, [intersection.latitude]);
    useEffect(() => { setLongitude(intersection.longitude) }, [intersection.longitude]);
    useEffect(() => { setIpMbb(intersection.ip_mmbb_atual) }, [intersection.ip_mmbb_atual]);
    useEffect(() => { setProtocolo(intersection.protocolo) }, [intersection.protocolo]);
    useEffect(() => { setModo_operacao(intersection.modo_operacao) }, [intersection.modo_operacao]);
    useEffect(() => { setProtocolo(intersection.controlador_protocolo) }, [intersection.controlador_protocolo]);
    useEffect(() => { setModo_operacao(intersection.modo_operacao) }, [intersection.modo_operacao]);
    useEffect(() => { setStatus(intersection.descricao_falha) }, [intersection.descricao_falha]);
    useEffect(() => { setPorta(intersection.porta) }, [intersection.porta]);

    /**
     * Verifica se esta filtrado por rotas e procura se o anel 1 esta na rota, se sim, então renderizar o Marker do anel mestre
     */
    useEffect(() => {
        if (typeFilter === 'Rotas') {
            let aneis = [];
            rotas.forEach(item => {
                aneis.push(item.anel)
            });
            let search = aneis.includes('1');
            setRotaComAnel1(search);
        }
    }, [intersection])

    /**
     * Verifica se esta filtrado por rotas e seta o state somente com os aneis que fazer parte da rota
     */
    useEffect(() => {
        if (typeFilter !== 'Rotas') {
            setAneis(intersection.aneis);
        } else {
            const aneisDaRota = [];
            intersection.aneis.forEach(itemIntersection => {
                rotas.forEach(itemRotas => {
                    if (itemRotas.anel === itemIntersection.identificador) {
                        aneisDaRota.push(itemIntersection);
                    }
                })
            });
            setAneis(aneisDaRota);
        }
    }, [intersection.aneis]);

    return (
        //definirIcon(intersection.modo_operacao, intersection.porta, intersection.descricao_falha),
        <>

            {/* Alfinete do controlador mestre */}
            {
                rotaComAnel1 === true ?
                    <Marker
                        className="Marker"
                        ref={initMarker}
                        key={id}
                        id={idCruzamento}
                        title={idCruzamento + ' Mestre'}
                        position={[latitude, longitude]}
                        icon={definirIcon(intersection.modo_operacao, intersection.porta, intersection.descricao_falha)}
                        openPopup={true}
                        riseOnHover='true'
                        onClick={() => handleOpenDescriptionIntersection(idCruzamento, '1', null, 'mestre')} // ao clicar envia como parametro o tipo de anel mestre, enviar para componente local
                        draggable={true}
                        autoPan={true}
                        bubblingMouseEvents={true}
                        style={style}
                        onmouseup={openAlertEditLatLng}
                        onmousedown={openAlertEditLatLng}
                        ondrag={() => setAnelDeslocamento(1)}
                    >
                        {popupMarker('1', intersection.referencia)}
                    </Marker>
                    : null}

            {/*Afinetes dos aneis 2 ao 4*/}
            {aneis.map((item, index) => (
                <Marker
                    className="Marker"
                    ref={initMarker}
                    key={index}
                    id={idCruzamento}
                    title={idCruzamento + '-' + item.identificador}
                    position={[item.latitude, item.longitude]}
                    icon={definirIcon(item.modo_operacional, intersection.porta, item.estado)}
                    openPopup={true}
                    riseOnHover='true'
                    onClick={() => handleOpenDescriptionIntersection(idCruzamento, item.identificador, item, 'aneis')} // ao clicar envia como parametro o tipo de anel mestre, enviar para componente local
                    draggable={true}
                    autoPan={true}
                    bubblingMouseEvents={true}
                    style={style}
                    onmouseup={openAlertEditLatLng}
                    onmousedown={openAlertEditLatLng}
                    ondrag={() => setAnelDeslocamento(item.identificador)}
                >
                    {popupMarker(item.identificador, item.referencia)}
                </Marker>
            ))}

            <Dialog
                open={openDescriptionIntersection}
                ref={dialogref}
                className="Dialog"
                onClose={handleCloseDescriptionIntersection}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                hideBackdrop={true}
                id="informacoes"
                style={modalInfo}
                anel={infoAnel}
            >
                <PageTitle // cabeçalho do modal
                    id="draggable-dialog-title"
                    text={`Informações: ${infoAnel.length > 30 ? infoAnel.substring(0, 35) + '...' : infoAnel}`}
                    onClick={handleCloseDescriptionIntersection}
                >
                </PageTitle>
                <Modal>
                    <Tabs
                        changeAbalocal={abaLocal} changeSetAbaLocal={setAbaLocal}
                        changeAbaControlador={abaControlador} changeSetAbaControlador={setAbaControlador}
                        changeAbaChip={abaChip} changeSetAbaChip={setAbaChip}
                        changeAbaCamera={abaCamera} changeSetAbaCamera={setAbaCamera}
                        onClose={handleCloseDescriptionIntersection} lerDados={lerDados}
                    />
                    <Local anel={infoAnel} dadosAnel={dadosAnel} dados={dados} tipoDados={typeAnel} status={abaLocal} id={idCruzamento} intersection={intersection} openEquipamento={setOpenModalEquipamento} closeModal={setOpenDescriptionIntersection} />
                    <Chip status={abaChip} id={idCruzamento} openEquipamento={setOpenModalEquipamento} closeModal={setOpenDescriptionIntersection} sinal={sinal} />
                    <Camera
                        numeroAnel={numeroAnel}
                        status={abaCamera}
                        id={idCruzamento}
                        openEquipamento={setOpenModalEquipamento}
                        ipExterno={intersection.ip_mmbb_atual}

                        openVideo={setOpenModalVideo}
                        closeModal={setOpenDescriptionIntersection}
                    />
                    <DialogContent className='content-dialog'>
                        <DropContainer>
                            <ContentModal status={abaControlador}>
                                {/*<Alert severity="error" className="alert">{info}</Alert>*/}
                                <fieldset>
                                    <legend>Responsáveis</legend>
                                    <AreaInputs>
                                        <InfoHelp onClick={() => openPopUp()}>
                                            <GrCircleQuestion size={15} />
                                        </InfoHelp>
                                        <AreaBallon visible={visibleBalloon}>
                                            <BallonsInfoModal><ContentPopBallonLocal /></BallonsInfoModal>
                                        </AreaBallon>
                                        <div className='groupone'>
                                            <div>
                                                <label htmlFor="">Consórcio:</label>
                                                <input type="text" value={dados.consorcio} disabled />
                                            </div>
                                            <div className="inputTwo">
                                                <label htmlFor="">Instaladora:</label>
                                                <input type="text" value={dados.instaladora} disabled />
                                            </div>
                                        </div>
                                        <div className='grouptwo'>
                                            <div style={{ width: '100%' }}>
                                                <label htmlFor="">Mantenedora:</label>
                                                <input type="text" value={dados.mantenedora} disabled />
                                            </div>
                                        </div>
                                    </AreaInputs>
                                </fieldset>
                                <fieldset>
                                    <legend>Controlador</legend>
                                    <AreaInputs>
                                        <div className='groupone'>
                                            <div>
                                                <label htmlFor="">IP:</label>
                                                <input type="text" value={dados.ipControlador} disabled />
                                            </div>
                                            <div className='inputTwo'>
                                                <label htmlFor="">Modelo:</label>
                                                <input type="text" value={dados.modelo} disabled />
                                            </div>
                                        </div>
                                        <div className='groupone'>
                                            <div>
                                                <label htmlFor="">Porta</label>
                                                <input type="text" value={dados.porta} disabled />
                                            </div>
                                            <div className='inputTwo'>
                                                <label htmlFor="">Protocolo:</label>
                                                <input type="text" value={protocolo} disabled />
                                            </div>
                                        </div>
                                    </AreaInputs>
                                </fieldset>
                                <fieldset>
                                    <legend>Geral</legend>
                                    <AreaControlador>
                                        <div className='grouponeControlador'>
                                            <label htmlFor="">Plano vigente:</label>
                                            <div className="areaButtons">
                                                <span>Anéis: </span>
                                                <button className={btn1} onClick={() => { setAnel(0); activeBtn1() }}>1</button>
                                                <button className={btn2} onClick={() => { setAnel(1); activeBtn2() }}>2</button>
                                                <button className={btn3} onClick={() => { setAnel(2); activeBtn3() }}>3</button>
                                                <button className={btn4} onClick={() => { setAnel(3); activeBtn4() }}>4</button>
                                            </div>
                                            <div className="areaButtons">
                                                <span>Plano: </span>
                                                <input type="text" id="inputPlano" value={verificacaoPlanoVigente()} disabled />
                                            </div>
                                        </div>
                                        <AreaInputs>
                                            <div className='groupone'>
                                                <div>
                                                    <label htmlFor="">Modo Operacional:</label>
                                                    <input type="text" value={modo_operacao} disabled style={
                                                        {
                                                            color:
                                                                modo_operacao === 'Supervisão' ? 'green' :
                                                                    modo_operacao === 'Normal' ? '#1771BA' :
                                                                        modo_operacao === 'Falha' ? 'red' :
                                                                            modo_operacao === 'Offline' ? 'gray' :
                                                                                modo_operacao === 'Manuntenção' ? 'orange' :
                                                                                    '#040404'
                                                        }
                                                    } />
                                                </div>
                                                <div>
                                                    <label htmlFor="">Porta:</label>
                                                    <input type="text" value={porta} disabled className="porta" style={ // teste
                                                        {
                                                            color:
                                                                porta === 'aberta' ? 'red' : 'green'
                                                        }
                                                    } />
                                                </div>
                                            </div>
                                            <div className='grouptwo'>
                                                <div style={{ width: '100%' }}>
                                                    <label htmlFor="">Estado:</label>
                                                    <input type="text" value={status} disabled style={
                                                        {
                                                            color:
                                                                status === 'Normal' ? 'green' :
                                                                    status === 'Supervisão' ? 'green' :
                                                                        status === 'Offline' ? 'gray' :
                                                                            status === 'Não existe anel cadastrado' ? 'black' : 'red'
                                                        }
                                                    } />
                                                </div>
                                                <div className="groupGeral">
                                                    <label htmlFor="">Data atualização:</label>
                                                    <input type="text" value={dataAtualizacao} />
                                                </div>
                                            </div>
                                        </AreaInputs>
                                    </AreaControlador>
                                </fieldset>
                                <AreaButtons>
                                    <div className="btnEditInfo" onClick={() => { setOpenModalEquipamento(true); setOpenDescriptionIntersection(); setIdGlobal(idCruzamento); setAbaEquipamento(1) }}>
                                        <img src={editInfo} alt="edit" title="Editar" />
                                    </div>
                                    <div className="btnEditInfo" onClick={handleOpenAlertResetController}>
                                        <img src={reset} alt="edit" title="Reiniciar" />
                                    </div>
                                    <div className="btnEditInfo" onClick={() => { props.children(idCruzamento); setTypeRequest('Controlador') }}>
                                        <img src={IconbtnLogs} alt="edit" title="Relatórios" />
                                    </div>
                                    <div className="btnEditInfo">
                                        <img src={maisOpcoes} alt="edit" title="Mais opções" onClick={() => setOpenOptionsPages(true)} />
                                    </div>
                                </AreaButtons>
                            </ContentModal>
                        </DropContainer>
                    </DialogContent>
                </Modal>

                <AlertDialog
                    open={alertResetController}
                    id={idCruzamento}
                    onClick={clickReset}
                    onClose={handleCloseAlertResetController}
                >
                    Confirma reset do equipamento ? {idCruzamento}
                </AlertDialog>
                <AlertDialog
                    open={alertDeleteController}
                    onClick={deleteController}
                    onClose={handleCloseAlertDeleteController}
                >
                    Confirme para excluir o equipamento {idCruzamento}
                </AlertDialog>
            </Dialog>
            <OpenPagesExternal
                open={openOptionsPages}
                onClose={closeOptionsPages}
                id={idCruzamento}
                ipMbb={ipMbb}
                anel={numeroAnel}
                porta={dados.porta}
            />
            <AlertDiaLog open={alertOpen} onClick={editLatLng} onClose={handleCloseAlert}>
                Editar local do equipamento?
            </AlertDiaLog>
            <RegisterControllers
                open={openModalEquipamento}
                onClose={clickCloseModalEquipamento}
                numeroAnel={numeroAnel}
            >
            </RegisterControllers>
            <ModalVideo openVideo={openModalVideo} setOpenModalVideo={setOpenModalVideo} onCloseVideo={clickCloseModalVideo} />
        </>
    );
}

export default memo(ReferenceController);