import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import PageTitle from '../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container, Close, GroupInputControlador, GroupInput, ButtonAdd, ButtonRemove, Button, AreaInputs} from './styles';
import { GrClose } from "react-icons/gr";
import LineTable from "../../LineTable";
import { validarPermissao } from '../../../services/permission';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

export default function UpdateRoutes(props) {

    const [nomeRota, setNomeRota] = useState([]);
    const [dataControlers, setDataControlers] = useState([]);

    const [idsRotas, setIdsRotas] = useState(props.valueIds)


    const [novaRotaName, setNovaRotaName] = useState(props.value)


    const [inputFields, setInputFields] = useState([
        { rota: props.value, id_cruzamento: '', anel: '', grupo: '' }
    ]);

  
    const handleInputChange = (index, event) => {

        const values = [...inputFields];
        if (event.target.name === "rota") {
            values[index].rota = event.target.value;
        }
        if (event.target.name === "id_cruzamento") {
            values[index].id_cruzamento = event.target.value;
        }

        if (event.target.name === "anel") {
            values[index].anel = event.target.value;
        }

        if (event.target.name === "grupo") {
            values[index].grupo = event.target.value;
        }


        values[index].rota =  props.value;


        setInputFields(values);
    };



    const handleSubmit = async () => {

        var res = [...dataControlers]
        var verificacaoId = [];
        var verificacaoAnel = [];

        if(inputFields[0].rota === props.value && inputFields[0].id_cruzamento !== "" && inputFields[0].anel !== "" && inputFields[0].grupo !== ""  ){
            
            if(inputFields[0].id_cruzamento !== "" || inputFields[0].id_cruzamento !== undefined ){

                res.forEach((e) =>{
                    verificacaoId.push(e.id_cruzamento)
                 
                })

                if(verificacaoId.indexOf(inputFields[0].id_cruzamento) === -1){

                    await api.post('centraldefalhas/routes/register', inputFields).then(res => {
              
                        alert("Sucesso ")

                        props.onClose();
                    }).catch(error => {
                        console.log("Erro ",error)
                    })

                }else{

                    res.forEach((e) =>{


                        if(e.id_cruzamento === inputFields[0].id_cruzamento){

                            verificacaoAnel.push(e.anel)
                        }

                    })

                    if(verificacaoAnel.indexOf(inputFields[0].anel) === -1){

                        await api.post('centraldefalhas/routes/register', inputFields).then(res => {
              
                            alert("Sucesso ")
    
                            props.onClose();
                        }).catch(error => {
                            console.log("Erro ",error)
                        })

                    }else{
                        alert("Intercecção ja está cadastrada")
                    }
                }
            }
        }else{
            alert("Campos vazios")
        }

    };

    const salvarEdcaoDaAreaName = async () =>{

        var result = novaRotaName === "" ? props.value : novaRotaName

        if(props.value === result ) {
            alert("Para modificar o nome da area, apenas altere o campo!")

        }else{

          await api.put('centraldefalhas/routes/atualizar',
                {
                    "rota":  props.value ,
                    "novaRotaName": result
                }).then(res => {
                
                alert("Alterado com sucesso ")

                window.location.href = "/mapa";
                

            }).catch(error => {
                console.log("Erro ",error)
            })
        }
    };


    async function deleteController(id,anel) {
  
            await api.post('centraldefalhas/routes/idRemove', {
                "rota": props.value,
                "id_cruzamento": id,
                "anel": anel
            }).then(res => {

                alert("Sucesso ao excluir")
                props.onClose();
                
            }).catch(error => {
                console.log("Erro ",error)
            })
    }


    const handleGetDataControler = async () => {
        await api.get('/centraldefalhas/controladores').then(res => {
            setNomeRota(res.data);
        
        }).catch(error => {
            console.log(error);
        });
    }


    useEffect(() => {

        handleGetDataControler()

        setDataControlers(props.valueIds)

    }, [props.open, props.value]);

    useEffect(() =>{
        handleGetDataControler()
    },[])


    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Atualizar Rota"
                onClick={props.onClose}
            ></PageTitle>

                <Container className={permissao}>
                    <Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
                    <fieldset>
                        <legend>Nome da Rota</legend>
                        <GroupInput>
                            <input type="text" className="infoArea" defaultValue={props.value} onChange={(e) => setNovaRotaName(e.target.value)}/>
                            <Button onClick={() => salvarEdcaoDaAreaName()}>
                                Salvar
                            </Button>
                        </GroupInput>
                    </fieldset>
                    <fieldset>
                        <legend className="idLocais">ID Cruzamento</legend>
                        {dataControlers.map((data, idx) => {

                            return (
                                <>
                                    <GroupInput key={data.anel+idx} >
                                        <input type="text" id="locais" value={data.id_cruzamento} />
                                        <input type="text" id="anel" value={data.anel} />
                                        <input type="text" id="monitor" value={data.grupo} />
                                        <ButtonRemove
                                            onClick={() => deleteController( data.id_cruzamento, data.anel)}>
                                                {"Remover"}
                                        </ButtonRemove>
                                    </GroupInput>
                                </>
                            );
                        })}
                    </fieldset>



                    <fieldset>
                        <legend>Controlador</legend>
                        {inputFields.map((inputField, index) => {
                            return (
                                <>
                                    <GroupInputControlador  key={`${inputField}~${index}`}>
                                        <select name="id_cruzamento"
                                            value={inputField.id_cruzamento} onChange={event => handleInputChange(index, event)}
                                        >
                                            <option value="">Selecione um Id...</option>
                                            {nomeRota.map((items) => {
                                                return (
                                                    <option key={items.id_cruzamento} value={items.id_cruzamento}>
                                                        {items.id_cruzamento}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <AreaInputs>
                                            <select name="anel" id="anel"
                                                value={inputField.anel} onChange={event => handleInputChange(index, event)}
                                            >
                                                <option value="">Anel...</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <input type="text" name="grupo" id="grupo" value={inputField.grupo} onChange={event => handleInputChange(index, event)} placeholder="Grupo" />
                                        </AreaInputs>
                                        <ButtonAdd onClick={() => handleSubmit()}>
                                            {"Adicionar"}
                                        </ButtonAdd>
                                    </GroupInputControlador>
                                </>
                            );
                        })}
                    </fieldset>
                        


                </Container>
        </Dialog>
    );
}

