import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PageTitle from '../PageTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import RegisterUsers from '../Registers/RegisterUsers';
import RegisterCad from '../Registers/RegisterCad';
import RegisterComunication from '../Registers/RegisterComunication';
import { Container, AreaMenu, VisibleRegister, Tabs } from './styles';

function PaperComponent(props) {
	return (
		<Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

function SettingUsers(props) {

	const [positionAba, setPositionAba] = useState('25');
	const [usuario, setUsuario] = useState('inactive');
	const [cadastro, setCadastro] = useState('inactive');
	const [comunicacao, setComunicacao] = useState('inactive');
	const [abaUsuario, setAbaUsuario] = useState('block')
	const [abaCadastro, setAbaCadastro] = useState('none');
	const [abaComunicacao, setAbaComunicacao] = useState('none');


	const Position1 = () => {
		setPositionAba('25');
		setUsuario('active');
		setCadastro('inactive');
		setComunicacao('inactive');
		setAbaUsuario('block');
		setAbaCadastro('none');
		setAbaComunicacao('none');
	}
	const Position2 = () => {
		setPositionAba('229');
		setUsuario('inactive');
		setCadastro('active');
		setComunicacao('inactive');
		setAbaUsuario('none');
		setAbaCadastro('block');
		setAbaComunicacao('none');
	}
	const Position3 = () => {
		setPositionAba('425');
		setUsuario('inactive');
		setCadastro('inactive');
		setComunicacao('active');
		setAbaUsuario('none');
		setAbaCadastro('none');
		setAbaComunicacao('block');
	}

	return (
		<Dialog
			open={props.open}
			className="Dialog"
			onClose={props.onClose}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
			hideBackdrop={true}
		>
			<PageTitle
				id="draggable-dialog-title"
				text="Configurações de usuários"
				onClick={props.onClose}
			></PageTitle>
			<Container>
				<AreaMenu>
					<Tabs position={positionAba}>
						<ul>
							<li onClick={Position1} id={usuario}>Usuário</li>
							<li onClick={Position2} id={cadastro}>Cadastro</li>
							<li onClick={Position3} id={comunicacao}>Templates de comunicação</li>
						</ul>
					</Tabs>
				</AreaMenu>
				<VisibleRegister status={abaUsuario}>
					<RegisterUsers atualizar={abaUsuario} onClose={props.onClose} />
				</VisibleRegister>
				<VisibleRegister status={abaCadastro}>
					<RegisterCad atualizar={abaCadastro} onClose={props.onClose} />
				</VisibleRegister>
				<VisibleRegister status={abaComunicacao}>
					<RegisterComunication atualizar={abaComunicacao} onClose={props.onClose} />
				</VisibleRegister>
			</Container>
		</Dialog>
	)
}

export default SettingUsers;