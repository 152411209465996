import React, { useState } from "react";
import Toast from 'light-toast';
import ModalRecover from './ModalRecover';
import ModalInactivity from "./ModalInactivity";
import useForm from "../../hooks/useForm";
import { AreaLogin, Container, ImageBackground, Form, Input, InputView, IconView, AreaButton, Button } from "./styles";
import logoLogin from '../../assets/logoLogin.png';
import IconViewTrue from "./Icons/IconViewTrue";
import IconViewFalse from "./Icons/IconViewFalse";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import { encripta } from "../../services/encrypt";

function Login() {

    localStorage.removeItem('@central-de-falhas-greenwave/tokenAuth');
    const [{ values }, handleChange, handleSubmit] = useForm();
    const { email, password } = values;
    const [infoEmail, setInfoEmail] = useState('');
    const [infoSenha, setInfoSenha] = useState('');
    const [statusInput, setustatusInput] = useState('access');
    const [useModal, setUseModal] = useState('none');
    const [useSlide, setUseSlide] = useState('-42vw');
    const [responsiveModal, setResponsiveModal] = useState('none');
    const [viewPassword, setViewPassword] = useState('password');
    const [visible, setVisible] = useState('none');
    const history = useHistory();


    async function handleLogin() {
        if (!email || !password || email === "" || password === "") {
            Toast.fail("Preencha todos os campos", 1000)
        } else {
            await api.post('/auth/login', {
                "email": email,
                "password": password
            }).then(res => {
                const { name, timerMap, permission, lat, long, zoom, townhall } = res.data.user;
                const permissions = (encripta(permission));
                if (email === values.email) {
                    Toast.success(`Bem vindo a Central de Semafórica ${name}`, 2500, () => {
                        localStorage.setItem('@central-de-falhas-greenwave/tokenAuth', res.data.token);
                        localStorage.setItem('@central-de-falhas-greenwave/permission', permissions);
                        localStorage.setItem('@central-de-falhas-greenwave/positionLat', lat);
                        localStorage.setItem('@central-de-falhas-greenwave/positionLng', long);
                        localStorage.setItem('@central-de-falhas-greenwave/townhall', townhall);
                        localStorage.setItem('@central-de-falhas-greenwave/setTimeReload', timerMap);
                        localStorage.setItem('@central-de-falhas-greenwave/zoom', zoom);
                        localStorage.setItem('@central-de-falhas-greenwave/name', name);
                        localStorage.setItem('modoMapa', 'segundo');
                        localStorage.setItem('tocarAudio', 'inativo');
                        localStorage.setItem('Alertas', true);
                        window.location.href = "/mapa";
                    });
                }

            }).catch(err => {
                try {
                    if (err.response.data === undefined) {
                        Toast.fail('Erro ao logar, tente mais tarde !!', 3000);
                        return
                    }
                    else if (err.response.data.mensagem === "Senha inválida") {
                        const tentativasRestantes = err.response.data.tentativasRestantes;
                        const maximoDeTentativas = err.response.data.maximoDeTentativas;
                        setInfoSenha(`Senha incorreta (${tentativasRestantes} de  ${maximoDeTentativas} tentativas).`);
                        setustatusInput('error');

                    } else if (err.response.data.mensagem === "Usuário não encontrado") {
                        setInfoEmail('Esse usuário não está cadastrado, verifique o e-mail digitado !');
                        setustatusInput('error');

                    } else if (err.response.data.mensagem === "Usuário bloqueado") {
                        setInfoEmail('Usuário bloqueado, siga as instruções enviadas para seu e-mail !');
                        setustatusInput('error');
                    }

                    else if (err.response.data.mensagem === "Email não autenticado") {
                        Toast.fail('Confirme seu cadastro acessando seu e-mail!', 2500);
                        setustatusInput('error');
                    }

                    else if (err.response.data.mensagem === "Licença expirada") {
                        Toast.fail('Licença expirada', 2500);
                        setustatusInput('error');
                    }

                } catch (error) {
                    history.push('/404');
                }
            })
        }
    };

    const recoverPassword = () => {
        setUseModal('block');
        setResponsiveModal('block');
        setVisible('block');

        if (useSlide === '-42vw') {
            setUseSlide('42vw');
        } else {
            setUseSlide('-42vw');
        }
    }

    const switchView = () => {
        if (viewPassword === 'password') {
            setViewPassword('text');
        } else {
            setViewPassword('password');
        }
    }

    return (
        localStorage.clear(),
        sessionStorage.clear(),
        <Container>
            <ImageBackground />
            <AreaLogin>
                <div className='logo'>
                    <img src={logoLogin} alt="" />
                </div>
                <Form onSubmit={handleSubmit(handleLogin)}>
                    <div className='group'>
                        <label htmlFor="">E-mail: <span className={statusInput}>{infoEmail}</span></label>
                        <Input
                            onChange={handleChange}
                            name="email"
                            type="email"
                            placeholder="nome@email.com"
                            className={statusInput}
                            outLine={statusInput === 'access' ? '#001E62' : 'red'}
                            tabIndex={1}
                        />
                    </div>
                    <div className='group'>
                        <label htmlFor="">Senha: <span className={statusInput}>{infoSenha}</span></label>
                        <InputView outLine={statusInput === 'access' ? '#001E62' : 'red'}>
                            <input
                                onChange={handleChange}
                                name="password"
                                type={viewPassword}
                                placeholder="Digite sua senha..."
                                className={statusInput}
                                tabIndex={2}
                            />
                            <IconView onClick={switchView}>
                                {viewPassword === 'password' ? <IconViewFalse /> : <IconViewTrue />}
                            </IconView>
                        </InputView>
                    </div>
                    <AreaButton>
                        <span href="" onClick={recoverPassword}>Esqueceu sua senha?</span>
                        <Button type="submit" tabIndex={3}>ENTRAR</Button>
                    </AreaButton>
                </Form>
            </AreaLogin>
            <ModalRecover status={useModal} setStatus={setUseModal} slide={useSlide} setSlide={setUseSlide} slideResponsive={responsiveModal} setSlideResponsive={setResponsiveModal} view={visible} setView={setVisible} />
            <ModalInactivity />
        </Container>
    );
}

export default Login;
