import React from 'react';
import { Container, Filedset, Buttons } from './styles';
import Button from '@material-ui/core/Button';
import { validarPermissao } from '../../../services/permission';
import './styles.css';

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

function Table(props) {

    const chamarInfo = () => {
        props.getInfo(props.children);
    }

    const deleteRoute = () => {
        props.deleteRoute(props.children)
    }

    const InfoArea = () => {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 18.25C15.4518 18.25 18.25 15.4518 18.25 12C18.25 8.54822 15.4518 5.75 12 5.75C8.54822 5.75 5.75 8.54822 5.75 12C5.75 15.4518 8.54822 18.25 12 18.25Z" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 14.5V12" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 9.5H12.0073" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#001E62" />
            </svg>
        );
    }

    const CloneArea = () => {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 5.125H7C6.3125 5.125 5.75 5.6875 5.75 6.375V15.125H7V6.375H14.5V5.125ZM13.875 7.625L17.625 11.375V17.625C17.625 18.3125 17.0625 18.875 16.375 18.875H9.49375C8.80625 18.875 8.25 18.3125 8.25 17.625L8.25625 8.875C8.25625 8.1875 8.8125 7.625 9.5 7.625H13.875ZM13.25 12H16.6875L13.25 8.5625V12Z" fill="#dedfe0" /*fill="#001E62"*/ />
                <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#dedfe0" /> {/*stroke="#001E62"*/}
            </svg>
        );
    }

    const ExcluirArea = () => {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.375 8.25H7.625H17.625" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.5 8.25V7C9.5 6.66848 9.6317 6.35054 9.86612 6.11612C10.1005 5.8817 10.4185 5.75 10.75 5.75H13.25C13.5815 5.75 13.8995 5.8817 14.1339 6.11612C14.3683 6.35054 14.5 6.66848 14.5 7V8.25M16.375 8.25V17C16.375 17.3315 16.2433 17.6495 16.0089 17.8839C15.7745 18.1183 15.4565 18.25 15.125 18.25H8.875C8.54348 18.25 8.22554 18.1183 7.99112 17.8839C7.7567 17.6495 7.625 17.3315 7.625 17V8.25H16.375Z" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#001E62" />
                <defs>
                    <rect width="15" height="15" fill="white" transform="translate(4.5 4.5)" />
                </defs>
            </svg>
        );
    }

    return (

        <Container>
            <Filedset>
                <div className="group">
                    <label>Nome da rota:</label>
                    <input type="text" value={props.children} />
                </div>
                <Buttons>
                    <Button
                        className="dialog-button"
                        title="Informações da rota"
                        onClick={chamarInfo}
                    >
                        <InfoArea />
                    </Button>
                    <Button
                        className="dialog-button"
                        title="Clonar rota"
                        disabled
                    >
                        <CloneArea />
                    </Button>
                    <Button
                        className="dialog-button"
                        title="Excluir rota"
                        onClick={deleteRoute}
                        id={permissao}
                    >
                        <ExcluirArea />
                    </Button>
                </Buttons>
            </Filedset>
        </Container>
    )
}

export default Table;