import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartTop from '../../../img/Setas/arrowStartTop.png';
import arrowPauseTop from '../../../img/Setas/arrowPauseTop.png';
import arrowStopTop from '../../../img/Setas/arrowStopTop.png';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveTop, Options, ButtonSettings } from './styles';

export default function ArrowTop(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Seta subindo', image: arrowStartTop, object: props.arrowTop, setObject: props.setArrowTop });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowTop.map((item, index) => {
                if (props.arrowTop[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowTop'
                            top={item.top}
                            left={item.left}
                            setArrowTop={props.setArrowTop}
                            arrowTop={props.arrowTop}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveTop
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartTop :
                                        item.color_group === 1 ? arrowStopTop :
                                            item.color_group === 2 ? arrowPauseTop :
                                                item.color_group === 4 ? arrowStartTop : ''
                                }
                                alt=""
                                move={
                                    item.color_group === undefined || item.color_group === '' ? 'pause' :
                                        item.color_group === 1 ? 'pause' :
                                            item.color_group === 2 ? 'pause' :
                                                item.color_group === 4 ? true : props.moveArrowTop
                                }
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowTop(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
