import styled from 'styled-components';

export const Container = styled.section`
    padding: 0 35px 35px 35px;
    overflow-x: scroll;
    height: 277px;

    &::-webkit-scrollbar {
            width: 8px;
            height: 8px;

            &-track-piece {
                background-color: #FFF;
            }

            &-thumb:vertical,
            &-thumb:horizontal {
                background-color: #001E62;
                border-radius:5px;
            }

            &-thumb:vertical:hover,
            &-thumb:horizontal:hover {
                background-color: #269F2A;
            }
        }

    @media(max-width: 420px){
        overflow: scroll;
        width: 90%;
        margin: 0 auto;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const Table = styled.table`
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;

    thead{
        tr{
            position: sticky;
            top: 0;
        }
        th{
            &:nth-of-type(1){
                border-radius: 6px 0 0 6px;
            }
            &:nth-of-type(7){
                border-radius: 0px 6px 6px 0px;
            }
        }
    }

    tbody{
        tr{
            td{
                &:nth-of-type(1){
                    
                }
            }
        }
    }

    td{
        border-bottom: 1px solid #ddd;
        padding: 7px;
        color: #494747;
        text-align: center;
        font-family: 'roboto';
        font-size: 13px;
        padding: 0;
        height: 30px;

        svg {
            margin-left: 10px;
            cursor: pointer;
        }

        #excluir{
            width: 40px;
            display: flex;
            margin: 0 auto;
        }

        select {
            width: 115px;
            height: 26px;
            border: 1px solid silver;
            border-radius: 3px;
            font-family: 'roboto', sans-serif;
            color: #383535;

            &:focus{
                border: 2px solid #001E62;
            }
        }
    }
    tr{
        &:hover {
            //background-color: #ddd;
        }
    }
    th{
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        width: 160px;
        height: 25px;
        font-size: 12px;

        @media(max-width: 420px){
            height: 22px;
            font-size: 13px;
        }
    }
`;

export const ModalConfirmDelete = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 350px;
    height: 150px;
    background: #FFF;
    border-radius: 4px;
    box-shadow: 5px 5px 4px -3px #898989;

    h3{
        text-align: center;
        color: #001E62;
        font-family: 'roboto', sans-serif;
    }

    span{
        color: red;
        font-family: 'roboto',sans-serif;
        font-size: 14px;
        text-align: center;
        display: block;
    }

    .areaButtons{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 25px;
        button{
            width: 100px;
            height: 29px;
            border: 0;
            border-radius: 4px;
            cursor: pointer;
            font-size: 12px;
            text-transform: uppercase;
            .confirm{

            }
            .cancel{

            }
        
        }
    }
`;
