import React, { useState, useEffect } from 'react';
import { Container, Table } from './styles';
import api from '../../../../../services/api';
import excluir from '../../../../../assets/img/excluir.png';
import lapis from '../../../../../assets/img/lapis.png';
import Alert from '@material-ui/lab/Alert';

export default function Licenca(props) {

    const [dados, setDados] = useState([]);
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');

    const lerDados = async () => {
        const response = await api.get('/centraldefalhas/licenca/list');
        setDados(response.data);
    }

    useEffect(() => {
        lerDados();
    }, []);

    useEffect(() => {
        lerDados();
    }, [props.cbAbaLicensa]);

    const excluirLicenca = async (item) => {
        setMsg('Licença ' + item + ' ' + 'deletada com sucesso!');
        setAlertMsg('block');
        setTypeAlert('success');
        await api.delete(`/centraldefalhas/licenca?townhall=${item}`)
            .then((res) => {
                setMsg('Licença ' + item + ' ' + 'deletada com sucesso!');
                setAlertMsg('block');
                setTypeAlert('success');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 2000);
                lerDados();
            })
            .catch((error) => {
                setMsg(error.response.data.mensagem);
                setAlertMsg('block');
                setTypeAlert('error');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 3000);
            })
    }

    const convertData = (data) => {
        const dataHora = new Date(data * 1000);
        const dia = dataHora.getUTCDate();
        const mes = dataHora.getUTCMonth() + 1;
        const ano = dataHora.getUTCFullYear();
        const dataHoraFormatada = dia + '/' + mes + '/' + ano;
        return dataHoraFormatada;
    }

    return (
        <Container view={alertMsg}>
            <div className="alert">
                <Alert severity={typeAlert}>{msg}</Alert>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Editar</th>
                        <th>Nome da licença</th>
                        <th>Logotipo</th>
                        <th>Máx. de usuários</th>
                        <th>Máx de equipamentos</th>
                        <th>Expiração</th>
                        <th>Nome</th>
                        <th>Telefone</th>
                        <th>Email</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {dados.map((item, index) => (
                        <tr key={item.id}>
                            <td><div onClick={() => { props.editDados(index); props.position1(); props.enabledBox(); props.setLicencaEdicao(item.townhall) }}><img src={lapis} alt="" /></div></td>
                            <td>{item.townhall}</td>
                            <td><img src={item.logo} alt="" /></td>
                            <td>{item.max_usuarios}</td>
                            <td>{item.max_equipamentos}</td>
                            <td>{convertData(item.validade)}</td>
                            <td>{item.responsavel}</td>
                            <td>{item.telefone}</td>
                            <td>{item.email}</td>
                            <td><img src={excluir} alt="" onClick={() => excluirLicenca(item.townhall)} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
}