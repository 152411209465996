import React from 'react';
import { BalloonUp, BalloonRight, BalloonMenuLateral, BallonParametros, BallonInfoModal, BalloonCroqui } from './styles';


/* Componentes para as caixas de dialogos que serão exibidas no tutorial guiado */

function BalloonsUp(props) {
    return (
        <BalloonUp visible={props.visible}>
            {props.children}
        </BalloonUp>
    );
}

function BalloonsRight(props) {
    return (
        <BalloonRight>
            {props.children}
        </BalloonRight>
    );
}

function BalloonsMenuLateral(props) {
    return (
        <BalloonMenuLateral>
            {props.children}
        </BalloonMenuLateral>
    );
}

function BallonsParametros(props) {
    return (
        <BallonParametros>
            {props.children}
        </BallonParametros>
    )
}

function BallonsInfoModal(props) {
    return (
        <BallonInfoModal>
            {props.children}
        </BallonInfoModal>
    );
}

function BallonCroqui(props) {
    return (
        <BalloonCroqui>
            {props.children}
        </BalloonCroqui>
    );
}

export { BalloonsUp, BalloonsRight, BalloonsMenuLateral, BallonsParametros, BallonsInfoModal, BallonCroqui }