import styled from 'styled-components';

export const Container = styled.div`
    padding: 3px 24px;

    @media(max-width: 480px){
        padding: 3px 18px;
    }
`;

export const Close = styled.div`
    display: none;
    @media(max-width: 580px){
        display: block;
        position: absolute;
        right: 4%;
        top: 13px;
        border: 1px solid #FFF;
        display: flex;
        padding: 1px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            path{
                stroke: #FFF;
                stroke-width: 3px;
            }
        }
    }
`;

export const Filedset = styled.fieldset`
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: 1px solid silver;
    height: 55px;
    border-radius: 4px;

    @media(max-width: 420px){
        flex-direction: column;
        height: 83px;
        padding-top: 20px;
    }
    
    .group{
        display: flex;
        flex-direction: column;
        margin-top: -15px;
        margin-left: 10px;

        label{
            color: rgba(0, 0, 0, 0.4);
        }
    }

    input{
        height: 32px;
        width: 200px;
        border: 1px solid silver;
        border-radius: 4px;
        outline: none;
        padding-left: 10px;

        @media(max-width: 420px){
            width: 65vw;
            padding-left: 3px;
        }
    }
`;

export const Buttons = styled.section`
    display: flex;
    margin-left: 5px;
    svg{
        width: 35px;
        height: 35px;
    }

    @media(max-width: 420px){
        width: 100%;
        justify-content: space-evenly;
    }
`;

export const Button = styled.button`
    width: 137px;
    height: 30px;
    background: #001E62;
    color: #FFF;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin: 10px 22px 10px 0;

    &#disabled{ // id para desabilitar permissões
	pointer-events: none;
	opacity: 0.4;
    }

    &:active{
        position: relative;
        top: 1px;
    }

    &:hover{
        background: #FFF;
        border: 1px solid #001E62;
        color: #001E62;
        transition: .3s;
        svg{
            path{
                fill:#001E62;
                transition: .3s;
            }
        }
    }

    svg{
        width: 17px;
        height: 17px;
        margin-right: 10px;
    }
`;