import React, { useState, useEffect, useContext } from 'react';
import { Container, AreaInputs, AreaButtons, InfoHelp, AreaBallon, ContentBallon } from './styles';
import editInfo from '../../../../assets/editInfo.svg';
import api from '../../../../services/api';
import Alert from '@material-ui/lab/Alert';
import { validarPermissao } from '../../../../services/permission';
import { ModalContext } from '../../../../contextModal';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../Balloons';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import { tocarVozLocal } from '../../../Audio';

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

export default function Local(props) {


    const [dados, setDados] = useState({
        id_cruzamento: '',
        logradouro: '',
        referencia: '',
        townhall: '',
    });
    const [info, setInfo] = useState('');
    const [visibleAlert, setVisibleAlert] = useState('none');
    const [versao, setVersao] = useState('Exibir');
    const [versaoSistema, setVersaoSistema] = useState('Exibir');
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const { setIdGlobal } = useContext(ModalContext);
    const { setAnelGlobal } = useContext(ModalContext);
    const { setAbaEquipamento } = useContext(ModalContext);

    //Verifica se o ip do chip está ativo, caso não esteja assume o ip local da beaglebone.
    var ipChip = props.intersection.sistema_ip_rmu != "000.000.000.000" ? props.intersection.sistema_ip_rmu : props.intersection.ip_mmbb_atual;

    const lerDados = async () => {
        try {
            if (props.tipoDados === 'mestre') { //faz verificação do tipo que o anel clicado é (ReferenceController.js) se caso for 'mestre' faz a 
                const response = await api.get(`/centraldefalhas/local/${props.id}`);//requisição, caso contrário seta as informações que vem dos anéis
                setDados(response.data.local);
                console.log('local', response.data.local)
                setInfo('');
            } else {
                console.log(props.dadosAnel)
                setDados(props.dadosAnel);
                setInfo('');
            }

        } catch (error) {
            setInfo('Equipamento não cadastrado');
            setVisibleAlert('flex');
        }
    }

    useEffect(() => {
        lerDados();
    }, []);

    useEffect(() => {
        lerDados();
    }, [props.status]);

    useEffect(() => {
        lerDados();
    }, [props.anel]);

    const mostrarVersaoImagem = () => {
        if (versao === 'Exibir') {
            setVersao(props.intersection.sistema_versao_img);
        } else {
            setVersao('Exibir');
        }
    }

    const mostrarVersaoSistema = () => {
        if (versaoSistema === 'Exibir') {
            setVersaoSistema(props.intersection.sistema_versao);
        } else {
            setVersaoSistema('Exibir');
        }
    }

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    const ContentPopBallonLocal = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Local</h3>
                    <label onClick={tocarVozLocal}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nesta opção estão listadas todas as informações concernentes ao ponto escolhido do mapa.
                    Para edição de qualquer informação como: consórcio, mantenedora, instaladora, modelo, ip, entre outros,
                    clique no botão "Editar", primeiro botão listado abaixo.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    const validaAnel = () => {
        if (['2', '3', '4'].includes(props.anel.substr(-1))) {
            setAnelGlobal(props.anel.substr(-1));
        } else {
            setAnelGlobal('1');
        }
    }

    return (
        <Container style={{ display: props.status }} visible={visibleAlert}>
            <Alert severity="error" className="alert">{info}</Alert>
            <fieldset>
                <legend>Sistema</legend>
                <AreaInputs>
                    <InfoHelp onClick={() => openPopUp()}>
                        <GrCircleQuestion size={15} />
                    </InfoHelp>
                    <AreaBallon visible={visibleBalloon}>
                        <BallonsInfoModal><ContentPopBallonLocal /></BallonsInfoModal>
                    </AreaBallon>
                    <div className='groupone'>
                        <div>
                            <label htmlFor="">ID do local:</label>
                            <input type="text" value={dados.id_cruzamento} disabled />
                        </div>
                        <div className="inputTwo">
                            <label htmlFor="">IP Externo:</label>
                            <input type="text" value={props.intersection.ip_mmbb_atual} disabled />
                        </div>
                    </div>
                    <div className='grouptwo'>
                        <div className="inputTwo" style={{ display: 'flex', gap: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px' }}>
                                <label htmlFor="" style={{ fontSize: '10px' }}>Versão Imagem:</label>
                                <input type="button" id="btnVersao" value={versao} onClick={mostrarVersaoImagem} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100px' }}>
                                <label htmlFor="" style={{ fontSize: '10px' }}>Versão Firmware:</label>
                                <input type="button" id="btnVersao" value={versaoSistema} onClick={mostrarVersaoSistema} />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="">IP RMU:</label>
                            <input type="text" value={ipChip} disabled />
                        </div>
                    </div>
                </AreaInputs>
            </fieldset>
            <fieldset>
                <legend>Geral</legend>
                <AreaInputs>
                    <div className='bloco2'>
                        <div>
                            <label htmlFor="">Licença:</label>
                            <input type="text" value={dados.townhall === undefined ? props.dados.townhall : dados.townhall} disabled />
                        </div>
                        <div>
                            <label htmlFor="">Logradouro:</label>
                            <input type="text" value={dados.logradouro} disabled />
                        </div>
                        <div>
                            <label htmlFor="">Ponto de referência:</label>
                            <input type="text" value={dados.referencia} disabled />
                        </div>
                    </div>
                </AreaInputs>
            </fieldset>
            <AreaButtons className={permissao}>
                <div className="btnEditInfo" onClick={() => {
                    props.openEquipamento(true);
                    setIdGlobal(dados.id_cruzamento); setAbaEquipamento(0); props.closeModal(false); validaAnel()
                }
                } title="Editar">
                    <img src={editInfo} alt="edit" />
                </div>
            </AreaButtons>
        </Container>
    );
}
