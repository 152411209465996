import { toast } from 'react-toastify';
import { descripta } from './encrypt';

function validarPermissao(permissoesAceitas){
  
    if(typeof permissoesAceitas !== 'object'){
        return false
    }

    const storage = () => {
        if(localStorage.getItem('@central-de-falhas-greenwave/permission') === null){
            return
        }else{
           return localStorage.getItem('@central-de-falhas-greenwave/permission');
        }
    }

    const descriptografado = descripta(storage())

    const permissionUser = parseInt(descriptografado);

    const verificacao = permissoesAceitas.includes(permissionUser);

    if(verificacao){
        return true
    }
    else{
        return false
    }
}

const msgPermissionDenied = () => toast.error(`USUÁRIO NÂO TEM PERMISSÂO !`, {
    position: "top-center",
    toastId:"openEditPermission",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});

export { validarPermissao, msgPermissionDenied } ;

/*legenda
 0 : 'Administrador'
 1 : 'Gestor'
 2 : 'Operador' 
 3 : 'Visitante'
 */