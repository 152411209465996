import React, { useEffect, useState, memo, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import FileDownload from 'js-file-download';
import 'react-toastify/dist/ReactToastify.css';
import Draggable from 'react-draggable';
import PageTitle from '../PageTitle';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import api from '../../services/api';
import AlertDialog from '../AlertDialog';
import Alertid from '../Alertid';
import IconLixeira from '../../assets/icons/IconLixeira';
import IconRelatorio from '../../assets/icons/IconRelatorio';
import { MenuHeader, AreaInputs, Button, Container, Close, AreaTable, Table, AreaLoading, Filter, AreaButtons, AreaPagination } from './styles';
import { FiFilter } from "react-icons/fi";
import Pagination from '@material-ui/lab/Pagination';
import FiltroAcao from './Filters/FiltroAcao';
import FiltroAgente from './Filters/FiltroAgente';
import FiltroDescricao from './Filters/FiltroDescricao';
import FiltroNivel from './Filters/FiltroNivel';
import { validarPermissao } from '../../services/permission';
import { GrClose } from "react-icons/gr";
import Alert from '@material-ui/lab/Alert';
import './styleTable.css';
import loader from '../../assets/img/loader.gif';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const estadoLixeira = (
    validarPermissao([0, 1]) ? 'enabled' : 'disabled'
);

function StickyHeadTable(props) {

    const [logs, setLogs] = useState({
        dados: [],
        quantidadeTotal: 0,
        pagina: ''
    });
    const [dados, setDados] = useState([]);
    const [dataMin, setDataMin] = useState('');
    const [dataMax, setDataMax] = useState('');
    const [maximoItensPagina, setMaximoItensPagina] = useState(100);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [descricoes, setDescricoes] = useState([]);
    const [agente, setAgente] = useState([]);
    const [nivel, setNivel] = useState([]);
    const [acao, setAcao] = useState([]);
    const [alertView, setAlertView] = useState('hidden');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [viewLoading, setViewLoading] = useState('none');
    const [filtroAgente, setFiltroAgente] = useState(false);
    const [filtroNivel, setFiltroNivel] = useState(false);
    const [filtroAcao, setFiltroAcao] = useState(false);
    const [filtroDescricao, setFiltroDescricao] = useState(false);
    const [paramAgente, setParamAgente] = useState([]);
    const [paramNivel, setParamNivel] = useState([]);
    const [paramAcao, setParamAcao] = useState([]);
    const [paramDescricao, setParamDescricao] = useState([]);

    const { statusFilterNivel } = useContext(ModalContext);
    const { statusFilterAcao } = useContext(ModalContext);
    const { statusFilterAgente } = useContext(ModalContext);
    const { statusFilterDescricao } = useContext(ModalContext);
    const { typeRequest } = useContext(ModalContext);

    useEffect(() => {
        setDataMin(dataMin);
        setLogs({
            dados: [],
            quantidadeTotal: 0,
            pagina: ''
        })
    }, [dataMin]);
    useEffect(() => {
        setDataMax(dataMax);
        setLogs({
            dados: [],
            quantidadeTotal: 0,
            pagina: ''
        });
    }, [dataMax]);


    const [alert, setAlert] = useState(false);
    localStorage.setItem('dataLogImp', props.id);
    const [alertId, setAlertId] = useState(false);
    const [numeroId, setNumeroId] = useState('');

    const handleOpenAlert = () => {
        setAlert(true);
    };

    const handleCloseAlert = () => {
        setAlert(false);
    };


    const openFilterDescricao = () => {
        if (filtroDescricao) {
            setFiltroDescricao(false);
        } else {
            setFiltroDescricao(true);
            setFiltroAgente(false);
            setFiltroNivel(false);
            setFiltroAcao(false);
        }
    }

    const openFilterAgente = () => {
        if (filtroAgente) {
            setFiltroAgente(false);
        } else {
            setFiltroAgente(true);
            setFiltroDescricao(false);
            setFiltroNivel(false);
            setFiltroAcao(false);
        }
    }

    const openFilterNivel = () => {
        if (filtroNivel) {
            setFiltroNivel(false);
        } else {
            setFiltroNivel(true);
            setFiltroDescricao(false);
            setFiltroAgente(false);
            setFiltroAcao(false);
        }
    }

    const openFilterAcao = () => {
        if (filtroAcao) {
            setFiltroAcao(false);
        } else {
            setFiltroAcao(true);
            setFiltroDescricao(false);
            setFiltroAgente(false);
            setFiltroNivel(false);
        }
    }

    async function deleteLogs() {
        if (validarPermissao([0, 1])) {
            const id = props.id;
            setAlertView('visible');
            setTypeAlert('info');
            setAlertMessage('Aguarde...');
            setTimeout(() => {
                setAlertView('hidden');
                setAlertMessage('');
                setTypeAlert('');
            }, 2500);

            await api.post('/centraldefalhas/intersection/removelogs', {
                "id_cruzamento": id,
            }).then(res => {
                setAlertView('visible');
                setTypeAlert('success');
                setAlertMessage('Logs apagados com sucesso!');
                setTimeout(() => {
                    setAlertView('hidden');
                    setAlertMessage('');
                    setTypeAlert('');
                }, 2500);
                getLogsForControllers(1); // após excluir chama a requisição novamente
                setAlert(false); // fecha modal de exclusão
                //return props.onClose()
            }).catch(error => {
                setAlertView('visible');
                setTypeAlert('error');
                setAlertMessage('Erro ao apagar logs!');
                setTimeout(() => {
                    setAlertView('hidden');
                    setAlertMessage('');
                    setTypeAlert('');
                }, 2500);
            });
        }
    };

    async function getLogsForControllers(pagina) {

        const id = props.id;
        if ([dataMin, dataMax].includes('')) {
            setAlertView('visible');
            setAlertMessage('Preencher data inicial e data final');
            setTypeAlert('error');

        } else {
            try {
                console.log(typeRequest);
                setAlertView('visible');
                setAlertMessage('Carregando logs...');
                setLoading(true);
                setViewLoading('block');
                const response = await api.get(`centraldefalhas/intersection/logs?id_cruzamento=${id}&dataMin=${dataMin}&dataMax=${dataMax}&pagina=${pagina}&itens=${maximoItensPagina}&agente=${paramAgente}&nivel=${paramNivel}&acao=${paramAcao}&descricao=${paramDescricao}&tipo=${typeRequest}`)
                setMaxPage(Math.ceil(response.data.quantidadeTotal / maximoItensPagina));

                if (response.data.quantidadeTotal == 0) {
                    setAlertView('visible');
                    setLoading(false);
                    setViewLoading('none')
                    setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
                    setTypeAlert('error');
                    setDados([]);

                    setTimeout(() => {
                        setAlertView('hidden');
                        setAlertMessage('');
                        setTypeAlert('');
                    }, 2500);

                } else {
                    setLogs(response.data);
                    setDados(response.data.dados.reverse());
                    setAlertView('visible');
                    setAlertMessage('Logs carregados!');
                    setTypeAlert('success');
                    setTimeout(() => { setAlertView('hidden') }, 2500);
                    setLoading(false);
                    setViewLoading('none');

                    setDescricoes(response.data.descricao);
                    setAgente(response.data.agente);
                    setNivel(response.data.nivel);
                    setAcao(response.data.acao);
                }

            } catch (error) {
                setAlertView('block');
                setAlertMessage('Erro ao carregar logs!');
                setTypeAlert('error');
                setTimeout(() => { setAlertView('hidden') }, 300);
                setLoading(false);
                setViewLoading('none');
            }

        }
    };

    const exportLogsExcel = async () => {
        setLoading(true);
        const id = props.id;

        const fileExcel = await api.get(`/centraldefalhas/xls/log/controlador?id_cruzamento=${id}&dataMin=${dataMin}&dataMax=${dataMax}&agente=${paramAgente}&nivel=${paramNivel}&acao=${paramAcao}&descricao=${paramDescricao}&tipo=${typeRequest}`, { responseType: 'blob' });
        FileDownload(fileExcel.data, `logs.xlsx`);
        setLoading(false);
        setViewLoading('none');
    }

    async function deleteLog() {
        setLoading(true);
        await api
            .delete(`centraldefalhas/relatorios/${numeroId}`)
            .then(() => getLogsForControllers(1));
        setAlertId(false);
        setLoading(false);
        setViewLoading('none');
    }

    const closeAlertId = () => {
        setAlertId(false);
    }

    const handleChange = (event, value) => {
        setLoading(true);
        setViewLoading('block');
        setPage(value);
        getLogsForControllers(value);
    };

    useEffect(() => {

        //pegar dia de hoje
        const data = new Date();
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        const dataAtual = ano + '-' + mes + '-' + dia + 'T00:00';

        // pegar o dia de amanhã
        const amanha = new Date((new Date()).valueOf() + 1000 * 3600 * 24);
        const diaAmanha = String(amanha.getDate()).padStart(2, '0');
        const mesAmanha = String(amanha.getMonth() + 1).padStart(2, '0');
        const anoAmanha = data.getFullYear();
        const dataAmanha = anoAmanha + '-' + mesAmanha + '-' + diaAmanha + 'T00:00';

        setDataMin(dataAtual);
        setDataMax(dataAmanha);

    }, []);

    useEffect(() => {
        getLogsForControllers(1);
    }, [dataMin]);

    useEffect(() => {
        getLogsForControllers(1);
    }, [paramAgente, paramDescricao, paramNivel, paramAcao]);

    //adiciona um zero na frente dos numeros < 9 para formatar a data
    function adicionaZeroData(numero) {
        if (numero <= 9) {
            return "0" + numero;
        }
        else {
            return numero;
        }
    }
    //faz a formatacao do timestamp recebido
    const converterDataHora = (data) => {

        if (data == undefined) {
            return
        } else {
            const dataHora = new Date(data);
            const dia = adicionaZeroData(dataHora.getDate())
            const mes = adicionaZeroData(dataHora.getMonth() + 1);
            const ano = dataHora.getFullYear();
            const hora = adicionaZeroData(dataHora.getHours());
            const minuto = adicionaZeroData(dataHora.getMinutes());
            const segundos = adicionaZeroData(dataHora.getSeconds());
            const dataHoraFormatada = dia + "/" + mes + "/" + ano + " " + hora + ":" + minuto + ":" + segundos;
            return dataHoraFormatada;
        }
    }

    useEffect(() => {
        setDados([]);
    }, [props.open]);

    return (
        <Dialog
            open={props.open}
            className="Dialog-logs"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
            maxWidth='xl'
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Relatórios do Equipamento"
                onClick={props.onClose}
                setStateLog={setLogs}
            ></PageTitle>
            <Container>
                <Close onClick={() => { props.onClose(); setDados([]) }}><GrClose size={14} /></Close>
                <MenuHeader visible={alertView}>
                    <AreaInputs>
                        <div className="areaButtons">
                            <div className="inputsData">
                                <input type="datetime-local" value={dataMin} onChange={(e) => setDataMin(e.target.value)} />
                                <input type="datetime-local" value={dataMax} onChange={(e) => setDataMax(e.target.value)} />
                            </div>
                            <div className="buttonPages">
                                <label htmlFor="">
                                    <input type="number" value={maximoItensPagina} onChange={(e) => setMaximoItensPagina(e.target.value)} />
                                </label>
                                <button onClick={() => getLogsForControllers(1)} className="btnCarregar">Pesquisar</button>
                            </div>
                        </div>
                        <AreaButtons>
                            <Button
                                className="dialog-button"
                                variant="outlined"
                                color="primary"
                                onClick={exportLogsExcel}
                                title="Exportar para Excel"
                            >
                                <IconRelatorio />
                            </Button>
                            <Button
                                className="dialog-button"
                                variant="outlined"
                                color="primary"
                                onClick={handleOpenAlert}
                                title="Excluir todos os registros de alarme"
                            >
                                <IconLixeira />
                            </Button>
                        </AreaButtons>
                    </AreaInputs>
                    <div className="areaMensagem">
                        <div className="alert">
                            <Alert severity={typeAlert}>{alertMessage}</Alert>
                        </div>
                    </div>
                </MenuHeader>
                <AreaTable>
                    <Table>
                        <thead>
                            <tr>
                                <th>Identificação</th>
                                <th>Tipo</th>
                                <th>Agente
                                    <Filter onClick={() => openFilterAgente()} status={statusFilterAgente}>
                                        <FiFilter size={18} />
                                    </Filter>
                                    <FiltroAgente open={filtroAgente} onClose={setFiltroAgente} setParamAgente={setParamAgente} agente={agente} toogle={openFilterAgente} />
                                </th>
                                <th>Nível
                                    <Filter onClick={() => openFilterNivel()} status={statusFilterNivel}>
                                        <FiFilter size={18} />
                                    </Filter>
                                    <FiltroNivel open={filtroNivel} onClose={setFiltroNivel} setParamNivel={setParamNivel} nivel={nivel} toogle={openFilterNivel} />
                                </th>
                                <th>Ação
                                    <Filter onClick={() => openFilterAcao()} status={statusFilterAcao}>
                                        <FiFilter size={18} />
                                    </Filter>
                                    <FiltroAcao open={filtroAcao} onClose={setFiltroAcao} setParamAcao={setParamAcao} acao={acao} toogle={openFilterAcao} />
                                </th>
                                <th>Descrição
                                    <Filter onClick={() => openFilterDescricao()} status={statusFilterDescricao}>
                                        <FiFilter size={18} />
                                    </Filter>
                                    <FiltroDescricao open={filtroDescricao} onClose={setFiltroDescricao} setParamDescricao={setParamDescricao} descricoes={descricoes} toogle={openFilterDescricao} />
                                </th>
                                <th>Data</th>
                                {/*<th>Excluir</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {dados.map(items => (
                                <tr>
                                    <td>{items.identificacao}</td>
                                    <td>{items.tipo}</td>
                                    <td>{items.agente}</td>
                                    <td>{items.nivel}</td>
                                    <td>{items.acao}</td>
                                    <td>{items.descricao}</td>
                                    <td>{converterDataHora(items.data)}</td>
                                    { /*<td><div className={estadoLixeira}><Lixeira /></div></td>*/}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </AreaTable>
                <AreaPagination>
                    <Pagination count={maxPage} color="primary" page={page} onChange={handleChange} />
                </AreaPagination>
                <AreaLoading status={viewLoading}>
                    <img src={loader} alt="" />
                </AreaLoading>
            </Container>
            <AlertDialog open={alert} onClick={deleteLogs} onClose={handleCloseAlert} >
                Confirme para excluir os logs
            </AlertDialog>
            <Alertid open={alertId} onClick={deleteLog} onClose={closeAlertId} >
                Confirme para excluir o log: {numeroId}
            </Alertid>
        </Dialog>
    );
}

export default memo(StickyHeadTable);
