import styled from 'styled-components';

export const Container = styled.section`
`;

export const AreaCharts = styled.div`
    text-align: center;

    @media(max-width:1366px){
        .bizcharts{
            height: 150px !important;
            canvas{
                width: 100% !important;
                height: 160px !important;
                margin-top: -14px;
            }
        }
    }

    @media(max-width:1280px){
        .bizcharts{
            canvas{
                width: 80% !important;
                height: 115px !important;
            }
        }
    }

    @media(max-width: 420px){
        margin-top: -40px;

        .bizcharts{
            display: flex;
            align-items: center;
            justify-content: center;
            canvas {
                width: 100% !important;
                height: 160px !important;
                margin-top: 0px;
            }
        }
    }
`;

export const AreaTable = styled.div`
    width: 90%;
    margin: 0 auto;
    @media(max-width: 1366px){
        margin: -12px auto;
    }
    @media(max-width: 1290px){
        margin: 0 auto;
        overflow: scroll;
        margin-bottom: 140px;
    }
    @media(max-width: 414px){
        margin: 0 auto;
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 15px;

    svg path {
        stroke: #001E62;
    }

    @media(max-width: 420px){
        top: 80px;
        right: 20px;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const Table = styled.table`
    font-family: 'roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;

    @media(max-width: 1290px){
        margin-bottom: 50px !important;
        width: 98%;
        margin: auto;

    }

    td{
        border: 1px solid #9e9e9e;
        padding: 5px;
        font-size: 13px;
        font-weight: 500;
        text-align: center;
        @media(max-width: 1290px){
            font-size: 12px;
        }
        @media(max-width: 1366px){
            padding: 0;
            height: 28px;
        }
        @media(max-width: 414px){ 
            font-size: 13px
        }
        img {
            width: 20px;
            height: auto;

            @media(max-width: 1290px){
                width: 15px;
            }
        }

        &#total{
            height: 28px;
            font-weight: 600;
            font-family: sans-serif;

            @media(max-width: 1290px){
                height: 22px;
            }
        }

        &#filter{
            position: relative;
        }
    }
    th {
        text-align: left;
        background-color: #001E62;
        color: white;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        padding-top: 10px;
        padding-bottom: 10px;
        @media(max-width: 414px){font-size: 13px};

        &:nth-of-type(1){
            border-radius: 5px 0 0 0;
        }
        &:nth-of-type(5){
            border-radius: 0px 5px 0 0;
        }

        @media(max-width: 1290px){
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 12px;
        }
    }
`;