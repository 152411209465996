import React, { useEffect } from 'react';

import './styles.css';

function ButtonNav(props){ // Componente Button na navbar lateral esquerdo

  const handleOnclick = (event) =>{
    props.onClick(event);
  }

  useEffect(() => {
    if(props.showText){
      document.getElementById(`textMenu${props.children}`).animate([
        {opacity:'0'},
        {opacity:'100'}
      ], {
        duration: 1000,
        iterations: 1
      });
      document.getElementById(`textMenu${props.children}`).style.opacity = '100';
    }else{
      document.getElementById(`textMenu${props.children}`).animate([
        {opacity:'100'},
        {opacity:'0'}
      ], {
        duration: 500,
        iterations: 1
      });
      document.getElementById(`textMenu${props.children}`).style.opacity = '0';
    }
  }, [props.showText]);

  return (
    <button id={props.id} className="content-button-nav" title={props.title} onClick={handleOnclick}>
      <div><props.icon className="img-icon" /></div>
      <h3 id={`textMenu${props.children}`} className="name-button">{props.children}</h3>
    </button>
  );
}

export default ButtonNav