import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageTitle from '../PageTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';

import api from "../../services/api";
import AlertDiaLog from "../AlertDialog";

import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function DeleteUser(props) {

    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [alert, setAlert] = React.useState(false);

    useEffect(() => {
        setEmail('');
        setEmailConfirm('');
    }, [props.open])


    function deleteUser() {
        if (email === emailConfirm) {
            api.post('/centraldefalhas/remove_user', {
                "email": email,
            }).then(res => {
                let erroUser = res.data.error;
                if (erroUser === "User not found" || erroUser === "Failed to delete User") {
                    toast.info(`Não a usuário com esse email cadastrado, verifique as informçoes!`, {
                        position: "bottom-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setAlert(false);
                } else {
                    toast.success(`Usuário deletado!`, {
                        position: "bottom-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    setAlert(false);
                    setEmail('');
                    setEmailConfirm('')
                    // window.location.href = "/mapa";
                }
            }).catch(err => {
                toast.error('Erro 1001');
            });
        } else {
            toast.info(`O email precisa ser o mesmo nos dois campos!`, {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleOpenAlert = (event) => {//abrir a caixa de confirmação para deletar um usuario
        event.preventDefault();
        if (email !== emailConfirm) {
            toast.info(`O email precisa ser o mesmo nos dois campos!`, {
                position: "bottom-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            return setAlert(true);//torna visivel o alerta de confirmação
        }
    }

    const handleCloseAlert = () => {
        setAlert(false);
    }

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Exluir usuário"
                onClick={props.onClose}
            > </PageTitle>
            <form onSubmit={handleOpenAlert}>
                <DialogContent className='content-dialog' >
                    <TextField
                        required
                        id="email"
                        name="email"
                        type="email"
                        label="Email do usuario:"
                        margin="normal"
                        variant="outlined"
                        value={email}
                        onChange={e => { setEmail(e.target.value) }}
                    ></TextField>
                    <TextField
                        required
                        id="emailConfirm"
                        name="emailConfirm"
                        type="email"
                        label="Confirma o email:"
                        variant="outlined"
                        margin="normal"
                        value={emailConfirm}
                        onChange={e => { setEmailConfirm(e.target.value) }}
                    ></TextField>
                </DialogContent>

                <DialogActions>
                    <Button
                        className="dialog-button"
                        variant="outlined"
                        type="submit"
                        color="primary"
                        title="Excluir usuário"
                    >
                        <DeleteIcon />
                    </Button>
                </DialogActions>
                <AlertDiaLog open={alert} onClick={deleteUser} onClose={handleCloseAlert}>
                    CONFIRME PARA EXCLUIR CONTA COM EMAIL: {email}
                </AlertDiaLog>
            </form>
        </Dialog>
    );
}

export default DeleteUser;