import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import Alert from '@material-ui/lab/Alert';
import 'react-toastify/dist/ReactToastify.css';
import './styles.css';
import PageTitle from '../../PageTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Container, Close, ContentData, GroupInput, AreaButtons, Button, ButtonRemove, ButtonSave, AreaInputs } from './styles';
import IconAddInputs from './icons/IconAddInputs';
import IconRemove from './icons/IconRemove';
import { GrClose } from "react-icons/gr";
import { Buttons } from '../../GetAreas/Table/styles';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function RegisterRoutes(props) {

    const [nameRoute, setNameRoute] = useState(props.value);
    const [dataControlers, setDataControlers] = useState([]);
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');
    const [inputFields, setInputFields] = useState([
        { rota: nameRoute, id_cruzamento: '', anel: '', grupo: '' }
    ]);

    const handleAddFields = () => {
        const values = [...inputFields];
        values.push({ rota: nameRoute, id_cruzamento: '', anel: '', grupo: '' });
        setInputFields(values);
    };

    const handleRemoveFields = index => {
        const values = [...inputFields];
        values.splice(index, 1)
        setInputFields(values);
    };

    const handleInputChange = (index, event) => {
        const values = [...inputFields];
        if (event.target.name === "rota") {
            values[index].rota = event.target.value;
        }
        if (event.target.name === "id_cruzamento") {
            values[index].id_cruzamento = event.target.value;
        }
        if (event.target.name === "anel") {
            values[index].anel = event.target.value;
        }
        if (event.target.name === "grupo") {
            values[index].grupo = event.target.value;
        }
        values[index].rota = nameRoute

        setInputFields(values);

    };

    /*
      'error'
    | 'info'
    | 'success'
    | 'warning'
    */

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!inputFields[0].rota) {

            setMsg('Nome da rota está vazio !');
            setAlertMsg('block');
            setTypeAlert('warning');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            },3000);

        } else {
            setMsg('Aguarde!');
            setAlertMsg('block');
            setTypeAlert('info');

            setTimeout(() => {
                setMsg('');
                setAlertMsg('none');
            },2000);

            await api.post('centraldefalhas/routes/register', inputFields).then(res => {
                setMsg('Rota salva com sucesso!');
                setAlertMsg('block');
                setTypeAlert('success');

                setTimeout(() => {
                    setMsg('');
                    setAlertMsg('none');
                },2000);
                //props.onClose(); // fecha modal ao salvar rota
            }).catch(error => {
                setMsg('Erro ao salvar rota!');
                setAlertMsg('block');
                setTypeAlert('error');

                setTimeout(() => {
                    setMsg('');
                    setAlertMsg('none');
                },2000);
            })
        }
        setNameRoute("")
    };

    const handleGetDataControler = async () => {
        await api.get('/centraldefalhas/controladores').then(res => {
            setDataControlers(res.data);
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        handleGetDataControler();
       // setNameRoute(props.value);
        setInputFields([
            { rota: nameRoute, id_cruzamento: '', anel: '', grupo: '' }
        ]);
    }, [props.open, nameRoute]);

    return (
        <Dialog
            open={props.open}
            className="Dialog"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Cadastro de rota"
                onClick={props.onClose}
            ></PageTitle>
            <Container>
                <Close onClick={() => props.onClose()}><GrClose size={14}/></Close>
                <fieldset>
                    <legend>Nome da Rota</legend>
                    <input type="text" name="rota" id="rota" placeholder="Digite o nome da rota..." value={nameRoute} onChange={e => setNameRoute(e.target.value)} />
                </fieldset>
                <ContentData>
                    <fieldset>
                        <legend>Controlador</legend>
                        {inputFields.map((inputField, index) => {
                            return (
                                <>
                                    <GroupInput key={`${inputField}~${index}`}>
                                        <select name="id_cruzamento"
                                            value={inputField.id_cruzamento} onChange={event => handleInputChange(index, event)}
                                        >
                                            <option value="">Selecione um Id...</option>
                                            {dataControlers.map((items) => {
                                                return (
                                                    <option key={items.id_cruzamento} value={items.id_cruzamento}>
                                                        {items.id_cruzamento}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <AreaInputs>
                                            <select name="anel" id="anel"
                                                value={inputField.anel} onChange={event => handleInputChange(index, event)}
                                            >
                                                <option value="">Anel...</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                            </select>
                                            <input type="text" name="grupo" id="grupo" value={inputField.grupo} onChange={event => handleInputChange(index, event)} placeholder="Grupo" />
                                        </AreaInputs>
                                        <ButtonRemove onClick={() => handleRemoveFields(index)}>
                                            <IconRemove />
                                        </ButtonRemove>
                                    </GroupInput>
                                </>
                            );
                        })}
                    </fieldset>
                </ContentData>
                <AreaButtons message={alertMsg}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{msg}</Alert>
                    </div>
                    <Buttons>
                        <Button onClick={() => handleAddFields()}>
                            <IconAddInputs />
                        </Button>
                        <ButtonSave onClick={handleSubmit}>Salvar</ButtonSave>
                    </Buttons>
                </AreaButtons>
            </Container>
        </Dialog>
    );
}

