import React, { useState } from "react";
import { Chart } from "react-google-charts";

function ChartArea(props){
  const [ passando, setPassando] = useState(23);


  return (
    <>
      <Chart
        width={'100%' }
        height={'50%'}
        
        chartType="AreaChart"
        
        loader={<div>Loading Chart</div>}
        data={props.data}
        options={{
          title: `${props.title}`,
          backgroundColor:'#fafaf4',
         
          isStacked: true,
          selectionMode: 'multiple',
          hAxis: { title: 'Momento/min', titleTextStyle: { color: "#666", } },
          focusTarget: 'category',
          height: 240,
          legend: { position: 'right' },
          vAxis: {
            title: 'Tempo/sec', 
            titleTextStyle: { color: "#666", },
            minValue: 0,
            ticks: [0, 20, 40, 60, 80, 100, 120, 140, 160],
          },
        }}
        rootProps={{ 'data-testid': '3' }}
      />
    </>
  )
}

export default ChartArea;
