import styled from 'styled-components';

export const Container = styled.figure`
    height: 380px;
    background: #FFF;
    width: 95.5%;
    height: auto;
    margin: 20px auto;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 20%);
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    position: relative;

    .chart{
        position: relative;
        height: 300px !important;
        width: 95.5% !important;
        padding: 27px;

        @media(max-width: 420px){
            margin-left: -6%;
        }
    }
`;

export const Title = styled.span`
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-family: 'roboto',sans-serif;
    height: 30px;
    align-items: flex-start;
    justify-content: center;
`;

export const AreaLoading = styled.img`
    width: 20%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: ${props => props.loading == false ? 'none' : 'block'};    
`;