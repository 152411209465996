import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../contextModal';
import { validarPermissao } from '../../services/permission';
import { useParams } from 'react-router-dom';
import MenuBlank from '../../components/MenuBlank';
import 'react-calendar/dist/Calendar.css';
import { Container, Tabs, AreaCamera, Screen, Options, AreaButtons, Button, BtnPhoto, AreaUnderlined, Underlined } from './styles';
import Photos from './Photos';
import IconEdit from './icons/IconEdit';
import IconLog from './icons/IconLog';
import IconCamera from './icons/IconCamera';
import Calendar from 'react-calendar';
import ModalFotoManual from './modal';
import api from '../../services/api';
import RegisterControllers from '../../components/Registers/RegisterControllers';
import StickyHeadTable from '../../components/StickyHeadTable';

const permissao = (validarPermissao([0,1,2]) ? 'enabled' : 'disabled');

export default function Cameras(props){

    const [positionAba, setPositionAba] = useState('41');
    const [abaTempo, setAbaTempo] = useState('active');
    const [abaManuais, setAbaManuais] = useState('inactive');
    const [abaFalha, setAbaFalhas] = useState('inactive');
    const [request, setRequest] = useState('0');
    const [recarregar, setRecarregar] = useState(false);
    const [valueDate, onChangeDate] = useState(new Date());
    const [openModalStatus, setOpenModalStatus] = useState(false);
    const [dados, setDados] = useState([]);
    const [cameraDescricao, setCameraDescricao] = useState('');
    const [openModalEquipamento, setOpenModalEquipamento] = useState(false);
    const [idCruzamento, setIdCruzamento] = useState('');
    const [openLogs, setOpenLogs] = useState(false);
    const { setAbaEquipamento } = useContext(ModalContext);
    const { setIdGlobal } = useContext(ModalContext); 
    const { setTypeRequest } = useContext(ModalContext); 

    const { refAnel } = useParams();
    
    const Position1 = () => {
        setPositionAba('41');
        setAbaTempo('active');
        setAbaManuais('inactive');
        setAbaFalhas('inactive');
        setRequest('0');
    }

    const Position2 = () => {
        setPositionAba('190');
        setAbaManuais('active');
        setAbaTempo('inactive');
        setAbaFalhas('inactive');
        setRequest('2');
    }

    const Position3 = () => {
        setPositionAba('340');
        setAbaFalhas('active');
        setAbaTempo('inactive');
        setAbaManuais('inactive');
        setRequest('1');
    }

    const closeModalStatus = () => {
        setOpenModalStatus(false);
    }

    const recarregarFotos = (estado) => {
        setRecarregar(estado);
    }

    const lerDados = async () => {
        const response = await api.get(`/centraldefalhas/camera/${refAnel}`);
        setDados(response.data);
    }

    useEffect(() => {
        lerDados();
        const idParams = props.match.params.refAnel;
        setIdCruzamento(idParams.split('_anel')[0]);
       
    },[]);

    const listarFotosPorDescricao = (camera) => {
        setCameraDescricao(camera);
    }
    
    const clickCloseModalEquipamento = () => {
        setOpenModalEquipamento(false);
    }

    return(
        <Container>
        <MenuBlank />
        {/*<h1>Câmeras:</h1>*/}  
            <Tabs position={positionAba}>
                <h1>Câmeras:</h1>
                <fieldset>
                    <legend>Câmeras</legend>
                    <select name="" id="" onChange={(e) => listarFotosPorDescricao(e.target.value)}>
                        <option value="">Listar todas</option>
                        {dados.map( (item,index) => (
                            <option key={index} value={item.descricao}>{item.descricao}</option>
                        ))}
                    </select>
                </fieldset>
                <ul>
                    <li onClick={Position1} id={abaTempo}>Fotos por tempo</li>
                    <li onClick={Position2} id={abaManuais}>Fotos manuais</li>
                    <li onClick={Position3} id={abaFalha}>Fotos por falha</li>
                </ul>
                {/*<AreaUnderlined position={positionAba}><Underlined></Underlined></AreaUnderlined>{/*undeline Somente para mobile*/}
            </Tabs>
            <AreaCamera>
                <Screen>
                    <fieldset>
                    <legend>ID do local</legend>
                        <Photos req={request} data={valueDate} recarregar={recarregar} cbRecarregar={recarregarFotos} cameraDescricao={cameraDescricao}/>
                    </fieldset>
                </Screen>
                <Options>
                    <Calendar 
                        onChange={onChangeDate}
                        value={valueDate}
                        className="calendario"
                    />
                    <AreaButtons>
                        <Button onClick={() => { setOpenLogs(true); setTypeRequest('Câmera') } }>
                            <IconLog />
                        </Button>
                        <Button onClick={() => { setOpenModalEquipamento(true); setAbaEquipamento(3); setIdGlobal(idCruzamento) } }>
                            <IconEdit />
                        </Button>
                        <BtnPhoto onClick={() => { setOpenModalStatus(true); Position2() } } className={permissao}>
                            <IconCamera />
                            Solicitar Foto
                        </BtnPhoto>
                        <BtnPhoto onClick={() => recarregarFotos(true)}> 
                            Recarregar
                        </BtnPhoto>
                    </AreaButtons>
                </Options>
            </AreaCamera>
            <ModalFotoManual
                open={openModalStatus}
                onClose={closeModalStatus}
                dados={dados}
            >   
            </ModalFotoManual> 
            <RegisterControllers
                open={openModalEquipamento}
                onClose={clickCloseModalEquipamento}
            >
            </RegisterControllers>
            <StickyHeadTable 
                id={idCruzamento}
                open={openLogs} 
                onClose={() => setOpenLogs(false)}
            >
            </StickyHeadTable>
        </Container>
    );
}