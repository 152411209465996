import styled from "styled-components";
import img from '../../assets/wallPapperLogin.png';


export const Container = styled.section`
  display: flex;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
`;

export const ImageBackground = styled.div`
  background-image: url(${img}); 
  background-size: cover;
  background-repeat: no-repeat;
  width: 70%;
  height: 100vh;

  @media(max-width: 414px){
    display: none;
  }
 /* @media(max-width: 768px){
    display: none;
  }*/
  
  &::before {
    content: '1';
    position: absolute;
    width: 70%;
    height: 100vh;
    left: 0;
    bottom: 0;
    background: #000000;
        opacity: 0.4;
}
`

export const AreaLogin = styled.form`
  width: 30%;

  @media(max-width: 414px){
    width: 100%;
    overflow: hidden;
  }
  /*@media(max-width:768px){
    width: 100%;
    overflow: hidden;
  }*/

  .logo{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 125px;
    @media(max-width: 1500px){
      margin-top: 20px;
    }
    @media(max-width: 1366px){
      margin-top: 60px;
    }
    @media(max-width: 1280px){
      margin-top: 20px;
    }

    img {
      width: 215px;
      height: auto;
    }
  }
  
  span{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 49px;
  }
`;

export const Form = styled.form`
  margin-top: 80px;

  @media(max-width: 1500px){
    margin-top: 52px;
  }
  @media(max-width: 1280px){
    margin-top: 52px;
  }
  
  .group{
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: 20px auto;

    @media(max-width: 414px){width:90%;}
    //@media(max-width: 768px){width:400px;}

    .error{
      color: red;
    }

    .access{
      color: #808080;
      @media(max-width: 414px){ width: 97%;}
    }

    span{
      font-size: 11px;
    }
  }

    label{
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 13px;
        width: 385px; 
    }
`;

export const InputView = styled.div`
    border: 1px solid red;
    width:  356px; 
    border-radius: 4px;
    border: 1px solid #908e8e;
    display: flex;
    align-items: center;
    @media(max-width: 414px){width: 98%}

    &:focus-within {
        /* Muda o background color da div pai*/
        border: 2px solid ${props => props.outLine};
    }

    input{
        height: 35px;
        outline: none;
        font-size: 14px;
        width: 330px;
        text-indent: 15px;
        border: 0;
        border-radius: 4px;
        
        &:-webkit-autofill {
            box-shadow: 0 0 0 30px white inset;
        }  
    }

`;

export const IconView = styled.div`
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active{
        position: relative;
        top:.5px;
    }

    svg{
        path{
            fill: green;
        }
    }
`;

export const Input = styled.input`
    height: 35px;
    outline: none;
    font-size: 14px;
    width: 350px;
    text-indent: 15px;
    border-radius: 4px;
    border: 0;
    border: 1px solid #908e8e; 

    &:focus {
      border: 2px solid ${props => props.outLine};
    }
    &:-webkit-autofill {
      box-shadow: 0 0 0 30px white inset;
    }
  
`;

export const AreaButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    span{
        margin-bottom: 30px;
        margin-top: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px;
        letter-spacing: 0em;
        text-decoration: none;
        position: relative;
        cursor: pointer;
        user-select: none;

        &:hover{
        font-size: 12.5px;
        font-weight: 600;
        transition: .5s;
        }

        &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        top: 13px;
        background-color: #040404;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        }

        &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        }
    }

    @media(max-width: 414px){
        width: 90%;
        margin: 0 auto;
    }

   /* @media(max-width: 768px){
        span{
            font-size: 16px;
        }
    }*/

  `;

export const Button = styled.button`
    width: 360px;
    height: 40px;
    background: #001E62;
    border-radius: 29px;
    color: #FFF;
    border: 0;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;

    &:hover {
        background: #616da3;
        transition: .5s ease-in-out;
    }

    &:active{
        position: relative;
        top: 2px;
    }

    @media(max-width: 414px){
        width:90%;
    }

    /*@media(max-width: 768px){
        font-size: 16px;
        height: 50px;
    }*/

`;

export const LinkLogin = styled.a`
    display: ${props => props.title || 'flex'};
    font-size: 12;
    font-weight: 400;
    color: red;
    text-decoration: none;
    font-family: var(--var-font-family);
    cursor: pointer;
`;