import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import close from '../../../img/close.png';
import icoOcupacao from '../../../img/icoOcupacao.png';
import settings from '../../../img/tool.png';
import { Container, AreaOcupacao, TaxaOcupacao, Options, ButtonSettings } from './styles';

export default function Ocupacao(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'box', title: 'Taxa de ocupação', image: icoOcupacao, object: props.objOcupacao, setObject: props.setObjOcupacao });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.objOcupacao.map((item, index) => {
                if (props.objOcupacao[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='objOcupacao'
                            top={item.top}
                            left={item.left}
                            setObjOcupacao={props.setObjOcupacao}
                            objOcupacao={props.objOcupacao}
                            classeObjeto={item.classeObjeto}
                        >
                            <AreaOcupacao taxa={item.ocupacao} tamanho={item.tamanho} angulo={item.angulo}>
                                <TaxaOcupacao>{item.ocupacao}%</TaxaOcupacao>
                            </AreaOcupacao>
                            <Options remove={props.remove} onClick={() => props.removeOcupacao(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
