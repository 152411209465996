import styled from 'styled-components';

export const Container = styled.div`
    width: 98%;
    margin: 0 auto;
    padding: 1px;
    overflow-x: scroll;
    height: 65vh;
    margin-bottom: 15px;
    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;
		&-track-piece {
			background-color: #FFF;
		}
		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}
		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}
    @media(max-width: 420px){
        overflow-y: hidden;
        overflow-x: scroll;
    }

    @media( max-width: 1280px){
        height: 54vh;
        overflow: scroll;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    font-family: 'roboto';
    font-size: 13px;
    .disabled{ // classe para desabilitar permissões
        pointer-events: none;
        opacity: 0.4;
    }
    thead {
        height: 30px;
        tr{
            &:nth-child(even){background-color: #f2f2f2;}
            &:hover {background-color: #ddd;}
        }
        th {
            text-align: center;
            background-color: #001E62;
            color: white;
            position: relative;
            border-right: 1px solid #FFF;
            position: sticky; 
            top: 0; 
            &:first-child{border-radius: 5px 0 0 0; min-width: 120px;}
            &:last-of-type{border-radius: 0 5px 0 0; border: none}
            &:nth-child(6){min-width: 60px; @media(max-width: 1280px){min-width: 90px;}}
            &:nth-child(7){min-width: 60px;}

        }
    }
    tbody{
        td{
            width: 1px;
            white-space: nowrap;
        }
        td, th {
            border: 1px solid #ddd; 
            padding: 8px;
            text-align: center;
        }
        tr{
            td:nth-child(3){
                min-width: 130px !important;
            }
            td:nth-child(10){
                text-align: center;
                svg{
                    cursor: pointer;
                }
            }
        }
    }
    @media(max-width: 1366px){
        
        tbody{
            tr{
                td:nth-child(3){
                    width: 120px;
                }
            }
        }
    }
    @media(max-width: 1160px){
        width: 1450px;
    }
    @media(max-width: 420px){
        tbody{
            tr{
                td:nth-child(1){
                    min-width: 110px;
                    max-width: 175px;
                }
            }
        }
    }
`;

export const Filter = styled.div`
    position: absolute;
    cursor: pointer;
    top: 5px;
    right: 10px;
    
    
    svg{
        fill: ${props => props.status === 'Filtro limpo' ? 'transparent' : props.status === 'Dados Filtrados' ? '#0c9410' : ''};
        stroke:${props => props.status === 'Filtro limpo' ? '#FFF' : props.status === 'Dados Filtrados' ? '#0c9410' : ''};
        &:hover{
            fill: #f2f2f2;
        }
    }
`;

export const AreaLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    display: ${props => props.status} !important;
   
    img{
        width: 20%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
`;