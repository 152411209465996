import axios from 'axios';

const tokenUser = localStorage.getItem('@central-de-falhas-greenwave/tokenAuth');

const apiMensageria = axios.create({
    baseURL: process.env.REACT_APP_MENSAGERIA + "/",
    headers: {
        Authorization: `Bearer ${tokenUser}`, //passar o token armazenado do usuario 
    },

});

export default apiMensageria;