import { useState } from "react";

const useForm = () => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        townhall: '',
        permission: '',
        filter: '',
        firstChipOperator: '',
        secondChipOperator: '',
        ipController: '',
        latitude: '',
        longitude: '',
        zoom: '',
        confTime: '',
        nameRoute:'',
    });

    const [loading, setLoading] = useState(false);

    const handleChange  = (event) => {
        
        const auxValues = { ...values };
        auxValues[ event.target.name ] = event.target.value;
        setValues(auxValues);
    };

    const handleSubmit = callback => event => {
        event.preventDefault();
        setLoading(true);
        callback();
        setLoading(false);
    };

    return [{ values, loading }, handleChange, handleSubmit];

}

export default useForm;