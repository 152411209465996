import React,{ useContext } from 'react';
import { Container, Overlay, Modal, Title } from './styles';
import { GiSandsOfTime } from "react-icons/gi";
import { ModalContext } from '../../../contextModal';

export default function ModalInactivity(){

    const { inactiveTime, setInactiveTime } = useContext(ModalContext);

    const closeModal = () => {
        setInactiveTime('none');
    }

    return(
        <Container open={inactiveTime} onClick={closeModal}>
            <Overlay>
                <Modal>
                    <Title>Desconexão por tempo de inatividade!<GiSandsOfTime size={30} color='#001E62'/></Title>
                    <button>Entrar novamente</button>
                </Modal>
            </Overlay>
        </Container>
    );
}