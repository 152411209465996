import React from 'react';
import { Container, Title, AreaLoading } from './styles';
import loader from '../../../assets/img/loader.gif';
import { Chart, Area, Line, Tooltip} from 'bizcharts';

export default function ChartArea(props){
   
    //propriedades dos eixos do grafico
    const scale = {

        timestamp: { 
        tickCount: 6,                 //quantidade de itens
        mask: 'DD-MM-YYYY HH:mm:ss',  //mascara dos dados
        },

        ocupacao:{
        type: 'linear',               //tipo do dado        
        min: 0,
        tickCount: 6,                 //quantidade de itens
        }

    };    

    return(
        <Container>
            <AreaLoading src={loader} loading={props.cbLoading}/>
            <Title>Taxa de ocupação (%) x Período de tempo</Title>
            <Chart scale={scale} data={props.dadosGraficos} autoFit className="chart">
            <Tooltip shared />
            <Area adjust="stack" color="id_detector" position="timestamp*ocupacao" 
            // popup de legenda
            tooltip={['ocupacao*id_detector', (value, name) => {
                return {
                value: `${value} %`,
                name: `Detector ${name}`
                }
            }]}/>
            <Line adjust="stack" color="id_detector" position="timestamp*ocupacao" tooltip={false} />
            </Chart>
        </Container>
    );
}