import styled from 'styled-components';

export const AreaButton = styled.div`
    width: 50%;
    display:flex;
    justify-content: flex-end;
    margin-top:10px;
    margin-bottom: 10px;
    
`;

export const Button = styled.div`
    border: 2px solid #001E62;
    width: 100%;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    cursor: pointer;
    user-select: none;
    background: #FFF;
    margin-bottom: 10px;

    &.openCamera{
        width: 205px;
    }

    @media(max-width: 420px){
        width: 60%;
        margin: 0 auto;
    }

    &:hover{
        background: #001E62;
        transition: .5s;
        
        span{
            color: #FFF;
            transition: .5s;
        }

        svg{
            fill: #FFF;
            stroke: #FFF;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg{
        margin-right: 3px;
    }

    span{
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        color: #001E62;
    }
`;

export const Container = styled.div`
    width: 92%;
    margin: 0 auto;
    

    @media(max-width: 580px){
        width: 97%;
        margin: 0 auto;
    }

    .alert{
        width: 92%;
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        display: ${props => props.visible};
    }
    
    fieldset{
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        margin-bottom: 4px;
        margin-top: 4px;

        @media(max-width: 1280px){
            margin-bottom: 9px;
        }
        
        &.controlador{
            position: relative;
        }

        .legendAnel{
            position: absolute;
            top: -15px;
            right: 50px;
            background: #FFF;
            z-index: 1;
            padding: 0 5px;
            font-size: 13px;
            font-style: normal;
            font-weight: 550;
            line-height: 12px;
            -webkit-letter-spacing: 0em;
            -moz-letter-spacing: 0em;
            -ms-letter-spacing: 0em;
            letter-spacing: 0em;
            color: #848484;
            font-family: 'roboto',sans-serif;
        }

        @media(max-width: 1500px){
            margin-top: 0;
            margin-bottom: 13px;
        }
    }
    legend{
        font-size: 13px;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        color: #848484;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 12px;
        }
    }

    label{
        font-size: 13px;
        color: #6b6a6a;
        font-style: normal;
        font-weight: 550;
        line-height: 12px;
        letter-spacing: 0em;
        font-family: 'roboto', sans-serif;

        @media(max-width: 380px){
            font-size: 11px;
        }
    }
`;

export const TopContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

export const AreaInputs = styled.div`
    margin-top: 5px;
    
    input{
        width: 100%;
        height: 29px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
        margin-bottom: 10px;
        @media(max-width: 1500px){
            margin-bottom: 2px;
            height: 26px;
        }
        @media(max-width: 1280px){
            margin-bottom: 2px;
            height: 22px;
            font-size: 12px;
        }
    }
    legend{
        padding-bottom: 3px;
    }

    .groupone {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 15px;
        align-items: center;

        .group{
            width: 48%;

            &.button{
                height: 31px;
                margin-top: 16px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3px;
    right: 19px;

    @media(max-width: 1280px){
        top: 18px;
        right: 24px;
    }

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible === true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 45px;

    @media(max-width: 420px){
        margin-top: 0px;
    }


    .btnEditInfo{
        cursor: pointer;

        img{
            width: 35px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
    }
   
    button{
        border: 0;
        width: 115px;
        height: 25px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }
`;