import styled from 'styled-components';

export const BoxMenu = styled.div`
    background: #f5f3f3;
    position: absolute;
    //width: 99%;
    width: 330px;
    max-height: 320px; @media(max-width: 1366px){max-height: 280px}
    height: 320px; @media(max-width: 1366px){height: 280px}
    overflow: hidden;
    top: 30px;
    left: 0;
    box-shadow: 6px 6px 2px -2px rgba(204,200,200,0.85);

    &.agente{
        height: ${props => props.display === false ? '0' : '320px'};
        transition: .5s;
    }
    &.operacao{
        height: ${props => props.display === false ? '0' : '320px'};
        transition: .5s;
    }
    &.nivel{
        height: ${props => props.display === false ? '0' : '320px'};
        transition: .5s;
    }
    &.descricao{
        height: ${props => props.display === false ? '0' : '320px'};
        transition: .5s;
    }
`;

export const Title = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5px;

    h2{
        color: #565151;
        font-size: 14px;
        margin-bottom: 2px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        user-select: none;
    }
    span{
        color: #565151;
        font-size: 11px;
        font-weight: normal;
        user-select: none;
    } 
`;

export const CloseFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover{
        border: 1px solid #001e62;
        background: #84a6f58a;
        transition: .3s;
    }
`;

export const AreaInput = styled.div`
    border: 1px solid silver;
    height: 25px;
    display: flex;

    input{
        width: 90%;
        border: 0;
        outline: none;
        text-indent: 7px;
    }
`;

export const Search = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    background: #001e62;
`;

export const BoxFilter = styled.nav`
    border: 1px solid #e4e4e4;
    background: #FFF;
    color: #040404;
    font-family: 'roboto', sans-serif;
    overflow-y: scroll;
    width: 90%;
    height: 63%; @media(max-width: 1366px){height: 57%}
    margin: 0 auto;

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #EEE;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

    ul{
        list-style: none;
        padding: 0;
        margin-left: 10px;

        li{
            height: 28px;
            display: flex;
            align-items: center;
            width: 100%;
            user-select: none;
            
            input[type="checkbox"]{
                height: 17px;
                width: 17px;
            }
        }
    }
`;

export const Button = styled.button`
    border: 0;
    background: #e4e2e2;
    height: 28px;
    width: 90px;
    cursor: pointer;
    font-family: 'Roboto',sans-serif;
   
    &:hover{
        background: #001E62;
        color: #FFF;
        transition: .5s;
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const AreaButtons = styled.div`
    width: 90%;
    margin: 4px auto;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    opacity: ${props => props.status === true ? '1' : '0.4'};
    pointer-events: ${props => props.status === true ? 'auto' : 'none'};
`;