import styled from 'styled-components';

export const ContentTab = styled.section`
    width: 92%;
    margin: 0 auto;
    user-select: none;
    font-family: 'roboto';
    position: relative;

    @media(max-width: 420px){
        margin-left: 3%;
        width: 94%;
    }

    fieldset{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: auto;
        border: 1px solid #a4a4a4;
        border-radius: 2px;
        position: relative;

        @media(max-width: 420px){
            width: 92% !important;
        }

        &:first-child {
            height: 50px;
                @media(max-width: 1500px){
                    height: 42px;
                @media(max-width: 420px){
                    width: 92% !important;
                }
            }
        }
        &:nth-child(2) {
            margin-top: 10px;
        }
        &:nth-child(3) {
            margin-top: 10px;

            @media(max-width: 420px){
                margin-top: 0;
            }
        }

        &.disable{
            pointer-events: none;
            opacity: 0.4;
        }
        &.enable{
            pointer-events: auto;
            opacity: 1;
        }

        select {
            width: 100%;
            height: 28px;
            border-radius: 2px;
            text-indent: 10px;
            border: 1px solid #a4a4a4;
            margin-bottom: 5px; 

            @media(max-width: 1500px){
                height: 30px;
                margin-bottom: 2px;
            }
            @media(max-width: 1280px){
                height: 26px;
                margin-bottom: 2px;
            }

        }

        legend {
            font-size: 12px;
            color: #1d1c1c;
        }

        svg{
            margin-bottom: 7px;
            margin-right: 2%;
            cursor: pointer;
        }

        form{
            width: 100%;
            .row{
                display: flex;
                gap: 15px;

                align-items: center;

                @media(max-width: 420px){
                    flex-direction: row;
                    gap: 5px;
                }

                .group { 
                    width: 72%;
                    display: flex;
                    flex-direction: column;

                    @media(max-width: 420px){
                        width: 49%;
                    }

                    #col-1 {
                        width: 100%;
                        @media(max-width: 420px){
                            width: 100%;
                            height: 27px;
                        }
                    }
                    #col-2{
                        width: 100%;
                        @media(max-width: 420px){
                            width: 100%;
                            height: 27px;
                        }
                    }

                    label{
                        font-size: 13px;
                        color: #1d1c1c;

                        @media(max-width: 420px){
                            font-size: 11px;
                        }
                    }

                    select {
                        width: 359px;
                        height: 28px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 5px;
                        font-size: 13px;

                        @media(max-width: 1500px){
                            height: 27px;
                        }
                    }

                    input{
                        height: 25px;
                        width: 98%;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        margin-bottom: 8px;
                        outline: none;
                        font-size: 13px;

                        @media(max-width: 1280px){
                            height: 24px;
                        }

                        @media(max-width: 420px){
                            width: 98%;
                        }
                    }
                }

                .conf {
                    display: flex;
                    //border: 1px solid red;
                    flex-direction: column;
                    width: 100%;

                    label {
                        font-size: 13px;
                        font-family: 'Roboto', sans-serif;
                        color: #1d1c1c;

                        @media(max-width: 420px){
                            font-size: 11px;
                        }
                    }

                    input {
                        height: 28px;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        font-size: 13px;
                        outline: none;
                    }
                }

                .detectores{
                    width: 65%;

                    @media(max-width: 420px){
                        width: 98%;
                        top: 3px;
                        position: relative;
                    }

                    input {
                        height: 27px;
                        width: 100%;
                        border-radius: 2px;
                        text-indent: 10px;
                        border: 1px solid #a4a4a4;
                        font-size: 13px;
                        outline: none;
                    }
                }

                .groupSelect {
                    width: 70px;

                    @media(max-width: 420px){
                        width: 23%;
                        position: relative;

                        #selectGrupo{
                            height: 30px;
                        }
                    }

                    label {
                        font-size: 13px;
                        font-family: 'Roboto', sans-serif;
                        color: #1d1c1c;

                        &#grupo{
                            @media(max-width: 420px){
                                //display: none;
                            }
                        }

                        &#id_grupo{

                        }

                        &.label_detector{
                            top: -3px;
                            position: relative;

                            @media(max-width: 420px){
                                font-size: 11px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -22px;
    right: 0;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div`
    display: ${props => props.visible == true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-size: 12px;
            font-weight: normal;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const Button = styled.button`
    background: #001E62;
    border: 1px solid #001E62;
    width: 110px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    border: 0;
    color: #FFF;
    font-size: 12px;
    font-weight: normal;
  

    &:hover{
        background: #FFF;
        color: #001E62;
        transition: .3s;
        border: 1px solid #001E62;
    }

    @media(max-width: 1500px){
        height: 28px;
    }

    @media(max-width: 1280px){
        height: 28px;
    }

    &.btnImportar{
        margin-top:11px;
        font-size: 12px;
        font-weight: normal;
    }

    &.btnNovo{
        margin-top: 12px;
        margin-left: 12px;
        width: 110px; 
        height: 30px;
        font-size: 12px;
        font-weight: normal;

        &:hover svg path{
            fill: #001E62;
        }

        @media(max-width: 1280px){
            height: 28px;
        }

        svg{
            width: 18px;
            height: 17px;
            margin-right: 5px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }

    svg {
        width: 65px;
        margin-top: 7px;
    }
`;

export const AreaBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    cursor: pointer;

    @media(max-width: 420px){
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        gap: 10px;
    }
    
    .alert{
        margin-right: 40px;
        width: 250px;
        display: ${props => props.view};

        @media(max-width: 420px){
            margin-right: 0;
            margin-bottom: 2px;
        }
    }
`;

export const BorderBtn = styled.div`
    border: 2px solid #001E62;
    border-radius: 5px;
    width: 43px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media(max-width: 1500px){
        height: 24px;
    }

    &.btnEditar{
        margin-top: 15px;
        cursor: pointer;
        margin-right: 10px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: normal;

        svg{
            width: 18px !important;
            height: 18px !important;
            margin-bottom: 1px !important;
            margin-right: 0px !important;

            @media(max-width: 1500px){
                width: 18px !important;
                height: 18px !important;
            }
        }
    }

    &.disable{
        pointer-events: none;
        opacity: 0.4;
    }

    &.enable{
        pointer-events: auto;
        opacity: 1;
    }


    &#btnRemove{
        width: 43px;
        height: 31px;
        box-sizing: border-box;
        padding-left: 1px;

        svg{
            width: 18px !important;
            height: 18px !important;
            margin-bottom: 1px;
        }

        @media(max-width: 1280px){
            width: 41px;
            height: 28px;
            box-sizing: border-box;
            padding: 1px 0 0 1px;
        }
    }

    &:active{
        position: relative;
        top: 1px;
    }
`;

export const Divider = styled.div`
    hr {
        border: 0;
        border-bottom: 1px solid silver;
    }

    .detector{
        width: 95%;
        height: 30px;
        height: 27px;
        border-radius: 2px;
        text-indent: 10px;
        border: 1px solid #a4a4a4;
        font-size: 13px;
        outline: none;
        margin-top: -10px;
        position: relative;
        top: -1px;

        @media(max-width: 420px){
            height: 26px;
            width: 80%;
            top: 1px;
        }
    }
`;

export const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 15px;
    
    @media(max-width: 420px){
        justify-content: flex-end;
        width: 100%;
        gap: 10px;
    }
`;

export const AreaSwitch = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;

    label{
        margin-left: 5px;
        font-size: 12px;
        font-weight: 600;
        color: #040404;
    }
`;

export const Switch = styled.label`
	position: relative;
	top: 2px;
	display: inline-block;
	vertical-align: top;
	width: 56px;
	height: 20px;
	padding: 3px;
	background-color: white;
	border-radius: 18px;
	box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
	cursor: pointer;
	background-image: -webkit-linear-gradient(top, #eeeeee, white 25px);
	background-image: -moz-linear-gradient(top, #eeeeee, white 25px);
	background-image: -o-linear-gradient(top, #eeeeee, white 25px);
	background-image: linear-gradient(to bottom, #eeeeee, white 25px);

	.switch-input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	.switch-label {
		position: relative;
		display: block;
		height: inherit;
		font-size: 10px;
		text-transform: uppercase;
		background: #eceeef;
		border-radius: inherit;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
		-webkit-transition: 0.15s ease-out;
		-moz-transition: 0.15s ease-out;
		-o-transition: 0.15s ease-out;
		transition: 0.15s ease-out;
	}
	.switch-label:before,
	.switch-label:after {
		position: absolute;
		top: 50%;
		margin-top: -.5em;
		line-height: 1;
		-webkit-transition: inherit;
		-moz-transition: inherit;
		-o-transition: inherit;
		transition: inherit;
	}
	.switch-label:before {
		content: attr(data-off);
		right: 11px;
		color: #aaa;
		text-shadow: 0 1px rgba(255, 255, 255, 0.5);
        font-weight: normal;
	}
	.switch-label:after {
		content: attr(data-on);
		left: 11px;
		color: white;
		text-shadow: 0 1px rgba(0, 0, 0, 0.2);
		opacity: 0;
        font-weight: normal;
	}
	.switch-input:checked ~ .switch-label {
		background: #47a8d8;
		box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
	}
	.switch-input:checked ~ .switch-label:before {
		opacity: 0;
	}
	.switch-input:checked ~ .switch-label:after {
		opacity: 1;
	}
	.switch-handle {
		position: absolute;
		top: 4px;
		left: 4px;
		width: 18px;
		height: 18px;
		background: white;
		border-radius: 10px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
		background-image: -webkit-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -moz-linear-gradient(top, white 40%, #f0f0f0);
		background-image: -o-linear-gradient(top, white 40%, #f0f0f0);
		background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
		-webkit-transition: left 0.15s ease-out;
		-moz-transition: left 0.15s ease-out;
		-o-transition: left 0.15s ease-out;
		transition: left 0.15s ease-out;
	}
	.switch-handle:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -6px 0 0 -6px;
		width: 12px;
		height: 12px;
		background: #f9f9f9;
		border-radius: 6px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
		background-image: -webkit-linear-gradient(top, #eeeeee, white);
		background-image: -moz-linear-gradient(top, #eeeeee, white);
		background-image: -o-linear-gradient(top, #eeeeee, white);
		background-image: linear-gradient(to bottom, #eeeeee, white);
	}
	.switch-input:checked ~ .switch-handle {
		left: 40px;
		box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
	}
	.switch-green > .switch-input:checked ~ .switch-label {
		background: #4fb845;
	}
`;
