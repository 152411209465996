import React, { useState, useEffect, useContext } from 'react';
import { ContentTab, AreaInput, AreaBtn, Button, MultiplyArea, BorderButton, AreaButtonRemove, BtnBorder, InfoHelp, AreaBallon, ContentBallon, Actions } from './styles';
import IconRemove from '../../Icons/IconRemove';
import BtnNovo from '../../Icons/BtnNovo';
import BtnAdd from '../../Icons/BtnAdd';
import api from '../../../../../services/api';
import InputMask from 'react-input-mask';
import Alert from '@material-ui/lab/Alert';
import { validarPermissao } from '../../../../../services/permission';
import { GrCircleQuestion } from 'react-icons/gr';
import { BallonsInfoModal } from '../../../../Balloons';
import icoInfo from '../../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../../assets/icons/icoSom.png';
import { VozInfoEquipamentoCamera } from '../../../../Audio';
import { ModalContext } from '../../../../../contextModal';

const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function Camera(props) { // permissao state para definir permissões do usuário

    const [editId_Cruzamento, setEditIdCruzamento] = useState('');
    const [edit_Anel, setEditAnel] = useState('');
    const [unalterable, setUnalterable] = useState('disable');
    const [consorcio, setConsorcio] = useState([]);
    const [instaladora, setInstaladora] = useState([]);
    const [mantenedora, setMantenedora] = useState([]);
    const [modelo, setModelo] = useState([]);
    const [idLocal, setIdLocal] = useState([]);
    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const [local, setLocal] = useState('');
    //const [porta, setPorta] = useState(80);
    const { anelGlobal, setAnelGlobal } = useContext(ModalContext);
    const { idGlobal, setIdGlobal } = useContext(ModalContext);
    const { setAbrirAbaParametro } = useContext(ModalContext);

    const [areaCamera, setAreaCamera] = useState([
        { id_cruzamento: editId_Cruzamento, refAnel: editId_Cruzamento + '_anel-0' + edit_Anel, consorcio: '', instaladora: '', mantenedora: '', descricao: '', modelo: '', ip: '', port: '', usuario: '', senha: '', canCapturaPorTempo: false, canCapturaPorFalha: false, tempoCaptura: 0, type: 'save' }
    ]);


    const handleAddCamera = () => {
        const values = [...areaCamera];
        values.push({ id_cruzamento: editId_Cruzamento, refAnel: editId_Cruzamento + '_anel-0' + anelGlobal, consorcio: '', instaladora: '', mantenedora: '', descricao: '', modelo: '', ip: '', port: '', usuario: '', senha: '', canCapturaPorTempo: false, canCapturaPorFalha: false, tempoCaptura: 0, type: 'save' });
        setAreaCamera(values);
    };

    const handleRemoveCamera = async (item, index) => { // add função futuramente
        await api.delete(`/centraldefalhas/camera/${item.refAnel}/${item.ip}`)
            .then(res => {
                setAlertMessage(`id: ${index} foi excluído com sucesso!`);
                setTypeAlert('success');
                setAlertView('block');

                const values = [...areaCamera];
                values.splice(index, 1);
                setAreaCamera(values);
            })
            .catch(err => {
                setAlertMessage('Erro ao excluir registro de câmera !');
                setTypeAlert('error');
                setAlertView('block');
            });
    };

    const selectCheckBox = (index, event) => {

        const values = [...areaCamera];

        if (event.target.name === 'canCapturaPorTempo') {
            if (values[index].canCapturaPorTempo === false) {
                values[index].canCapturaPorTempo = true;
            }
            else {
                values[index].canCapturaPorTempo = false;
            }
        }

        if (event.target.name === 'canCapturaPorFalha') {
            if (values[index].canCapturaPorFalha === false) {
                values[index].canCapturaPorFalha = true;
            }
            else {
                values[index].canCapturaPorFalha = false;
            }
        }

        setAreaCamera(values);
    };


    const handleCameraChange = (index, event) => {
        const values = [...areaCamera];
        if (event.target.name === 'consorcio') {
            values[index].consorcio = event.target.value;
        }
        if (event.target.name === 'instaladora') {
            values[index].instaladora = event.target.value;
        }
        if (event.target.name === 'mantenedora') {
            values[index].mantenedora = event.target.value;
        }
        if (event.target.name === 'descricao') {

            /*const chars = event.target.value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove acentos
                .replace(/([^\w]+|\s+)/g, '-') // Substitui espaço e outros caracteres por hífen
                .replace(/\-\-+/g, '-')	// Substitui multiplos hífens por um único hífen
                .replace(/(^-+|-+$)/, ''); // Remove hífens extras do final ou do inicio da string*/
            const sanitizedValue = event.target.value.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '');
            values[index].descricao = sanitizedValue;
        }
        if (event.target.name === 'modelo') {
            values[index].modelo = event.target.value;
        }
        if (event.target.name === 'ip') {
            values[index].ip = event.target.value;
        }
        if (event.target.name === 'porta') {
            values[index].port = event.target.value;
        }
        if (event.target.name === 'usuario') {
            values[index].usuario = event.target.value;
        }
        if (event.target.name === 'senha') {
            values[index].senha = event.target.value;
        }
        if (event.target.name === 'tempoCaptura') {
            values[index].tempoCaptura = event.target.value;
        }
        setAreaCamera(values);
    };

    const saveData = async () => {
        let permitidoEnviar = true;

        for (let i = 0; i < areaCamera.length; i++) {
            if ([
                areaCamera[i].id_cruzamento, areaCamera[i].refAnel, areaCamera[i].consorcio, areaCamera[i].instaladora, areaCamera[i].mantenedora, areaCamera[i].descricao,
                areaCamera[i].modelo, areaCamera[i].ip, areaCamera[i].port, areaCamera[i].usuario, areaCamera[i].senha, areaCamera[i].canCapturaPorTempo,
                areaCamera[i].canCapturaPorFalha, areaCamera[i].tempoCaptura
            ].includes('')) {

                setAlertMessage('Ainda há campos vazios!');
                setTypeAlert('error');
                setAlertView('block');
                permitidoEnviar = false;
            }
            if (areaCamera[i].canCapturaPorTempo === true && areaCamera[i].tempoCaptura <= 0) {
                permitidoEnviar = false;
                setAlertMessage('Tempo de captura deve ser maior que 0!');
                setTypeAlert('error');
                setAlertView('block');
            }
        }

        if (permitidoEnviar) {
            await api.post('/centraldefalhas/camera', areaCamera)
                .then(res => {
                    listarDados(editId_Cruzamento);
                    clearData();
                    setAlertMessage('Registro salvo com sucesso!');
                    setTypeAlert('error');
                    setAlertView('block');
                    setTypeAlert('success');
                })
                .catch(err => {

                    setAlertMessage(err.response.data.mensagem);
                    setTypeAlert('error');
                    setAlertView('block');
                    setTypeAlert('error');
                });
        }
    };

    const clearData = () => {
        setAreaCamera([{ id_cruzamento: '', refAnel: '', consorcio: '', instaladora: '', mantenedora: '', descricao: '', modelo: '', ip: '', usuario: '', port: '', senha: '', canCapturaPorTempo: false, canCapturaPorFalha: false, tempoCaptura: 0, type: 'save' }]);
        setConsorcio([]);
        setInstaladora([]);
        setMantenedora([]);
        setModelo([]);
        setEditIdCruzamento('');
        setEditAnel('');
        setIdLocal([]);
        setAnelGlobal('');
        props.setId('');
    };

    const listData = async () => {
        const response = await api.get('/CentralDeFalhas/local/Lista');
        setIdLocal(response.data);
    };

    useEffect(() => {
        listData();
    }, []);

    const reqInfos = async (id) => {
        setEditIdCruzamento(id);
        setAreaCamera([{ id_cruzamento: id, refAnel: id + '_anel-0' + anelGlobal, consorcio: '', instaladora: '', mantenedora: '', descricao: '', modelo: '', ip: '', port: '', usuario: '', senha: '', canCapturaPorTempo: false, canCapturaPorFalha: false, tempoCaptura: 0, type: 'save' }]);
    };

    const reqAnel = async (anel) => {
        setUnalterable('enable');
        setEditAnel(anel);
        setAreaCamera([{ id_cruzamento: editId_Cruzamento, refAnel: editId_Cruzamento + '_anel-0' + anel, consorcio: '', instaladora: '', mantenedora: '', descricao: '', modelo: '', ip: '', port: '', usuario: '', senha: '', canCapturaPorTempo: false, canCapturaPorFalha: false, tempoCaptura: 0, type: 'save' }]);
    };

    const reqConsorcio = async () => {
        const response = await api.get('/CentralDeFalhas/consorcio');
        setConsorcio(response.data);
    };

    const reqModelo = async () => {
        const response = await api.get('/centraldefalhas/modelo/camera');
        setModelo(response.data);
    };

    const reqEmpresa = async () => {
        const response = await api.get('/CentralDeFalhas/empresa');
        setInstaladora(response.data);
        setMantenedora(response.data);
    };

    const listarDados = async (id) => {
        setAlertView('none');
        const response = await api.get(`/centraldefalhas/camera/${id}`);
        setAreaCamera(response.data);
    };

    useEffect(() => {
        listarDados(props.id + '_anel-0' + anelGlobal);
        setUnalterable('enable');
        reqInfos(props.id);
        setLocal(props.id);
        listarDados(props.id);


        if (props.id.length > 0) {
            const values = [...areaCamera];
            values.forEach(item => {
                item.type = 'update';
                item.id_cruzamento = props.id;
                item.refAnel = props.id + '_anel-0' + anelGlobal;

            });
            setAreaCamera(values);
        } else {
            const values = [...areaCamera];
            values.forEach(item => {
                item.type = 'save';
            });
            setAreaCamera(values);
        }
    }, []);

    const buscarId = async () => {
        setAlertView('none');
        const response = await api.get(`/centraldefalhas/camera/${local + '_anel-0' + anelGlobal}`);
        setAreaCamera(response.data);
    };

    useEffect(() => {
        buscarId();
    }, [anelGlobal, local]);

    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    };

    const ContentPopBallonCamera = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Câmera</h3>
                    <label onClick={VozInfoEquipamentoCamera}><img src={icoSom} alt="" /></label>
                </div>
                <span>
                    Será destinado ao cadastro da câmera para o local referenciado pelo local ID, devemos informar os
                    responsáveis sendo eles o consórcio responsável, a empresa instaladora e a empresa mantedora.
                    Dados – Nesse campo será informado os dados referentes a câmera a ser utilizada na central.
                    Acesso – Deve-se informar o IP da câmera, usuário e senha da mesma.
                    Automatização de fotos – tem como função habilitar fotos automáticas colocando um
                    intervalo de segundos e também habilitar/desabilitar fotos for falha.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    };

    return (
        <ContentTab>
            <InfoHelp onClick={() => openPopUp()}>
                <GrCircleQuestion size={15} />
            </InfoHelp>
            <AreaBallon visible={visibleBalloon}>
                <BallonsInfoModal><ContentPopBallonCamera /></BallonsInfoModal>
            </AreaBallon>
            <fieldset>
                <legend>ID do local</legend>
                <span className="legend">Nº Anel</span>
                <AreaInput>
                    <select name="local" id="local" onClick={listData} onChange={(e) => { reqInfos(e.target.value); setLocal(e.target.value); }}> {/*listarDados(anelGlobal === 'Selecione o anel...' ? e.target.value + '_anel-01'  : e.target.value + '_anel-0' + anelGlobal)*/}
                        <option defaultValue={props.id} value={props.id}>{props.id === '' ? 'Selecione...' : props.id}</option>
                        {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(item => {
                            if (props.id !== item.id_cruzamento) {
                                return (
                                    <option key={item.id_cruzamento} value={item.id_cruzamento}>{item.id_cruzamento}</option>
                                );
                            }
                        })}
                        {/*idLocal.map(item => (
                                <option key={item.id_cruzamento} value={item.id_cruzamento}>{item.id_cruzamento}</option>
                        ))*/}

                    </select>
                    <select name="anel" id="anel" value={anelGlobal} style={local === '' ? { opacity: 0.4, pointerEvents: 'none' } : { opacity: 1, pointerEvents: 'auto' }}
                        onChange={(e) => { reqAnel(e.target.value); setAnelGlobal(e.target.value); }}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </AreaInput>
            </fieldset>
            {areaCamera.map((item, index) => {
                return (
                    <MultiplyArea key={`${item}~${index}`} className={unalterable}>
                        <fieldset>
                            <AreaButtonRemove className={permissao}>
                                <BtnBorder onClick={() => handleRemoveCamera(item, index)} title="Remover câmera">
                                    <IconRemove />
                                </BtnBorder>
                            </AreaButtonRemove>
                            <legend>Câmera {index + 1}</legend>
                            <fieldset>
                                <legend>Responsáveis</legend>
                                <form>
                                    <div className='row'>
                                        <div className="group column">
                                            <label>Consórcio:</label>
                                            <select name="consorcio" value={item.consorcio} className="col-2" onClick={reqConsorcio} onChange={(event) => handleCameraChange(index, event)}>
                                                <option defaultValue={item.consorcio}>{item.consorcio === '' ? 'Selecione...' : item.consorcio}</option>
                                                {consorcio.map(itemConsorcio => {
                                                    if (itemConsorcio.nome !== item.consorcio) {
                                                        return (
                                                            <option key={itemConsorcio.nome} value={itemConsorcio.nome}>{itemConsorcio.nome}</option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                        </div>
                                        <div className="group column">
                                            <label>Instaladora:</label>
                                            <select name="instaladora" className="col-2" value={item.instaladora} onClick={reqEmpresa} onChange={(event) => handleCameraChange(index, event)}>
                                                <option defaultValue={item.instaladora}>{item.instaladora === '' ? 'Selecione' : item.instaladora}</option>
                                                {instaladora.map(itemInstaladora => {
                                                    if (itemInstaladora.nome !== item.instaladora) {
                                                        return (
                                                            <option key={itemInstaladora.nome} value={itemInstaladora.nome}>{itemInstaladora.nome}</option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="group column">
                                            <label>Mantenedora:</label>
                                            <select name="mantenedora" className="col-2" value={item.mantenedora} onClick={reqEmpresa} onChange={(event) => handleCameraChange(index, event)}>
                                                <option defaultValue={item.mantenedora}>{item.mantenedora === '' ? 'Selecione...' : item.mantenedora}</option>
                                                {mantenedora.map(itemMantenedora => {
                                                    if (itemMantenedora.nome !== item.mantenedora) {
                                                        return (
                                                            <option key={itemMantenedora.nome} value={itemMantenedora.nome}>{itemMantenedora.nome}</option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                        </div>
                                        <div className={permissao}>
                                            <Button className="btnNovo" onClick={(e) => { e.preventDefault(); props.setOpenParametros(true); }}>
                                                <BtnNovo />NOVO
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>

                            <fieldset className="content">
                                <legend>Dados</legend>
                                <form>
                                    <div className='row'>
                                        <div className="group column">
                                            <label>Descrição:</label>
                                            <input type="text" name="descricao"
                                                value={item.descricao}
                                                onChange={(event) => handleCameraChange(index, event)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="group">
                                            <label>Modelo:</label>
                                            <select name="modelo" value={item.modelo} onClick={reqModelo} onChange={event => handleCameraChange(index, event)} >
                                                <option value={item.modelo}>{item.modelo === '' ? 'Selecione...' : item.modelo}</option>
                                                {modelo.map(itemModelo => (
                                                    <option key={itemModelo.modelo} value={itemModelo.modelo}>{itemModelo.modelo}</option>
                                                )
                                                )}
                                            </select>
                                        </div>
                                        <div className={permissao}>
                                            <Button className="btnNovo" onClick={(e) => { e.preventDefault(); props.setOpenParametros(true); setAbrirAbaParametro('camera'); }}>
                                                <BtnNovo />NOVO
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                            <fieldset className="content">
                                <legend>Acesso</legend>
                                <form>
                                    <div className='row'>
                                        <div className="group">
                                            <label>IP:</label>
                                            <input as={InputMask} mask="999.999.999.999" maxLength="15" type="text" name="ip" defaultValue={item.ip} onChange={(event) => handleCameraChange(index, event)} />
                                        </div>
                                        <div className="group">
                                            <label>Porta:</label>
                                            <input type="text" name="porta" value={item.port} onChange={(event) => handleCameraChange(index, event)} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="group column">
                                            <label>Usuário:</label>
                                            <input type="text" name="usuario" value={item.usuario} onChange={(event) => handleCameraChange(index, event)} />
                                        </div>
                                        <div className="group column">
                                            <label>Senha:</label>
                                            <input type="text" name="senha" value={item.senha} onChange={(event) => handleCameraChange(index, event)} />
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                            <fieldset className="content">
                                <legend>Automatização de fotos</legend>
                                <form>
                                    <div className='row'>
                                        <input type="checkbox" name="canCapturaPorTempo" checked={item.canCapturaPorTempo} onChange={(event) => selectCheckBox(index, event)} />
                                        <div className="group">
                                            <input type="number" name="tempoCaptura" value={item.tempoCaptura} onChange={(event) => handleCameraChange(index, event)}
                                                placeholder="Digite o intervalo em segundos..."
                                                className="minutes"
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <input type="checkbox" name="canCapturaPorFalha" checked={item.canCapturaPorFalha}
                                            onChange={(event) => selectCheckBox(index, event)}
                                        />
                                        <div className="group">
                                            <span>Fotos por falha - Habilitado</span>
                                        </div>
                                    </div>
                                </form>
                            </fieldset>
                        </fieldset>
                    </MultiplyArea>
                );
            })}
            <AreaBtn view={alertView} className={permissao}>
                <div className="alert">
                    <Alert severity={typeAlert} >{alertMessage}</Alert>
                </div>
                <Actions>
                    <BorderButton className={permissao === 'disabled' ? '' : unalterable} onClick={() => handleAddCamera()}><BtnAdd /></BorderButton>
                    <Button width="92" heigth="41" className="btnSalvar" onClick={saveData}>SALVAR</Button>
                </Actions>
            </AreaBtn>
        </ContentTab>
    );
}