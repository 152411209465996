import React from 'react';

export default function BtnEditar(){
    return(
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M6.875 3H2.5C2.16848 3 1.85054 3.1317 1.61612 3.36612C1.3817 3.60054 1.25 3.91848 1.25 4.25V13C1.25 13.3315 1.3817 13.6495 1.61612 13.8839C1.85054 14.1183 2.16848 14.25 2.5 14.25H11.25C11.5815 14.25 11.8995 14.1183 12.1339 13.8839C12.3683 13.6495 12.5 13.3315 12.5 13V8.625" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11.5625 2.06215C11.8111 1.81351 12.1484 1.67383 12.5 1.67383C12.8516 1.67383 13.1889 1.81351 13.4375 2.06215C13.6861 2.31079 13.8258 2.64802 13.8258 2.99965C13.8258 3.35128 13.6861 3.68851 13.4375 3.93715L7.5 9.87465L5 10.4997L5.625 7.99965L11.5625 2.06215Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}