import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import BtnAdd from '../RegisterControllers/Icons/BtnAdd';
import IconRemove from '../RegisterControllers/Icons/IconRemove';
import { validarPermissao } from '../../../services/permission';
import { Container, MultiplyArea, AreaButtonRemove, BtnBorder, AreaBtn, Actions, BorderButton, AreaButton, Button } from './styles';
import apiMensageria from '../../../services/apiMensageria';


const permissao = (validarPermissao([0, 1]) ? 'enabled' : 'disabled');

export default function RegisterComunication() {

    const [alertView] = useState('none');
    const [alertMessage] = useState('');
    const [typeAlert] = useState('');
    const [messages, setMessages] = useState([
        { title: '', content: '', status: '' }
    ]);

    const handleAddMessage = () => {
        const values = [...messages];
        values.push({ title: '', content: '', status: 'newItem' });
        setMessages(values);
    }

    const handleChangeInput = (e, index) => {
        const values = [...messages];

        if (e.target.name === 'title') {
            values[index].title = e.target.value;
        } else {
            values[index].content = e.target.value;
        }

        setMessages(values);
    }

    const deleteMessage = async (id, index) => {
        const values = [...messages];
        values.splice(index, 1);
        await apiMensageria.delete(`/api/v1/template-models/${id}`)
            .then(res => {
                setMessages(values);
            }).catch(err => { });
    }

    const postTemplate = async (index) => {
        await apiMensageria.post(`/api/v1/template-models`, {
            "title": messages[index].title,
            "content": messages[index].content,
        }).then(res => {
            /*setTypeAlert('success');
            setAlertMessage('Dados salvos com sucesso!');
            setAlertView('block');
            setTimeout(() => {
                setAlertMessage('');
                setAlertView('none');
            },5000);*/
            alert('Dados salvos com sucesso!')
        }).catch(err => {
            alert('Erro ao salvar dados!')
        });
    }

    const editTemplate = async (id, index) => {

        await apiMensageria.put(`/api/v1/template-models/${id}`, {
            "title": messages[index].title,
            "content": messages[index].content,
        }).then(res => {
            alert('Dados editados com sucesso!')
        }).catch(err => {
            alert('Erro ao editar dados!')
        });
    }

    useEffect(() => {
        const listTemplates = () => {
            apiMensageria.get(`/api/v1/template-models?skip&take=&filter}`)
                .then(res => {

                    const arraylist = res.data;
                    arraylist.forEach(item => {
                        item.status = 'edition'
                    });

                    setMessages(arraylist);
                }).catch(err => {
                    alert('Erro ao listar templates de content');
                });
        }
        listTemplates();
    }, []);

    return (
        <Container>
            {messages.map((item, index) => (
                <MultiplyArea key={index}>
                    <fieldset>
                        <AreaButtonRemove className={permissao}>
                            <BtnBorder onClick={() => deleteMessage(item._id, index)}>
                                <IconRemove />
                            </BtnBorder>
                        </AreaButtonRemove>
                        <legend>Mensagem {index + 1}</legend>
                        <div className='group'>
                            <label>Titulo:</label>
                            <input type="text" name='title' value={item.title} onChange={(e) => handleChangeInput(e, index)} />
                        </div>
                        <div className='group'>
                            <label>Mensagem:</label>
                            <textarea name='content' value={item.content} placeholder='Mensagem' onChange={(e) => handleChangeInput(e, index)} maxLength={120}></textarea>
                            <div className='areaCharacters'>
                                <span>{item.content.length}/120</span>
                            </div>
                        </div>
                        <AreaButton view={alertView}>
                            <div className='alert'>
                                <Alert severity={typeAlert}>{alertMessage}</Alert>
                            </div>
                            <Button width="92" heigth="41" className={messages[index].status === 'edition' ? 'btnEditarEnabled' : 'btnEditarDesabled'} onClick={() => editTemplate(item._id, index)}>Editar</Button>
                            <Button width="92" heigth="41" className={messages[index].status === 'newItem' ? 'btnSalvarEnabled' : 'btnSalvarDesabled'} onClick={() => postTemplate(index)}>Salvar</Button>
                        </AreaButton>
                    </fieldset>
                </MultiplyArea>
            ))}
            <AreaBtn className={permissao}>
                <Actions>
                    <BorderButton onClick={handleAddMessage}><BtnAdd /></BorderButton>
                </Actions>
            </AreaBtn>
        </Container>
    );
}
