import React from 'react';
import { AreaButton } from './styles';
 
export default function ButtonReflesh(){

    const Icon = () => (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.75 1.875H1.25L6.25 7.7875V11.875L8.75 13.125V7.7875L13.75 1.875Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );

    return(
        <AreaButton>
            <Icon />
        </AreaButton>
    );
}