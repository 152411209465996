import styled from 'styled-components';

export const Container = styled.figure`
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    width: 95.5%;
    height: 380px;
    margin: 0 auto;
    margin-top: -60px;
    position: relative;

    .chart{
        position: relative;
        height: 300px !important;
        width: 95.5% !important;
        padding: 27px;

        @media(max-width: 420px){
            margin-left: -6%;
        }
    }
`;

export const Title = styled.span`
    width: 100%;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    font-family: 'roboto', sans-serif;
    height: 30px;
    align-items: flex-end;
    justify-content: center;
`;

export const AreaLoading = styled.img`
    width: 20%;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: ${props => props.loading == false ? 'none' : 'block'};
`;