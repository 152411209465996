import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import MenuBlank from '../../components/MenuBlank';
import ChartLine from './ChartLine';
import ChartBar from './ChartBar';
import ChartArea from './ChartArea';
import ChartFill from './ChartFill';
import ChartCircle from './ChartCircle';
import {
    Container, AreaMenu, Row, FieldsetSmall, FieldsetMedium, FieldsetLarge, Title, ChartsCircles,
    AreaInputs, Span, AreaButton, BtnCarregar, BtnExportar, AreaCharts, MenuProps, useStyles

} from './styles';
import FileDownload from 'js-file-download';
import { validarPermissao } from '../../services/permission';

//selecao multipla
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const permissao = (validarPermissao([0, 1, 2, 3]) ? 'enabled' : 'disabled');

export default function Estatisticas() {

    const [idLocal, setIdLocal] = useState([{ _id: '', id_cruzamento: '' }]);
    const [selectId, setSelectId] = useState('');
    const [detectores, setDetectores] = useState([]);
    const [selectDetectores, setSelectDetectores] = useState('');
    const [detectoresSelecionados, setDetectoresSelecionados] = useState([]);
    const [grupoSelecionados, setGrupoSelecionados] = useState([]);

    const [dadosGraficos, setDadosGraficos] = useState([]);
    const [dadosMedia, serDadosMedia] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dataInicial, setDataInicial] = useState(new Date());
    const [dataFinal, setDataFinal] = useState('');
    const [visibleChart, setVisibleChart] = useState(false);

    const [gruposSelect, setGruposSelect] = useState([])
    const [gruposTodos, setGruposTodos] = useState([])

    const classes = useStyles();

    const handleChange = (event) => {

        setGrupoSelecionados([])
        setDetectoresSelecionados(event.target.value);

        const detectoresEscolhidos = [];
        event.target.value.forEach((element) => {
            const d = (((element.toString()).split("*"))[0]).split("D")[1];
            detectoresEscolhidos.push(d);
        })

        setSelectDetectores(detectoresEscolhidos.toString());
    };

    const handleChange1 = (event) => {

        setDetectoresSelecionados([])
        setGrupoSelecionados(event.target.value);

        const detectoresEscolhidos = [];
        event.target.value.forEach((element) => {

            const d = (((element.toString()).split("G"))[1]);

            detectores.map((e) => {

                if (e.id_grupo === null || e.id_grupo === undefined) {

                    return null;

                } else {

                    if (e.id_grupo.toString() === d) {

                        detectoresEscolhidos.push(e.id_detector);
                    }
                }
            })
        })

        setSelectDetectores(detectoresEscolhidos.toString());

    };

    const reqIds = async () => {
        try {
            const response = await api.get('/centraldefalhas/controladores');
            setIdLocal(response.data);
        } catch (error) {
            setIdLocal({ _id: '', id_cruzamento: '' });
        }
    }

    const reqDetectoresCadastrados = async () => {
        try {
            limparDadosGraficos();
            limparDetectoresSelecionados();

            if (typeof selectId !== 'undefined' && selectId !== '') {
                const response = await api.get(`/centraldefalhas/detectores/${selectId}`);
                setDetectores(response.data);

                var arrayGrupo = [];

                response.data.forEach((e) => {

                    arrayGrupo.push(e.id_grupo)
                })

                setGruposTodos(arrayGrupo)

                var arrayGrupo1 = arrayGrupo.filter((e, i) => {
                    return arrayGrupo.indexOf(e) === i;
                })

                setGruposSelect(arrayGrupo1)

            }

        } catch (error) {
            setDetectores([]);
        }
    }

    const reqDadosGraficos = async () => {
        try {
            limparDadosGraficos();

            if ([dataInicial, dataFinal, selectId].includes('') || selectDetectores === '') {
                alert('Defina todos os parâmetros para realizar a consulta')
                return
            }

            setLoading(true);
            setVisibleChart(false);

            let queryDetectores = selectDetectores;

            const response = await api.get(`/centraldefalhas/estatisticas/${selectId}?dataMin=${dataInicial}&dataMax=${dataFinal}&detectores=${queryDetectores}`);

            setDadosGraficos(response.data.dados);
            serDadosMedia(response.data.medias);
            console.log('todos', response.data);
            console.log(response.data.medias)
            setLoading(false);
        } catch (error) {
            window.location.href = "/"
            limparDadosGraficos();
        }
    }

    function limparDetectoresSelecionados() {
        setDetectoresSelecionados([])
    }

    function limparDadosGraficos() {
        setDadosGraficos([]);
    }

    useEffect(() => {
        reqDetectoresCadastrados();
    }, [selectId]);

    useEffect(() => {

        //pegar dia de hoje
        var data = new Date();
        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        var dataAtual = ano + '-' + mes + '-' + dia + 'T00:00';

        // pegar o dia de amanhã
        var amanha = new Date((new Date()).valueOf() + 1000 * 3600 * 24);
        var diaAmanha = String(amanha.getDate()).padStart(2, '0');
        var mesAmanha = String(amanha.getMonth() + 1).padStart(2, '0');
        var anoAmanha = amanha.getFullYear();
        var dataAmanha = anoAmanha + '-' + mesAmanha + '-' + diaAmanha + 'T00:00';

        setDataInicial(dataAtual);
        setDataFinal(dataAmanha);

    }, []);

    //adiciona um zero na frente dos numeros < 9 para formatar a data
    function adicionaZeroData(numero) {
        if (numero <= 9) {
            return "0" + numero;
        }
        else {
            return numero;
        }
    }

    //faz a formatacao do timestamp recebido
    function converterData(data) {
        const dataHora = new Date(data);
        const dia = adicionaZeroData(dataHora.getDate())
        const mes = adicionaZeroData(dataHora.getMonth() + 1);
        const ano = dataHora.getFullYear();
        const hora = adicionaZeroData(dataHora.getHours());
        const minuto = adicionaZeroData(dataHora.getMinutes());
        const segundos = adicionaZeroData(dataHora.getSeconds());
        const dataHoraFormatada = ano + "/" + mes + "/" + dia + " " + hora + ":" + minuto + ":" + segundos;

        return dataHoraFormatada;
    }

    /*converte os dados antes de inserir no grafico, transformando
    o id_detector em texto e formatando o timestamp */
    function converterDadosGrafico(dados) {
        dados.forEach(dado => {
            dado.id_detector = dado.id_detector.toString();
            dado.timestamp = converterData(dado.timestamp);
        });
        return dados;
    }

    async function exportData() {
        const fileExcel = await api.get(`/centraldefalhas/estatisticas/${selectId}/xls?dataMin=${dataInicial}&dataMax=${dataFinal}&detectores=${selectDetectores}`, { responseType: 'blob' })
        FileDownload(fileExcel.data, `logs.xlsx`);
    }

    return (
        <Container>
            <MenuBlank />
            <AreaMenu>
                <Title>
                    <h1>Estatísticas - Detectores</h1>
                </Title>
                <AreaInputs>
                    <Row>
                        <FieldsetMedium>
                            <legend>ID do local</legend>
                            <select name="" id="" onClick={() => reqIds()} onChange={(e) => setSelectId(e.target.value)}>
                                <option value="">Id</option>
                                {idLocal.sort((idA, idB) => idA.id_cruzamento > idB.id_cruzamento ? 1 : -1).map(items => (
                                    <option key={items._id} value={items.id_cruzamento}>{items.id_cruzamento}</option>
                                ))}
                            </select>
                        </FieldsetMedium>
                        <Span>*Para ter os dados á disposição, selecione os parâmetros desejados.</Span>
                    </Row>
                    <Row>

                        <FieldsetLarge>
                            <legend>Grupos</legend>
                            <Select
                                autoWidth={true}
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={grupoSelecionados}
                                onChange={handleChange1}
                                input={<Input fullWidth={true} inputMultiline={true} id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {gruposSelect.sort((grupoA, grupoB) => grupoA > grupoB ? 1 : -1).map((grupo) => (

                                    <MenuItem

                                        key={grupo}
                                        value={`*G${grupo}`}
                                    >
                                        {`*G${grupo}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FieldsetLarge>

                        <FieldsetLarge>
                            <legend>Detectores</legend>
                            <Select
                                autoWidth={true}
                                labelId="demo-mutiple-chip-label"
                                id="demo-mutiple-chip"
                                multiple
                                value={detectoresSelecionados}
                                onChange={handleChange}
                                input={<Input fullWidth={true} inputMultiline={true} id="select-multiple-chip" />}
                                renderValue={(selected) => (
                                    <div className={classes.chips}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                            >
                                {detectores.sort((detectorA, detectorB) => detectorA.id_grupo > detectorB.id_grupo ? 1 : -1).map((detector) => (

                                    <MenuItem

                                        key={detector.id_detector}
                                        value={`D${detector.id_detector}*G${detector.id_grupo}`}
                                    >
                                        {`D${detector.id_detector}*G${detector.id_grupo}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FieldsetLarge>


                    </Row>
                    <Row className="data">
                        <FieldsetMedium>
                            <legend>Filtro por data </legend>
                            <input type="datetime-local" value={dataInicial} onChange={(e) => setDataInicial(e.target.value)} />
                            <input type="datetime-local" value={dataFinal} onChange={(e) => setDataFinal(e.target.value)} />
                        </FieldsetMedium>
                        <FieldsetSmall>
                            <legend>Intervalos de tempo</legend>
                            <select name="" id="" disabled={true}>
                                <option value="">Selecione um intervalo</option>
                                <option value="">1</option>
                            </select>
                        </FieldsetSmall>
                        <AreaButton>
                            <BtnExportar className={permissao} onClick={exportData}>Exportar Dados</BtnExportar>
                            <BtnCarregar onClick={() => reqDadosGraficos()}>Carregar Dados</BtnCarregar>
                        </AreaButton>
                    </Row>
                </AreaInputs>
            </AreaMenu>
            <AreaCharts>
                <ChartsCircles>
                    <ChartFill dadosMedia={dadosMedia} dadosGraficos={converterDadosGrafico(dadosGraficos)} cbLoading={loading} cbSetVisibleChart={setVisibleChart} cbVisibleChart={visibleChart} />
                    <ChartCircle dadosMedia={dadosMedia} dadosGraficos={converterDadosGrafico(dadosGraficos)} cbLoading={loading} />
                </ChartsCircles>
                <ChartLine dadosGraficos={converterDadosGrafico(dadosGraficos)} cbLoading={loading} />
                <ChartBar dadosGraficos={converterDadosGrafico(dadosGraficos)} cbLoading={loading} />
                <ChartArea dadosGraficos={converterDadosGrafico(dadosGraficos)} cbLoading={loading} />
            </AreaCharts>
        </Container>
    );
}