import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartBottomToRight from '../../../img/Setas/arrowStartBottomToRight.png';
import arrowStopBottomToRight from '../../../img/Setas/arrowStopBottomToRight.png';
import arrowPauseBottomToRight from '../../../img/Setas/arrowPauseBottomToRight.png';
import animateArrowBottomToRight from '../../../img/Setas/animateArrowBottomToRight.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveBottomToRight, Options, ButtonSettings } from './styles';

export default function ArrowBottomToRight(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Seta descendo vira à direita', image: arrowStartBottomToRight, object: props.arrowBottomToRight, setObject: props.setArrowBottomToRight });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowBottomToRight.map((item, index) => {
                if (props.arrowBottomToRight[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowBottomToRight'
                            top={item.top}
                            left={item.left}
                            setArrowBottomToRight={props.setArrowBottomToRight}
                            arrowBottomToRight={props.arrowBottomToRight}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveBottomToRight
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartBottomToRight :
                                        item.color_group === 1 ? arrowStopBottomToRight :
                                            item.color_group === 2 ? arrowPauseBottomToRight :
                                                item.color_group === 4 ? animateArrowBottomToRight : ''
                                }
                                alt=""
                                tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowBottomToRight(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
