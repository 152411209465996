import React, { useState, useEffect } from 'react';
import BtnNovo from '../../../Registers/RegisterControllers/Icons/BtnNovo';
import excluir from '../../../../assets/img/excluir.png';
import Alert from '@material-ui/lab/Alert';
import api from '../../../../services/api';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import { BallonsParametros } from '../../../Balloons';
import { Container, AreaIcon, InfoEmpresa, ContentBallon, AreaInput, Group, AreaButtons, Button, AreaTable, Table} from './styles';
import { validarPermissao } from '../../../../services/permission';
import { GrCircleQuestion } from "react-icons/gr";
import { tocarAudioEmpresa } from '../../../Audio';

const permissao = (validarPermissao([0,1]) ? 'enabled' : 'disabled');

export default function Empresa(props){

    const[modoOperacional, setModoOperacional] = useState([]);
    const[enabled, setEnabled] = useState('enabled');
    const[msg, setMsg] = useState('');
    const[alertMsg, setAlertMsg] = useState('none');
    const[typeAlert, setTypeAlert] = useState('');
    const[nome, setNome] = useState('');
    const[_id, set_id] = useState('');
    const[visibleBallon, setVisibleBallon] = useState(false);

    const lerDados = async() =>{
        const response = await api.get('/centraldefalhas/empresa');
        setModoOperacional(response.data);
    }

     //Usado para verificar se mudou de aba
    //e entao limpar os campos
    useEffect(() => {
        lerDados();
        setEnabled('enabled');
        setNome('');

    },[props.positionAba]);

    const addItem = () =>{
        setEnabled('disabled');
        set_id('');
        setNome('');
    }

    const deleteItem = async(nome) =>{
        await api.delete(`/centraldefalhas/empresa/${nome}`)
        .then(() =>{
            setMsg('O nome excluido com sucesso!');
            setAlertMsg('block');
            setTypeAlert('success');
            lerDados();

            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            },5000);

        }).catch(() => {
            setMsg('O nome não foi excluido!');
            setAlertMsg('block');
            setTypeAlert('warning');
        });
    }

    const saveData = async() => {
        if([nome].includes('')){
            setAlertMsg('block');
            setMsg('Preencha o campo nome!');
            setTypeAlert('warning');

            setTimeout(() => {
                setAlertMsg('none');
                setMsg('');
            },2000);
        }else{
            await api.post('/centraldefalhas/empresa',{
                "nome": nome,
                "_id": _id
            }).then((res) =>{
            
                setAlertMsg('block');
                setMsg('Salvo com sucesso!');
                setTypeAlert('success');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

                lerDados();
                setNome('');

            }).catch((err) =>{
                setAlertMsg('block');
                setMsg(err.response.data.mensagem);
                setTypeAlert('error');

                setTimeout(() => {
                    setAlertMsg('none');
                    setMsg('');
                },5000);

            });
        }
    }

    const listDataInput = (item) => {
        setNome(item.nome);
        setEnabled('disabled');
        set_id(item._id);
    }

    const closePopUp = () => {
        if(visibleBallon === false){
            setVisibleBallon(true);
        }else{
            setVisibleBallon(false);
        }
       
    }   

    const openPopUp = () => {
        if(visibleBallon === false){
            setVisibleBallon(true);
        }else{
            setVisibleBallon(false);
        }
    }

    const ContentBallonEmpresa = () => {
        return(
            <ContentBallon>
                <div className="title"> 
                    <img src={icoInfo} alt="" />
                    <h3>Empresa</h3>
                    <label onClick={tocarAudioEmpresa}><img src={icoSom} alt="" /></label><br/>
                </div>
                <span>
                   Campo responsável por cadastrar empresas responsáveis por alguma das etapas seja manutenção ou instalação.
                </span>
                <div className="areaButton">
                    <button onClick={() => closePopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }

    return(
        <Container style={{display: props.status}}>
            <AreaIcon>
                <div className='icon' onClick={openPopUp}>
                    <GrCircleQuestion />
                </div>
            </AreaIcon>
            <InfoEmpresa view={visibleBallon}>
                <BallonsParametros>
                    <ContentBallonEmpresa />
                </BallonsParametros>
            </InfoEmpresa>
            <AreaInput>
                <Group id={enabled}>
                    <label htmlFor="">Nome da Empresa:</label>
                    <input type="text" placeholder="Selecione na tabela ou adicione..." value={nome} onChange={(e) => setNome(e.target.value)}/>
                </Group>
                <AreaButtons message={alertMsg} className={permissao}>
                    <div className="alert">
                        <Alert severity={typeAlert}>{msg}</Alert>
                    </div>
                    <div className={permissao}>
                        <Button id="btnNovo" onClick={addItem}>
                            <BtnNovo />Novo
                        </Button>
                    </div>
                    <Button className="btnSalvar" id={enabled} onClick={saveData}>
                        Salvar
                    </Button>
                </AreaButtons>
            </AreaInput>
            <AreaTable>
                <Table>
                    <tr>
                        <th>Nome</th>
                        <th>Excluir</th>
                    </tr>
                    {modoOperacional.map( (item,index) => (
                        <tr key={item.id} onClick={permissao === 'disabled' ? '' : () => listDataInput(item)}>
                            <td>{item.nome}</td>
                            <td><img src={excluir} alt="" className={permissao} onClick={() => deleteItem(item.nome)}/></td>
                        </tr>
                    ))}
                </Table>       
            </AreaTable>
        </Container>
    );
}