import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./services/auth";
import Login from "./pages/Login";
import Mapa from "./pages/Mapa";
import ImprimirLogs from "./pages/ImprimirLogs";
import Dashboard from "./pages/Dashboard";
import Relatorios from './pages/Relatorios';
import ModalContextProvider from './contextModal';
import Cameras from "./pages/Cameras";
import Estatisticas from "./pages/Estatisticas";
import Croqui from './pages/Croqui';
import NotFound from "./pages/404";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    )} />
)
const Routes = () => (
    <ModalContextProvider>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute exact path="/mapa" component={Mapa} />
                <PrivateRoute exact path="/logsim" component={ImprimirLogs} />
                <PrivateRoute exact path="/board" component={Dashboard} />
                <PrivateRoute exact path="/relatorios" component={Relatorios} />
                <PrivateRoute exact path="/camera/:refAnel" component={Cameras} />
                <PrivateRoute exact path="/estatisticas" component={Estatisticas} />
                <PrivateRoute exact path="/croqui/:id/:anel/:ipmbb" component={Croqui} />
                <Route exact path="*" component={NotFound} />
            </Switch>
        </BrowserRouter>
    </ModalContextProvider>
);

export default Routes;