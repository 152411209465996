import React from 'react';
import { ContentBallon } from './styles';
import icoSom from '../../../../assets/icons/icoSom.png';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import tutoCroqui from '../../../Croqui/components/img/tutoCroqui.png';
import dedo from '../../../Croqui/components/img/dedo.png';

export default function ContentBallonCroqui({ close }) {
    return (
        <ContentBallon>
            <div className="title">
                <img src={icoInfo} alt="" />
                <h3>Croqui Semafórico</h3>
                <label><img src={icoSom} alt="" /></label><br />
            </div>
            <div className='areaImagem'>
                <img src={tutoCroqui} alt="" />
            </div>
            <div className='areaArrow'>
                <img src={dedo} alt="" />
            </div>
            <div className="areaButton">
                <button onClick={() => close(false)}>Entendi</button>
            </div>
        </ContentBallon>
    );
}