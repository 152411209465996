import React from 'react';
import ItemDraggable from '../../../ItemDraggable';
import arrowStartTopLeft from '../../../img/Setas/arrowStartTopLeft.png';
import arrowPauseTopLeft from '../../../img/Setas/arrowPauseTopLeft.png';
import arrowStopTopLeft from '../../../img/Setas/arrowStopTopLeft.png';
import animateArrowTopToLeft from '../../../img/Setas/animateArrowTopToLeft.gif';
import close from '../../../img/close.png';
import settings from '../../../img/tool.png';
import { Container, MoveTopToLeft, Options, ButtonSettings } from './styles';

export default function ArrowTopToLeft(props) {

    const insertConfigModalSettings = (item, index) => {
        props.setPropsSettings({ index: index, item: item, type: 'arrow', title: 'Seta subindo vira a esquerda', image: arrowStartTopLeft, object: props.arrowTopToLeft, setObject: props.setArrowTopToLeft });
        props.setOpenSettings(true);
    }

    return (
        <Container>
            {props.arrowTopToLeft.map((item, index) => {
                if (props.arrowTopToLeft[index] !== '') {
                    return (
                        <ItemDraggable
                            key={index}
                            index={index}
                            object='arrowTopToLeft'
                            top={item.top}
                            left={item.left}
                            setArrowTopToLeft={props.setArrowTopToLeft}
                            arrowTopToLeft={props.arrowTopToLeft}
                            classeObjeto={item.classeObjeto}
                        >
                            <MoveTopToLeft
                                src={
                                    item.color_group === undefined || item.color_group === '' ? arrowStartTopLeft :
                                        item.color_group === 1 ? arrowStopTopLeft :
                                            item.color_group === 2 ? arrowPauseTopLeft :
                                                item.color_group === 4 ? animateArrowTopToLeft : ''
                                }
                                alt="" tamanho={item.tamanho}
                                angulo={item.angulo}
                            />
                            <Options remove={props.remove} onClick={() => props.removeArrowTopToLeft(index, item)}>
                                <img src={close} alt="" />
                            </Options>
                            <ButtonSettings remove={props.remove}>
                                <img src={settings} alt="" onClick={() => insertConfigModalSettings(item, index)} />
                            </ButtonSettings>
                        </ItemDraggable>
                    );
                } else {
                    return null;
                }
            })}
        </Container>
    );
}
