import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../../contextModal';
import Lixeira from '../../../assets/icons/Lixeira.jsx';
import { Container, Table, AreaLoading, Filter } from './styles';
import FiltroIdentificacao from './Filters/FiltroIdentificacao';
import FiltroEquipamento from './Filters/FiltroEquipamento';
import FiltroNivel from './Filters/FiltroNivel';
import FiltroAcao from './Filters/FiltroAcao';
import FiltroAgente from './Filters/FiltroAgente';
import FiltroDescricao from './Filters/FiltroDescricao';
import api from '../../../services/api';
import loader from '../../../assets/img/loader.gif';
import { FiFilter } from "react-icons/fi";
import { validarPermissao } from '../../../services/permission';

const estadoLixeira = (
    validarPermissao([0, 1]) ? 'enabled' : 'disabled'
);

export default function Report(props) {

    const [filtroIdentificacao, setFiltroIdentificacao] = useState(false);
    const [filtroEquipamento, setFiltroEquipamento] = useState(false);
    const [filtroNivel, setFiltroNivel] = useState(false);
    const [filtroAcao, setFiltroAcao] = useState(false);
    const [filtroAgente, setFiltroAgente] = useState(false);
    const [filtroDescricao, setFiltroDescricao] = useState(false);
    const { statusFilterIdentificacao } = useContext(ModalContext);
    const { statusFilterEquipamento } = useContext(ModalContext);
    const { statusFilterNivel } = useContext(ModalContext);
    const { statusFilterAcao } = useContext(ModalContext);
    const { statusFilterAgente } = useContext(ModalContext);
    const { statusFilterDescricao } = useContext(ModalContext);

    const excluir = async (id) => {
        try {
            await api.delete(`centralDeFalhas/relatorios/${id}`);
            props.cbPesquisar();

            props.cbsetAlertView('none');
            props.cbSetAlertMessage('');
            props.cbSetTypeAlert('');
        } catch (error) {
            props.cbsetAlertView('block');
            props.cbSetAlertMessage(`Erro ao excluir o id:${id}`);
            props.cbSetTypeAlert('error');
        }
    }

    //adiciona um zero na frente dos numeros < 9 para formatar a data
    function adicionaZeroData(numero) {
        if (numero <= 9) {
            return "0" + numero;
        }
        else {
            return numero;
        }
    }
    //faz a formatacao do timestamp recebido
    const converterDataHora = (data) => {

        if (data == undefined) {
            return
        } else {
            const dataHora = new Date(data);
            const dia = adicionaZeroData(dataHora.getDate())
            const mes = adicionaZeroData(dataHora.getMonth() + 1);
            const ano = dataHora.getFullYear();
            const hora = adicionaZeroData(dataHora.getHours());
            const minuto = adicionaZeroData(dataHora.getMinutes());
            const segundos = adicionaZeroData(dataHora.getSeconds());
            const dataHoraFormatada = dia + "/" + mes + "/" + ano + " " + hora + ":" + minuto + ":" + segundos;
            return dataHoraFormatada;
        }
    }

    const openFilterIdentificacao = () => {
        if (filtroIdentificacao) {
            setFiltroIdentificacao(false);
            props.cbPesquisar()

        } else {
            setFiltroIdentificacao(true);
            setFiltroEquipamento(false);
            setFiltroNivel(false);
            setFiltroAcao(false);
            setFiltroAgente(false);
            setFiltroDescricao(false);
        }
    }

    const openFilterEquipamento = () => {
        if (filtroEquipamento) {
            setFiltroEquipamento(false);
            props.cbPesquisar()
        } else {
            setFiltroEquipamento(true);
            setFiltroIdentificacao(false);
            setFiltroNivel(false);
            setFiltroAcao(false);
            setFiltroAgente(false);
            setFiltroDescricao(false);
        }
    }

    const openFilterNivel = () => {
        if (filtroNivel) {
            setFiltroNivel(false);
            props.cbPesquisar()
        } else {
            setFiltroNivel(true);
            setFiltroIdentificacao(false);
            setFiltroEquipamento(false);
            setFiltroAcao(false);
            setFiltroAgente(false);
            setFiltroDescricao(false);
        }
    }

    const openFilterAcao = () => {
        if (filtroAcao) {
            setFiltroAcao(false);
            props.cbPesquisar()
        } else {
            setFiltroAcao(true);
            setFiltroIdentificacao(false);
            setFiltroEquipamento(false);
            setFiltroNivel(false);
            setFiltroAgente(false);
            setFiltroDescricao(false);
        }
    }

    const openFilterAgente = () => {
        if (filtroAcao) {
            setFiltroAgente(false);
            props.cbPesquisar()
        } else {
            setFiltroAcao(false);
            setFiltroIdentificacao(false);
            setFiltroEquipamento(false);
            setFiltroNivel(false);
            setFiltroAgente(true);
            setFiltroDescricao(false);
        }
    }

    const openFilterDescricao = () => {
        if (filtroDescricao) {
            setFiltroDescricao(false);
            props.cbPesquisar()
        } else {
            setFiltroAcao(false);
            setFiltroIdentificacao(false);
            setFiltroEquipamento(false);
            setFiltroNivel(false);
            setFiltroAgente(false);
            setFiltroDescricao(true);
        }
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setFiltroIdentificacao(false);
                setFiltroEquipamento(false);
                setFiltroNivel(false);
                setFiltroAcao(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

    }, []);

    return (
        <Container>
            <Table>
                <thead>
                    <tr>
                        <th>Identificação
                            <Filter onClick={() => openFilterIdentificacao()} status={statusFilterIdentificacao}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroIdentificacao setParamIdent={props.setParamIdent} open={filtroIdentificacao} toggle={openFilterIdentificacao} onClose={setFiltroIdentificacao} dados={props.cbIdentificacao} />
                        </th>
                        <th>Tipo</th>
                        <th>Equipamento
                            <Filter onClick={() => openFilterEquipamento()} status={statusFilterEquipamento}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroEquipamento setParamEquip={props.setParamEquip} open={filtroEquipamento} toggle={openFilterEquipamento} onClose={setFiltroEquipamento} dados={props.cbEquipamento} />
                        </th>
                        <th>Item</th>
                        <th>Agente
                            <Filter onClick={() => openFilterAgente()} status={statusFilterAgente}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroAgente setParamAgente={props.setParamAgente} open={filtroAgente} toggle={openFilterAgente} onClose={setFiltroAgente} dados={props.cbAgente} />
                        </th>
                        <th>Nível
                            <Filter onClick={() => openFilterNivel()} status={statusFilterNivel}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroNivel setParamNivel={props.setParamNivel} open={filtroNivel} toggle={openFilterNivel} onClose={setFiltroNivel} dados={props.cbNivel} />
                        </th>
                        <th>Ação
                            <Filter onClick={() => openFilterAcao()} status={statusFilterAcao}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroAcao setParamAcao={props.setParamAcao} open={filtroAcao} toggle={openFilterAcao} onClose={setFiltroAcao} dados={props.cbAcao} cbPesquisar={props.cbPesquisar} />
                        </th>
                        <th>Descrição
                            <Filter onClick={() => openFilterDescricao()} status={statusFilterDescricao}>
                                <FiFilter size={18} />
                            </Filter>
                            <FiltroDescricao setParamDescricao={props.setParamDescricao} open={filtroDescricao} toggle={openFilterDescricao} onClose={setFiltroDescricao} dados={props.cbDescricao} />
                        </th>
                        <th>Data</th>
                        <th>Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    {props.contentData.dados.map(item => (
                        <tr key={item._id}>
                            <td>{item.identificacao}</td>
                            <td>{item.tipo}</td>
                            <td>{item.equipamento}</td>
                            <td>{item.item}</td>
                            <td>{item.agente}</td>
                            <td>{item.nivel}</td>
                            <td>{item.acao}</td>
                            <td>{item.descricao}</td>
                            <td>{converterDataHora(item.data)}</td>
                            <td><div className={estadoLixeira} onClick={() => excluir(item._id)}><Lixeira /></div></td>
                        </tr>
                    )).reverse()}
                </tbody>
            </Table>
            <AreaLoading status={props.cbViewLoading}>
                <img src={props.cbLoading === true ? loader : ''} alt="" />
            </AreaLoading>
        </Container>
    );
}