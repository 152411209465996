import styled from 'styled-components';

export const Container = styled.section`
    width: 640px;
    height: 460px;
    overflow-y: scroll;
	
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #717171;
		}
	}

	@media(max-width: 580px){
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100vh;
	}
`;