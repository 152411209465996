import React, { useState } from 'react';

import './styles.css';
// função que nomeia o titulo do filtro ( barrinha azul com o nome do filtro)
function DetailNameRoute(props) {
  const [nameTitle, setNameTitle] = useState(localStorage.getItem('valueFilterControllers'));

  if (nameTitle === 'aberta') {
    setNameTitle('Porta aberta')
  } else if (nameTitle === "Subtensao de Rede") {
    setNameTitle('Subtensão de Rede')
  } else if (nameTitle === "Perda de Comunicacao") {
    setNameTitle('Perda de Comunicação')
  } else if (nameTitle === "Subtensao de Rede e Estacionado") {
    setNameTitle('Subtensão de Rede e Estacionado')
  } else if (nameTitle === "Subtensao de Rede, Estacionado e Apagado") {
    setNameTitle('Subtensão de Rede, Estacionado e Apagado')
  } else if (nameTitle === "Servidor Reiniciado,Offline") {
    setNameTitle('Sem comunicação')
  }

  return (
    <h3 className="detail-name-route" id="detail-name-route">{nameTitle}</h3>
  )
}

export default DetailNameRoute;