import styled, { css } from "styled-components";

const dragActive = css`
   border-color: #78e5d5;
 `;

const dragReject = css`
   border-color: #e57878;
 `;

type IDropContainer = {
    isDragActive?: boolean;
    isDragReject?: boolean;
};

interface Props {
    view: string;
    visible: boolean;
}


export const DropContainer = styled.div<IDropContainer>`
  
   transition: height 0.2s ease;
 
   ${(props: any) => props.isDragActive && dragActive};
   ${(props: any) => props.isDragReject && dragReject};

   
    .container{
        .MuiDialogContent-root.content-dialog {
            //width: 100% !important;
        }
        .MuiFormControl-root.makeStyles-formControl-6 {
            width: 96%;
        }
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
            height: 40px;
        }
    }
 `

const messageColors = {
    default: "#999",
    error: "#e57878",
    success: "#78e5d5",
};

interface ITypeMessageColor {
    type?: "default" | "error" | "success";
}

export const UploadMessage = styled.p<ITypeMessageColor>`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const Modal = styled.div`
    //width: 553px;
    max-height: 630px;
    width: 30vw;
    min-width: 500px;
    height: 630px;
    overflow-y: scroll;
    //overflow-y: inherit;
    @media(max-width: 1500px){
        height: 465px; 
    }
    @media(max-width:768px){
        max-height: 100vh !important;
        height: 100vh;
    }
    @media(max-width: 580px){
        height: auto !important;
        max-height: 100% !important;
        min-width: 100%;
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
		width: 8px;
        height: 8px;

		&-track-piece {
			background-color: #FFF;
		}

		&-thumb:vertical,
		&-thumb:horizontal {
			background-color: #001E62;
			border-radius:5px;
		}

		&-thumb:vertical:hover,
		&-thumb:horizontal:hover {
			background-color: #269F2A;
		}
	}

    .MuiDialogContent-root.content-dialog {
        //width: 90%;
        margin: -9px auto;
        height: auto;
        overflow-x: hidden;
    }
`;

type ModalStatus = {
    status: String
};

export const ContentModal = styled.div<ModalStatus>`
    //width: 90%;
    margin: 0 auto;
    display: ${(props: any) => props.status};
    font-family: 'roboto', sans-serif;

    @media(max-width: 580px){
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        padding: 0 5px;
    }

    .alert{
        width: 92%;
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    label{
        display: block;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        color: #A4A4A4;
        padding-bottom: 3px;
        margin: 2px 0;
        @media(max-width: 1500px){
            padding-bottom: 0;
        }
        @media(max-width: 380px){
            font-size: 12px;
        }
    }

    fieldset{
        border: 1px solid #A4A4A4;
        border-radius: 2px;
        margin-bottom: 4px;
        position: relative;

        @media(max-width: 1280px){
            margin-bottom: 5px;
        }

        legend{
            font-size: 13px;
            font-style: normal;
            font-weight: 550;
            line-height: 12px;
            letter-spacing: 0em;
            color: #848484;

            @media(max-width: 380px){
                font-size: 12px;
            }

        }
        input[type="text"] {
            border: 1px solid #A4A4A4;
            height: 29px;
            outline: none;
            border-radius: 2px;
            width: 90%;
            text-indent: 10px;
            width: 99%;

            @media(max-width: 1500px){
                margin-bottom: 2px;
                height: 26px;
            }
            @media(max-width: 1280px){
                margin-bottom: 2px;
                height: 22px;
                font-size: 12px;
            }
            @media(max-width: 580px){
                text-indent: 1px;
                margin-bottom: 2px;
                height: 22px;
                font-size: 12px;
                width: 100%;
            }

            @media(max-width: 380px){
                text-indent: 1px;
                margin-bottom: 2px;
                height: 22px;
                font-size: 11px;
                width: 100%;
            }

            &#licenca{
                width: 44%;
                text-indent: 10px;
            }
        }
    }
`;

export const AreaInsertFolder = styled.div`
    display: flex;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
`;

export const BoxFolder = styled.div`
    width: 45%;
    .box{
        border: 1px solid #A4A4A4;
        width: 100%;
        min-height: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 4vw;
        cursor: pointer;
        border-radius: 4px;
    }

    span{
        color: #54ADFF;
        text-decoration: underline;
        font-size: 9px;
        font-style: normal;
        font-weight: 300;
        line-height: 11px;
        letter-spacing: 0em;
        padding-bottom: 29px;
    }

    img {
        width: 15%;
    }
    label{
        display: block;
    }
`;

export const AreaInputs = styled.div`
    display: flex;
    justify-content: space-between;


    @media(max-width: 420px){
        flex-direction: column;
    }
    
    input[type="number"] {
        width: 90%;
        height: 29px;
        border: 1px solid #A4A4A4;
        outline: none;
        border-radius: 2px;
        text-indent: 10px;
    }
    label{

        @media(max-width: 1280px){
            margin-top: 0;
        }

        @media(max-width: 380px){
            font-size: 11px;
        }
    }

    .groupone{
        min-width: 48%;
        padding-right: 14px;

        .inputTwo{
            margin-top: 4px;
            @media(max-width: 1280px){
                margin-top: 7px;
            }
            @media(max-width: 420px){
                margin-top: 0;
            }
        }

        @media(max-width: 420px){
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    .groupGeral{
        width: 100%;
    }
    
    .grouptwo{
        width: 50%;

        @media(max-width: 420px){
            width: 100%;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .inputTwo{
            margin-top:15px;
        }

        #btnVersao{
            border: 1px solid #001E62;
            border-radius: 12px;
            background: #001E62;
            color: #FFF;
            height: 31px;
            outline: none;
            width: 99%;

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }

    .porta{
        text-transform: capitalize; 
    }
`;

export const InfoHelp = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 2px;
    right: 15px;

    svg path {
        stroke: #001E62;
    }
`;

export const AreaBallon = styled.div<Props>`
    display: ${props => props.visible == true ? 'block' : 'none'};
`;

export const ContentBallon = styled.div`
    .title{
        display: flex;
        align-items: center;
        gap: 28px;
        align-items: center;
        justify-content: center;

        img{
            width: 20px;
            height: 20px;
        }
        h3 {
            font-family: 'Montserrat', sans-serif;
            font-size: 16px;
        }

        label, img{
            cursor: pointer;
        }
    }
   
    span{
        font-family: 'Roboto',sans-serif;
        font-size: 13px;
        display: block;
        width: 98%;
        margin: 0 auto;
        margin-right: 0px;
    }

    .areaButton{
        text-align: end;

        button{
            background: #001E62;
            border: 1px solid #001E62;
            color: #fff;
            transition: .3s;
            font-family: 'roboto';
            font-size: 14px;
            width: 107px;
            height: 25px;
            border-radius: 6px;
            cursor: pointer; 
        
            &:hover{
                border: 1px solid #001E62;
                background: #FFF;
                color: #001E62;
            }

            &:active{
                position: relative;
                top: 1px;
            }
        }
    }
`;

export const AreaControlador = styled.div`

    display: flex;
    flex-direction: column;
    
    @media(max-width: 580px){
        width: 100%;
    }

    .grouponeControlador{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .grouptwoControlador{
        

        .group{
            width: 100%;
            display: flex;
            align-items: center;
        }
    }
    .areaButtons {
        border: 1px solid silver;
        width: 100%;
        height: 30px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        
        span{
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0em;
            margin: 0 10px;
        }

        button{
            cursor: pointer;
            border: 0;
            width: 45px;
            height: 21px;
            margin: 0 15px;
            border-radius: 12px;

            @media(max-width: 580px){
                width: 15%;
                height: 21px;
                margin: 0px 5px;
            }

            &.deactive{
                background: #001E62;
                color: #FFF;
                transition: .5s;
            }

            &.active{
                background: #FFF;
                color: #001E62;
                border: 1px solid #001E62;
                font-weight: 600;
                transition: .5s;
            }

            &:active{
                position: relative;
                top: 1px;
            }

            &:hover{
                background:#FFF;
                color: #001E62;
                border: 1px solid #001E62;
                font-weight: 600;
                transition: .5s;
            }
        }

        #inputPlano{
            width: 75%;
            border: 0;
        }
    }

    .valueFlutuante{
        border: 1px solid red;
    }
`;

export const AreaButtons = styled.div`
    display: flex;
    justify-content: space-evenly;
   
    @media(max-width: 1280px) and (max-height: 496px){
        margin-bottom: 50px;
    }


    .btnEditInfo{
        cursor: pointer;

        img{
            width: 35px;
        }

        &:active{
            position: relative;
            top: 1px;
        }
    }
   
    button{
        border: 0;
        width: 115px;
        height: 25px;
        margin-left: 25px;
        border-radius: 6px;
        background: #001E62;
        color: #FFF;
        cursor: pointer;

        &:active{
            position: relative;
            top: 1px;
        }

        &:hover{
            background: #495174;
        }
    }
`;

export const ViewId = styled.div`
    display: flex;
    flex-direction: column;
`;


