import styled, { keyframes } from 'styled-components';

const slider = keyframes`
    0% { display: none; right: -830px};
    100% { display: block };

`;

export const Overlay = styled.section`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000b8;
    z-index: 0;
    cursor: pointer;
    transition: 1s;
    display: ${props => props.status};
    
    @media(max-width: 414px){
        height: 100%;
    }
`;

export const Modal = styled.section`
    width: 400px;
    height: 250px;
    background: #FFF;
    position: absolute;
    top: 0;
    //left: 0;
    right: ${props => props.slide};
    bottom: 0;
    margin: auto;
    border: 2px solid #DDDDDD;
    border-radius: 8px;
    font-family: sans-serif;
    z-index: 1;
    transition: 0.3s ease-in-out;
    //display: ${props => props.status};

    @media(max-width: 414px){
        display: ${props => props.slideResponsive};
        width: 93%;
        height: 250px;
        background: #FFF;
        position: absolute;
        margin: auto;
        border: 2px solid #DDDDDD;
        border-radius: 8px;
        font-family: sans-serif;
        z-index: 1;
        -webkit-transition: 0.3s ease-in-out;
        transition: 0.3s ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    @media(max-width: 768px){
        right: ${props => props.slide === '-42vw' ? '-70vw' : props.slide === '42vw' ? '23vw' : '' };
    }
`;

export const AreaLogo = styled.div`
    .logoPhor{
        width: 130px;
        margin: 10px auto;
        img{
            width: 100%;
            height: auto;
        }
    }
`;

export const Form = styled.form`
    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;

        input{
            height: 28px;
            outline: none;
            font-size: 14px;
            width: 200px;
            text-indent: 15px;
            border-radius: 4px;
            border: 0;
            border: 1px solid #908e8e;
        }

        button {
            width: 136px;
            height: 32px;
            border-radius: 29px;
            border: 0;
            margin-left: 10px;
            background: #001E62;
            color: #FFF;
            cursor: pointer;

            &:active{
                position:relative;
                top: 2px;
            }
        }
    }
`;

export const Footer = styled.div`
    h6 {
        width: 75%;
        font-size: 12px;
        font-weight: normal;
        margin: 0 auto;
        text-align: center;
        margin-top: 40px;
    }
`;