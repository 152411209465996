import React, { useState, useEffect, useContext, useRef } from 'react';
import { ModalContext } from '../../../../../contextModal';
import { FaSearch } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BoxMenu, Title, AreaInput, Search, CloseFilter, BoxFilter, AreaButtons, Button } from '../styles';


export default function FiltroNivel(props) {

    const [pesquisa, setPesquisa] = useState('');  //guarda resultado da pesquisa
    //const [resultado, setResultado] = useState([]); //guarda resultado do filtro 
    const [selecao, setSelecao] = useState([]); // estados para guardar a seleção de items do filtro
    const [enabled, setEnabled] = useState(true);

    const { setStatusFilterNivel } = useContext(ModalContext);

    const myRef = useRef();

    const getIdentificacao = (e, index) => {

        if (e.target.checked) {
            const values = [...selecao];
            values[index] = e.target.value;
            setSelecao(values);
        } else {
            const values = [...selecao];
            values[index] = '';
            setSelecao(values);
        }
    }

    const filtrarIdentificacao = () => {
        const filtered = selecao.filter(Boolean);
        props.setParamNivel(filtered);
        props.toggle();

        if (filtered.length > 0) {
            setStatusFilterNivel('Dados Filtrados');
        } else {
            setStatusFilterNivel('Filtro Limpo');
        }
    }

    useEffect(() => {
        const results = props.dados.filter(palavra => palavra.toLowerCase().includes(pesquisa));
        if (results === '') {
            //setResultado(['Nenhuma correspondência']);
            setEnabled(false);
        } else {
            //setResultado(results);
            setEnabled(true);
        }
    }, [pesquisa]);

    //fechar caixa de filtro ao clicar fora da caixa
    useEffect(() => {
        const clickFora = (e) => {
            if (!myRef.current.contains(e.target)) {
                props.onClose(false);
            }
        }
        document.addEventListener('mousedown', clickFora);
    }, []);

    return (
        <BoxMenu className='identificacao' ref={myRef} display={props.open}>
            <Title>
                <h2>Filtrar
                    <CloseFilter onClick={() => props.onClose(false)}>
                        <MdClose size="18" />
                    </CloseFilter>
                </h2>
                <span>Selecionar item:</span>
                <AreaInput>
                    <input type="text" value={pesquisa} onChange={(e) => setPesquisa(e.target.value)} />
                    <Search>
                        <FaSearch />
                    </Search>
                </AreaInput>
            </Title>
            <BoxFilter>
                <ul>
                    {props.dados != undefined || props.dados != "" ?
                        props.dados.map((items, index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)} />{items}</li>
                        )) :
                        props.dados.map((items, index) => (
                            <li><input type="checkbox" value={items} name="filtro" onChange={(e) => getIdentificacao(e, index)} />{items}</li>
                        ))
                    }
                </ul>
            </BoxFilter>
            <AreaButtons status={enabled}>
                <Button onClick={filtrarIdentificacao}>Ok</Button>
                <Button onClick={() => props.onClose(false)}>Cancelar</Button>
            </AreaButtons>
        </BoxMenu>
    );
}