import React, { useState, useEffect } from 'react';
import { AreaPhotos, Photo, Settings, AreaLoad, Load, Overlay, ModalZoom } from './styles';
import IconClose from '../icons/IconClose';
import IconZoom from '../icons/IconZoom';
import api from '../../../services/api';
import loadPhor from '../../../assets/img/loadPhor.gif';
import Zoom from '../Zoom';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useParams } from 'react-router-dom';
import { validarPermissao } from '../../../services/permission';

const permissao = (validarPermissao([0, 1, 2]) ? 'enabled' : 'disabled');

export default function Photos(props) {

    const { refAnel } = useParams();
    const [menu, setMenu] = useState('0');
    const [clickedPhoto, setClickedPhoto] = useState('');
    const [statusModal, setStatusModal] = useState('none');
    const [handleOpen, setHandleOpen] = useState({ open: false });
    const matches = useMediaQuery("(max-width:600px)");
    const [maximoItensPagina] = useState(10);
    const [photos, setPhotos] = useState([]);
    const [fotosReorganizadas, setFotosReorganizadas] = useState([])
    const [loading, setLoading] = useState(true);
    const [request, setRequest] = useState('0');
    const [maxPage, setMaxPage] = useState(0);
    const [page, setPage] = useState(1);
    const [dataCalendario, setDataCalendario] = useState(new Date());

    const openMenu = (index) => { setMenu('45'); }
    const closeMenu = (index) => { setMenu('0'); }

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                marginTop: theme.spacing(2),
                marginBottom: '50px',

            },
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        if (props.recarregar) {
            props.cbRecarregar(false);
        }

        lerFotos(props.req, 1, maximoItensPagina, props.data, props.cameraDescricao);
        setDataCalendario(props.data);
        setPage(1);

    }, [props.req, props.recarregar, props.data, props.cbRecarregar, props.cameraDescricao]);

    const lerFotos = async (req, pagina, maxItens, data, descricao) => {
        try {
            const dataFormatada = converterDataRequisicao(data);
            const timezoneOffset = timezoneOffsetDataNavegador(data);

            limparFotos();
            if (descricao === '') {
                const response = await api.get(`/centraldefalhas/imagens/cameras/${refAnel}?tipo=${req}&pagina=${pagina - 1}&itens=${maxItens}&data=${dataFormatada}&timezoneOffset=${timezoneOffset}`);
                setPhotos(response.data.fotos);
                setMaxPage(Math.ceil(response.data.quantidadeTotal / maximoItensPagina));
                setRequest(req);
                setLoading(false);
                setPage(pagina);

            } else {
                const response = await api.get(`centraldefalhas/imagens/cameras/${refAnel}/path?descricao=${descricao}&tipo=${req}&pagina=${pagina - 1}&itens=${maxItens}&data=${dataFormatada}&timezoneOffset=${timezoneOffset}`);
                setPhotos(response.data.fotos);
                setMaxPage(Math.ceil(response.data.quantidadeTotal / maximoItensPagina));
                setRequest(req);
                setLoading(false);
                setPage(pagina);
            }

        } catch (error) {
            limparFotos();
            console.log("----> Deu erro na requisição de carregar fotos");
        }
    }

    const converterDataRequisicao = (data) => {
        const dataOriginal = new Date(data);
        const ano = dataOriginal.getFullYear();
        const mes = dataOriginal.getMonth() + 1;
        const dia = dataOriginal.getDate();
        const novaData = `${ano}-${mes}-${dia}`
        return novaData
    }

    const timezoneOffsetDataNavegador = (data) => {
        const timezoneOffset = new Date(data).getTimezoneOffset() / 60;
        return timezoneOffset;
    }

    const handleClick = (index) => {
        setarPosicaoInicialFotosCarrosel(index);
        setHandleOpen({ open: true });
    }

    const setarPosicaoInicialFotosCarrosel = (index) => {
        let posicoesTemporarias = [];
        let fotosNovasPosicoes = []
        const fotos = photos;

        fotos.forEach((element, indexElement) => {
            if (indexElement < index) {
                posicoesTemporarias.push(element);
            }
            else {
                fotosNovasPosicoes.push(element);
            }
        });

        posicoesTemporarias.forEach(element => {
            fotosNovasPosicoes.push(element)
        });

        setFotosReorganizadas(fotosNovasPosicoes);
    }

    const AutoRotatingCarouselModal = ({ handleOpen, setHandleOpen, isMobile, req }) => {

        const info = (infoImg) => `${convertDate(infoImg.timestamp)},  Origem da captura: ${req === '0' ? 'Automática' : req === '1' ? `Falha: ${infoImg.descricaoFalha}` : 'Manual'}`

        return (
            <div>
                <AutoRotatingCarousel
                    label="Fechar"
                    open={handleOpen.open}
                    onClose={() => setHandleOpen({ open: false })}
                    onStart={() => setHandleOpen({ open: false })}
                    autoplay={false}
                    mobile={isMobile}
                    style={{ position: "absolute" }}
                >
                    {fotosReorganizadas.map((item, index) => {
                        return (
                            <Slide key={index}
                                media={
                                    <img src={item.url} width="100%" />

                                }
                                mediaBackgroundStyle={{ backgroundColor: '#001E62' }}
                                style={{ backgroundColor: '#001E62' }}
                                title={item.refAnel}
                                subtitle={info(item)}
                            />
                        );
                    })}
                </AutoRotatingCarousel>
            </div>
        );
    };

    const convertDate = (timestamp) => {

        var formatarTime = timestamp.split(".", [2])    // 2022-04-28T00:46:26.057Z  == 2022-04-28T00:46:26
        let date = new Date(formatarTime[0]);

        let dayName = new Array("Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado")
        let monName = new Array("Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro")

        //armazena somente a hora, pois se a data que foi recebida for estanciada o valor da hora fica errado.
        let teste = timestamp.split("T", [2])
        let teste2 = teste[1].split(":", [3])

        let hora = teste2[0].toString()
        let minutos = date.getMinutes().toString()
        let segundos = date.getSeconds().toString()

        if (hora.length == 1) {
            hora = "0" + hora
        }
        if (minutos.length == 1) {
            minutos = "0" + minutos
        }

        if (segundos.length == 1) {
            segundos = "0" + segundos
        }

        let dateFormated = dayName[date.getDay()] + ", " + date.getDate() + " de " + monName[date.getMonth()] + " de " + date.getFullYear() + " " + hora + ":" + minutos + ":" + segundos;

        return dateFormated;
    }

    const removePhoto = async (index) => {
        try {
            const response = await api.delete(`/centraldefalhas/imagens/cameras/${photos[index]._id}`);
            const values = [...photos];
            values.splice(index, 1);
            setPhotos(values);
            props.cbRecarregar(true);

        } catch (error) {
            props.cbRecarregar(true);//temporário até arrumar requisição
        }
    }

    const limparFotos = () => {
        setPhotos([]);
        setFotosReorganizadas([]);
    }

    return (
        <AreaPhotos>
            <AreaLoad>
                <Load src={loading == true ? loadPhor : ''} alt="" />
            </AreaLoad>
            {photos.map((item, index) => {
                return (
                    <Settings onMouseOver={() => openMenu()} onMouseOut={() => closeMenu()} status={menu} key={item._id}>
                        <div className="menu">
                            <span>{convertDate(item.timestamp)}</span>
                            <div className="options">
                                <div className="optionZoom" onClick={() => { setClickedPhoto(item.url); setStatusModal('block') }}>
                                    <IconZoom />
                                </div>
                                <div className="optionClose" id={permissao} onClick={() => removePhoto(index)} >
                                    <IconClose />
                                </div>
                            </div>
                        </div>
                        <Photo src={item.url} alt="944 x 533" onClick={() => handleClick(index)} />
                    </Settings>
                );
            })}
            <AutoRotatingCarouselModal
                isMobile={matches}
                handleOpen={handleOpen}
                setHandleOpen={setHandleOpen}
                req={request}
            />
            <Overlay open={statusModal} onClick={() => setStatusModal('none')}></Overlay>
            <ModalZoom open={statusModal}>
                <Zoom img={clickedPhoto} />
            </ModalZoom>
            <div className={classes.root}>
                <Pagination count={maxPage} color="primary" page={page} onChange={function (event, page) { lerFotos(props.req, page, maximoItensPagina, dataCalendario, props.cameraDescricao) }} />
            </div>
        </AreaPhotos>
    );
}
