import React, { useState, useContext, useEffect } from 'react';
import { ModalContext } from '../../contextModal';
import MenuBlank from '../../components/MenuBlank';
import { Container, AreaMenu, ColButtons, AreaInput, Tabs, AreaFilterDate, AreaTabela, AreaButton, Export, AreaPagination } from './styles';
import Alert from '@material-ui/lab/Alert';
import Report from './Report';
import Pagination from '@material-ui/lab/Pagination';
import IconRelatorio from '../../assets/icons/IconRelatorio';
import FileDownload from 'js-file-download';
import api from '../../services/api';

var acao = [];
var identificacao = [];
var equipamento = [];
var nivel = [];
var agente = [];
var descricao = [];

export default function Relatorios(props) {

    const [positionAba, setPositionAba] = useState('80');
    const [geral, setGeral] = useState('inactive');
    const [cadastro, setCadastro] = useState('inactive');
    const [operacao, setOperacao] = useState('inactive');
    const [alarme, setAlarme] = useState('inactive');
    const [usuario, setUsuario] = useState('inactive');
    const [typeReq, setTypeReq] = useState('geral');
    const [dataInicial, setDataInicial] = useState(new Date());
    const [dataFinal, setDataFinal] = useState('');
    const [paramIdent, setParamIdent] = useState([]); //states armazenamento onde estão os valores dos filtros, passados por props
    const [paramEquip, setParamEquip] = useState([]);
    const [paramNivel, setParamNivel] = useState([]);
    const [paramAcao, setParamAcao] = useState([]);
    const [paramAgente, setParamAgente] = useState([]);
    const [paramDescricao, setParamDescricao] = useState([]);

    const { contentData, setContentData } = useContext(ModalContext);

    const [alertView, setAlertView] = useState('none');
    const [alertMessage, setAlertMessage] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0);
    const [maximoItensPagina, setMaximoItensPagina] = useState(100);
    const [loading, setLoading] = useState(false);
    const [viewLoading, setViewLoading] = useState('none');
    //const [identificacao, setIdentificacao ] = useState([]);
    //const [equipamento, setEquipamento ] = useState([]);
    //const [nivel, setNivel ] = useState([]);
    //const [acao, setAcao ] = useState([]);

    const Position1 = () => {
        setPositionAba('80');
        setGeral('active');
        setCadastro('inactive');
        setOperacao('inactive');
        setAlarme('inactive');
        setUsuario('inactive');
        setTypeReq('geral');
        setPage(1);

        if ([dataInicial, dataFinal].includes('')) {
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });

            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
        } else {
            lerDados('geral', 1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }

    const Position2 = () => {
        setPositionAba('200');
        setCadastro('active');
        setGeral('inactive');
        setOperacao('inactive');
        setAlarme('inactive');
        setUsuario('inactive');
        setTypeReq('cadastro');
        setPage(1);

        if ([dataInicial, dataFinal].includes('')) {
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });
            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
        } else {
            lerDados('cadastro', 1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }
    const Position3 = () => {
        setPositionAba('322');
        setOperacao('active');
        setGeral('inactive');
        setCadastro('inactive');
        setAlarme('inactive');
        setUsuario('inactive');
        setTypeReq('operacao');
        setPage(1);

        if ([dataInicial, dataFinal].includes('')) {
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });
            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
        } else {
            lerDados('operacao', 1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }
    const Position4 = () => {
        setPositionAba('442');
        setAlarme('active');
        setGeral('inactive');
        setCadastro('inactive');
        setOperacao('inactive');
        setUsuario('inactive');
        setTypeReq('alarme');
        setPage(1);
        if ([dataInicial, dataFinal].includes('')) {
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });
            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
        } else {
            lerDados('alarme', 1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }

    const Position5 = () => {
        setPositionAba('562');
        setUsuario('active');
        setAlarme('inactive');
        setGeral('inactive');
        setCadastro('inactive');
        setOperacao('inactive');
        setTypeReq('usuario');
        setPage(1);
        if ([dataInicial, dataFinal].includes('')) {
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });
            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
        } else {
            lerDados('usuario', 1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }

    const lerDados = async (tipoRelatorio, pagina) => {
        console.log('chamou', paramDescricao);
        setViewLoading('flex');
        setLoading(true);

        const response = await api.get(`CentralDeFalhas/relatorios?tipo=${tipoRelatorio}&dataMin=${dataInicial}&dataMax=${dataFinal}&pagina=${pagina}&itens=${maximoItensPagina}&identificacao=${paramIdent}&equipamento=${paramEquip}&nivel=${paramNivel}&acao=${paramAcao}&agente=${paramAgente}&descricao=${paramDescricao}`);
        setMaxPage(Math.ceil(response.data.quantidadeTotal / maximoItensPagina));
        setLoading(false);
        setViewLoading('none');

        if (response.data.quantidadeTotal == 0) {
            setAlertView('block');
            setAlertMessage('Nada para ser exibido com os parâmetros pesquisados!');
            setTypeAlert('error');
            setContentData({ dados: [{ identificacao: 'Nada para ser exibido com os parâmetros pesquisados!' }] });
        } else {
            setContentData(response.data);

            acao = [];
            identificacao = [];
            nivel = [];
            equipamento = [];
            agente = [];
            descricao = [];

            console.log(response.data)

            response.data.acao.forEach(element => {
                acao.push(element)
            });
            response.data.identificacao.forEach(element => {
                identificacao.push(element)
            });
            response.data.nivel.forEach(element => {
                nivel.push(element)
            });
            response.data.equipamento.forEach(element => {
                equipamento.push(element)
            });
            response.data.agente.forEach(element => {
                agente.push(element)
            });
            response.data.descricao.forEach(element => {
                descricao.push(element)
            });
        }
    }

    const pesquisar = () => {
        if ([dataInicial, dataFinal].includes('')) {
            setAlertView('block');
            setAlertMessage('Preencher data inicial e data final');
            setTypeAlert('error');

        } else {
            lerDados(typeReq, 1);
            setPage(1);
            setAlertView('none');
            setAlertMessage('');
            setTypeAlert('');
        }
    }

    const handleChange = (event, value) => {
        setPage(value);
        lerDados(typeReq, value);
    };

    useEffect(() => {

        //pegar dia de hoje
        var data = new Date();
        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        var horas = String(data.getHours()).padStart(2, '0');
        var minutos = String(data.getMinutes()).padStart(2, '0');
        var dataAtual = ano + '-' + mes + '-' + dia + 'T00:00';

        // pegar o dia de amanhã
        var amanha = new Date((new Date()).valueOf() + 1000 * 3600 * 24);
        var diaAmanha = String(amanha.getDate()).padStart(2, '0');
        var mesAmanha = String(amanha.getMonth() + 1).padStart(2, '0');
        var anoAmanha = data.getFullYear();
        var dataAmanha = anoAmanha + '-' + mesAmanha + '-' + diaAmanha + 'T00:00';

        setDataInicial(dataAtual);
        setDataFinal(dataAmanha);
    }, []);

    useEffect(() => {
        pesquisar();
        lerDados(typeReq, 1);
    }, [dataInicial]);

    useEffect(() => {
        pesquisar();
    }, [paramIdent, paramEquip, paramNivel, paramAcao, paramDescricao, paramAgente]);

    const exportExcel = async () => {
        const fileExcel = await api.get(`/centraldefalhas/xls/relatorio?tipo=${typeReq}&dataMin=${dataInicial}&dataMax=${dataFinal}&identificacao=${paramIdent}&equipamento=${paramEquip}&nivel=${paramNivel}&acao=${paramAcao}&agente=${paramAgente}&descricao=${paramDescricao}`, { responseType: 'blob' });
        FileDownload(fileExcel.data, `Phor.xlsx`);
    }

    return (
        <Container view={alertView}>
            <MenuBlank />
            <h1>Relatório</h1>
            <AreaMenu>
                <Tabs position={positionAba}>
                    <ul>
                        <li onClick={Position1} id={geral}>Geral</li>
                        <li onClick={Position2} id={cadastro}>Cadastro</li>
                        <li onClick={Position3} id={operacao}>Operação</li>
                        <li onClick={Position4} id={alarme}>Alarme</li>
                        <li onClick={Position5} id={usuario}>Usuário</li>
                    </ul>
                </Tabs>
                <AreaFilterDate>
                    <fieldset>
                        <legend>Filtro por intervalo de data</legend>
                        <div className="borderInput">
                            <label htmlFor="">Data inicial</label>
                            <input type="datetime-local" value={dataInicial} onChange={(e) => setDataInicial(e.target.value)} />
                        </div>
                        <div className="borderInput">
                            <label htmlFor="">Data final</label>
                            <input type="datetime-local" value={dataFinal} onChange={(e) => setDataFinal(e.target.value)} />
                        </div>
                    </fieldset>
                    <ColButtons>
                        <fieldset className="numberLines">
                            <legend>Nº linhas/Pág</legend>
                            <div className="borderInput">
                                <input type="number" id="number" value={maximoItensPagina} onChange={(e) => setMaximoItensPagina(e.target.value)} />
                            </div>
                        </fieldset>
                        <AreaButton>
                            <button onClick={pesquisar}>Pesquisar</button>
                            <Export title="Exportar para Excel" onClick={exportExcel}><IconRelatorio /></Export>
                        </AreaButton>
                    </ColButtons>
                </AreaFilterDate>
            </AreaMenu>
            <div className="alert">
                <Alert severity={typeAlert}>{alertMessage}</Alert>
            </div>
            <AreaTabela>
                <Report contentData={contentData} cbPesquisar={pesquisar} cbsetAlertView={setAlertView}
                    cbSetAlertMessage={setAlertMessage} cbSetTypeAlert={setTypeAlert} cbLoading={loading}
                    cbViewLoading={viewLoading} cbSetViewLoading={setViewLoading} cbIdentificacao={identificacao}
                    cbEquipamento={equipamento} cbNivel={nivel} cbAcao={acao} cbAgente={agente} cbDescricao={descricao} setParamIdent={setParamIdent}
                    setParamEquip={setParamEquip} setParamNivel={setParamNivel} setParamAcao={setParamAcao}
                    setParamAgente={setParamAgente} setParamDescricao={setParamDescricao}
                />
            </AreaTabela>
            <AreaPagination>
                <Pagination count={maxPage} color="primary" page={page} onChange={handleChange} />
            </AreaPagination>
        </Container>
    );
}