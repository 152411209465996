import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PageTitle from '../../PageTitle';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import {
    Modal, ContentModal, Close, AreaLicensa, AreaInsertFolder, BoxFolder,
    AreaInputs, AreaButtons, AreaDados, AreaTabs, Tabs, ContentLicenca, Button
} from './styles';
import addFolder from '../../../assets/addFolder.png';
import BtnNovo from '../RegisterControllers/Icons/BtnNovo';
import './style.css';
import InputMask from 'react-input-mask';
import Licenca from './Abas/Licenca';
import { DropContainer, AreaButtonAdd, AreaMultiplyFields } from "./styles.ts";
import api from '../../../services/api';
import Alert from '@material-ui/lab/Alert';
import { GrClose } from "react-icons/gr";
import BtnAdd from "../RegisterControllers/Icons/BtnAdd";
import IconRemove from '../RegisterControllers/Icons/IconRemove';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles((theme) => ({

    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

    root: {
        width: '100%',
    },
    container: {
        maxHeight: 430,
        maxWidth: 520,
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
    },
    section: {
        background: '#e4e0e0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
    }
}));

const thumbsContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: '87%',
    height: '87%',
    padding: 4,
    boxSizing: 'border-box',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    width: '100%',
    height: 'auto'
};


function RegisterLicenca(props) {

    //const classes = useStyles();
    const [age, setAge] = useState('');
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const formData = new FormData();
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [nomeLicenca, setNomeLicenca] = useState('');
    const [logotipo, setLogoTipo] = useState('');
    const [maxUsuarios, setMaxUsuarios] = useState('');
    const [maxEquipamentos, setMaxEquipamentos] = useState('');
    const [data, setData] = useState('');
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [positionAba, setPositionAba] = useState('80');
    const [abaUsuario, setAbaUsuario] = useState('block')
    const [abaLicensa, setAbaLicensa] = useState('none');
    const [cadastro, setCadastro] = useState('active');
    const [licenca, setLicenca] = useState('inactive');
    const [view, setView] = useState('disabled');
    const [msg, setMsg] = useState('');
    const [alertMsg, setAlertMsg] = useState('none');
    const [typeAlert, setTypeAlert] = useState('');
    const [dados, setDados] = useState([]);
    const [editLicenca, setEditLicenca] = useState(true);
    const [modoEdicao, setModoEdicao] = useState(false);
    const [dominios, setDominios] = useState([]);
    const [listTownhall, setListTownhall] = useState([]);
    const [licencaEdicao, setLicencaEdicao] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                />
            </div>
        </div>
    ));

    const handleUploadFile = async (e) => {

        let type;
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        const dominiosStringify = JSON.stringify(dominios);

        files.map(file => {
            type = file.type.split('/');
            type = type[1];
            formData.append('logo', file);
            formData.append('townhall', nomeLicenca);
            formData.append('dominio', dominiosStringify);
            formData.append('max_usuarios', maxUsuarios);
            formData.append('max_equipamentos', maxEquipamentos);
            formData.append('validade', data);
            formData.append('responsavel', nome);
            formData.append('telefone', telefone);
            formData.append('email', email);
        })

        if ([nomeLicenca, maxUsuarios, maxEquipamentos, data, nome, telefone, email].includes('')) {
            setMsg('Por favor preencher todos os campos!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return;
        }

        // Verifica se a data de expiração está fazia
        if ([data].includes('')) {
            setMsg('Por favor preencher data de expiração!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }
        // Verifica se o email contem @
        if (!(lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2)) {
            setMsg('Por favor preencher um e-mail válido!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }

        // Verificação max e min de usuários
        if (maxUsuarios <= 0 || maxUsuarios > 1000) {
            setMsg('máx. de usuário deve ser entre 0 á 1000!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }

        if (maxEquipamentos <= 0 || maxEquipamentos > 1000) {
            setMsg('máx. de equipamentos deve ser entre 0 á 1000!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }

        if (type === 'png' || type === 'jpeg' || type === 'jpg') {
            await api.post("/centraldefalhas/licenca", formData, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                }
            }).then(res => {
                if (res.data.mensagem == 'Licença criada com sucesso') {
                    setMsg(res.data.mensagem);
                    setAlertMsg('block');
                    setTypeAlert('success');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 3000);
                    setFiles([]);
                    setNomeLicenca('');
                    setDominios([]);
                    setMaxUsuarios('');
                    setMaxEquipamentos('');
                    setData('');
                    setNome('');
                    setTelefone('');
                    setEmail('');
                }
                else {
                    setMsg(res.data.mensagem);
                    setAlertMsg('block');
                    setTypeAlert('error');
                    setTimeout(() => {
                        setAlertMsg('none');
                    }, 3000);
                    setFiles([])
                }

            }).catch(error => {
                setAlertMsg('block');
                setTypeAlert('error');
                setTimeout(() => {
                    setAlertMsg('none');
                }, 3000);
            });

        } else {
            setMsg('Formato do aquivo invalido');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            setFiles([]);
        }
    }

    const EditUploadFile = async () => {
        let type;
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        files.map(file => {
            type = file.type.split('/');
            type = type[1];
            console.log(type);
            formData.append('logo', file);
            formData.append('townhall', nomeLicenca);
            formData.append('dominio', dominios);
            formData.append('max_usuarios', maxUsuarios);
            formData.append('max_equipamentos', maxEquipamentos);
            formData.append('validade', data);
            formData.append('responsavel', nome);
            formData.append('telefone', telefone);
            formData.append('email', email);
        })


        const dados = {
            'fileName': '',
            'townhall': nomeLicenca,
            'dominio': dominios,
            'max_usuarios': maxUsuarios,
            'max_equipamentos': maxEquipamentos,
            'validade': data,
            'responsavel': nome,
            'telefone': telefone,
            'email': email
        }

        if ([nomeLicenca, maxUsuarios, maxEquipamentos, data, nome, telefone, email].includes('')) {
            setMsg('Por favor preencher todos os campos!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return;
        }

        // Verifica se a data de expiração está fazia
        if ([data].includes('')) {
            setMsg('Por favor preencher data de expiração!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }
        // Verifica se o email contem @
        if (!(lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") == -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2)) {
            setMsg('Por favor preencher um e-mail válido!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }

        // Verificação max e min de usuários
        if (maxUsuarios <= 0 || maxUsuarios > 1000) {
            setMsg('máx. de usuário deve ser entre 0 á 1000!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }

        if (maxEquipamentos <= 0 || maxEquipamentos > 1000) {
            setMsg('máx. de equipamentos deve ser entre 0 á 1000!');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            return
        }



        if (type === 'png' || type === 'jpeg' || type === 'jpg' || type === undefined) {

            if (files.length == 0) {
                await api.put('/centraldefalhas/licenca/update', dados)
                    .then(res => {
                        if (res.data.mensagem === 'Licença criada com sucesso') {
                            setMsg(res.data.mensagem);
                            setAlertMsg('block');
                            setTypeAlert('success');
                            setTimeout(() => {
                                setAlertMsg('none');
                            }, 3000);
                            setFiles([]);
                            setDominios([]);
                            setLogoTipo('');
                            setNomeLicenca('');
                            setMaxUsuarios('');
                            setMaxEquipamentos('');
                            setData('');
                            setNome('');
                            setTelefone('');
                            setEmail('');
                        }
                        else {
                            setMsg(res.data.mensagem);
                            setAlertMsg('block');
                            setTypeAlert('error');
                            setTimeout(() => {
                                setAlertMsg('none');
                            }, 3000);
                            setFiles([])
                        }

                    }).catch(error => {
                        setMsg(error.data.mensagem);
                        setAlertMsg('block');
                        setTypeAlert('error');
                        setTimeout(() => {
                            setAlertMsg('none');
                        }, 3000);
                    });
            } else {
                await api.put("/centraldefalhas/licenca/update", formData, {
                    headers: {
                        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                    }
                })
                    .then(res => {
                        if (res.data.mensagem === 'Licença criada com sucesso') {
                            setMsg(res.data.mensagem);
                            setAlertMsg('block');
                            setTypeAlert('success');
                            setTimeout(() => {
                                setAlertMsg('none');
                            }, 3000);
                            setFiles([]);
                            setNomeLicenca('');
                            setMaxUsuarios('');
                            setMaxEquipamentos('');
                            setData('');
                            setNome('');
                            setTelefone('');
                            setEmail('');
                        }
                        else {
                            setMsg(res.data.mensagem);
                            setAlertMsg('block');
                            setTypeAlert('error');
                            setTimeout(() => {
                                setAlertMsg('none');
                            }, 3000);
                            setFiles([])
                        }

                    }).catch(error => {
                        setMsg('Erro ao editar licença');
                        setAlertMsg('block');
                        setTypeAlert('error');
                        setTimeout(() => {
                            setAlertMsg('none');
                        }, 3000);
                    });
            }
        } else {
            setMsg('Formato do aquivo invalido');
            setAlertMsg('block');
            setTypeAlert('error');
            setTimeout(() => {
                setAlertMsg('none');
            }, 3000);
            setFiles([]);
        }
    }

    useEffect(() => () => {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const Position1 = () => {
        setPositionAba('80');
        setCadastro('active');
        setLicenca('inactive');
        setAbaUsuario('block');
        setAbaLicensa('none');
    }
    const Position2 = () => {
        setPositionAba('229');
        setLicenca('active');
        setCadastro('inactive');
        setAbaUsuario('none');
        setAbaLicensa('block');
    }

    const enabledBox = () => {
        if (view === 'disabled') {
            setView('enabled');
        }
    }

    const activeArea = () => {
        if (view === 'disabled') {
            setView('enabled');
            if (modoEdicao === true) {
                setModoEdicao(false);
            }
        } else {
            /*setNomeLicenca([]);
            setMaxUsuarios([]);
            setMaxEquipamentos([]);
            setNome([]);
            setTelefone([]);
            setEmail([]);
            setLogoTipo('');
            setData('');*/
            if (modoEdicao === true) {
                setModoEdicao(false);
            }

        }
    }

    const clearResponse = () => {
        setNomeLicenca([]);
        setMaxUsuarios([]);
        setMaxEquipamentos([]);
        setNome([]);
        setTelefone([]);
        setEmail([]);
        setData('');
        setView('enabled');
        setFiles([]);

    }

    const lerDados = async () => {
        const response = await api.get('/centraldefalhas/licenca/list');
        setDados(response.data);
        console.log('get', response.data)
    }

    const getItemList = async (value) => {
        await api.get('/centraldefalhas/licenca/list')
            .then(res => {
                res.data.forEach(licencas => {
                    if (licencas.townhall === value) {
                        console.log(licencas)
                        setNomeLicenca(licencas.townhall);
                        setMaxUsuarios(licencas.max_usuarios);
                        setDominios(licencas.dominio);
                        setMaxEquipamentos(licencas.max_equipamentos);
                        setNome(licencas.responsavel);
                        setTelefone(licencas.telefone);
                        setEmail(licencas.email);
                        setLogoTipo(licencas.logo);

                        //conversão de numero inteiro timestamp para data americana (mas exibido no formato PTbr)
                        const dataHora = new Date(licencas.validade * 1000);
                        const dia = dataHora.getUTCDate();
                        const mes = (dataHora.getUTCMonth() + 1).toString().padStart(2, 0);
                        const ano = dataHora.getUTCFullYear();
                        const dataHoraFormatada = ano + '-' + mes + '-' + dia;
                        setData(dataHoraFormatada);
                    }
                })
                activeArea();
                setModoEdicao(true);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const editDados = async (index) => {
        const response = await api.get('/centraldefalhas/licenca/list');
        setDados(response.data);
        setNomeLicenca(response.data[index].townhall);
        setMaxUsuarios(response.data[index].max_usuarios);
        setDominios(response.data[index].dominio);
        setMaxEquipamentos(response.data[index].max_equipamentos);
        setNome(response.data[index].responsavel);
        setTelefone(response.data[index].telefone);
        setEmail(response.data[index].email);
        setLogoTipo(response.data[index].logo);
        setLicencaEdicao(response.data[index].townhall);


        //conversão de numero inteiro timestamp para data americana (mas exibido no formato PTbr)
        const dataHora = new Date(response.data[index].validade * 1000);
        const dia = dataHora.getUTCDate();
        const mes = (dataHora.getUTCMonth() + 1).toString().padStart(2, 0);
        const ano = dataHora.getUTCFullYear();
        const dataHoraFormatada = ano + '-' + mes + '-' + dia;
        setData(dataHoraFormatada);
        setFiles([]);
        setModoEdicao(true);

    }

    const loadData = () => {
        activeArea();
    }

    const callData = (index) => {
        setNomeLicenca(dados[index].townhall);
        setDominios(dados[index].dominio);
        setLogoTipo(dados[index].logo);
        setMaxUsuarios(dados[index].max_usuarios);
        setMaxEquipamentos(dados[index].max_equipamentos);
        setNome(dados[index].responsavel);
        setTelefone(dados[index].telefone);
        setEmail(dados[index].email);

        //conversão de numero inteiro timestamp para data americana (mas exibido no formato PTbr)
        const dataHora = new Date(dados[index].validade * 1000);
        const dia = dataHora.getUTCDate();
        const mes = (dataHora.getUTCMonth() + 1).toString().padStart(2, 0);
        const ano = dataHora.getUTCFullYear();
        const dataHoraFormatada = ano + '-' + mes + '-' + dia;
        setData(dataHoraFormatada);

    }

    const handleAddField = () => {
        setDominios((oldInputs) => [...oldInputs, '']);
    }

    const handleAddLicenca = (e, index) => {
        const values = [...dominios];
        if (e.target.name === 'licenca') {
            values[index] = e.target.value;
        }
        setDominios(values);
        console.log('values', values)
    }

    const handleDeleteLicenca = (e, index) => {
        const values = [...dominios];
        values.splice(index, 1);
        setDominios(values);
    }

    const HandlelistTownhall = async () => {
        setLicencaEdicao('');
        const list = [];
        await api.get('/centraldefalhas/licenca/list')
            .then(res => {
                res.data.forEach((item) => {
                    list.push(item.townhall)
                });
            })
            .catch(err => console.log(err))
        setListTownhall(list)

        console.log('list', list)
    }


    return (
        <Dialog
            open={props.open}
            className="Dialog modalLicenca"
            onClose={props.onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            hideBackdrop={true}
            style={{ height: '800px' }}
        >
            <PageTitle
                id="draggable-dialog-title"
                text="Licença"
                onClick={props.onClose}
            ></PageTitle>
            <Modal>
                <Close onClick={() => props.onClose()}><GrClose size={14} /></Close>
                <DialogContent className='content-dialog'>
                    <AreaTabs>
                        <Tabs position={positionAba}>
                            <ul>
                                <li onClick={() => { Position1(); clearResponse() }} id={cadastro}>Cadastro</li>
                                <li onClick={Position2} id={licenca}>Licenças</li>
                            </ul>
                        </Tabs>
                    </AreaTabs>
                    <DropContainer>
                        <ContentModal visible={abaUsuario}>
                            <fieldset className="idLocal">
                                <legend>Licença</legend>
                                <select onClick={() => HandlelistTownhall()} onChange={(e) => getItemList(e.target.value)}>
                                    {
                                        licencaEdicao === '' ?
                                            listTownhall.sort((itemA, itemB) => itemA > itemB ? 1 : -1).map((townhalls, index) => (
                                                <option value={townhalls} key={index}>{townhalls}</option>
                                            ))
                                            :
                                            <option value="">{licencaEdicao}</option>
                                    }
                                </select>
                                <Button className="btnNovo" onClick={activeArea}>
                                    <BtnNovo />NOVO
                                </Button>
                            </fieldset>
                            <AreaLicensa className={view}>
                                <fieldset>
                                    <legend>Dados</legend>
                                    <AreaDados>
                                        <div className="areaInputDados">
                                            <label htmlFor="">Nome da Licença:</label>
                                            <input type="text" id="licenca" value={nomeLicenca} onChange={(e) => setNomeLicenca(e.target.value)} required />
                                            <AreaButtonAdd>
                                                <span>Adicionar licenças</span>
                                                <div className='button' onClick={handleAddField}><BtnAdd /></div>
                                            </AreaButtonAdd>
                                            {
                                                dominios.length !== 0 ?
                                                    <AreaMultiplyFields>
                                                        {dominios.map((item, index) => (
                                                            <div className='fields' key={index}>
                                                                <label htmlFor="">Dominios:</label>
                                                                <div className='areaInputs'>
                                                                    <select name='licenca' id={item + index} onClick={() => HandlelistTownhall()} onChange={(e) => handleAddLicenca(e, index)}>
                                                                        <option value={item}>{item}</option>
                                                                        {listTownhall.sort((itemA, itemB) => itemA > itemB ? 1 : -1).map((townhalls, index) => (
                                                                            <option value={townhalls} key={index}>{townhalls}</option>
                                                                        ))}
                                                                    </select>

                                                                    <div className='btnRemove' onClick={(e) => handleDeleteLicenca(e, index)}><IconRemove /></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </AreaMultiplyFields>
                                                    : null
                                            }
                                        </div>
                                        <AreaInsertFolder>
                                            <BoxFolder>
                                                <label htmlFor="">Logotipo:</label>
                                                <div className="box" onClick={() => setLogoTipo('')}>
                                                    <div {...getRootProps({ className: 'dropzone' })}>
                                                        <input {...getInputProps()} />
                                                        <img src={
                                                            logotipo === '' ? addFolder : logotipo
                                                        } alt="" />{/* ip temp para desenvolvimento*/}
                                                    </div>
                                                    <aside style={thumbsContainer} onClick={() => { setFiles([]); setLogoTipo('') }}>
                                                        {thumbs}
                                                    </aside>
                                                </div>
                                            </BoxFolder>
                                        </AreaInsertFolder>
                                    </AreaDados>
                                </fieldset>
                                <fieldset>
                                    <legend>Parâmetros</legend>
                                    <AreaInputs>
                                        <div className='groupone'>
                                            <div>
                                                <label htmlFor="">Máximo de usuários:</label>
                                                <input type="number" min="10" max="1000" value={maxUsuarios} onChange={(e) => setMaxUsuarios(e.target.value)} required />
                                            </div>
                                            <div className="inputTwo">
                                                <label htmlFor="">Máximo de equipamentos:</label>
                                                <input type="number" min="10" max="1000" value={maxEquipamentos} onChange={(e) => setMaxEquipamentos(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className='grouptwo'>
                                            <div>
                                                <label htmlFor="">Data de expiração:</label>
                                                <input type="date" value={data} onChange={(e) => setData(e.target.value)} required />
                                            </div>
                                        </div>
                                    </AreaInputs>
                                </fieldset>
                                <fieldset>
                                    <legend>Responsável</legend>
                                    <AreaInputs>
                                        <div className='groupone'>
                                            <div>
                                                <label htmlFor="">Nome:</label>
                                                <input type="text" min="10" max="100" value={nome} onChange={(e) => setNome(e.target.value)} required />
                                            </div>
                                            <div className="inputTwo">
                                                <label htmlFor=""> E-mail:</label>
                                                <input type="email" min="10" max="100" value={email} onChange={(e) => setEmail(e.target.value)} required />
                                            </div>
                                        </div>
                                        <div className='grouptwo'>
                                            <div>
                                                <label htmlFor="">Telefone:</label>
                                                <InputMask type="tel" name="telefone" id="telefone" placeholder="Telefone:" mask="(99) 99999-9999" value={telefone} onChange={(e) => setTelefone(e.target.value)} required />
                                            </div>
                                        </div>
                                    </AreaInputs>
                                </fieldset>
                                <AreaButtons view={alertMsg}>
                                    <div className="alert">
                                        <Alert severity={typeAlert}>{msg}</Alert>
                                    </div>
                                    <Button
                                        title="Editar"
                                        type="submit"
                                        onClick={EditUploadFile}
                                        update={modoEdicao}
                                        id="editar"
                                    >Atualizar
                                    </Button>
                                    <Button
                                        title="Salvar"
                                        type="submit"
                                        onClick={handleUploadFile}
                                        update={modoEdicao}
                                        id="salvar"
                                    >Salvar
                                    </Button>
                                </AreaButtons>
                            </AreaLicensa>
                        </ContentModal>
                        <ContentLicenca visible={abaLicensa}>
                            <Licenca cbAbaLicensa={abaLicensa} editDados={editDados} position1={Position1} enabledBox={enabledBox} setLicencaEdicao={setLicencaEdicao} />
                        </ContentLicenca>
                    </DropContainer>
                </DialogContent>
            </Modal>
            <DialogActions>

            </DialogActions>
            {/* </Paper> */}
        </Dialog>
    );
}


export default RegisterLicenca;