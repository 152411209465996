import React, { useState, useEffect, useContext } from 'react';
import { ModalContext } from '../../../../contextModal';
import { Container, TopContainer, AreaInputs, AreaButton, Button, AreaButtons, InfoHelp, AreaBallon, ContentBallon } from './styles';
import IconbtnLogs from '../../../../assets/btnLogs.svg';
import editInfo from '../../../../assets/editInfo.svg';
import api from '../../../../services/api';
import Alert from '@material-ui/lab/Alert';
import { GrCircleQuestion } from "react-icons/gr";
import { BallonsInfoModal } from '../../../Balloons';
import icoInfo from '../../../../assets/icons/icoInfo.png';
import icoSom from '../../../../assets/icons/icoSom.png';
import { tocarVozInfoLocal } from '../../../Audio';
import { IoIosVideocam } from "react-icons/io";

export default function Camera(props) {
    const IconEye = () => (
        <svg width="21" height="20" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 8.41177C2 8.41177 4.27751 4 8.26316 4C12.2488 4 14.5263 8.41177 14.5263 8.41177C14.5263 8.41177 12.2488 12.8235 8.26316 12.8235C4.27751 12.8235 2 8.41177 2 8.41177Z" stroke="#001E62" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.26315 10.1769C9.25145 10.1769 10.0526 9.38679 10.0526 8.41217C10.0526 7.43755 9.25145 6.64746 8.26315 6.64746C7.27485 6.64746 6.47368 7.43755 6.47368 8.41217C6.47368 9.38679 7.27485 10.1769 8.26315 10.1769Z" stroke="#001E62" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );

    const openPageCamera = () => {
        const url = `/camera/${props.id + '_anel-0' + props.numeroAnel}`;
        const win = window.open(url, '_blank');
        win.focus();
    }

    const [dados, setDados] = useState([]);
    const [info, setInfo] = useState('');
    const [visibleAlert, setVisibleAlert] = useState('none');
    const [visibleBalloon, setVisibleBalloon] = useState(false);
    const { idGlobal, setIdGlobal } = useContext(ModalContext);
    const { abaEquipamento, setAbaEquipamento } = useContext(ModalContext);
    const { anelGlobal, setAnelGlobal } = useContext(ModalContext);

    const lerDados = async () => {

        try {
            const response = await api.get(`/centraldefalhas/camera/${props.id + '_anel-0' + props.numeroAnel}`);
            setDados(response.data);
            setInfo('');
            if (dados == ['']) {
                setDados([{
                    canCapturaPorFalha: '',
                    canCapturaPorTempo: '',
                    consorcio: '',
                    descricao: '',
                    id_cruzamento: '',
                    instaladora: '',
                    ip: '',
                    mantenedora: '',
                    modelo: '',
                    refAnel: '',
                    senha: '',
                    tempoCaptura: '',
                    tempoCapturaPorFalha: '',
                    townhall: '',
                    usuario: '',
                }]);
                setInfo('Equipamento não cadastrado');
                setVisibleAlert('block');
            }
        } catch (error) {
            setDados([{
                canCapturaPorFalha: '',
                canCapturaPorTempo: '',
                consorcio: '',
                descricao: '',
                id_cruzamento: '',
                instaladora: '',
                ip: '',
                mantenedora: '',
                modelo: '',
                refAnel: '',
                senha: '',
                tempoCaptura: '',
                tempoCapturaPorFalha: '',
                townhall: '',
                usuario: '',
            }]);
            setInfo('Equipamento não cadastrado');
            setVisibleAlert('flex');
        }

    }

    const ContentPopBallonCamera = () => {
        return (
            <ContentBallon>
                <div className="title">
                    <img src={icoInfo} alt="" />
                    <h3>Câmera</h3>
                    <label onClick={tocarVozInfoLocal}><img src={icoSom} alt="" /></label><br />
                </div>
                <span>
                    Nesta opção estão listadas todas as informações concernentes as câmeras.
                    Os inputs abaixo demonstram os dados referentes aos responsáveis e descrição da câmera.
                    Para editar qualquer informação, clique no botão abaixo para editar os itens.
                </span>
                <div className="areaButton">
                    <button onClick={() => openPopUp()}>Entendi</button>
                </div>
            </ContentBallon>
        );
    }


    const openPopUp = () => {
        if (visibleBalloon) {
            setVisibleBalloon(false);
        } else {
            setVisibleBalloon(true);
        }
    }

    useEffect(() => {
        lerDados();
    }, []);

    useEffect(() => {
        lerDados();
    }, [props.status, props.numeroAnel]);


    //ver https://www.npmjs.com/package/axios-digest

    const openVideo = async (index) => {

        const params = { 'port': dados[index].port, 'ip': props.ipExterno };

        await api.post('api/cameras/video', params)
            .then(res => {
                window.open(`http://${dados[index].usuario}:${dados[index].senha}@${props.ipExterno}:${dados[index].port}/cgi-bin/mjpg/video.cgi?channel=1&subtype=1`, "", "width=650,height=650");
            })
            .catch(err => {
                alert('Erro ao solicitar cameras');
            });
    }

    return (
        <Container style={{ display: props.status }} visible={visibleAlert}>
            <TopContainer>
                <AreaButton>
                    <Button onClick={openPageCamera} className='openCamera'>
                        <IconEye />
                        <span>ABRIR CÂMERAS</span>
                    </Button>
                </AreaButton>
            </TopContainer>
            <Alert severity="error" className="alert">{info}</Alert>
            {dados.map((item, index) => (
                <>
                    <fieldset className="controlador" key={index}>
                        <legend>{item.descricao}</legend>
                        <span className="legendAnel">{item.refAnel}</span>
                        <fieldset>
                            <legend>Responsáveis</legend>
                            <AreaInputs>
                                <InfoHelp onClick={() => openPopUp()}>
                                    <GrCircleQuestion size={15} />
                                </InfoHelp>
                                <AreaBallon visible={visibleBalloon}>
                                    <BallonsInfoModal><ContentPopBallonCamera /></BallonsInfoModal>
                                </AreaBallon>
                                <div className='groupone'>
                                    <div>
                                        <label htmlFor="">Consórcio:</label>
                                        <input type="text" value={item.consorcio} disabled />
                                    </div>
                                    <div>
                                        <label htmlFor="">Instaladora:</label>
                                        <input type="text" value={item.instaladora} disabled />
                                    </div>
                                </div>
                                <div className='groupone'>
                                    <div className='group'>
                                        <label htmlFor="">Mantenedora:</label>
                                        <input type="text" value={item.mantenedora} disabled />
                                    </div>
                                    <div className='group button'>
                                        <Button onClick={() => openVideo(index)}>{/*onClick={props.openVideo(true)}*/}
                                            <IoIosVideocam size={20} color="#001E62" />
                                            <span>VIDEO AO VIVO</span>
                                        </Button>
                                    </div>
                                </div>
                            </AreaInputs>
                        </fieldset>
                        <fieldset>
                            <legend>Descrição da câmera</legend>
                            <AreaInputs>
                                <div className='groupone'>
                                    <div className='group'>
                                        <label htmlFor="">IP:</label>
                                        <input type="text" value={item.ip} disabled />
                                    </div>
                                    <div className='group'>
                                        <label htmlFor="">Modelo:</label>
                                        <input type="text" value={item.modelo} disabled />
                                    </div>
                                </div>
                                <div className='groupone'>
                                    <div className='group'>
                                        <label htmlFor="">Usuário:</label>
                                        <input type="text" value={item.usuario} disabled />
                                    </div>
                                    <div className='group'>
                                        <label htmlFor="">Senha:</label>
                                        <input type="text" value={item.senha} disabled />
                                    </div>
                                </div>
                            </AreaInputs>
                        </fieldset>
                    </fieldset>
                </>
            ))}
            <AreaButtons>
                <div className="btnEditInfo">
                    <img src={editInfo} alt="edit" onClick={() => {
                        props.openEquipamento(true); setIdGlobal(props.id); setAbaEquipamento(3); setAnelGlobal(props.numeroAnel);
                        props.closeModal(false)
                    }} title="Editar" />
                </div>
                <div className="btnEditInfo" style={{ display: 'none' }}> {/* oculto até criar logs manual de cameras*/}
                    <img src={IconbtnLogs} alt="edit" />
                </div>
            </AreaButtons>
        </Container>
    );
}